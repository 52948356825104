import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { WalletInfoItem } from '@libs/types';
import { WalletTypeEnum } from '@libs/backend-common';
import { CurrencyIcons, SelectData } from '@libs/components';

import { Asset, getWalletInfo } from 'src/gql';
import { useApiResponse, useAssets } from 'src/hooks';
import { getAlternativeAssetLabel } from 'src/helpers';
import { CurrencyType } from 'src/constants';

import { useCurrencyIcons } from './useCurrencyIcons';

export const mapCurrencyToOption = (
  item: Asset,
  icons: CurrencyIcons
): CurrencyOption => ({
  value: item.code,
  label:
    item.description || getAlternativeAssetLabel(item.code as CurrencyType),
  disabled: item.isDisabled,
  icon: icons[item.code as CurrencyType],
  isStable: item.isStable,
  isCrypto: item.isCrypto,
  roundingPrecision: item.roundingPrecision,
});

export type CurrencyOption = SelectData<string> & {
  isStable?: boolean;
  isCrypto?: boolean;
  roundingPrecision?: number;
};

type CurrencySelectOptions = {
  stableCurrencyOptions: SelectData<string>[];
  fiatOptions: SelectData<string>[];
  cryptoOptions: SelectData<string>[];
  enabledCryptoOptions: SelectData<string>[];
  cryptoWithNetwork: SelectData<string>[];
};
type UseCurrencyOptionsType = (
  assetsToMap?: Asset[],
  shouldFetchAssets?: boolean
) => CurrencySelectOptions;
export const useCurrencyOptions: UseCurrencyOptionsType = (
  assetsToMap,
  shouldFetchAssets = true
) => {
  const currencyIcons = useCurrencyIcons('3.5rem');
  const { assets } = useAssets(!shouldFetchAssets);

  const currencyOptions = useMemo(() => {
    const defaultOptions: CurrencySelectOptions = {
      stableCurrencyOptions: [],
      fiatOptions: [],
      cryptoOptions: [],
      enabledCryptoOptions: [],
      cryptoWithNetwork: [],
    };
    if (!assets.allAssets) return defaultOptions;

    return (assetsToMap || assets.allAssets).reduce((acc, item) => {
      const mappedItem = mapCurrencyToOption(item, currencyIcons);
      if (item.isCrypto) {
        acc.cryptoOptions.push(mappedItem);
        if (!item.isDisabled) {
          acc.enabledCryptoOptions.push(mappedItem);
          if (item.isStable) {
            acc.stableCurrencyOptions.push(mappedItem);
          }
          if (item.assetSupportedNetworks?.length) {
            acc.cryptoWithNetwork?.push(mappedItem);
          }
        }
      } else {
        acc.fiatOptions.push(mappedItem);
      }

      return acc;
    }, defaultOptions);
  }, [assets, currencyIcons, assetsToMap]);

  return currencyOptions;
};

export const mapCurrencyToOptionWithBalance = (
  item: WalletInfoItem,
  icons: CurrencyIcons
): CurrencyOptionWithBalance => ({
  value: item.code,
  label: item.code,
  disabled: item.isDisabled,
  icon: icons[item.code as CurrencyType],
  isStable: item.isStable,
  isCrypto: item.isCrypto,
  rate: item.value,
  price: item.price,
  amount: item.amount,
  name: item.name,
  isMemoRequired: item.isMemoRequired,
  roundingPrecision: item.roundingPrecision,
});

export type CurrencyOptionWithBalance = SelectData<CurrencyType> & {
  isStable?: boolean;
  isCrypto?: boolean;
  rate: number;
  price: string;
  amount: number;
  name: string;
  isMemoRequired?: boolean;
  roundingPrecision?: number;
};
type CurrencySelectOptionsWithBalance = {
  stableCurrencyOptions: CurrencyOptionWithBalance[];
  fiatOptions: CurrencyOptionWithBalance[];
  cryptoOptions: CurrencyOptionWithBalance[];
  enabledCryptoOptions: CurrencyOptionWithBalance[];
  cryptoWithNetwork: CurrencyOptionWithBalance[];
};
type UseCurrencyOptionsWithBalanceType = (
  assetsToMap?: WalletInfoItem[],
  walletInfoNoneZeroAssets?: boolean
) => CurrencySelectOptionsWithBalance;

export const useCurrencyOptionsWithBalance: UseCurrencyOptionsWithBalanceType =
  (assetsToMap, walletInfoNoneZeroAssets) => {
    const currencyIcons = useCurrencyIcons('3.5rem');
    const walletTypeCondition = walletInfoNoneZeroAssets
      ? WalletTypeEnum.NON_ZERO_ASSETS
      : WalletTypeEnum.ALL_ASSETS;

    const walletInfoRes = useQuery(getWalletInfo, {
      variables: { record: { walletType: walletTypeCondition } },
    });
    const { response } = useApiResponse(walletInfoRes);

    const currencyOptions = useMemo(() => {
      const defaultOptions: CurrencySelectOptionsWithBalance = {
        stableCurrencyOptions: [],
        fiatOptions: [],
        cryptoOptions: [],
        enabledCryptoOptions: [],
        cryptoWithNetwork: [],
      };
      if (!response?.getWalletInfo) return defaultOptions;
      return (assetsToMap || response.getWalletInfo).reduce((acc, item) => {
        const mappedItem = mapCurrencyToOptionWithBalance(item, currencyIcons);
        if (item.isCrypto) {
          acc.cryptoOptions.push(mappedItem);
          if (!item.isDisabled) {
            acc.enabledCryptoOptions.push(mappedItem);
            if (item.isStable) {
              acc.stableCurrencyOptions.push(mappedItem);
            }
            if (item.hasNetworks) {
              acc.cryptoWithNetwork?.push(mappedItem);
            }
          }
        } else {
          acc.fiatOptions.push(mappedItem);
        }

        return acc;
      }, defaultOptions);
    }, [response, assetsToMap, currencyIcons]);

    return currencyOptions;
  };
