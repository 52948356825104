import styled from 'styled-components';

export const ResultContainer = styled.div`
  width: 100%;
  margin: auto;
  max-width: 50rem;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.tablet`
    margin-top: 9.2rem;
  `}
`;

export const Form = styled.form`
  width: 100%;
  max-width: 40rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: 6.5rem;

  ${({ theme }) => theme.media.tablet`
    margin-bottom: 7.5rem;
  `}
`;

export const FAQWrapper = styled.div`
  margin-top: 4rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: 3.4rem;
  `};
`;
