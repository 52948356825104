import { FC, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import { TwoFaActions, TwoFactorAuthType } from '@libs/types';
import {
  sendEmailVerificationCodeMutation,
  sendSmsTwoFaMutation,
  toggle2FaArgs,
} from '@libs/graphql';
import { useApiResponse } from '@libs/hooks';

import { useTranslation } from 'src/hooks';

import { TwoFaAuthProps, TwoFaAuth } from '../two-fa-auth/TwoFaAuth';

import { EnterSms, EnterEmail } from './components';
import { EnterGoogle } from './components/EnterGoogle';

type EnterAuthProps = Omit<TwoFaAuthProps, 'type'> & {
  type: TwoFactorAuthType;
  address?: string;
  setAddress: (val: string) => void;
  setHasEnteredAddress: (val: boolean) => void;
  onFinish?: () => void;
  hasEnteredAddress: boolean;
  activateCodes: Omit<toggle2FaArgs, 'twoFactorCode'>;
  alreadyHasAddress?: boolean;
  action: TwoFaActions;
};

export const EnterAuth: FC<EnterAuthProps> = ({
  type,
  address,
  setAddress,
  setHasEnteredAddress,
  onFinish,
  hasEnteredAddress,
  activateCodes,
  action,
  alreadyHasAddress,
  ...props
}) => {
  const { t } = useTranslation();
  const [sendSms, sendSmsRes] = useMutation(sendSmsTwoFaMutation);

  const [sendEmail, sendEmailRes] = useMutation(
    sendEmailVerificationCodeMutation
  );

  const getArgs = (key: 'phone' | 'email', ignoreAddress?: boolean) => {
    const args = !ignoreAddress ? { [key]: address } : {};

    return {
      variables: {
        record: args,
      },
    };
  };

  const handleButtonClick = () => {
    if (type === TwoFactorAuthType.EMAIL) {
      sendEmail(getArgs('email'));
    } else if (type === TwoFactorAuthType.SMS) {
      sendSms(getArgs('phone'));
    }
  };

  useEffect(() => {
    if (alreadyHasAddress) {
      if (type === TwoFactorAuthType.EMAIL) {
        sendEmail(getArgs('email', true));
      } else if (type === TwoFactorAuthType.SMS) {
        sendSms(getArgs('phone', true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyHasAddress, sendEmail, sendSms, type]);

  const handleSucess = () => setHasEnteredAddress(true);

  const {
    loading: sendSmsLoading,
    errorMessage,
    clearErrorMessage: clearSmsErrorMsg,
  } = useApiResponse(sendSmsRes, handleSucess);

  const { loading: sendEmailLoading, errorMessage: sendEmailErrorMessage } =
    useApiResponse(sendEmailRes, handleSucess);

  if (action === TwoFaActions.DEACTIVATE) {
    return null;
  } else if (hasEnteredAddress) {
    return <TwoFaAuth type={type} onResend={handleButtonClick} {...props} />;
  } else if (type === TwoFactorAuthType.SMS) {
    return (
      <EnterSms
        address={address}
        setAddress={setAddress}
        handleButtonClick={handleButtonClick}
        loading={sendSmsLoading}
        errorMessage={t(errorMessage)}
        clearSmsErrorMsg={clearSmsErrorMsg}
      />
    );
  } else if (type === TwoFactorAuthType.EMAIL) {
    return (
      <EnterEmail
        address={address}
        setAddress={setAddress}
        handleButtonClick={handleButtonClick}
        loading={sendEmailLoading}
        errorMessage={t(sendEmailErrorMessage)}
      />
    );
  } else {
    return <EnterGoogle onFinish={onFinish} activateCodes={activateCodes} />;
  }
};
