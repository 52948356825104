import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const ScanIcon: React.FC<SvgIconDefaultProps> = ({ color, className }) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.65454 10.0834C2.24246 10.0834 1.91663 9.75758 1.91663 9.3455V7.13175C1.91663 4.53466 4.03454 2.41675 6.63163 2.41675H8.84537C9.25746 2.41675 9.58329 2.74258 9.58329 3.15466C9.58329 3.56675 9.25746 3.89258 8.84537 3.89258H6.63163C4.83954 3.89258 3.39246 5.34925 3.39246 7.13175V9.3455C3.39246 9.75758 3.05704 10.0834 2.65454 10.0834Z"
        fill={iconColor}
      />
      <path
        d="M20.3454 10.0834C19.9429 10.0834 19.6075 9.75758 19.6075 9.3455V7.13175C19.6075 5.33966 18.1508 3.89258 16.3683 3.89258H14.1545C13.7425 3.89258 13.4166 3.55716 13.4166 3.15466C13.4166 2.75216 13.7425 2.41675 14.1545 2.41675H16.3683C18.9654 2.41675 21.0833 4.53466 21.0833 7.13175V9.3455C21.0833 9.75758 20.7575 10.0834 20.3454 10.0834Z"
        fill={iconColor}
      />
      <path
        d="M16.3683 21.5833H15.0363C14.6338 21.5833 14.2983 21.2574 14.2983 20.8454C14.2983 20.4429 14.6242 20.1074 15.0363 20.1074H16.3683C18.1604 20.1074 19.6075 18.6508 19.6075 16.8683V15.5458C19.6075 15.1433 19.9333 14.8079 20.3454 14.8079C20.7479 14.8079 21.0833 15.1337 21.0833 15.5458V16.8683C21.0833 19.4654 18.9654 21.5833 16.3683 21.5833Z"
        fill={iconColor}
      />
      <path
        d="M8.84537 21.5834H6.63163C4.03454 21.5834 1.91663 19.4655 1.91663 16.8684V14.6547C1.91663 14.2426 2.24246 13.9167 2.65454 13.9167C3.06663 13.9167 3.39246 14.2426 3.39246 14.6547V16.8684C3.39246 18.6605 4.84913 20.1076 6.63163 20.1076H8.84537C9.24787 20.1076 9.58329 20.4334 9.58329 20.8455C9.58329 21.2576 9.25746 21.5834 8.84537 21.5834Z"
        fill={iconColor}
      />
      <path
        d="M17.6909 11.262H16.3875H6.61254H5.30921C4.89712 11.262 4.57129 11.5974 4.57129 11.9999C4.57129 12.4024 4.89712 12.7378 5.30921 12.7378H6.61254H16.3875H17.6909C18.103 12.7378 18.4288 12.4024 18.4288 11.9999C18.4288 11.5974 18.103 11.262 17.6909 11.262Z"
        fill={iconColor}
      />
      <path
        d="M6.61255 13.8592V14.1755C6.61255 15.7663 7.89672 17.0505 9.48755 17.0505H13.5125C15.1034 17.0505 16.3876 15.7663 16.3876 14.1755V13.8592C16.3876 13.7442 16.3013 13.658 16.1863 13.658H6.8138C6.6988 13.658 6.61255 13.7442 6.61255 13.8592Z"
        fill={iconColor}
      />
      <path
        d="M6.61255 10.1407V9.82446C6.61255 8.23363 7.89672 6.94946 9.48755 6.94946H13.5125C15.1034 6.94946 16.3876 8.23363 16.3876 9.82446V10.1407C16.3876 10.2557 16.3013 10.342 16.1863 10.342H6.8138C6.6988 10.342 6.61255 10.2557 6.61255 10.1407Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default ScanIcon;
