import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type UpdateCryptoAddressNameArgs = {
  id: number;
  name: string;
};

export type UpdateCryptoAddressNameResponse = {
  updateCryptoAddressName: DefaultApiResponse<boolean>;
};
export const updateCryptoAddressNameMutation: TypedDocumentNode<
  UpdateCryptoAddressNameResponse,
  DefaultArg<UpdateCryptoAddressNameArgs>
> = gql`
  mutation UpdateCryptoAddressName($record: UpdateCryptoAddressNameRecord!) {
    updateCryptoAddressName(record: $record)
  }
`;
