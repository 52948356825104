import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'src/hooks';

import RegisterForm from './components/RegisterForm';

import { CustomWebsitePreviewLayout } from './styles';

const RegisterPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const [hasBanner, setHasBanner] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const heading = hasBanner ? t('register_title') : '';

  const highlightedHeading = hasBanner ? t('register_title_highlighted') : '';

  return (
    <CustomWebsitePreviewLayout
      heading={heading}
      highlightedHeading={highlightedHeading}
      headerButtonTitle={t('register_login')}
      onHeaderButtonClick={handleLoginClick}
      hasBanner={hasBanner}
      hasSubmitted={hasSubmitted}
    >
      <RegisterForm
        setHasBanner={setHasBanner}
        hasSubmitted={hasSubmitted}
        setHasSubmitted={setHasSubmitted}
      />
    </CustomWebsitePreviewLayout>
  );
};

export default RegisterPage;
