import { useCallback } from 'react';

import { CardIcons, CardProviders } from 'src/constants';

export const useCardDetector = () => {
  const cardDetector = useCallback((cardMask: string) => {
    for (const key in CardProviders) {
      if (CardProviders[key].test(cardMask)) {
        return { cardProvider: key, cardIcon: CardIcons[key] };
      }
    }
    return { cardProvider: undefined, cardIcon: undefined };
  }, []);

  return { cardDetector };
};
