import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { EmptyState, SearchInput, Typography } from '@libs/components';
import { WalletInfoItem } from '@libs/types';
import { filterData } from '@libs/helpers';

import { useTranslation, useCurrencyIcons } from 'src/hooks';
import { AssetItem, DepositAddresses, FeatureRenderer } from 'src/components';
import { CurrencyType } from 'src/constants';
import { DesertedIcon } from 'src/assets';

import { FeatureModuleEnum } from '@libs/backend-common';
import {
  CustomAssetCont,
  CustomAssetItemCont,
  CustomAssetTitle,
  CustomSearchInputCont,
} from './style';

type AssetSelectionProps = {
  currentStep?: number;
  assets?: WalletInfoItem[];
  defaultAsset?: WalletInfoItem;
  nextStep: () => void;
  setActiveAsset?: (newAsset: WalletInfoItem) => void;
};
const DepositCrypto: React.FC<AssetSelectionProps> = ({
  defaultAsset,
  currentStep,
  assets,
  nextStep,
  setActiveAsset,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const currencyIcons = useCurrencyIcons('4rem');
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const filteredAsset = useMemo(() => {
    return filterData(assets, searchInputValue, ['name', 'code']);
  }, [searchInputValue, assets]);

  useEffect(() => {
    if (currentStep === 0 && defaultAsset) {
      nextStep();
    }
  }, [defaultAsset, nextStep, currentStep]);

  useEffect(() => {
    setSearchInputValue('');
  }, [currentStep]);

  const navigate = useNavigate();

  return (
    <FeatureRenderer
      acceptedFeature={[FeatureModuleEnum.CRYPTO_DEPOSIT]}
      shouldCheckKyc={false}
    >
      <CustomAssetCont data-testid="depositCrypto" data-cy="depositCrypto">
        {currentStep === 0 && (
          <>
            <CustomAssetTitle>
              <Typography
                type="subHeadline"
                fontFamily="primaryBold"
                color={colors.text.default}
              >
                {t('select_asset')}
              </Typography>
            </CustomAssetTitle>
            <CustomSearchInputCont>
              <SearchInput
                onChangeText={setSearchInputValue}
                value={searchInputValue}
                placeholder={t('common_search')}
                size="md"
              />
            </CustomSearchInputCont>
            {filteredAsset &&
              filteredAsset.map((i, index) => {
                const handleAssetSelect = () => {
                  setActiveAsset?.(i);
                  nextStep();
                  navigate(`/deposit?selectedCurrency=${i.code}`);
                };

                return (
                  <CustomAssetItemCont key={index}>
                    <AssetItem
                      testId={'asset-' + i.code}
                      icon={currencyIcons?.[i.code as CurrencyType]}
                      text={i.name}
                      description={i.code}
                      disabled={i.isDisabled}
                      disabledText={i.isDisabled ? t('coming_soon') : ''}
                      amount={i.amount}
                      rate={String(i.value)}
                      onClick={handleAssetSelect}
                    />
                  </CustomAssetItemCont>
                );
              })}
            {filteredAsset?.length === 0 && (
              <EmptyState
                img={DesertedIcon}
                title={t('error_asset_not_found')}
              />
            )}
          </>
        )}
        {currentStep === 1 && defaultAsset && (
          <DepositAddresses initialAsset={defaultAsset} />
        )}
      </CustomAssetCont>
    </FeatureRenderer>
  );
};
export default DepositCrypto;
