import { UserToken } from '@libs/hooks';
import { TwoFactorAuthType } from '../constants';

export const detectTwoFaTypeFromToken = (decodedToken: UserToken | null) => {
  if (decodedToken?.isSmsTwoFactorEnabled) {
    return TwoFactorAuthType.SMS;
  } else if (decodedToken?.isEmailTwoFactorEnabled) {
    return TwoFactorAuthType.EMAIL;
  } else if (decodedToken?.isTwoFactorEnabled) {
    return TwoFactorAuthType.GOOGLE;
  }
};

export const getTokenPayload = (decodedToken: UserToken | null) => {
  return {
    isSecondFactorAuthenticated: !!decodedToken?.isSecondFactorAuthenticated,
    isSmsSecondFactorAuthenticated:
      !!decodedToken?.isSmsSecondFactorAuthenticated,
    isEmailSecondFactorAuthenticated:
      !!decodedToken?.isEmailSecondFactorAuthenticated,
  };
};
