import { useCallback, useEffect, useMemo } from 'react';

import Lottie from 'lottie-react';

import {
  Button,
  CodeInputs,
  Typography,
  CloseIcon,
  AlertIcon,
  ErrorMessage,
} from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';
import { screenSizes } from '@libs/theme';
import { useAuth, useCounter, useDimensions } from '@libs/hooks';

import {
  authLoadingAnimation,
  emailSentAnimation,
  messageAnimation,
} from 'src/animations';
import { useTranslation } from 'src/hooks';

import {
  AnimationAndTextWrapper,
  LottieWrapper,
  InputsContainer,
  Container,
  CustomGoogleAuthIcon,
  Header,
  IconWrapper,
  DisaclaimerWrapper,
  LoadingLottieWrapper,
  Blur,
} from './styles';

export type TwoFaAuthProps = {
  type: TwoFactorAuthType;
  errorMessage?: string;
  title?: string;
  description?: string | JSX.Element;
  onComplete: (type: TwoFactorAuthType, code: string) => void;
  onResend?: () => void;
  loading?: boolean;
  className?: string;
  onCloseIconClick?: () => void;
  disclaimerText?: string;
  shouldHideAnimation?: boolean;
};

const TEST_IDS: Record<TwoFactorAuthType, string> = {
  [TwoFactorAuthType.GOOGLE]: 'googleTwoFa',
  [TwoFactorAuthType.EMAIL]: 'emailTwoFa',
  [TwoFactorAuthType.SMS]: 'smsTwoFa',
};

const ANIMATIONS: Record<TwoFactorAuthType, JSX.Element> = {
  [TwoFactorAuthType.EMAIL]: <Lottie animationData={emailSentAnimation} />,
  [TwoFactorAuthType.SMS]: <Lottie animationData={messageAnimation} />,
  [TwoFactorAuthType.GOOGLE]: <CustomGoogleAuthIcon />,
};

export const RESEND_CODE_TIME = 60;

export const TwoFaAuth: React.FC<TwoFaAuthProps> = ({
  description,
  type,
  onResend,
  onComplete,
  loading,
  className,
  title,
  onCloseIconClick,
  disclaimerText,
  errorMessage,
  shouldHideAnimation,
}) => {
  const { decodeToken } = useAuth();

  const onCodeInputFill = (code: string) => {
    onComplete?.(type, code);
  };

  const { countDown, counterInterval, isCounterRunning } =
    useCounter(RESEND_CODE_TIME);

  const { t } = useTranslation();

  const { width } = useDimensions();

  const handleResendCode = useCallback(() => {
    onResend?.();
    counterInterval();
  }, [counterInterval, onResend]);

  useEffect(() => {
    if (type === TwoFactorAuthType.GOOGLE) return;
    counterInterval();
  }, [counterInterval, type]);

  const titleOfButton =
    countDown > 0
      ? t('register_resend_code', {
          seconds: countDown,
        })
      : t('register_resend');

  const desc = useMemo(() => {
    if (description) return description;

    const token = decodeToken();

    if (type === TwoFactorAuthType.GOOGLE) {
      return t('two_fa_google_desc');
    } else if (type === TwoFactorAuthType.EMAIL) {
      return t('activate_2fa_enter_email', {
        email: token?.email || '',
      });
    } else
      return t('two_fa_sms_desc', {
        lastDigits: token?.phone || '',
      });
  }, [t, description, type, decodeToken]);

  const descriptionToRender =
    typeof desc === 'string' ? (
      <Typography
        type={width < screenSizes.laptop ? 'subHeadline' : 'headline'}
        fontFamily="primaryBold"
      >
        {desc}
      </Typography>
    ) : (
      desc
    );

  const discalimerToRender = disclaimerText && (
    <DisaclaimerWrapper>
      <AlertIcon />
      <Typography type="caption">{disclaimerText}</Typography>
    </DisaclaimerWrapper>
  );

  return (
    <>
      {loading && (
        <>
          <LoadingLottieWrapper>
            <Lottie animationData={authLoadingAnimation} />
          </LoadingLottieWrapper>
          <Blur />
        </>
      )}

      <Container className={className} data-testid={TEST_IDS[type]}>
        {title && (
          <Header>
            <Typography type="headline" fontFamily="primaryBold">
              {title}
            </Typography>
            <IconWrapper onClick={onCloseIconClick}>
              <CloseIcon />
            </IconWrapper>
          </Header>
        )}
        <div className="content">
          {discalimerToRender}
          <AnimationAndTextWrapper>
            {!shouldHideAnimation && (
              <LottieWrapper>{ANIMATIONS[type]}</LottieWrapper>
            )}
            {descriptionToRender}
          </AnimationAndTextWrapper>
          <InputsContainer hasErrorMessage={!!errorMessage}>
            <CodeInputs type={type} onComplete={onCodeInputFill} />
            <ErrorMessage
              errorMessage={errorMessage}
              errorTestId="two-fa-error"
            />
          </InputsContainer>

          {type !== TwoFactorAuthType.GOOGLE && (
            <Button
              disabled={isCounterRunning}
              onClick={handleResendCode}
              title={titleOfButton}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default TwoFaAuth;
