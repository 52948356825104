import styled from 'styled-components';

import { Button, Typography } from '@libs/components';

type ContainerProps = {
  imgUrl: string;
};

export const Container = styled.div<ContainerProps>`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-image: url(${({ imgUrl }) => imgUrl});
  background-repeat: no-repeat;
  padding: 2rem 3rem;
  margin: 2rem;
  overflow: hidden;
  position: relative;
  ${({ theme }) => theme.media.laptop`
        margin: 2rem 4rem 2rem 0;
    `}
`;

export const CustomButton = styled(Button)`
  height: 5rem;
`;
export const CustomTypography = styled(Typography)`
  display: block;
  margin-right: 4rem;
`;
export const Description = styled(Typography)`
  max-width: 15rem;
  ${({ theme }) => theme.media.laptop`
        max-width:unset;
    `}
`;
export const Wrapper = styled.div`
  margin: 1rem 0 2rem 0;
`;
export const Img = styled.img`
  right: -5rem;
  top: -7rem;
  position: absolute;
  height: 20rem;
`;
export const ImgSpace = styled.div`
  height: 5rem;
  width: 20rem;
  ${({ theme }) => theme.media.laptop`
    width: 13rem;
  `};
  ${({ theme }) => theme.media.laptopL`
    width: 20rem;
  `};
`;
export const ImgAndTitleWrapper = styled.div`
  display: flex;
`;
