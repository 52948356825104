import { Typography } from '@libs/components';
import { CopyIcon } from 'src/assets';
import { useCopy, useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import { AddressCopyContainer, ImgContainer } from './styles';

type AddressCopyProps = {
  text: string;
};

export const AddressCopy: React.FC<AddressCopyProps> = ({ text }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { isCounterRunning, handleCopy } = useCopy(text);
  return (
    <AddressCopyContainer isCopied={isCounterRunning} onClick={handleCopy}>
      <Typography
        type="bodyButton"
        fontFamily="primaryBold"
        color={isCounterRunning ? colors.text.interactive : colors.text.default}
      >
        {t(isCounterRunning ? 'common_copied' : 'common_copy')}
      </Typography>
      <ImgContainer>
        {isCounterRunning ? (
          <CopyIcon color={colors.surface.default} />
        ) : (
          <CopyIcon color={colors.text.default} />
        )}
      </ImgContainer>
    </AddressCopyContainer>
  );
};
