import styled from 'styled-components';

type ContainerProps = {
  shouldPopTop?: boolean;
};

export const PopUpContainer = styled.div<ContainerProps>`
  width: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.surface.default};
  ${({ shouldPopTop }) => (shouldPopTop ? 'top: 0;' : 'bottom: 0;')};
  z-index: ${({ theme }) => theme.zIndex.highest};
  box-shadow: 0px -1px 40px 0px #19191914;
`;
