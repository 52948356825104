import { SelectData } from '@libs/components';
import { CustomSelectModalOption } from './styles';

type TradePageRenderItemProps = {
  handleChange: () => void;
  selectedValue?: string | number;
} & SelectData<string | number>;

export const TradePageRenderItem = ({
  handleChange,
  selectedValue,
  ...data
}: TradePageRenderItemProps) => {
  if (data.isHidden) {
    return <></>;
  }

  const isSelected = selectedValue === data.value;

  return (
    <CustomSelectModalOption
      icon={data.icon}
      label={data.label}
      value={data.value}
      onClick={handleChange}
      isSelected={isSelected}
      disabled={!!data?.disabled}
      description={data?.description}
      testId={data.value}
      rigthItem={data.rightItem}
    />
  );
};

export default TradePageRenderItem;
