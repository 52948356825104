import styled from 'styled-components';

import { Copy, GeorgianFlagIcon, Input, Typography } from '@libs/components';

type HowToButtonProps = {
  isInfoOpened: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const LottieWrapper = styled.div`
  height: 10rem;
  width: 10rem;
`;

export const NumberInput = styled(Input)`
  flex: 1;
`;

export const TextAndInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  height: 6.2rem;
  div {
    height: 100%;
  }
`;

export const NumberPrefixWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  padding: 1rem 1.8rem;
`;

export const CustomGeorgiaIcon = styled(GeorgianFlagIcon)`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
`;

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  padding: 2rem 0;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};
`;

export const QrCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  width: 12rem;
  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  ${({ theme }) => theme.media.mobileM` 
      height: 15.2rem;
      width: 15.2rem;
  `}
`;

export const Qr = styled.img`
  height: 10rem;
  width: 10rem;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.mobileM`
     height: 13.6rem;
     width: 13.6rem;
  `}
`;

export const CopyButtonWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  width: min-content;
  padding: 0.8rem 1.2rem;
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;

export const CustomCopy = styled(Copy)`
  padding: 0.8rem 1.2rem;
`;

export const EnterGoogleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const ButtonAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.media.mobileM` 
      gap: 2.4rem;
  `}
`;

export const HowToButton = styled.button<HowToButtonProps>`
  padding: 1rem 0;
  position: relative;
  cursor: pointer;
  background-color: ${({ theme, isInfoOpened }) =>
    isInfoOpened
      ? theme.colors.interactive.secondary
      : theme.colors.surface.default};
  border: 0.1rem solid
    ${({ theme, isInfoOpened }) =>
      isInfoOpened
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highL};
`;

export const Line = styled.div`
  flex: 1;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  text-align: start;
`;
export const ErrorMessageWrapper = styled.div`
  height: 1.8rem;
`;
export const TutorialContainer = styled.div`
  position: absolute;

  bottom: 21rem;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  z-index: ${({ theme }) => theme.zIndex.highest};
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  width: 90%;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  max-width: 37rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  .modal-header {
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-bottom: none;
    border-radius: inherit;
  }

  ${({ theme }) => theme.media.laptop`
    max-width: 43rem;
    bottom: 22rem;
  `}

  &::after {
    content: '';
    width: 2.8rem;
    height: 2.8rem;
    position: absolute;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
    bottom: -0.5rem;
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  }
`;

export const StepTypography = styled(Typography)`
  display: block;
  margin-bottom: 0.5rem;
`;

export const StepsWrapper = styled.ul`
  border-top: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  padding: 1rem 2rem;
  text-align: start;
  list-style-type: disc;
`;

export const LinksAndQr = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${({ theme }) => theme.media.laptop` 
      margin: 1rem 0;
  `}
`;

export const QrWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.media.laptop`
      display: flex;
      svg {
        border-radius: ${
          theme.borderRadius.low as unknown as TemplateStringsArray
        }}
      }
      border-radius: ${
        theme.borderRadius.mediumHigh as unknown as TemplateStringsArray
      };
       background-color: ${
         theme.colors.interactive.brandColor as unknown as TemplateStringsArray
       };
       justify-content: center;
       align-items: center;
       height: 11rem;
       width: 11rem;

      background: linear-gradient(
        90deg,
        rgba(252, 188, 5, 0.2),
        rgba(25, 115, 232, 0.2),
        rgba(233, 67, 53, 0.2),
        rgba(52, 168, 83, 0.2)
    );
  `}
`;

export const QrCode = styled.img`
  display: none;
  ${({ theme }) => theme.media.laptop`
  display: block;
  height: 10rem;
  width: 10rem;
  border-radius: ${
    theme.borderRadius.medium as unknown as TemplateStringsArray
  };
  `};
`;

export const Li = styled.li`
  list-style-type: disc;
`;

export const LinksImgs = styled.div`
  display: flex;
  gap: 0.8rem;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.6rem;
  ${({ theme }) => theme.media.laptop` 
      flex-direction: column;
      margin-bottom: unset;
      gap: unset;
  `}
`;

export const Img = styled.img`
  max-width: 14rem;
  cursor: pointer;
`;
