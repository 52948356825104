import styled from 'styled-components';

type TogglerProps = {
  isContentReversed?: boolean;
};

export const Container = styled.div<TogglerProps>`
  display: none;
  margin-bottom: 2.2rem;
  ${({ theme, isContentReversed }) => {
    const borderColor = theme.colors.borders.default;
    const borderRadius = theme.borderRadius.mediumHigh;
    const flexDirection = isContentReversed ? 'row-reverse' : 'row';
    const justifyContent = isContentReversed ? 'space-between' : 'unset';

    return theme.media.tablet`
        display: flex;
        flex-direction: ${flexDirection as unknown as TemplateStringsArray};
        justify-content: ${justifyContent as unknown as TemplateStringsArray};
        gap: 4.8rem;
        width: 100%;
        border: 0.1rem solid ${borderColor as unknown as TemplateStringsArray};
        border-radius: ${borderRadius as unknown as TemplateStringsArray};
        padding: 2.4rem 1.6rem 3.2rem 1.6rem;
    `;
  }}
`;

export const ToggleContainer = styled.div`
  padding-top: 0;
  align-self: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  max-width: 55rem;
`;

export const MobileContainer = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  padding: 2rem 1.6rem;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
`;
