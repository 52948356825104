import styled from 'styled-components';

import TwoFaAuth from '../two-fa-auth/TwoFaAuth';

export const CustomTwoFaAuth = styled(TwoFaAuth)`
  .content {
    width: 100%;
    max-width: 51.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 2.2rem;
  }
`;
