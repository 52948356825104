import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TwoFactorAuthType } from '@libs/types';
import { getAssetUrl } from '@libs/helpers';

import { useTranslation } from 'src/hooks';
import { AuthPageLayout } from 'src/components';

import PasswordRecoveryForm from './components/PasswordRecoveryForm';

const PasswordRecoveryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [twoFaType, setTwoFaType] = useState<TwoFactorAuthType>();

  const [hasVerified, setHasVerified] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const headings = useMemo(() => {
    if (hasChanged) return null;

    let heading = 'password_recovery_heading';
    let highlightedHeading = 'password_recovery_highlighted_heading';

    if (twoFaType) {
      heading = 'password_recovery_check';
      highlightedHeading =
        twoFaType === TwoFactorAuthType.SMS
          ? 'common_phone'
          : 'common_shortened_mail';
    }

    return { heading, highlightedHeading };
  }, [twoFaType, hasChanged]);

  const shouldhideBanner = !!twoFaType || hasVerified;

  return (
    <AuthPageLayout
      heading={t(headings?.heading)}
      isHeadingCenter={!!twoFaType || hasVerified}
      isHeadingPrimary={false}
      banner={!shouldhideBanner ? getAssetUrl('account-recovery.png') : ''}
      highlightedHeading={t(headings?.highlightedHeading)}
      headerButtonTitle={t('login_register_text')}
      onHeaderButtonClick={handleRegisterClick}
      shouldHeaderStick={false}
    >
      <PasswordRecoveryForm
        twoFaType={twoFaType}
        setTwoFaType={setTwoFaType}
        hasVerified={hasVerified}
        setHasVerified={setHasVerified}
        setHasChanged={setHasChanged}
        hasChanged={hasChanged}
      />
    </AuthPageLayout>
  );
};

export default PasswordRecoveryPage;
