import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const PercentIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.secondary;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.51724 15.3103C1.30536 15.3103 1.09379 15.2295 0.93213 15.0679C0.608803 14.7448 0.608803 14.2207 0.93213 13.8976L13.8976 0.932089C14.221 0.608763 14.7445 0.608763 15.0679 0.932089C15.3912 1.25509 15.3912 1.77923 15.0679 2.10227L2.10235 15.0678C1.94069 15.2295 1.72913 15.3103 1.51724 15.3103ZM16 12.1379C16 10.0082 14.2676 8.27586 12.1379 8.27586C10.0083 8.27586 8.27586 10.0082 8.27586 12.1379C8.27586 14.2675 10.0083 16 12.1379 16C14.2676 16 16 14.2675 16 12.1379ZM14.3449 12.1379C14.3449 13.3548 13.3548 14.3448 12.138 14.3448C10.9211 14.3448 9.93108 13.3548 9.93108 12.1379C9.93108 10.9211 10.9211 9.93108 12.138 9.93108C13.3548 9.93108 14.3449 10.9211 14.3449 12.1379ZM7.72414 3.86207C7.72414 1.73241 5.99172 0 3.86207 0C1.73241 0 0 1.73241 0 3.86207C0 5.99172 1.73241 7.72414 3.86207 7.72414C5.99172 7.72414 7.72414 5.99172 7.72414 3.86207ZM6.06897 3.86207C6.06897 5.0789 5.0789 6.06897 3.86207 6.06897C2.64523 6.06897 1.65517 5.0789 1.65517 3.86207C1.65517 2.64523 2.64523 1.65517 3.86207 1.65517C5.0789 1.65517 6.06897 2.64527 6.06897 3.86207Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default PercentIcon;
