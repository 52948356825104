import { useMemo } from 'react';

import { Typography } from '@libs/components';
import { colors } from '@libs/theme';
import { RegexValidators } from '@libs/validations';

import { useTranslation } from 'src/hooks';

import {
  Container,
  IconTextWrapper,
  Tile,
  TileContainer,
  CustomToolTip,
  CustomIcon,
  StrengthInfoContainer,
  Line,
  TextContainer,
  CustomDiv,
} from './styles';

type PasswordStrengthIndicatorProps = {
  password: string;
};

type TyleProps = {
  text: string;
  color: string;
  desc: string;
};

const TYLE_COLOR_AND_TEXT: TyleProps[] = [
  {
    color: colors.text.critical,
    text: 'register_weak_password',
    desc: 'register_weak_password_desc',
  },
  {
    color: colors.text.pending,
    text: 'register_medium_password',
    desc: 'register_medium_password_desc',
  },
  {
    color: colors.text.success,
    text: 'register_strong_password',
    desc: 'register_strong_password_desc',
  },
];

export const PasswordStrengthIndicator: React.FC<
  PasswordStrengthIndicatorProps
> = ({ password }) => {
  const { t } = useTranslation();

  const validationsPassed = useMemo(() => {
    let passedAmount = 0;

    if (password.length >= 8) passedAmount++;
    if (RegexValidators.HAS_DIGIT.test(password)) passedAmount++;
    if (
      RegexValidators.HAS_DIGIT.test(password) &&
      RegexValidators.HAS_UPPER_CASE_LETTERS.test(password)
    )
      passedAmount++;

    return passedAmount;
  }, [password]);

  const textAndColor = TYLE_COLOR_AND_TEXT?.[validationsPassed - 1];

  return (
    <Container>
      <TileContainer>
        {[...Array(3)].map((_, index) => {
          const color = index < validationsPassed ? textAndColor?.color : '';

          return <Tile key={index} color={color} />;
        })}
      </TileContainer>
      <IconTextWrapper>
        <Typography type="caption" color={textAndColor?.color}>
          {t(textAndColor?.text)}
        </Typography>
        <CustomToolTip icon={<CustomIcon color={colors.text.secondary} />}>
          <StrengthInfoContainer>
            {TYLE_COLOR_AND_TEXT.map((info, index) => {
              return (
                <CustomDiv key={index}>
                  {!!index && <Line />}
                  <TextContainer>
                    <Typography color={info.color} type="bodyButton">
                      {t(info.text)}
                    </Typography>
                    <Typography type="bodyButton">{t(info.desc)}</Typography>
                  </TextContainer>
                </CustomDiv>
              );
            })}
          </StrengthInfoContainer>
        </CustomToolTip>
      </IconTextWrapper>
    </Container>
  );
};

export default PasswordStrengthIndicator;
