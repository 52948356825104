import { getSwapTaskQuery } from '@libs/graphql';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  RateTypes,
  SwapStatusEnumKeys,
  SwapTaskStatusEnum,
  SWAP_INFO_POOL_INTERVAL,
} from '@libs/types';
import { swapClient } from 'src/setup';
import {
  clearDateUnit,
  formatDate,
  getDate,
  getDateDifferenceInSeconds,
  useApiResponse,
} from '@libs/hooks';
import { SwapPairStatus } from '@libs/components';
import { useTranslation } from 'src/hooks';
import { SWAP_BLOCK_DATE_FORMAT } from '@libs/components';
import { hasAnyFieldEmpty } from '@libs/helpers';

export const useSwapTaskDetails = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const swapTaskId = searchParams.get('id');
  useEffect(() => {
    if (!swapTaskId) {
      navigate('/swap', {
        replace: true,
      });
    }
  }, [searchParams, navigate, swapTaskId]);

  const res = useQuery(getSwapTaskQuery, {
    variables: {
      record: { uuid: swapTaskId }, // Pass swapId as uuid
    },
    pollInterval: SWAP_INFO_POOL_INTERVAL,
    client: swapClient,
    fetchPolicy: 'network-only',
  });

  const { response: swapTaskRes, loading } = useApiResponse(res);

  useEffect(() => {
    if (
      swapTaskRes &&
      ![SwapTaskStatusEnum.CANCELED, SwapTaskStatusEnum.COMPLETED].includes(
        swapTaskRes.getSwapTask.status
      )
    ) {
      if (res.error) {
        navigate('/');
      } else {
        res.startPolling(SWAP_INFO_POOL_INTERVAL);
      }
    } else {
      res.stopPolling();
    }
  }, [navigate, res, swapTaskRes]);

  const swapPairStatus = useMemo(() => {
    const dateDifference = getDateDifferenceInSeconds(
      clearDateUnit(getDate(swapTaskRes?.getSwapTask?.expiresAt)),
      clearDateUnit()
    );

    if (!swapTaskRes || dateDifference > 0) return SwapPairStatus.PENDING;

    const isFinished = !swapTaskRes.getSwapTask.swaps.find(
      (item) =>
        ![
          SwapStatusEnumKeys.DEPOSIT_FAILED,
          SwapStatusEnumKeys.EXCHANGE_FAILED,
          SwapStatusEnumKeys.WITHDRAW_FAILED,
          SwapStatusEnumKeys.WITHDRAW_COMPLETED,
        ].includes(item.status)
    );

    if (
      swapTaskRes.getSwapTask.swaps.length &&
      isFinished &&
      dateDifference <= 0
    ) {
      return SwapPairStatus.FINISHED;
    }

    if (swapTaskRes.getSwapTask.swaps.length && dateDifference <= 0) {
      return SwapPairStatus.EXPIRED_BUT_WAITING;
    }

    return SwapPairStatus.EXPIRED;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapTaskRes, res]);

  const expiredButWaitingTitle = useMemo(
    () =>
      swapTaskRes?.getSwapTask.swaps.filter((item) =>
        [
          SwapStatusEnumKeys.DEPOSIT_PENDING,
          SwapStatusEnumKeys.WITHDRAW_IN_PROGRESS,
          SwapStatusEnumKeys.EXCHANGE_IN_PROGRESS,
        ].includes(item.status)
      ).length
        ? t('swap_trade_ongoing_swaps_title')
        : t('swap_trade_ongoing_swap_title'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swapTaskRes?.getSwapTask.swaps, t, res]
  );

  const amount = useMemo(() => {
    if (hasAnyFieldEmpty(swapTaskRes?.getSwapTask as Record<string, unknown>))
      return '';
    let rateFieldText =
      swapTaskRes?.getSwapTask?.originalNeededAmount +
      ' ' +
      swapTaskRes?.getSwapTask?.sourceAsset.code;

    rateFieldText +=
      swapTaskRes?.getSwapTask?.rateType === RateTypes.FIXED ? '=' : '≈';
    rateFieldText +=
      ' ' +
      swapTaskRes?.getSwapTask?.originalReceivedAmount +
      ' ' +
      swapTaskRes?.getSwapTask?.destinationAsset.code;

    return rateFieldText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapTaskRes?.getSwapTask, res]);

  const operationDetailsInfo = [
    {
      title: t('swap_details_recipient_address'),
      desc: swapTaskRes?.getSwapTask?.destinationAddress,
      shouldCopyButtonRender: true,
      shouldExportButtonRender: true,
    },
    {
      title: t('swap_details_deposit_address'),
      desc: swapTaskRes?.getSwapTask?.accountCopy.address,
      shouldCopyButtonRender: true,
      shouldExportButtonRender: true,
    },
    {
      title: t('trade_rate'),
      desc: amount,
    },
    {
      title: t('swap_info_date'),
      desc: formatDate(
        SWAP_BLOCK_DATE_FORMAT,
        getDate(`${swapTaskRes?.getSwapTask?.expiresAt}`)
      ),
    },
  ];

  const additionalInformation = [
    {
      title: t('swap_info_refund_address'),
      desc: swapTaskRes?.getSwapTask?.refundAddress || '--',
      shouldCopyButtonRender: !!swapTaskRes?.getSwapTask?.refundAddress,
    },
    {
      title: t('swap_info_email'),
      desc: swapTaskRes?.getSwapTask?.email || '--',
    },
  ];

  const handleTimeOut = useCallback(() => {
    res.refetch();
  }, [res]);

  return {
    swapTask: swapTaskRes?.getSwapTask,
    swapPairStatus,
    additionalInformation,
    operationDetailsInfo,
    expiredButWaitingTitle,
    totals: swapTaskRes?.getSwapTask.totals,
    handleTimeOut,
    loading,

    rateType: swapTaskRes?.getSwapTask.rateType,
  };
};
