import { Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';

import {
  AddressContianer,
  AddressDetailsContainerWrapper,
  AddressDetailsWrapper,
  CustomInfoCircleIcon,
  DepositInfoCont,
} from '../style';

import { AddressDetail } from './AddressDetail';

type AddressDetailsProps = {
  address: string;
  bnbMemoAddress?: string;
  isBnbMemo?: boolean;
};

export const AddressDetails: React.FC<AddressDetailsProps> = ({
  address,
  isBnbMemo = false,
  bnbMemoAddress,
}) => {
  const { t } = useTranslation();
  const warrning_text = isBnbMemo
    ? t('deposit_memo_info_warning')
    : t('deposit_info_warning');

  return (
    <AddressDetailsContainerWrapper>
      <AddressContianer>
        <AddressDetailsWrapper>
          <AddressDetail
            address={address}
            addressTitle={t('wallet_address')}
            scanQrTitle={t('scan_qr')}
            isBnbMemo={isBnbMemo}
            bottomBorderShouldhave={isBnbMemo}
          />
          {isBnbMemo && bnbMemoAddress && (
            <AddressDetail
              address={bnbMemoAddress}
              addressTitle={t('common_memo')}
              isBnbMemo={isBnbMemo}
            />
          )}
        </AddressDetailsWrapper>

        <DepositInfoCont>
          <CustomInfoCircleIcon />
          <Typography type="caption" fontFamily="primaryMedium">
            {warrning_text}
          </Typography>
        </DepositInfoCont>
      </AddressContianer>
    </AddressDetailsContainerWrapper>
  );
};
