import styled from 'styled-components';

import { Tabs, Typography } from '@libs/components';

import { CurrencySwitcher } from 'src/components';

type IconWrapperProps = {
  isDroppedDown: boolean;
};

export const ContentWrapper = styled.div`
  position: relative;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.surface.gradient};
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.high}
    ${({ theme }) => theme.borderRadius.high};
  padding-bottom: 8rem;
  ${({ theme }) => theme.media.laptop`
    padding-bottom:1.6rem;
    border-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };
  `}
`;
export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1.7rem 2rem;
  ${({ theme }) => theme.media.laptop`
    padding: 0 2.4rem 2rem;
    `};
`;
export const BalanceContainer = styled.div`
  width: 100%;
  min-height: 20rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  box-shadow: ${({ theme }) => theme.shadows.xl3};

  ${({ theme }) => theme.media.laptop`
    box-shadow: 0px 135px 54px rgba(0, 0, 0, 0.01),
    0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09),
    0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, w0, 0.1);
  
    display: flex;
    justify-content: space-between;
    height: 31rem;
   
    max-height: 31rem;
  `};
`;

export const Content = styled.div`
  margin-top: 2.9rem;
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: 1.7rem;
  `}
`;

export const AllBalanceTypography = styled(Typography)`
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.4rem;
  ${({ theme }) => theme.media.laptop`
    font-size: 3.6rem;
    line-height: 4.4rem;
  `}
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

export const AllBalance = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.4rem;
`;

export const CustomCurrencySwitcher = styled(CurrencySwitcher)`
  max-width: 19.2rem;
  width: 19.2rem;
  margin: 1.2rem 0;
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  .activeCurrencyUnderline {
    border-radius: ${({ theme }) => theme.borderRadius.highM};
  }

  ${({ theme }) => theme.media.laptop`
    align-self: baseline;
    max-width: 14rem;
    width: 14rem;
    margin-bottom: 2rem;
    border-radius: ${
      theme.borderRadius.medium as unknown as TemplateStringsArray
    };
    margin-top:unset;
    order: -1;
    .activeCurrencyUnderline {
      border-radius: ${
        theme.borderRadius.medium as unknown as TemplateStringsArray
      };
      
  `}
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  transform: ${({ isDroppedDown }) => (isDroppedDown ? '' : 'rotate(180deg)')};
  cursor: pointer;
  ${({ theme }) => theme.media.laptop`
      .svg {
        height: 3.2rem;
        width: 3.2rem;
      }
  `}
`;

export const BalanceDivision = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  overflow: hidden;
  width: 100%;
  max-width: 35rem;

  padding-top: ${({ isDroppedDown }) => (isDroppedDown ? '0.2rem' : '0')};

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  display: ${({ isDroppedDown }) => (isDroppedDown ? 'flex' : 'none')};
  margin: 0 auto;
`;

export const CryptoBalanceWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
  ${({ theme }) => theme.media.laptop`
    align-items: center;
  `}
`;

export const CryptoBalance = styled.div`
  margin-top: 0.3rem;
`;

export const Line = styled.div`
  width: 0.1rem;
  height: 5.2rem;

  margin: 0 2.4rem;

  background-color: ${({ theme }) => theme.colors.borders.default};

  ${({ theme }) => theme.media.laptop`
    margin: 0;
  `}
`;

export const FiatBalanceWrapper = styled.div`
  width: 50%;
  padding: 1.4rem 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  ${({ theme }) => theme.media.laptop`
    align-items: center;
  `}
`;

export const FiatBalance = styled.div`
  margin-top: 0.3rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ChangesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartWrapper = styled.div`
  margin-top: 1rem;
  height: 15rem;
  ${({ theme }) => theme.media.laptop`
      height: 20rem;
  `}
`;
export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.laptop`
    width: 60%;
    justify-content:space-evenly;
    margin-top: unset;
    flex-direction:column-reverse;
`}
`;
export const FinancialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 29rem;
`;
export const CustomTabs = styled(Tabs)`
  height: 4rem;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.laptop`
      height: 3.8rem;
  `}
  .tab-highlighter {
    height: 3.4rem;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    ${({ theme }) => theme.media.laptop`
        height: 3.2rem;
    `}
  }
`;
export const TabsWrapper = styled.div<{ shouldStrecth: boolean }>`
  padding: 1.6rem 1.2rem;
  ${({ theme, shouldStrecth }) => {
    const width = (shouldStrecth
      ? 'calc(100% - 7rem)'
      : '16.5rem') as unknown as TemplateStringsArray;

    return theme.media.laptop`
        width: ${width};
        margin-right: 3rem;
        align-self: end;
        padding: unset;
`;
  }}
`;
export const NavigationItemsContainer = styled.div`
  bottom: -3.5rem;
  position: absolute;
  width: calc(100% - 3.2rem);
  padding: 1.6rem 3rem 1.4rem 3rem;
  display: flex;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  ${({ theme }) => theme.media.laptop`
      display: none;
  `};
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;
export const NavigationItemIconWrapper = styled.div`
  width: 6rem;
  height: 4.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    `${theme.colors.interactive.brandColor}1A`};
  border-radius: 2.8rem;
`;
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CurrencySwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.media.laptop`
      justify-content: flex-start;
  `};
`;
