import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type verifayEmailArgs = {
  token: string;
};

export type VerifyEmailResponse = {
  verifyEmail: DefaultApiResponse<{ accessToken: string }>;
}; // NOTE: always wrap with DefaultApiResponse;

export const verifyEmailMutation: TypedDocumentNode<
  VerifyEmailResponse,
  DefaultArg<verifayEmailArgs>
> = gql`
  mutation VerifyEmail($record: VerifyEmailRecord!) {
    verifyEmail(record: $record) {
      accessToken
    }
  }
`;
