import { PublicRoute as NativePublicRoute } from '@libs/components';
import { useAuth } from '@libs/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOKEN_KEY } from 'src/constants';
import { useLanguageAndThemeFromURL } from 'src/hooks';

type PublicRouteProps = {
  component: React.ReactNode;
};
const PublicRoute: React.FC<PublicRouteProps> = (props) => {
  const { checkTokenValidity } = useAuth();

  useLanguageAndThemeFromURL();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      const isTokenValid = checkTokenValidity(token);
      if (isTokenValid) {
        navigate('/', {
          replace: true,
        });
      }
    }
  }, [checkTokenValidity, navigate]);

  return <NativePublicRoute tokenKey={TOKEN_KEY} {...props} />;
};

export default PublicRoute;
