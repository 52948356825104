import { TypedDocumentNode, gql } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type getPlatformUnderConstructionStatusResponse = {
  getPlatformUnderConstructionStatus: DefaultApiResponse<boolean>;
};

export const getPlatformUnderConstructionStatusQuery: TypedDocumentNode<
  getPlatformUnderConstructionStatusResponse,
  DefaultArg
> = gql`
  query getPlatformUnderConstructionStatus {
    getPlatformUnderConstructionStatus
  }
`;
