import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql/dist';

export type UpdateUserAvatarArgs = {
  avatarUrl: string;
};

export type updateUserAvatarResponse = {
  updateUserAvatar: DefaultApiResponse<boolean>;
};

export const updateUserAvatarMutation: TypedDocumentNode<
  updateUserAvatarResponse,
  DefaultArg<UpdateUserAvatarArgs>
> = gql`
  mutation UpdateUserAvatar($record: UpdateUserAvatarRecord!) {
    updateUserAvatar(record: $record)
  }
`;
