import { useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import Lottie from 'lottie-react';

import { Typography } from '@libs/components';

import { newPageAnimation } from 'src/animations';
import { DashboardNavItemType } from 'src/constants';
import {
  IconWrapper,
  LottieWrapper,
  NavItem,
  CustomDropDownIcon,
} from './styles';

type DashboardNavItemProps = {
  item: DashboardNavItemType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  shouldRenderArrowIcon?: boolean;
};

const DashboardNavItem: React.FC<DashboardNavItemProps> = ({
  item,
  onClick,
  className,
  shouldRenderArrowIcon,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <NavItem
      to={item.route}
      onClick={handleClick}
      data-cy={item.title}
      $isNew={item.isNew}
      className={className}
    >
      {({ isActive }) => {
        const color = isActive
          ? colors.interactive.brandColor
          : colors.text.secondary;
        const font = isActive ? 'primaryBold' : 'primaryMedium';
        const icon = isActive ? item.activeIcon : item.icon;

        if (item.isNew) {
          return (
            <>
              <IconWrapper data-cy={`${item.title}-icon`}>{icon}</IconWrapper>
              <Typography
                testId={`${item.title}-text`}
                font-family={font}
                type="subHeadline"
                color={color}
              >
                {t(item.title)}
              </Typography>
              <LottieWrapper>
                <Lottie loop={true} animationData={newPageAnimation} />
              </LottieWrapper>
            </>
          );
        }

        if (shouldRenderArrowIcon) {
          return (
            <>
              <IconWrapper data-cy={`${item.title}-icon`}>{icon}</IconWrapper>

              <Typography
                testId={`${item.title}-text`}
                font-family={font}
                type="subHeadline"
                color={color}
              >
                {t(item.title)}
              </Typography>
              <CustomDropDownIcon />
            </>
          );
        }

        return (
          <>
            <IconWrapper data-cy={`${item.title}-icon`}>{icon}</IconWrapper>

            <Typography
              testId={`${item.title}-text`}
              font-family={font}
              type="subHeadline"
              color={color}
            >
              {t(item.title)}
            </Typography>
          </>
        );
      }}
    </NavItem>
  );
};

export default DashboardNavItem;
