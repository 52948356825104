import styled from 'styled-components';

export const Container = styled.div`
  height: 8.4rem;
  padding: 1.1rem 1.5rem 1.2rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;
export const TextWrapper = styled.div`
  gap: 5.1rem;
  margin-bottom: 1.9rem;
  display: flex;
  justify-content: space-between;
`;
