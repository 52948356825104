import { useQuery } from '@apollo/client';
import { WalletTypeEnum } from '@libs/backend-common';
import {
  useApiResponse,
  useWalletAssets as useNativeWalletAssets,
} from '@libs/hooks';
import { getWalletInfo } from 'src/gql';

export const useWalletAssets = (isNoneZeroAsset?: boolean) => {
  const { data, loading } = useQuery(getWalletInfo, {
    variables: {
      record: {
        walletType: isNoneZeroAsset
          ? WalletTypeEnum.NON_ZERO_ASSETS
          : WalletTypeEnum.ALL_ASSETS,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    response,
    errors,
    loading: assetsLoading,
  } = useApiResponse({ data, loading });

  return useNativeWalletAssets(errors, assetsLoading, response?.getWalletInfo);
};
