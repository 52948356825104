import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const ViewAllArrowIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();
  const colorToApply = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.11999 12.0466C9.11999 11.92 9.16666 11.7933 9.26666 11.6933L12.96 7.99996L9.26666 4.30662C9.07333 4.11329 9.07333 3.79329 9.26666 3.59996C9.45999 3.40662 9.77999 3.40662 9.97333 3.59996L14.02 7.64662C14.2133 7.83996 14.2133 8.15996 14.02 8.35329L9.97333 12.4C9.77999 12.5933 9.45999 12.5933 9.26666 12.4C9.16666 12.3066 9.11999 12.1733 9.11999 12.0466Z"
        fill={colorToApply}
      />
      <path
        d="M1.83333 8C1.83333 7.72667 2.06 7.5 2.33333 7.5L13.5533 7.5C13.8267 7.5 14.0533 7.72667 14.0533 8C14.0533 8.27333 13.8267 8.5 13.5533 8.5L2.33333 8.5C2.06 8.5 1.83333 8.27333 1.83333 8Z"
        fill={colorToApply}
      />
    </CustomSvg>
  );
};
export default ViewAllArrowIcon;
