import { FeatureModuleEnum, JurisdictionsEnum } from '@libs/backend-common';

import { useEnabledFeatures, useJurisdictionDetector } from '.';

export const usePageNameDetector = () => {
  const { checkFeature } = useEnabledFeatures();

  const { currentJurisdiction } = useJurisdictionDetector();
  const isUaJurisdiction = currentJurisdiction === JurisdictionsEnum.UA;

  const PAGENAMES = [
    {
      name: isUaJurisdiction ? 'swap_colored_title' : 'common_dashboard',
      path: '/',
    },
    { name: 'dashboard_buy', path: '/trade/buy' },
    { name: 'dashboard_sell', path: '/trade/sell' },
    { name: 'dashboard_deposit', path: '/deposit' },
    { name: 'dashboard_convert', path: '/convert/BUY' },
    { name: 'dashboard_convert', path: '/convert/SELL' },
    { name: 'dashboard_withdraw', path: '/withdraw' },
    { name: 'account_info_title', path: '/account-information/personal' },
    { name: 'account_info_title', path: '/account-information/security' },
    { name: 'account_info_title', path: '/account-information/address-book' },
    {
      name: 'account_info_title',
      path: '/account-information/notifications',
    },
    { name: 'common_referrals', path: '/referrals' },
    { name: 'common_wallet', path: '/wallet' },
    { name: 'common_transactions', path: '/transactions' },

    { name: 'common_crexswap', path: '/swap-task-details' },
    { name: 'common_crexswap', path: '/swap-details' },
    { name: 'common_fees', path: '/dashboard-fees/deposit' },
    { name: 'common_fees', path: '/dashboard-fees/withdraw-methods' },
    { name: 'common_fees', path: '/dashboard-fees/withdraw-crypto-fee' },
    { name: 'common_fees', path: '/dashboard-fees/convert-fee' },
    { name: 'common_help', path: '/support-info/general' },
    { name: 'common_help', path: '/support-info/deposit-methods' },
    { name: 'common_help', path: '/support-info/withdraw-methods' },
  ];

  const isStakingAvailable = checkFeature(FeatureModuleEnum.STAKING);

  if (isStakingAvailable.isAvailable) {
    PAGENAMES.push({ name: 'common_staking', path: '/staking' });
  }
  const pathname = window.location.pathname;

  const page = PAGENAMES.find((e) => e.path === pathname);

  return page ? page.name : '';
};
