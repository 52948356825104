import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';

export type GetOngoingSwapsCountResponse = {
  getOngoingSwapsCount: DefaultApiResponse<number>;
};
export const getOngoingSwapsQuery: TypedDocumentNode<GetOngoingSwapsCountResponse> = gql`
  query GetSwap {
    getOngoingSwapsCount
  }
`;
