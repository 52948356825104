import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
* {
  -webkit-tap-highlight-color: transparent;
}
body {
  height:100%;

  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:${({ theme }) => theme.colors.surface.interactiveDefault};
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: -webkit-fill-available;
}

::-webkit-scrollbar {
  width: 0.7rem;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(176, 176, 170, 0.6);
  border-radius: 1rem;
  height: 0.2rem;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(176, 176, 170, 0.8);
}

::-webkit-scrollbar-track {
  border-radius: 0.3rem;
  background: transparent;
}
::-webkit-scrollbar-track:hover {
   background: rgba(189, 189, 189, 0.1);
 }
 input::-webkit-search-decoration,
  input::-webkit-search-cancel-button {
    display: none;
  }
  
 `;
