import styled from 'styled-components';
import { MaxContentWrapper } from '@libs/components';
import { ResultFeedback } from 'src/components';

export const Container = styled(MaxContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 3.2rem;
  max-width: 40rem;
  text-align: center;
  height: 100%;
`;

export const CustomResult = styled(ResultFeedback)`
  padding: 0;
`;
