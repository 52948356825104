import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DefaultApiResponse,
  DefaultArg,
  swapFragment,
  swapTaskFragment,
} from '@libs/graphql';
import { Swap } from '@libs/types';

type GetSwapResponse = {
  getSwap: Swap;
};

type GetSwapArgsType = {
  id?: number;
};

export const getSwapQuery: TypedDocumentNode<
  DefaultApiResponse<GetSwapResponse>,
  DefaultArg<GetSwapArgsType>
> = gql`
  query GetSwap($record: IdRecord!) {
    getSwap(record: $record) {
      ...swapFields
      exchange {
        originalAmount
      }
      swapTask {
        ...swapTaskFields
      }

      deposit {
        amount
      }
      withdrawal {
        amount
      }
    }
  }
  ${swapTaskFragment}
  ${swapFragment}
`;
