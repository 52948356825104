import { GetWithdrawalFeesType } from 'src/gql';

export const getRoundedFee = (fee: number) => Math.ceil(fee * 100) / 100;

export const getWithdrawalAmountWithFee = (amount: number, fee: number) =>
  Math.floor((amount - fee) * 100) / 100;

export const sortWithdrawalFees = (fees?: GetWithdrawalFeesType[]) => {
  if (!fees || fees.length <= 0) return [];

  const feesArray = [...fees];
  const sortedFees = feesArray.sort(
    (a: GetWithdrawalFeesType, b: GetWithdrawalFeesType) =>
      b.greaterThan - a.greaterThan
  );

  return sortedFees;
};
