import { gql, TypedDocumentNode } from '@apollo/client';

import {
  DefaultArg,
  DefaultApiResponse,
  transactionDetailsFragment,
} from '@libs/graphql';
import {
  Transaction,
  TransactionStatusType,
  ExtendedTransactionDetails,
} from '@libs/types';

import { CurrencyType, TransactionType } from 'src/constants';

export type GetRecentTransactionsRes = {
  getRecentTransactions: DefaultApiResponse<Transaction[]>;
};

export type GetRecentTransactionsArgs = {
  skip?: number;
  limit?: number;
  assetCodes?: CurrencyType[];
  types?: TransactionType[];
  statuses?: TransactionStatusType[];
  minDate?: string;
  maxDate?: string;
};

export const getRecentTransactionsQuery: TypedDocumentNode<
  GetRecentTransactionsRes,
  DefaultArg<GetRecentTransactionsArgs>
> = gql`
  query getTransactions($record: GetRecentTransactionsRecord!) {
    getRecentTransactions(record: $record) {
      id
      createdAt
      status
      details {
        ...transactionDetailsFields
      }
    }
  }

  ${transactionDetailsFragment}
`;

// Transaction Details
type GetTransactionDetailsType = {
  details: ExtendedTransactionDetails;
};

type GetTransactionDetailsRes = {
  getTransactionDetails: DefaultApiResponse<GetTransactionDetailsType[]>;
};

type GetTransactionDetailsArgs = {
  transactionId: number;
};

export const getTransactionDetailsQuery: TypedDocumentNode<
  GetTransactionDetailsRes,
  DefaultArg<GetTransactionDetailsArgs>
> = gql`
  query getTransactionDetails($record: GetTransactionDetailsRecord!) {
    getTransactionDetails(record: $record) {
      details {
        assetCode
        assetLabel
        status
        internalOperationId
        date
        amount
        type
        destination
        blockchainTransactionId
        network
        rate
        assetIconUrl
        fee
        feeAssetCode

        quoteAmount
        quoteAssetCode
        quoteAssetLabel
      }
    }
  }
`;

// Transactions amount
type getUserTransactionsCountRes = {
  getUserTransactionsCount: DefaultApiResponse<number>;
};

type GetTransactionAmountArgs = {
  assetCodes?: CurrencyType[];
  types?: TransactionType[];
  statuses?: TransactionStatusType[];
  minDate?: string;
  maxDate?: string;
};

export const getUserTransactionsCountQuery: TypedDocumentNode<
  getUserTransactionsCountRes,
  DefaultArg<GetTransactionAmountArgs>
> = gql`
  query getTransactionAmount($record: GetUserTransactionsCountRecord!) {
    getUserTransactionsCount(record: $record)
  }
`;
