import { useLazyQuery } from '@apollo/client';

import { Typography } from '@libs/components';
import { getEstimatedAmount } from '@libs/helpers';
import { useEffect } from 'react';

import { PopularAssetBlock } from 'src/components';
import { PRICES_POLL_INTERVAL } from 'src/constants';
import { DashboardPopularAssetsLoader } from 'src/content-loaders';

import { getPopularAssetsQuery } from 'src/gql';

import {
  useApiResponse,
  useFetchPriceQueries,
  useTranslation,
} from 'src/hooks';

import { PopularAssetListContainer, PopularAssetsListWrapper } from './styles';

const PopularAssetsList = () => {
  const { t } = useTranslation();

  const { fetchPriceQueriesStatus } = useFetchPriceQueries();

  const [fetchPopularAssets, { data, loading }] = useLazyQuery(
    getPopularAssetsQuery,
    {
      fetchPolicy: 'network-only',
      pollInterval: PRICES_POLL_INTERVAL,
    }
  );

  const { response } = useApiResponse({ data, loading });

  useEffect(() => {
    fetchPopularAssets();
  }, [fetchPopularAssets, fetchPriceQueriesStatus]);

  if (!loading && response?.getPopularAssets.length === 0) return null;

  return (
    <PopularAssetListContainer>
      <Typography type="titleTwo" fontFamily="primaryBold">
        {t('dashboard_popular_assets')}
      </Typography>
      <PopularAssetsListWrapper>
        {loading && <DashboardPopularAssetsLoader />}
        {response?.getPopularAssets?.map((e, index) => {
          return (
            <PopularAssetBlock
              key={index}
              assetCode={e.code}
              data={e.rateHistory}
              changeRate={e.change}
              cost={getEstimatedAmount(e.price)}
              iconUrl={e.iconUrl}
            />
          );
        })}
      </PopularAssetsListWrapper>
    </PopularAssetListContainer>
  );
};

export default PopularAssetsList;
