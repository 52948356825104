import { Pagination as NativePagination } from '@libs/components';

import { useTranslation } from 'src/hooks';

type PagesProps = {
  totalPages: number;
  currentPage: number;
  onClick: (page: number) => void;
  className?: string;
};

const Pagination: React.FC<PagesProps> = ({
  totalPages,
  currentPage,
  onClick,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <NativePagination
      className={className}
      totalPages={totalPages}
      currentPage={currentPage}
      onClick={onClick}
      prevButtonText={t('common_previous')}
      nextButtonText={t('common_next')}
    />
  );
};

export default Pagination;
