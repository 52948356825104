import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';
import { Asset } from '@libs/types';

import { assetFragment } from '@libs/graphql';

export type getStakingAssetsResponse = {
  getStakingAssets: DefaultApiResponse<Asset[]>;
};
export const getStakingAssetsQuery: TypedDocumentNode<
  getStakingAssetsResponse
> = gql`
  query GetStakingTypes {
    getStakingAssets {
      ...assetFields
    }
  }

  ${assetFragment}
`;
