import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const LoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};

  margin-top: 1.3rem;
  padding: 1.5rem 0 0;

  border-radius: ${({ theme }) => theme.borderRadius.high};

  border: 1px solid ${({ theme }) => theme.colors.borders.default};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;

    return theme.media.tabletL`
      margin-top: 0;  
      padding: 0 1.7rem;

      border: none;

      box-shadow: ${shadow};
  `;
  }};
`;

export const Line = styled.rect`
  x: 0;
  y: 4.8rem;

  width: 100%;
  height: 0.2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Field = styled(ContentLoader)`
  height: 4.95rem;
  width: 100%;

  &:last-child {
    ${Line} {
      display: none;
    }
  }
`;

export const Title = styled.rect`
  x: 1.8rem;
  y: 1.5rem;

  width: 12rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Desc = styled.rect`
  x: calc(100% - 10.8rem);
  y: 1.5rem;

  width: 9rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
