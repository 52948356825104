import { Copy as NativeCopy } from '@libs/components';
import { CopyProps } from '@libs/components/src/copy/Copy';
import { useTranslation } from 'src/hooks';

const Copy: React.FC<
  Omit<CopyProps, 'buttonTextBeforeCopying' | 'buttonTextAfterCopying'>
> = (props) => {
  const { t } = useTranslation();

  return (
    <NativeCopy
      buttonTextAfterCopying={t('common_copied')}
      buttonTextBeforeCopying={t('common_copy')}
      {...props}
    />
  );
};

export default Copy;
