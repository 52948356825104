import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const AccountIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
  width,
  height,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.secondary;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.12 13.53C12.1 13.53 12.07 13.53 12.05 13.53C12.02 13.53 11.98 13.53 11.95 13.53C9.68 13.46 7.98 11.69 7.98 9.51001C7.98 7.29001 9.79 5.48001 12.01 5.48001C14.23 5.48001 16.04 7.29001 16.04 9.51001C16.03 11.7 14.32 13.46 12.15 13.53C12.13 13.53 12.13 13.53 12.12 13.53ZM12 6.97001C10.6 6.97001 9.47 8.11001 9.47 9.50001C9.47 10.87 10.54 11.98 11.9 12.03C11.93 12.02 12.03 12.02 12.13 12.03C13.47 11.96 14.52 10.86 14.53 9.50001C14.53 8.11001 13.4 6.97001 12 6.97001Z"
        fill={iconColor}
      />
      <path
        d="M12 22.75C9.31 22.75 6.74 21.75 4.75 19.93C4.57 19.77 4.49 19.53 4.51 19.3C4.64 18.11 5.38 17 6.61 16.18C9.59 14.2 14.42 14.2 17.39 16.18C18.62 17.01 19.36 18.11 19.49 19.3C19.52 19.54 19.43 19.77 19.25 19.93C17.26 21.75 14.69 22.75 12 22.75ZM6.08 19.1C7.74 20.49 9.83 21.25 12 21.25C14.17 21.25 16.26 20.49 17.92 19.1C17.74 18.49 17.26 17.9 16.55 17.42C14.09 15.78 9.92 15.78 7.44 17.42C6.73 17.9 6.26 18.49 6.08 19.1Z"
        fill={iconColor}
      />
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default AccountIcon;
