import styled from 'styled-components';

import { TransparentButton } from '@libs/components';

export const BalanceWrapper = styled.div`
  width: 100%;
  padding: 2.2rem 2.2rem 4.9rem;
  background: ${({ theme }) => theme.colors.surface.gradient};

  ${({ theme }) => {
    const radius = theme.borderRadius
      .mediumHigh as unknown as TemplateStringsArray;
    return theme.media.laptop`
      margin-bottom: 1.7rem;
      padding: 2.6rem 2.9rem 2.7rem;
      border-radius: ${radius};
    `;
  }};
`;

export const NavigationItems = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.7rem;
  justify-content: space-between;

  ${({ theme }) => theme.media.laptop`
    display: none;
  `};
`;

export const NavigationItem = styled(TransparentButton)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 4.8rem;
  margin-bottom: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.26);
  box-shadow: inset 0px -4px 14px rgba(255, 255, 255, 0.25);
`;

export const WalletWrapper = styled.div`
  width: 100%;
  margin-top: -3.2rem;

  ${({ theme }) => theme.media.laptop`
    margin-top:0;
  `};
`;
