import { PropsWithChildren, useMemo } from 'react';

import { FeatureModuleEnum } from '@libs/backend-common';
import { FeatureRenderer as NativeFeatureRenderer } from '@libs/components';

import { useEnabledFeatures } from 'src/hooks';

type FeatureRendererProps = PropsWithChildren<{
  acceptedFeature: FeatureModuleEnum[];
  shouldNavigate?: boolean;
  shouldCheckKyc?: boolean;
}>;

export const FeatureRenderer: React.FC<FeatureRendererProps> = ({
  acceptedFeature,
  children,
  ...props
}) => {
  const { checkFeature, checkMultipleFeatures } = useEnabledFeatures();
  const checker = useMemo(() => {
    if (acceptedFeature.length === 1) {
      return checkFeature(acceptedFeature[0]);
    } else {
      const { isAvailable, isLoading } = checkMultipleFeatures(acceptedFeature);

      return { isAvailable, isKycRequired: false, isLoading };
    }
  }, [acceptedFeature, checkFeature, checkMultipleFeatures]);

  return (
    <NativeFeatureRenderer {...checker} {...props}>
      {children}
    </NativeFeatureRenderer>
  );
};
