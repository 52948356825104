import styled from 'styled-components';

import { GoogleAuthIcon } from 'src/assets';

type InputsContainerProps = {
  hasErrorMessage: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LottieWrapper = styled.div`
  height: 10rem;
  width: 10rem;
`;

export const AnimationAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const InputsContainer = styled.div<InputsContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin: 2rem 0
    ${({ hasErrorMessage }) => (hasErrorMessage ? '1.4rem' : '2.4rem')};
`;

export const CustomGoogleAuthIcon = styled(GoogleAuthIcon)`
  height: 10rem;
  width: 10rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  padding: 1.7rem 2.4rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  position: absolute;
  right: 2.4rem;
`;

export const DisaclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1.2rem 1.2rem 1.2rem 2.1rem;
  margin-bottom: 2.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
`;

export const LoadingLottieWrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndex.highest};
  position: absolute;
  height: 10.6rem;
  width: 10.6rem;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Blur = styled.div`
  z-index: ${({ theme }) => theme.zIndex.high};
  backdrop-filter: blur(6px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
