import { useTheme } from 'styled-components';
import { Typography, EmptyState } from '@libs/components';

import { DesertedIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';

import {
  NotificationsListContainer,
  NotificationWrapper,
  UnreadNotificationIcon,
  NotificationAlert,
  NotificationContentWrapper,
  NotificationTitle,
  NotificationDescription,
  NotificationDate,
} from './styles';

type NotificationsListType = {
  title: string;
  description: string;
  date: string;
  isRead: boolean;
}[];

const myArray: NotificationsListType = [
  {
    title: 'You have received a new task',
    description:
      'Check your task center and complete your tasks to get a reward! Complete task',
    date: '2 days ago',
    isRead: true,
  },
  {
    title: 'New Notification',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ips...',
    date: '3 days ago',
    isRead: false,
  },
  {
    title: 'Verification process info',
    description:
      'Check your task center and complete your tasks to get a reward! Complete task',
    date: '4 days ago',
    isRead: false,
  },
  {
    title: 'Small title',
    description:
      'Check your task center and complete your tasks to get a reward! Complete task',
    date: '5 days ago',
    isRead: false,
  },
];

const NotificationsList = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  return (
    <NotificationsListContainer>
      {myArray.length > 0 ? (
        myArray.map((item, index) => {
          const handleClick = () =>
            console.log('item.isRead should be changed');
          return (
            <NotificationWrapper key={index} onClick={handleClick}>
              <NotificationAlert isHidden={item.isRead}>
                <UnreadNotificationIcon />
              </NotificationAlert>
              <NotificationContentWrapper>
                <NotificationTitle>
                  <Typography type="subHeadline" fontFamily="primaryBold">
                    {item.title}
                  </Typography>
                </NotificationTitle>
                <NotificationDescription>
                  <Typography
                    type="bodyButton"
                    fontFamily="primaryMedium"
                    color={colors.text.secondary}
                  >
                    {item.description}
                  </Typography>
                </NotificationDescription>
                <NotificationDate>
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={colors.text.secondary}
                  >
                    {item.date}
                  </Typography>
                </NotificationDate>
              </NotificationContentWrapper>
            </NotificationWrapper>
          );
        })
      ) : (
        <EmptyState
          img={DesertedIcon}
          description={t('notifications_not_found_description')}
        />
      )}
    </NotificationsListContainer>
  );
};

export default NotificationsList;
