import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type CryptoWithdrawalAddressArgs = {
  destinationAddress: string;
  destinationAddressName: string;
  googleCode?: string;
  smsCode?: string;
  networkId: number;
  assetCode: string;
};
export type SaveCryptoWithdrawalAddressResponse = {
  saveCryptoWithdrawalAddress: DefaultApiResponse<boolean>;
};
export const saveCryptoWithdrawalAddressMutation: TypedDocumentNode<
  SaveCryptoWithdrawalAddressResponse,
  DefaultArg<CryptoWithdrawalAddressArgs>
> = gql`
  mutation SaveCryptoWithdrawalAddress(
    $record: SaveCryptoWithdrawalAddressRecord!
  ) {
    saveCryptoWithdrawalAddress(record: $record)
  }
`;
