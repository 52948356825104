import styled from 'styled-components';
import LottiePlayer from 'lottie-react';
import { Typography } from '@libs/components';

import TwoFaAuth from '../two-fa-auth/TwoFaAuth';

export const Description = styled(Typography)`
  display: inline-block;
  padding: 2rem 3.7rem 0;
  max-width: 40rem;
`;
export const TwoFaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.6rem;
  min-width: 30rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

export const CustomLottiePlayer = styled(LottiePlayer)`
  width: 10rem;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  right: 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

export const CustomTwoFaAuth = styled(TwoFaAuth)`
  .content {
    width: 100%;
    max-width: 51.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 2.2rem;
  }
`;
