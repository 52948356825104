import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type UpdateBankRequisitesByUser = {
  id: number;
  name: string;
  googleCode?: string;
  smsCode?: string;
};
export type UpdateBankRequisitesByUserResponse = {
  updateBankRequisitesByUser: DefaultApiResponse<boolean>;
};
export const updateBankRequisitesByUserMutation: TypedDocumentNode<
  UpdateBankRequisitesByUserResponse,
  DefaultArg<UpdateBankRequisitesByUser>
> = gql`
  mutation UpdateBankRequisitesByUser($record: UpdateBankRequisiteRecord!) {
    updateBankRequisitesByUser(record: $record)
  }
`;
