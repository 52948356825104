import { FormikErrors, FormikTouched } from 'formik';
import { Button, ErrorMessage, Input } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { PasswordInput } from 'src/components';

import {
  InputContainer,
  ButtonWrapper,
  Form,
  ErrorMessageWrapper,
} from '../styles';

type GenericForm<T> = {
  oldPassword: T;
  password: T;
  confirmPassword: T;
};
type ChangePasswordFormProps = {
  isFormInValid?: boolean;
  values: GenericForm<string>;
  touched: FormikTouched<GenericForm<boolean>>;
  errors: FormikErrors<GenericForm<string>>;
  errorMessage?: string;
  handleChange: (e: React.ChangeEvent) => void;
  setTouched: (touched: FormikTouched<GenericForm<boolean>>) => void;
  onCancel?: () => void;
  onFormSubmit?: () => void;
};
const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  errors,
  values,
  touched,
  errorMessage,
  isFormInValid,
  setTouched,
  onFormSubmit,
  handleChange,
}) => {
  const { t } = useTranslation();

  const passwordDoNotMatch =
    !!values.password &&
    !!values.confirmPassword &&
    values.password != values.confirmPassword;

  const samePasswordError =
    !!values.password && values.password === values.oldPassword
      ? 'error_same_password'
      : '';
  const newPasswordNotMatchError = passwordDoNotMatch
    ? 'validation_confirmation_password'
    : '';

  const resetPasswordError =
    samePasswordError ||
    (newPasswordNotMatchError && samePasswordError) ||
    newPasswordNotMatchError ||
    '';
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setTouched({ ...touched, [e.target.name]: true });
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      data-cy="changePassword"
      data-testid="changePasswordModal"
    >
      <InputContainer>
        <Input
          onBlur={handleBlur}
          name="oldPassword"
          type="password"
          testId="oldPassword"
          labelTestId="oldPasswordLabel"
          errorTestId="oldPasswordError"
          value={values.oldPassword}
          onChange={handleChange}
          errorMessage={touched.oldPassword ? t(errors.oldPassword) : ''}
          label={t('password_change_old_password')}
          placeholder={t('password_change_old_password_placeholder')}
        />
      </InputContainer>
      <InputContainer>
        <PasswordInput
          onBlur={handleBlur}
          name="password"
          type="password"
          testId="password"
          labelTestId="passwordLabel"
          errorTestId="passwordError"
          value={values.password}
          onChange={handleChange}
          errorMessage={touched.password ? t(errors.password) : ''}
          label={t('password_change_new_password')}
          placeholder={t('password_change_new_password_placeholder')}
        />
      </InputContainer>
      <InputContainer>
        <Input
          onBlur={handleBlur}
          name="confirmPassword"
          type="password"
          testId="confirmPassword"
          labelTestId="confirmPasswordLabel"
          errorTestId="confirmPasswordError"
          value={values.confirmPassword}
          onChange={handleChange}
          errorMessage={
            touched.confirmPassword && !resetPasswordError
              ? t(errors.confirmPassword)
              : ''
          }
          label={t('password_change_conf_password')}
          placeholder={t('password_change_conf_password_placeholder')}
        />
      </InputContainer>
      <ErrorMessageWrapper>
        {errorMessage && !samePasswordError && (
          <ErrorMessage
            errorTestId="change-password-error"
            errorMessage={t(errorMessage)}
          />
        )}
        {resetPasswordError && (
          <ErrorMessage errorMessage={t(resetPasswordError)} />
        )}
      </ErrorMessageWrapper>
      <ButtonWrapper>
        <Button
          type="submit"
          disabled={!!samePasswordError || isFormInValid}
          title={t('common_save')}
          testId="submitButton"
        />
      </ButtonWrapper>
    </Form>
  );
};

export default ChangePasswordForm;
