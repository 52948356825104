import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

type ShowMoreProps = {
  $isOpen: boolean;
};

type MenuItemProps = {
  placement: number;
};

export const PartialRepaymentContainer = styled(ContentLoader)<ShowMoreProps>`
  width: 100%;

  height: ${({ $isOpen }) => ($isOpen ? `32.8rem` : `16.8rem`)};

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme, $isOpen }) => theme.media.tablet`
    height: ${
      ($isOpen ? `30.5rem` : `14.5rem`) as unknown as TemplateStringsArray
    };
`}
`;

export const ShowMore = styled.rect<ShowMoreProps>`
  x: 0;
  y: ${({ $isOpen }) =>
    $isOpen ? `calc(100% - 20.8rem)` : `calc(100% - 4.8rem)`};

  width: 100%;
  height: 4.8rem;

  ${({ theme, $isOpen }) => theme.media.tablet`
    height: 3.8rem;

    y: ${
      ($isOpen
        ? `calc(100% - 19.8rem)`
        : `calc(100% - 3.8rem)`) as unknown as TemplateStringsArray
    };
  `}
`;

export const MenuItemTitle = styled.rect<MenuItemProps>`
  x: 1.2rem;
  y: ${({ placement }) => `calc(100% - 15.3rem + ${placement * 4}rem )`};

  height: 2.2rem;
  width: 15rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const MenuItemDesc = styled.rect<MenuItemProps>`
  x: calc(100% - 16.2rem);
  y: ${({ placement }) => `calc(100% - 15.3rem + ${placement * 4}rem )`};

  height: 2.2rem;
  width: 15rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
