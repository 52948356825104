import styled from 'styled-components';

export const LoanDetailsDescrCont = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  margin-top: 3rem;
  padding: 1.6rem 2.6rem;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.19) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03));
`;
export const LoanDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;
  &:last-child {
    margin: 0;
  }
`;
