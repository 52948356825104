import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const OperationsIcon: React.FC<SvgIconDefaultProps> = ({ color }) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.secondary;

  return (
    <CustomSvg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M16.0002 23.7188C15.7302 23.7188 15.4802 23.5688 15.3502 23.3388C15.2202 23.1088 15.2202 22.8188 15.3602 22.5888L16.4102 20.8388C16.6202 20.4788 17.0802 20.3688 17.4402 20.5788C17.8002 20.7888 17.9102 21.2488 17.7002 21.6088L17.4302 22.0588C20.1902 21.4088 22.2602 18.9288 22.2602 15.9688C22.2602 15.5588 22.6002 15.2188 23.0102 15.2188C23.4202 15.2188 23.7602 15.5588 23.7602 15.9688C23.7502 20.2387 20.2702 23.7188 16.0002 23.7188Z"
        fill={iconColor}
      />
      <path
        d="M1 8.71875C0.59 8.71875 0.25 8.37875 0.25 7.96875C0.25 3.69875 3.73 0.21875 8 0.21875C8.27 0.21875 8.52 0.368755 8.65 0.598755C8.78 0.828755 8.78 1.11875 8.64 1.34875L7.59 3.09875C7.38 3.45876 6.92 3.56876 6.56 3.35876C6.2 3.14876 6.09 2.6888 6.3 2.3288L6.57 1.87878C3.81 2.52878 1.74 5.00875 1.74 7.96875C1.75 8.37875 1.41 8.71875 1 8.71875Z"
        fill={iconColor}
      />
      <path
        d="M9.43012 16.0801H6.62012C6.21012 16.0801 5.87012 15.7401 5.87012 15.3301V13.0801C5.87012 12.6701 6.21012 12.3301 6.62012 12.3301H9.43012C10.4301 12.3301 11.3101 13.2101 11.3101 14.2101C11.3101 15.2301 10.4601 16.0801 9.43012 16.0801ZM7.37012 14.5801H9.43012C9.64012 14.5801 9.81012 14.4101 9.81012 14.2001C9.81012 14.0501 9.64012 13.8201 9.43012 13.8201H7.37012V14.5801Z"
        fill={iconColor}
      />
      <path
        d="M9.82986 18.3301H6.60986C6.19986 18.3301 5.85986 17.9901 5.85986 17.5801V15.3301C5.85986 14.9201 6.19986 14.5801 6.60986 14.5801H9.82986C10.9499 14.5801 11.8699 15.4201 11.8699 16.4601C11.8699 17.5001 10.9599 18.3301 9.82986 18.3301ZM7.36986 16.8301H9.83986C10.1599 16.8301 10.3799 16.6301 10.3799 16.4501C10.3799 16.2701 10.1599 16.0701 9.83986 16.0701H7.36986V16.8301Z"
        fill={iconColor}
      />
      <path
        d="M8.41992 19.4503C8.00992 19.4503 7.66992 19.1103 7.66992 18.7003V17.5703C7.66992 17.1603 8.00992 16.8203 8.41992 16.8203C8.82992 16.8203 9.16992 17.1603 9.16992 17.5703V18.7003C9.16992 19.1203 8.82992 19.4503 8.41992 19.4503Z"
        fill={iconColor}
      />
      <path
        d="M8.41992 13.8195C8.00992 13.8195 7.66992 13.4795 7.66992 13.0695V11.9395C7.66992 11.5295 8.00992 11.1895 8.41992 11.1895C8.82992 11.1895 9.16992 11.5295 9.16992 11.9395V13.0695C9.16992 13.4895 8.82992 13.8195 8.41992 13.8195Z"
        fill={iconColor}
      />
      <path
        d="M8.67 22.2502C4.85 22.2502 1.75 19.1402 1.75 15.3302C1.75 11.5202 4.86 8.41016 8.67 8.41016C8.85 8.41016 9.01 8.42018 9.19 8.43018C12.58 8.69018 15.32 11.4302 15.57 14.8002C15.58 15.0202 15.59 15.1702 15.59 15.3302C15.6 19.1402 12.49 22.2502 8.67 22.2502ZM8.67 9.90021C5.68 9.90021 3.25 12.3302 3.25 15.3202C3.25 18.3102 5.68 20.7402 8.67 20.7402C11.66 20.7402 14.1 18.3102 14.1 15.3202C14.1 15.1902 14.09 15.0602 14.08 14.9302C13.88 12.2502 11.73 10.1102 9.09 9.91016C8.96 9.91016 8.82 9.90021 8.67 9.90021Z"
        fill={iconColor}
      />
      <path
        d="M15.3302 15.5997H14.8402C14.4502 15.5997 14.1202 15.2997 14.0902 14.9097C13.8902 12.2597 11.7502 10.1197 9.10015 9.91974C8.71015 9.88974 8.41016 9.55974 8.41016 9.16974V8.67975C8.41016 4.85975 11.5202 1.75977 15.3402 1.75977C19.1602 1.75977 22.2602 4.86975 22.2602 8.67975C22.2602 12.4897 19.1402 15.5997 15.3302 15.5997ZM9.90016 8.50977C12.7202 9.01977 14.9802 11.2697 15.4902 14.0997C18.4102 14.0097 20.7502 11.6097 20.7502 8.67975C20.7502 5.68975 18.3202 3.25977 15.3302 3.25977C12.3902 3.24977 9.99016 5.58977 9.90016 8.50977Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default OperationsIcon;
