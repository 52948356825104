import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

type OptionListContainerProps = {
  height: number;
};

export const OptionListContainer = styled.div<OptionListContainerProps>`
  width: calc(100% - 4.4rem);

  margin: 0 2.2rem 2.1rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};

  ${({ theme }) => theme.media.tablet`
    width: 100%;

    margin: 0;
    
    border: none;
  `};
`;

export const Line = styled.rect`
  x: 0;
  y: calc(100% - 0.1rem);

  width: 100%;
  height: 0.1rem;

  ${({ theme }) => theme.media.tablet`
    y: 0;
  `};
`;

export const Loader = styled(ContentLoader)<OptionListContainerProps>`
  height: calc(${({ height }) => height}rem - 0.13rem);
  width: 100%;

  &:last-child {
    ${Line} {
      display: none;
    }
  }

  ${({ theme }) => theme.media.tablet`
    &:last-child {
      ${Line as unknown as TemplateStringsArray} {
        display: initial;
      }
    }
  `};
`;

export const Title = styled.rect`
  x: 1.6rem;
  y: calc(50% - 1rem);

  width: 15rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Desc = styled.rect`
  x: calc(100% - 11.6rem);
  y: calc(50% - 1rem);

  width: 10rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
