import { useCallback, useContext, useState } from 'react';
import { ApiResponseContext } from 'src/contexts';

export type ApiResponses = Record<string | number, unknown>;
export type AddResponse = (jobId: string, response: unknown) => void;
export type ListenToResponse = (jobId: string | number) => unknown;

export type ApiResponsesContextState = {
  responses: ApiResponses;
  addResponse: AddResponse;
  listenToResponse: ListenToResponse;
};

export const useApi = (): ApiResponsesContextState => {
  const [responses, setResponses] = useState<ApiResponses>({});

  const listenToResponse = useCallback<ListenToResponse>(
    (jobId: string | number) => responses?.[jobId],
    [responses]
  );

  const addResponse = useCallback<AddResponse>(
    (jobId: string, response: unknown) => {
      setResponses((prevResponses) => ({
        ...prevResponses,
        [jobId]: response,
      }));
    },
    []
  );

  return {
    responses,
    addResponse,
    listenToResponse,
  };
};

export const useApiResponseContext = () => useContext(ApiResponseContext);
