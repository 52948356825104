import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const ActionIcon: React.FC<SvgIconDefaultProps> = ({ color, className, testId }) => {
  const { colors } = useTheme();
  const colorToApply = color ? color : colors.text.default;

  
  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="4"
      height="18"
      fill="none"
      viewBox="0 0 4 18"
    >
      <circle cx="1.8" cy="1.8" r="1.8" fill={colorToApply} />
      <circle cx="1.8" cy="9" r="1.8" fill={colorToApply} />
      <circle cx="1.8" cy="16.2" r="1.8" fill={colorToApply} />
    </CustomSvg>
  );
};
export default ActionIcon;
