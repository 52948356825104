import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useTranslation } from 'src/hooks';

import {
  RequireDepositBackground,
  RequireDepositBackgroundGlass,
} from 'src/assets';

import {
  Container,
  CustomButton,
  CustomTypography,
  Wrapper,
  Description,
  Img,
  ImgAndTitleWrapper,
  ImgSpace,
} from './styles';

const RequireDeposit = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const handleButtonClick = () => navigate('/deposit');

  return (
    <Container imgUrl={RequireDepositBackground}>
      <ImgAndTitleWrapper>
        <CustomTypography
          type="subHeadline"
          fontFamily="primaryBold"
          color={colors.text.default}
        >
          {t('require_deposit_title')}
        </CustomTypography>
        <ImgSpace />
      </ImgAndTitleWrapper>
      <Img src={RequireDepositBackgroundGlass} />

      <Wrapper>
        <Description
          type="bodyButton"
          fontFamily="primaryMedium"
          color={colors.text.default}
        >
          {t('require_deposit_desc')}
        </Description>
      </Wrapper>
      <CustomButton onClick={handleButtonClick}>
        {t('wallet_action_deposit')}
      </CustomButton>
    </Container>
  );
};

export default RequireDeposit;
