import styled from 'styled-components';

export const LoanContainer = styled.div`
  &:first-child {
    margin-top: 0;
  }
  box-shadow: ${({ theme }) => theme.shadows.xs};

  margin-top: 1.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 2.4rem;
  ${({ theme }) => theme.media.tablet`
  margin-top: 3rem;
  border-radius: ${theme.borderRadius.high as unknown as TemplateStringsArray};
  background-color: ${
    theme.colors.surface.default as unknown as TemplateStringsArray
  };
  padding: 2.4rem;
  box-shadow: ${theme.shadows.sm as unknown as TemplateStringsArray};
  `};
`;

export const LoanHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;
  ${({ theme }) => theme.media.tablet`
  align-items: center;
    flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5.4rem;
  `};
`;
export const TabContainer = styled.div`
  max-width: 50rem;
  width: 100%;
  margin-top: 3rem;
  ${({ theme }) => theme.media.tablet`
    margin:0;
  `};
`;
export const ActiveLoanContainer = styled.div`
  padding-bottom: 2rem;
  &:last-child {
    padding: 0;
  }
`;
