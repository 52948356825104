import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFormik } from 'formik';

import { Button, LoginForm as Form } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';

import { JurisdictionRenderer, TwoFactorAuth } from 'src/components';
import { TwoFactorAuthType } from 'src/constants';
import { useJurisdictionDetector, useModal, useTranslation } from 'src/hooks';
import {
  bitnetLoginFormValidation,
  loginFormValidation,
  RegexValidators,
} from 'src/validations';

import { useLoginForm } from '../hooks/useLoginForm';
import {
  CustomTypography,
  ModalContent,
  RegistrationContainer,
} from '../styles';

const LoginForm = () => {
  const { t } = useTranslation();

  const { Dialog, openModal } = useModal();

  const { currentJurisdiction } = useJurisdictionDetector();

  const {
    twoFaType,
    errorMessage,
    onTwoFaStepCompete,
    clearErrorMessage,
    loading,
    onTwoFaAuthFinish,
    handleLogin,
    setEmail,
  } = useLoginForm(openModal);

  const { values, handleChange, handleSubmit, errors, touched, isValid } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      onSubmit: (record) => {
        if (loading) return;

        const formatedEmailOrPhone = record.email.replace(
          RegexValidators.HAS_GEORGIAN_PHONE_PREFIX,
          ''
        );

        setEmail(formatedEmailOrPhone);

        handleLogin({
          variables: {
            record: {
              ...record,
              email: formatedEmailOrPhone,
            },
          },
        });
      },
      validationSchema:
        currentJurisdiction === JurisdictionsEnum.GE
          ? bitnetLoginFormValidation
          : loginFormValidation,
    });

  const onInputChange = (e: React.ChangeEvent) => {
    handleChange(e);
    clearErrorMessage();
  };
  const isButtonEnabled = !!values.email && !!values.password;
  const headerTitle = useMemo(() => {
    if (twoFaType === TwoFactorAuthType.EMAIL) {
      return t('two_fa_email_title');
    } else if (twoFaType === TwoFactorAuthType.SMS) {
      return t('two_fa_sms_title');
    }
    return t('two_fa_google_title');
  }, [t, twoFaType]);

  const navigate = useNavigate();
  const { colors } = useTheme();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const emailInputPlaceholder = useMemo(() => {
    if (currentJurisdiction === JurisdictionsEnum.GE) {
      return t('login_phone_or_email_placeholder');
    } else {
      return t('login_placeholder');
    }
  }, [currentJurisdiction, t]);

  const emailInputLabel = useMemo(() => {
    if (currentJurisdiction === JurisdictionsEnum.GE) {
      return t('login_phone_or_email');
    } else {
      return t('login_email');
    }
  }, [currentJurisdiction, t]);

  return (
    <>
      <Form
        values={values}
        isValid={isValid}
        isSubmitting={loading}
        onSubmit={handleSubmit}
        formError={t(errorMessage)}
        onInputChange={onInputChange}
        isButtonEnabled={isButtonEnabled}
        buttonText={t('common_continue')}
        linkPath="/password-recovery"
        linkText={t('login_forgot_password')}
        emailLabel={emailInputLabel}
        emailPlaceholder={emailInputPlaceholder}
        emailInputError={touched.email ? t(errors.email) : ''}
        passwordLabel={t('login_password')}
        passwordPlaceholder={t('login_password')}
        passwordInputError={touched.password ? t(errors.password) : ''}
      />

      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.UA]}>
        <RegistrationContainer>
          <CustomTypography
            color={colors.text.default}
            type="bodyButton"
            fontFamily="primaryMedium"
          >
            {t('common_or')}
          </CustomTypography>
          <Button
            size="md"
            title={t('login_register_text')}
            onClick={handleRegisterClick}
            type="button"
            buttonType="secondary"
          />
        </RegistrationContainer>
      </JurisdictionRenderer>

      <Dialog leftSpace={0} title={headerTitle}>
        {twoFaType && (
          <ModalContent>
            <TwoFactorAuth
              type={twoFaType}
              onAuthFinish={onTwoFaAuthFinish}
              onComplete={onTwoFaStepCompete}
            />
          </ModalContent>
        )}
      </Dialog>
    </>
  );
};

export default LoginForm;
