import { FC, useEffect, useState } from 'react';

import { Tabs, DefaultTabItem, EmptyState } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';

import {
  useTranslation,
  useDashboardWallet,
  useJurisdictionDetector,
} from 'src/hooks';
import { DashboardWalletLoader, WalletLoader } from 'src/content-loaders';
import { DesertedIcon } from 'src/assets';

import { useDebounce } from '@libs/hooks';
import AssetSearchInput from '../asset-search-input/AssetSearchInput';

import Table from '../table/Table';
import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import {
  DashboardWalletContainer,
  InputAndTitleWrapper,
  MobileTableWrapper,
  TabsWrapper,
  WalletHeader,
  WalletMobileTypography,
  WalletTypography,
  WideTableWrapper,
} from './styles';

type DashboardWalletType = {
  testId?: string;
  hasActionButtons?: boolean;
};

const MAX_ASSETS_AMOUNT = 5;

const DashboardWallet: FC<DashboardWalletType> = ({
  hasActionButtons,
  testId = 'dashboardWallet',
}) => {
  const { t } = useTranslation();
  const { currentJurisdiction } = useJurisdictionDetector();
  const TRANSACTIONS_TAB_ITEMS: DefaultTabItem[] = [
    {
      id: 1,
      title: t('common_crypto'),
      disabled: false,
    },
    {
      id: 2,
      title: t('common_fiat'),
      disabled: currentJurisdiction === JurisdictionsEnum.UA ? true : false,
    },
  ];
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const debouncedSearchInputValue = useDebounce(
    searchInputValue,
    200
  ) as string;

  const CRYPTO_TAB_ID = TRANSACTIONS_TAB_ITEMS[0].id;
  const [activeTab, setActiveTab] = useState(CRYPTO_TAB_ID);
  const { columns, data, mobileColumns, mobileData, loading, walletResponse } =
    useDashboardWallet(
      debouncedSearchInputValue,
      setSearchInputValue,
      searchInputValue,
      activeTab === CRYPTO_TAB_ID,
      hasActionButtons
    );

  const loader =
    loading &&
    (hasActionButtons ? <WalletLoader /> : <DashboardWalletLoader />);

  useEffect(() => {
    setSearchInputValue('');
  }, [activeTab]);

  const isInputVisible =
    walletResponse.length > MAX_ASSETS_AMOUNT ||
    debouncedSearchInputValue !== '';

  return (
    <DashboardWalletContainer data-cy={testId}>
      <WalletHeader>
        <InputAndTitleWrapper>
          <WalletTypography
            type="titleTwo"
            data-cy="walletTitle"
            fontFamily="primaryBold"
          >
            {t('dashboard_wallet_title')}
          </WalletTypography>
          <WalletMobileTypography
            type="headline"
            testId="walletMobileTitle"
            fontFamily="primaryBold"
          >
            {t('dashboard_wallet_title')}
          </WalletMobileTypography>
          {isInputVisible && (
            <AssetSearchInput
              setSearchInputValue={setSearchInputValue}
              searchInputValue={searchInputValue}
            />
          )}
        </InputAndTitleWrapper>
        <JurisdictionRenderer
          acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
        >
          <TabsWrapper data-cy="wallet-tabs">
            <Tabs
              activeTab={activeTab}
              testId="walletTabs"
              tabs={TRANSACTIONS_TAB_ITEMS}
              handleTabClick={setActiveTab}
            />
          </TabsWrapper>
        </JurisdictionRenderer>
      </WalletHeader>
      {loader || (
        <>
          <WideTableWrapper data-cy="walletTable">
            {walletResponse.length > 0 ? (
              <Table
                columns={columns}
                data={data}
                headerHeight={4.7}
                bodyHeight={6.1}
                hasStickyHeader={false}
              />
            ) : (
              <EmptyState
                img={DesertedIcon}
                title={t('error_asset_not_found')}
              />
            )}
          </WideTableWrapper>

          <MobileTableWrapper data-cy="walletTable">
            {walletResponse.length > 0 ? (
              <Table
                columns={mobileColumns}
                data={mobileData}
                headerHeight={3.2}
                bodyHeight={6.1}
                hasStickyHeader={false}
              />
            ) : (
              <EmptyState
                img={DesertedIcon}
                title={t('error_asset_not_found')}
              />
            )}
          </MobileTableWrapper>
        </>
      )}
    </DashboardWalletContainer>
  );
};

export default DashboardWallet;
