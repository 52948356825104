import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { filterData, formatPrice, getEstimatedAmount } from '@libs/helpers';

import { JurisdictionsEnum } from '@libs/backend-common';

import { getWalletInfo } from 'src/gql';
import { TableCell, WalletActions } from 'src/components';
import { CurrencyType, PRICES_POLL_INTERVAL } from 'src/constants';

import {
  useTranslation,
  useApiResponse,
  useCurrencyIcons,
  useJurisdictionDetector,
} from 'src/hooks';
import { WalletInfoItem } from '@libs/types';

export const useDashboardWallet = (
  debouncedSearchInputValue: string,
  setSearchInputValue: (val: string) => void,
  searchInputValue: string,
  isCrypto = false,
  hasAction = false
) => {
  const { t } = useTranslation();

  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;

  const tableValue = IS_GLOBAL_JURISDICTION
    ? t('table_value_USDT')
    : t('table_value');

  const tablePrice = IS_GLOBAL_JURISDICTION
    ? t('table_price') + ' (USDT)'
    : t('table_price');

  const tableBalance = IS_GLOBAL_JURISDICTION
    ? t('table_balance') + ' (USDT)'
    : t('table_balance');

  const dollarSign = IS_GLOBAL_JURISDICTION ? '' : '$';

  // const [walletResponse, setWalletResponse] = useState<WalletInfoItem[]>();
  const { data: walletData, loading: walletLoading } = useQuery(getWalletInfo, {
    variables: { record: { isCrypto } },
    fetchPolicy: 'network-only',
    pollInterval: PRICES_POLL_INTERVAL,
  });
  const { response, loading } = useApiResponse({
    data: walletData,
    loading: walletLoading,
  });

  const walletResponse: WalletInfoItem[] = useMemo(() => {
    if (response) {
      if (debouncedSearchInputValue) {
        return filterData(response.getWalletInfo, debouncedSearchInputValue, [
          'name',
          'code',
        ]);
      }
      return response.getWalletInfo;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, debouncedSearchInputValue]);

  const ICONS = useCurrencyIcons('3.8rem');

  const columns = useMemo(() => {
    const cols = [
      {
        Header: t('table_assets'),
        accessor: 'assets',
      },
      {
        Header: t('table_short_amount'),
        accessor: 'amount',
      },
      {
        Header: tableValue,
        accessor: 'formatedBalance',
      },
    ];

    if (hasAction) {
      cols.push({
        Header: t('table_action'),
        accessor: 'actions',
      });
    } else {
      cols.push({
        Header: tablePrice,
        accessor: 'price',
      });
    }

    return cols;
  }, [t, hasAction]);

  const mobileColumns = useMemo(() => {
    const cols = [
      {
        Header: t('table_assets'),
        accessor: 'assets',
      },
      {
        Header: tableBalance,
        accessor: 'balance',
      },
    ];

    if (hasAction) {
      cols.push({
        Header: t('table_action'),
        accessor: 'actions',
      });
    } else {
      cols.push({
        Header: tablePrice,
        accessor: 'price',
      });
    }
    return cols;
  }, [t, hasAction]);

  const data = useMemo(() => {
    if (!walletResponse) return [];

    return walletResponse?.map((item) => {
      const { code, value: walletValue, name } = item;
      const roundedRate = isCrypto
        ? 1 / walletValue
        : (1 / walletValue).toFixed(4);
      const price =
        isCrypto || code === CurrencyType.USD ? walletValue : roundedRate;

      const formatedBalance = getEstimatedAmount(item.value);
      const formatedPrice = price > 999 ? formatPrice(+price) : price;
      const amount = Number(item.amount)
        .toLocaleString('en-US', {
          minimumFractionDigits: 10,
        })
        .replace(/\.?0+$/, '');

      return {
        assets: (
          <TableCell
            isAsset={true}
            title={name}
            description={code}
            img={ICONS[code]}
          />
        ),
        balance: (
          <TableCell
            title={`≈ ${dollarSign}` + formatedBalance}
            description={`${amount} ${CurrencyType[code]}`}
          />
        ),
        amount,
        formatedBalance: `≈ ${dollarSign}` + formatedBalance,
        price: `≈ ${dollarSign}` + formatedPrice,
        actions: <WalletActions currency={code} isCrypto={isCrypto} />,
        // (
        //   <TableCell
        //     title={'$' + item.value}
        //     description="-0.9 %"
        //     color={colors.text.critical}
        //     justifyToLeft={false}
        //   />
        // ),
        // change: <TableCell description="+1.5 %" color={colors.text.success} />,
      };
    });
  }, [walletResponse, ICONS, isCrypto]);

  return {
    columns,
    data,
    mobileColumns,
    mobileData: data,
    loading,
    walletResponse,
  };
};
