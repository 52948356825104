import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.2rem;
  ${({ theme }) => theme.media.laptop`
      max-width: 53rem;
  `}
`;

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.6rem;
`;

export const LootieWrapper = styled.div`
  height: 11rem;
  width: 11rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
