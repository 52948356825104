import styled from 'styled-components';
import { Input } from '@libs/components';

type CustomPriceInputProps = {
  focused?: boolean;
  borderTopFlat?: boolean;
  borderBottomFlat?: boolean;
  hover?: boolean;
};

export const CustomPriceInput = styled.div<CustomPriceInputProps>`
  display: flex;
  align-items: center;
  padding-right: 1.6rem;

  border: ${({ theme, focused, hover }) => {
    const borderHover = hover
      ? theme.colors.borders.hover
      : theme.colors.borders.default;
    return `1px solid ${focused ? theme.colors.borders.pressed : borderHover}`;
  }};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  box-shadow: ${({ theme, focused }) => (focused ? theme.shadows.xl : 'none')};
  border-radius: ${({ theme, borderTopFlat, borderBottomFlat }) =>
    `${borderTopFlat ? 0 : theme.borderRadius.high} ${
      borderTopFlat ? 0 : theme.borderRadius.high
    } ${borderBottomFlat ? 0 : theme.borderRadius.high} ${
      borderBottomFlat ? 0 : theme.borderRadius.high
    }`};
  overflow: hidden;

  ${({ theme }) => {
    const backgroundColor = theme.colors.surface.hover;
    const borderColor = theme.colors.borders.hover;
    return theme.media.laptop`
    &:hover {
      background-color: ${backgroundColor as unknown as TemplateStringsArray}
      border: 1px solid ${borderColor as unknown as TemplateStringsArray}
    }
    `;
  }}
`;

export const CustomInput = styled(Input)`
  flex: 1;
`;
