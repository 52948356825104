import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

export const EmailAuthIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <CustomSvg
      width="50"
      height="41"
      viewBox="0 0 50 41"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26012_117875)">
        <path
          d="M1.14091 15.258L0.353756 15.9906C-0.0626586 15.3003 -0.0276988 14.5653 0.390707 14.1759L1.17786 13.4434C0.759512 13.8327 0.724553 14.5678 1.14091 15.258Z"
          fill="#3658C1"
        />
        <path
          d="M0.353964 16.0492C0.351271 16.0492 0.348518 16.049 0.345825 16.0486C0.328374 16.0462 0.312914 16.036 0.303838 16.0209C-0.115622 15.3256 -0.0961802 14.5493 0.351036 14.133L1.13819 13.4005C1.16185 13.3784 1.19891 13.3797 1.22093 13.4035C1.24295 13.4272 1.2416 13.4642 1.21795 13.4863C0.811722 13.8643 0.800712 14.5805 1.19124 15.2278C1.20559 15.2515 1.20126 15.2821 1.181 15.301L0.393843 16.0335C0.382951 16.0437 0.368663 16.0492 0.353964 16.0492ZM0.868172 13.8117L0.430794 14.2188C0.0376275 14.5847 0.0147309 15.2673 0.367726 15.8977L1.06675 15.2473C0.782032 14.7528 0.71709 14.2221 0.868172 13.8117Z"
          fill="#111111"
        />
        <path
          d="M15.2261 38.6064L14.4389 39.339L0.353516 15.9904L1.14067 15.2578L15.2261 38.6064Z"
          fill="#3658C1"
        />
        <path
          d="M14.4389 39.3975C14.4362 39.3975 14.4334 39.3973 14.4308 39.3969C14.4133 39.3944 14.3978 39.3842 14.3888 39.3691L0.303349 16.0206C0.289002 15.9969 0.293335 15.9663 0.313597 15.9474L1.10075 15.2149C1.11369 15.2029 1.13132 15.1974 1.14877 15.1998C1.16622 15.2022 1.18168 15.2124 1.19075 15.2275L15.2762 38.5761C15.2905 38.5998 15.2862 38.6304 15.2659 38.6493L14.4788 39.3818C14.4679 39.3919 14.4536 39.3975 14.4389 39.3975ZM0.428197 16.0008L14.4518 39.2469L15.1513 38.5959L1.12774 15.3498L0.428197 16.0008Z"
          fill="#111111"
        />
        <path
          d="M49.296 27.0301L17.3994 39.6182C16.7028 39.8931 15.7302 39.4412 15.2266 38.6064L14.4395 39.339C14.9431 40.1739 15.9156 40.6257 16.6123 40.3508L48.5088 27.7627C48.6299 27.715 48.7346 27.6484 48.8225 27.5665L49.6097 26.834C49.5218 26.9158 49.417 26.9824 49.296 27.0301Z"
          fill="#3658C1"
        />
        <path
          d="M16.1743 40.4889C15.5304 40.4889 14.8027 40.0543 14.3893 39.3691C14.3749 39.3454 14.3793 39.3148 14.3995 39.296L15.1867 38.5634C15.1996 38.5515 15.2174 38.5457 15.2347 38.5483C15.2522 38.5507 15.2676 38.5609 15.2768 38.5761C15.7648 39.3852 16.7074 39.8282 17.3779 39.5636L49.2745 26.9755C49.3862 26.9314 49.4856 26.8694 49.5698 26.791C49.5934 26.7691 49.6305 26.7704 49.6525 26.794C49.6745 26.8177 49.6732 26.8548 49.6495 26.8768L48.8624 27.6094C48.7676 27.6977 48.6558 27.7675 48.5302 27.8172L16.6337 40.4052C16.4899 40.4619 16.3347 40.4889 16.1743 40.4889ZM14.5145 39.349C15.0092 40.1324 15.9314 40.5565 16.5908 40.2961L48.4873 27.7081C48.5992 27.6639 48.6985 27.6019 48.7826 27.5236L49.2079 27.1278L17.4209 39.6726C16.717 39.9504 15.741 39.5143 15.2145 38.6975L14.5145 39.349Z"
          fill="#111111"
        />
        <path
          d="M33.3871 0.660344C34.0838 0.385387 35.0578 0.839646 35.5614 1.67453L49.6468 25.0231C50.1505 25.858 49.9923 26.756 49.2957 27.031L17.3992 39.6191C16.7025 39.894 15.73 39.4422 15.2263 38.6073L1.14087 15.2586C0.637199 14.4238 0.793903 13.5233 1.49058 13.2483L33.3871 0.660344Z"
          fill="#B0C3FF"
        />
        <path
          d="M16.9618 39.7565C16.3179 39.7565 15.5902 39.322 15.1768 38.6368L1.0914 15.2882C0.796843 14.7999 0.711113 14.2736 0.856222 13.8443C0.960575 13.5356 1.17279 13.3103 1.46975 13.1931L33.3662 0.605074C34.0875 0.320452 35.095 0.78631 35.6122 1.64357L49.6976 24.9922C49.9915 25.4795 50.0772 26.0046 49.9324 26.4329C49.8279 26.7419 49.6154 26.9674 49.3178 27.0849L17.4213 39.6727C17.2774 39.7295 17.1222 39.7564 16.9618 39.7565ZM33.4093 0.714085L1.51279 13.3021C1.24886 13.4062 1.06018 13.6067 0.967192 13.8818C0.83315 14.2784 0.914957 14.7689 1.19165 15.2277L15.2771 38.5762C15.7652 39.3854 16.7077 39.8286 17.3783 39.5637L49.2748 26.9757C49.5393 26.8713 49.7284 26.6706 49.8214 26.3953C49.9551 25.9997 49.8734 25.5104 49.5973 25.0526L35.512 1.70403C35.023 0.893621 34.0797 0.449554 33.4093 0.714085Z"
          fill="#111111"
        />
        <path
          d="M26.7899 17.5996L25.3914 20.1391L22.056 19.4678L15.2246 38.6068C15.7282 39.4416 16.7008 39.8935 17.3974 39.6186L49.294 27.0305C49.9907 26.7556 50.1487 25.8575 49.6451 25.0228L26.7899 17.5996Z"
          fill="#B0C3FF"
        />
        <path
          d="M16.9613 39.7569C16.3174 39.7569 15.5898 39.3224 15.1764 38.6372C15.1673 38.6221 15.1655 38.6038 15.1714 38.5872L22.0028 19.4483C22.0126 19.4209 22.0408 19.4046 22.0695 19.4105L25.3628 20.0733L26.7406 17.5714C26.7541 17.5468 26.7832 17.5351 26.8099 17.544L49.6651 24.967C49.6785 24.9713 49.6899 24.9804 49.6972 24.9925C49.9911 25.4798 50.0767 26.0049 49.932 26.4332C49.8276 26.7421 49.615 26.9675 49.3174 27.085L17.4208 39.6731C17.277 39.7299 17.1217 39.7568 16.9613 39.7569ZM15.2911 38.6002C15.7832 39.3944 16.7139 39.8262 17.3779 39.5641L49.2745 26.976C49.539 26.8717 49.728 26.671 49.8211 26.3957C49.9529 26.0055 49.8753 25.524 49.6081 25.0717L26.8198 17.6704L25.4446 20.1676C25.4323 20.19 25.4068 20.2016 25.3818 20.1968L22.0961 19.5355L15.2911 38.6002Z"
          fill="#111111"
        />
        <path
          d="M33.3872 0.660333L1.49056 13.2484C0.793878 13.5233 0.637232 14.4239 1.14084 15.2587L22.0577 19.4684L25.3931 20.1397L26.7916 17.6001L35.5613 1.67452C35.0577 0.839752 34.0838 0.385376 33.3872 0.660333Z"
          fill="#B0C3FF"
        />
        <path
          d="M25.3918 20.1974C25.3879 20.1974 25.384 20.197 25.3802 20.1962L1.12799 15.3152C1.11189 15.3119 1.09789 15.3021 1.0894 15.288C0.794846 14.7998 0.709174 14.2735 0.854283 13.8442C0.958636 13.5355 1.17085 13.3102 1.46781 13.193L33.3644 0.604962C34.0856 0.320633 35.0931 0.786197 35.6102 1.64346C35.621 1.66139 35.6215 1.6837 35.6114 1.70198L25.4431 20.1671C25.4326 20.186 25.4128 20.1974 25.3918 20.1974ZM1.17653 15.2055L25.3612 20.0729L35.4923 1.67527C34.9986 0.882907 34.0698 0.452312 33.4073 0.713973L1.51073 13.302C1.24681 13.4061 1.05819 13.6066 0.965195 13.8817C0.833261 14.272 0.910383 14.7532 1.17653 15.2055Z"
          fill="#111111"
        />
      </g>
      <defs>
        <clipPath id="clip0_26012_117875">
          <rect
            width="50"
            height="40"
            fill="white"
            transform="translate(0 0.505859)"
          />
        </clipPath>
      </defs>
    </CustomSvg>
  );
};

export default EmailAuthIcon;
