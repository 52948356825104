import { createContext } from 'react';

type FetchQueriesContextType = {
  fetchPriceQueriesStatus: boolean;
  triggerRefetchForDashboardPrices: () => void;
};

export const FetchPriceQueriesContext = createContext<FetchQueriesContextType>(
  {} as FetchQueriesContextType
);

export const FetchPriceQueriesProvider = FetchPriceQueriesContext.Provider;
