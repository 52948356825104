import styled from 'styled-components';

import { DropdownIcon, TransparentButton } from '@libs/components';

import { BOTTOM_TAB_HEIGHT, DISCLAIMER_HEIGHT } from 'src/constants';

type CustomTransactionsContProps = {
  isUnderConstruction?: boolean;
  isAppDisclaimerVisible?: boolean;
};

export const CustomTransactionsCont = styled.div<CustomTransactionsContProps>`
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: none;
  overflow-y: auto;
  border-radius: unset;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.default};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${({ isUnderConstruction, isAppDisclaimerVisible }) => {
    if (isAppDisclaimerVisible && isUnderConstruction) {
      return `${DISCLAIMER_HEIGHT * 2}rem`;
    } else if (isUnderConstruction || isAppDisclaimerVisible) {
      return `${DISCLAIMER_HEIGHT}rem`;
    } else {
      return '0';
    }
  }};

  /* bottom: ${BOTTOM_TAB_HEIGHT}; */
  /* modal does not appear on top of tabs on iOS */

  z-index: ${({ theme }) => theme.zIndex.highest};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;
    const borderRadius = theme.borderRadius
      .high as unknown as TemplateStringsArray;
    const zIndex = theme.zIndex.medium as unknown as TemplateStringsArray;
    return theme.media.tabletL`
      overflow: unset;
      position: unset;
      max-width: 50rem;
      margin: 0 auto;
      height: unset;
      box-shadow: ${shadow};
      border-radius: ${borderRadius};
      z-index: ${zIndex};
      padding-bottom: 2rem;
      margin-bottom: 3rem;
    `;
  }}
`;
export const CustomTransactionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.low};
  min-height: 6.6rem;
  max-height: 6.6rem;
  padding: 0 2.2rem;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  border-radius: ${({ theme }) => {
    const rad = theme.borderRadius.high as unknown as TemplateStringsArray;
    return `${rad} ${rad} 0 0`;
  }};
`;

export const CustomImgCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
`;

export const CustomBackBtnImg = styled(DropdownIcon)`
  transform: rotate(90deg);
`;

type BackBtnProps = { hasCursor: boolean };
export const BackBtn = styled(TransparentButton)<BackBtnProps>`
  width: 3.2rem;
  height: 3.2rem;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: ${({ hasCursor }) => (hasCursor ? 'pointer' : 'default')};
  ${({ theme }) => theme.media.tabletL`
    display: flex;
  `}
`;

export const BitnetLogo = styled.img`
  height: 3rem;
  width: 2.8rem;
  ${({ theme }) => theme.media.tabletL`
    display: none;
  `}
`;
