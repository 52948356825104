import styled from 'styled-components';

import { Tabs, Typography, TableCell, Table, Button } from '@libs/components';
import { AssetSearchInput } from 'src/components';

type CustomTableCellProps = {
  isCrypto?: boolean;
};

type ButtonsWrapperProps = {
  shouldHideDetailsButton: boolean;
};

export const CustomTableCell = styled(TableCell)<CustomTableCellProps>`
  ${({ isCrypto }) => !isCrypto && 'margin-right: 8rem'};
`;

export const ButtonsAndIconWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  ${({ shouldHideDetailsButton }) =>
    shouldHideDetailsButton && 'padding-right: 2.6rem'};
`;

export const ChangeTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailNavigationButton = styled(Button)`
  width: 16rem;
`;

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ shouldHideDetailsButton }) =>
    shouldHideDetailsButton ? 'flex-end' : 'space-between'};
  width: min-content;
  margin-right: auto;

  gap: 1.2rem;
`;

export const ButtonPlaceHolder = styled.div`
  width: 16rem;
`;

export const TableContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  margin-top: 1.8rem;
`;
export const TableHeaderWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.laptop`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem 3.2rem 3.2rem 3.2rem;
  `};
`;
export const CustomSearchInput = styled(AssetSearchInput)`
  background-color: ${({ theme }) => theme.colors.text.interactive};
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  margin-top: 1.2rem;
  ${({ theme }) => theme.media.laptop`
    max-width: 20rem;
    margin-top: unset;
  `};
`;
export const CustomTabs = styled(Tabs)`
  height: 4.4rem;
  margin-top: 1.5rem;
  .tab-highlighter {
    height: 3rem;
  }
  ${({ theme }) => theme.media.laptop`
    height: 5rem;
    margin-top: 0;
    width: 47rem;
    .tab-highlighter{
       height:4rem;
    };
  `}
`;

export const ChangeRateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CurrencyIcon = styled.img`
  height: 3.8rem;
  width: 3.8rem;
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
export const Title = styled(Typography)`
  /* ${({ theme }) => theme.media.laptop`
      width: min-content;
  `} */
`;
export const TabsAndSearchWrapper = styled.div`
  ${({ theme }) => theme.media.laptop`
      display: flex;
      gap: 1rem;
  `};
`;
export const CustomTable = styled(Table)`
  .tableItem {
    &:first-child {
      padding-left: 1.6rem;
    }

    ${({ theme }) => theme.media.laptop`
        padding-right: 0;
       
    `};
    ${({ theme }) => theme.media.laptopL`
        padding-right: 2.4rem;
    `};
  }
  thead {
    display: none;
    ${({ theme }) => theme.media.laptop`
    display: table-header-group;
    height: 6rem;
    border-top: 0.1rem solid ${
      theme.colors.borders.default as unknown as TemplateStringsArray
    };
    background-color: ${
      theme.colors.surface.interactiveDefault as unknown as TemplateStringsArray
    };
  `};
  }
`;
