import styled from 'styled-components';

export const CustomForm = styled.form`
  width: 100%;
  padding: 2.2rem;
  ${({ theme }) => theme.media.tablet`
    padding:  3.7rem;
    min-width: 42.2rem;
  `};
`;
export const CustomInputCont = styled.div`
  margin-bottom: 4rem;
`;

export const MutationErrorContn = styled.div`
  margin: 0.8rem 0;
  text-align: center;
`;

export const CustomBtnCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.6rem;
`;
