import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type ToggleAutoConversionArgs = {
  enabled: boolean;
};

export type ToggleAutoConversionResponse = {
  toggleAutoConversion: DefaultApiResponse<boolean>;
};

export const toggleAutoConversionMutation: TypedDocumentNode<
  ToggleAutoConversionResponse,
  DefaultArg<ToggleAutoConversionArgs>
> = gql`
  mutation ToggleAutoConversion($record: ToggleAutoConversionRecord!) {
    toggleAutoConversion(record: $record)
  }
`;
