export const AUTHENTICATOR_LINK = {
  appStore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  googlePlay:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ka',
};

export const APP_LINKS = {
  appStore: 'https://apps.apple.com/us/app/bitnet/id6477567064',
  googlePlay: 'https://play.google.com/store/apps/details?id=ge.bitnet',
};
