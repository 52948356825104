import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const BankIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const iconColor = color ? color : '#7D99D6';

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M19.7302 17.2063V19.8254H2.26984V17.2063C2.26984 16.7262 2.66269 16.3333 3.14285 16.3333H18.8571C19.3373 16.3333 19.7302 16.7262 19.7302 17.2063Z"
        fill={iconColor}
        stroke={iconColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.6"
        d="M7.50793 10.2222H4.01587V16.3333H7.50793V10.2222Z"
        fill={iconColor}
      />
      <path
        opacity="0.4"
        d="M11 10.2222H7.50793V16.3333H11V10.2222Z"
        fill={iconColor}
      />
      <path
        opacity="0.6"
        d="M14.4921 10.2222H11V16.3333H14.4921V10.2222Z"
        fill={iconColor}
      />
      <path
        opacity="0.4"
        d="M17.9841 10.2222H14.4921V16.3333H17.9841V10.2222Z"
        fill={iconColor}
      />
      <path
        d="M20.6032 20.4802H1.39683C1.03889 20.4802 0.742065 20.1833 0.742065 19.8254C0.742065 19.4675 1.03889 19.1706 1.39683 19.1706H20.6032C20.9611 19.1706 21.2579 19.4675 21.2579 19.8254C21.2579 20.1833 20.9611 20.4802 20.6032 20.4802Z"
        fill={iconColor}
      />
      <path
        d="M19.1801 5.63889L11.323 2.49606C11.1484 2.42621 10.8516 2.42621 10.677 2.49606L2.81984 5.63889C2.51429 5.76111 2.26984 6.11904 2.26984 6.45079V9.34921C2.26984 9.82937 2.66269 10.2222 3.14285 10.2222H18.8571C19.3373 10.2222 19.7302 9.82937 19.7302 9.34921V6.45079C19.7302 6.11904 19.4857 5.76111 19.1801 5.63889ZM11 8.03968C10.2754 8.03968 9.69047 7.45476 9.69047 6.73016C9.69047 6.00556 10.2754 5.42064 11 5.42064C11.7246 5.42064 12.3095 6.00556 12.3095 6.73016C12.3095 7.45476 11.7246 8.03968 11 8.03968Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default BankIcon;
