import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const StakingRewardsContainer = styled.div`
  width: 100%;
`;

export const Line = styled.rect`
  x: 0;
  y: 0;

  width: 100%;
  height: 0.1rem;

  ${({ theme }) => theme.media.tablet`
    y: 0;
  `};
`;

export const HeaderLoader = styled(ContentLoader)`
  height: 3.6rem;
  width: 100%;

  ${Line} {
    display: none;
  }

  ${({ theme }) => theme.media.tablet`
    height: 4.6rem;

    background-color: ${
      theme.colors.surface.interactiveDefault as unknown as TemplateStringsArray
    };

    ${Line as unknown as TemplateStringsArray} {
      display: initial;

      y: 0;
    }
  `};
`;

export const TitleHeader = styled.rect`
  x: 1.4rem;
  y: calc(50% - 1rem);

  width: 8rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: 1.4rem;

    margin: 0;
  `};
`;

export const DescHeader = styled.rect`
  x: 50%;
  y: calc(50% - 1rem);

  width: 8rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Loader = styled(ContentLoader)`
  height: 5.7rem;
  width: 100%;
`;

export const Title = styled.rect`
  x: 1.2rem;
  y: calc(50% - 1rem);

  width: 15rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Desc = styled.rect`
  x: 50%;
  y: calc(50% - 1rem);

  width: 10rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
