import styled from 'styled-components';
import { Typography } from '@libs/components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 2.4rem auto 0;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet`
    margin-left: 0rem;
  `};
`;

type InputContainerProps = { isLast?: boolean };
export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : '2.4rem')};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2.2rem;
`;

export const LinkWrapper = styled.div`
  width: 100%;
`;

export const ErrorMessageWrapper = styled.div`
  width: 100%;
  height: 2rem;
  margin-top: 0.4rem;
`;

export const ModalContent = styled.div`
  max-width: 45rem;
  margin: 0 auto;
`;

export const CustomTypography = styled(Typography)`
  margin-bottom: 1.6rem;
`;

export const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1.6rem 0;
  ${({ theme }) => theme.media.tablet`
     display: none;
  `}
`;
