import { Typography } from '@libs/components';
import { FC } from 'react';
import { useTranslation } from 'src/hooks';

type SectionDescriptionProps = {
  step?: number;
  className?: string;
  description: string;
  testId?: string;
};
export const SectionDescription: FC<SectionDescriptionProps> = ({
  step,
  className,
  description,
  testId = 'section-desc',
}) => {
  const { t } = useTranslation();

  if (!description) return null;

  return (
    <div className={className}>
      {!!step && (
        <Typography
          fontFamily="primaryBold"
          fontSize={1.4}
          testId="section-desc-step"
          lineHeight={1.7}
          type="secondary"
        >
          {t('step', { step })}
        </Typography>
      )}
      <Typography
        fontFamily="primary"
        testId={testId}
        fontSize={1.4}
        lineHeight={1.7}
        type="secondary"
      >
        {description}
      </Typography>
    </div>
  );
};
