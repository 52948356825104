import {
  useModal as useNativeModal,
  UseModalHookReturnType as UseNativeModalHookReturnType,
  DialogProps as NativeDialogProps,
} from '@libs/components';

import { DASHBOARD_NAV_WIDTH } from 'src/components';

export type DialogProps = NativeDialogProps;
export type UseModalHookReturnType = UseNativeModalHookReturnType;
export type UseModalHook = (
  onClose?: () => void,
  isOpenByDefault?: boolean,
  canCloseViaLayer?: boolean,
  shouldNotScroll?: boolean
) => UseModalHookReturnType;

export const useModal: UseModalHook = (
  onClose,
  isOpenByDefault,
  canCloseViaLayer
) =>
  useNativeModal(
    onClose,
    isOpenByDefault,
    canCloseViaLayer,
    DASHBOARD_NAV_WIDTH
  );
