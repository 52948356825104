import gql from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';

import {
  DefaultArg,
  DefaultApiResponse,
  AccessRefreshToken,
} from '@libs/graphql';

export type LoginArgs = {
  email: string;
  password: string;
};

export type LoginWithRefreshTokenResponse = {
  login: DefaultApiResponse<AccessRefreshToken>; // NOTE: always wrap with DefaultApiResponse
};

export const loginWithRefreshTokenMutation: TypedDocumentNode<
LoginWithRefreshTokenResponse,
  DefaultArg<LoginArgs>
> = gql`
  mutation login($record: LoginRecord!) {
    login(record: $record) {
      accessToken
      refreshToken
    }
  }
`;

export type RefreshTokenResponse = {
  refreshToken: DefaultApiResponse<AccessRefreshToken>;
};

export const refreshTokenMutation: TypedDocumentNode<RefreshTokenResponse> = gql`
  mutation RefreshTokens {
    refreshTokens {
      accessToken
      refreshToken
    }
  }
`;
