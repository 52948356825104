import * as langs from 'src/languages';

export type LanguageKeys = keyof typeof langs;
export type Language = {
  label: { text: string };
  value: LanguageKeys;
}

export const languages: Language[] = [
  {
    label: {
      text: 'GEO',
    },
    value: 'ka',
  },
  {
    label: {
      text: 'ENG',
    },
    value: 'en',
  },
  {
    label: {
      text: 'RUS',
    },
    value: 'ru',
  },
];
