import styled from 'styled-components';

import { MaxContentWrapper, Typography } from '@libs/components';

import { WebsitePreviewLayout } from 'src/components';

export const Container = styled(MaxContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 62rem;
  margin: 2.4rem auto 0;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletS`
    justify-content: space-between;
  `}
`;

type InputContainerProps = { isLast?: boolean };
type LayoutProps = { hasSubmitted?: boolean };

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : '1.2rem')};
  position: relative;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 1.6rem;
`;

export const PhoneInputContainer = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1.6rem;
`;

export const CustomTypography = styled(Typography)`
  margin-bottom: 1.6rem;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1.6rem 0;
  ${({ theme }) => theme.media.tablet`
     display: none;
  `}
`;

export const CodeInputContainer = styled.div`
  margin-bottom: 4rem;
  width: 100%;
`;
export const PasswordInputContainer = styled(InputContainer)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : '1.2rem')};
`;

export const ErrorMessageWrapper = styled.div`
  width: 100%;
  padding-top: 0.8rem;
  margin-top: 1rem;
`;

export const TextAndLineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.6rem 0;
`;

export const Line = styled.div`
  flex: 1;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;

export const Option = styled(Typography)`
  margin: 0 2rem;
`;

export const RegisterOptionWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1.2rem;
  padding: 1.8rem 2.8rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
  `}
`;

export const SocialPlatformOptions = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  width: 100%;
`;

export const CustomWebsitePreviewLayout = styled(
  WebsitePreviewLayout
)<LayoutProps>`
  ${({ theme, hasSubmitted }) => {
    const padding = (hasSubmitted
      ? 'unset'
      : '') as unknown as TemplateStringsArray;

    return theme.media.tabletL`
      max-width: 50rem;
      padding: ${padding};
    `;
  }}
`;
