import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DefaultArg,
  InitSwapResponse,
  InitSwapArgs,
  DefaultApiResponse,
} from '@libs/graphql';

type InitSwap = {
  initSwap: InitSwapResponse;
};

export const initSwapMutation: TypedDocumentNode<
  DefaultApiResponse<InitSwap>,
  DefaultArg<InitSwapArgs>
> = gql`
  mutation InitSwap($record: InitSwapRecord!) {
    initSwap(record: $record) {
      uuid
    }
  }
`;
