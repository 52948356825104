import { useNavigate } from 'react-router-dom';

import { Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';

import { useTheme } from 'styled-components';
import { IconWrapper, ModalItemContainer, TextWrapper } from '../styles';
import { ModalItemType } from '../DashboardMobileNav';

type NavModalProps = {
  item: ModalItemType;
  closeModal: () => void;
  testId?: string;
};

const ModalItem: React.FC<NavModalProps> = ({
  item,
  closeModal,
  testId = 'modaltem',
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(item.route);
    closeModal();
  };

  return (
    <ModalItemContainer onClick={handleClick} data-cy={testId}>
      <IconWrapper>{item.icon}</IconWrapper>
      <TextWrapper>
        <Typography
          type="subHeadline"
          fontFamily="primaryBold"
          color={colors.text.default}
        >
          {t(item.title)}
        </Typography>
        {/* <Typography
          type="bodyButton"
          fontFamily="primaryMedium"
          color={colors.text.secondary}
        >
          {t(item.desc)}
        </Typography> */}
      </TextWrapper>
    </ModalItemContainer>
  );
};

export default ModalItem;
