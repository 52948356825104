import { DEFAULT_AVATAR_SIZE } from '@libs/components';
import { useTheme } from 'styled-components';

import {
  ImgLoader,
  NameLoader,
  UserIdLoader,
  Loader,
  GreetingLoader,
} from './styles';

type LoaderType = {
  size?: number;
  showGreeting?: boolean;
};

export const UserDetailsLoader: React.FC<LoaderType> = ({
  size = DEFAULT_AVATAR_SIZE,
  showGreeting,
}) => {
  const { colors } = useTheme();

  return (
    <Loader
      backgroundColor={colors.surface.interactiveDefault}
      foregroundColor={colors.surface.pressed}
      size={size}
    >
      {showGreeting && <GreetingLoader size={size} />}
      <ImgLoader size={size} />
      <NameLoader size={size} showGreeting={showGreeting} />
      <UserIdLoader size={size} showGreeting={showGreeting} />
    </Loader>
  );
};
