import { useTheme } from 'styled-components';
import { AssetIcon, AssetName, DetailsHeaderLoader } from './styles';

const DetailsHeaderMobileLoader = () => {
  const { colors } = useTheme();
  return (
    <DetailsHeaderLoader
      backgroundColor={colors.surface.interactiveDefault}
      foregroundColor={colors.surface.pressed}
    >
      <AssetIcon />
      <AssetName />
    </DetailsHeaderLoader>
  );
};

export default DetailsHeaderMobileLoader;
