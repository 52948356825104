import { useTheme } from 'styled-components';

import {
  Amount,
  Asset,
  AssetIcon,
  Price,
  Value,
  WalletContainer,
  BodyLoaderWrapper,
  HeaderLoader,
  HeaderLine,
  BodyLine,
  AssetName,
  AssetCode,
  AmountInUsd,
  AssetAmount,
  AssetPrice,
  AssetValue,
} from './styles';

export const DashboardWalletLoader = () => {
  const { colors } = useTheme();
  return (
    <WalletContainer>
      <HeaderLoader
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <Asset />
        <Amount />
        <Value />
        <Price />
        <HeaderLine />
      </HeaderLoader>
      {[...Array(4)].map((item, i) => {
        return (
          <BodyLoaderWrapper
            key={i}
            backgroundColor={colors.surface.interactiveDefault}
            foregroundColor={colors.surface.pressed}
          >
            <AssetIcon />
            <AssetName />
            <AssetCode />
            <BodyLine />
            <AmountInUsd />
            <AssetAmount />
            <AssetPrice />
            <AssetValue />
          </BodyLoaderWrapper>
        );
      })}
    </WalletContainer>
  );
};
