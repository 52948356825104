import { TetherIcon } from 'src/assets';
import { ImagesContainer, CustomImg } from './styles';
type UsdtImageProperties = {
  shouldDisplayOnMobile: boolean;
  mobileHeight: number;
  shouldTransform: boolean;
  blur: number;
  rightPositionMobile: number;
  topPositionMobile: number;
  laptopHeight: number;
  laptopTopPosition: number;
  laptopRightPosition: number;
};
const customImages: UsdtImageProperties[] = [
  {
    shouldDisplayOnMobile: true,
    mobileHeight: 11.9,
    shouldTransform: false,
    blur: 0,
    rightPositionMobile: -1.4,
    topPositionMobile: -0.4,
    laptopHeight: 13,
    laptopTopPosition: 0.3,
    laptopRightPosition: 5,
  },
  {
    shouldDisplayOnMobile: true,
    mobileHeight: 3.5,
    shouldTransform: true,
    blur: 0.1,
    rightPositionMobile: 11.7,
    topPositionMobile: 6.5,
    laptopHeight: 4.5,
    laptopTopPosition: 6.5,
    laptopRightPosition: 21,
  },
  {
    shouldDisplayOnMobile: true,
    mobileHeight: 2,
    shouldTransform: false,
    blur: 0.2,
    rightPositionMobile: 10.7,
    topPositionMobile: 2,
    laptopHeight: 3,
    laptopTopPosition: 2,
    laptopRightPosition: 18,
  },
  {
    shouldDisplayOnMobile: true,
    mobileHeight: 2,
    shouldTransform: true,
    blur: 0.3,
    rightPositionMobile: -0.8,
    topPositionMobile: 8.2,
    laptopHeight: 2,
    laptopTopPosition: 10,
    laptopRightPosition: 2.8,
  },
  {
    shouldDisplayOnMobile: false,
    mobileHeight: 6,
    shouldTransform: true,
    blur: 0.2,
    rightPositionMobile: -2,
    topPositionMobile: 1.5,
    laptopHeight: 6,
    laptopTopPosition: 1.5,
    laptopRightPosition: -2,
  },
];
export default function DashboardWalletUsdtImages() {
  return (
    <ImagesContainer>
      {customImages.map((e, index) => (
        <CustomImg
          src={TetherIcon}
          key={index}
          shouldDisplayOnMobile={e.shouldDisplayOnMobile}
          mobileHeight={e.mobileHeight}
          shouldTransform={e.shouldTransform}
          blur={e.blur}
          rightPositionMobile={e.rightPositionMobile}
          topPositionMobile={e.topPositionMobile}
          laptopHeight={e.laptopHeight}
          laptopTopPosition={e.laptopTopPosition}
          laptopRightPosition={e.laptopRightPosition}
        />
      ))}
    </ImagesContainer>
  );
}
