import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const DetailsHeaderLoader = styled(ContentLoader)`
  display: flex;
  height: 5.3rem;
  width: 20rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
`;

export const AssetIcon = styled.circle`
  cx: 2rem;
  cy: 2.5rem;

  r: 2.1rem;
`;

export const AssetName = styled.rect`
  width: 12rem;
  height: 2.5rem;

  x: 5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const AssetCode = styled.rect`
  width: 3rem;
  height: 1.5rem;

  x: 5rem;
  y: 3rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
