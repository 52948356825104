import { useTheme } from 'styled-components';

import {
  AmountHeaderLoader,
  AsetIconLoader,
  AssetDescLoader,
  AssetHeaderLoader,
  AssetTitleLoader,
  BodyLoader,
  ButtonLoader,
  HeaderLoader,
  AmountLoader,
  PercentageHeaderLoader,
  PercentageLoader,
  PeriodHeaderLoader,
  PeriodLoader,
  StakingListContainer,
} from './styles';

export const StartStakingLoader = () => {
  const { colors } = useTheme();

  return (
    <StakingListContainer>
      <HeaderLoader
        foregroundColor={colors.surface.pressed}
        backgroundColor={colors.surface.default}
      >
        <AssetHeaderLoader />
        <PercentageHeaderLoader />
        <AmountHeaderLoader />
        <PeriodHeaderLoader />
      </HeaderLoader>
      <BodyLoader
        foregroundColor={colors.surface.pressed}
        backgroundColor={colors.surface.interactiveDefault}
      >
        <AsetIconLoader />
        <AssetTitleLoader />
        <AssetDescLoader />
        <PercentageLoader />
        <AmountLoader />
        <PeriodLoader />
        <ButtonLoader />
      </BodyLoader>
    </StakingListContainer>
  );
};
