import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

type GetStakingRewardsArgs = {
  id: string;
};

export type Reward = {
  createdAt: Date;
  amount: number;
};

type GetStakingRewards = {
  rewardRedeems: Reward[];
  expectedRewards: Reward[];
  rewardAccruals: Reward[];
};

export type GetStakingRewardsResponse = {
  getStakingRewards: DefaultApiResponse<GetStakingRewards>;
};

export const getStakingRewardsQuery: TypedDocumentNode<
  GetStakingRewardsResponse,
  DefaultArg<GetStakingRewardsArgs>
> = gql`
  query GetStakingRewards($record: StakingIdRecord!) {
    getStakingRewards(record: $record) {
      rewardRedeems {
        createdAt
        amount
      }
      expectedRewards {
        createdAt
        amount
      }
      rewardAccruals {
        createdAt
        amount
      }
    }
  }
`;
