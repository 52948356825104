import { useMemo } from 'react';

import { SmsAuthIcon, GoogleAuthIcon, EmailAuthIcon } from 'src/assets';

import {
  useAuth,
  useTranslation,
  useCheckEnabledTwoFaFeatures,
} from 'src/hooks';
import { TwoFactorAuthType } from '@libs/types';

export const useTwoFaOptions = (
  handleTogglerClick?: (type: TwoFactorAuthType, isChecked: boolean) => void
) => {
  const { t } = useTranslation();

  const { decodeToken } = useAuth();

  const token = decodeToken();

  const { isEmail2FaAvailabe, isSms2faAvailable } =
    useCheckEnabledTwoFaFeatures();

  const options = useMemo(() => {
    if (!token) return [];

    const isSmsActivated = token.isSmsTwoFactorEnabled;

    const isGoogleActivated = token.isTwoFactorEnabled;

    const isEmailActivated = token.isEmailTwoFactorEnabled;

    const activatedTwoFaMethods = [];

    if (isSms2faAvailable) {
      activatedTwoFaMethods.push({
        icon: <SmsAuthIcon />,
        title: t('activate_2fa_sms'),
        isChecked: isSmsActivated,
        handleClick: () =>
          handleTogglerClick?.(TwoFactorAuthType.SMS, isSmsActivated),
        testId: 'smsTwoFaToggle',
      });
    }

    activatedTwoFaMethods.push({
      icon: <GoogleAuthIcon />,
      title: t('activate_2fa_google'),
      isChecked: isGoogleActivated,
      handleClick: () =>
        handleTogglerClick?.(TwoFactorAuthType.GOOGLE, isGoogleActivated),
      testId: 'googleTwoFaToggle',
    });

    if (isEmail2FaAvailabe) {
      activatedTwoFaMethods.push({
        icon: <EmailAuthIcon />,
        title: t('activate_2fa_email'),
        isChecked: isEmailActivated,
        handleClick: () =>
          handleTogglerClick?.(TwoFactorAuthType.EMAIL, isEmailActivated),
        testId: 'emailTwoFaToggle',
      });
    }

    return activatedTwoFaMethods;
  }, [token, isSms2faAvailable, t, isEmail2FaAvailabe, handleTogglerClick]);

  return { options };
};
