import { useTheme } from 'styled-components';
import { Typography } from '@libs/components';
import { Info, InfoIcon, InfoTextContainer, Img } from './styles';

type DisabledInfoViewProps = {
  title?: string;
  description?: string;
  icon?: string | JSX.Element;
};

export const DisabledInfoView: React.FC<DisabledInfoViewProps> = ({
  title,
  description,
  icon,
}) => {
  const { colors } = useTheme();
  const image =
    typeof icon === 'string' ? <Img src={icon} alt="tableIcon icon" /> : icon;
  return (
    <Info>
      {icon && <InfoIcon>{image}</InfoIcon>}
      <InfoTextContainer>
        <Typography
          type="caption"
          fontFamily="primaryMedium"
          color={colors.text.secondary}
        >
          {title}
        </Typography>
        <Typography
          type="subHeadline"
          fontFamily="primaryMedium"
          color={colors.text.disabled}
        >
          {description}
        </Typography>
      </InfoTextContainer>
    </Info>
  );
};
export default DisabledInfoView;
