import { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';

import { ErrorMessageKey, ErrorMessageTexts } from 'src/constants';

export const useError = (error?: ApolloError) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!error) return;

    const errMessage =
      ErrorMessageTexts[error.message as ErrorMessageKey] ||
      'common_unexpected_error';

    setErrorMessage(errMessage);
  }, [error]);

  const clearErrorMessage = () => {
    setErrorMessage('');
  };

  return {
    errorMessage,
    clearErrorMessage,
  };
};
