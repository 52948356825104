import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

import { WithdrawalMethods } from '@libs/types';

export type CalculatedWithdrawal = {
  fee: number;
  totalAmount: number;
  remainingAmount: number;
};

export type CalculateWithdrawalArgs = {
  amount: number;
  assetCode: string;
  method: WithdrawalMethods;

  iban?: string;
  fireblocksId?: string;
  walletAddress?: string;
};

export type CalculateWithdrawalResponse = {
  calculateWithdrawalValues: DefaultApiResponse<CalculatedWithdrawal>;
};

export const calculateWithdrawalValuesQuery: TypedDocumentNode<
  CalculateWithdrawalResponse,
  DefaultArg<CalculateWithdrawalArgs>
> = gql`
  query CalculateWithdrawalValues($record: CalculateWithdrawalValuesRecord!) {
    calculateWithdrawalValues(record: $record) {
      fee
      totalAmount
      remainingAmount
    }
  }
`;
