import styled from 'styled-components';
import { Button, TransparentButton } from '@libs/components';
import { NotificationIcon } from 'src/assets';

export const Container = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2.2rem 0.8rem 2.2rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  width: 36rem;
  height: auto;
  right: 0;
  top: 7rem;
  z-index: ${({ theme }) => theme.zIndex.low};

  ${({ theme }) => theme.media.laptop`
  display: flex;
  `}
`;

export const JustifiedContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  height: auto;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  padding: 1.7rem 1.9rem 1.4rem;
  margin: 0 0.8rem 1.6rem;
`;

export const CustomNotificationIcon = styled(NotificationIcon)`
  width: 2.2rem;
  height: 1.9rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 0 1.4rem 1.1rem;
`;

export const NavSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  padding: 1.1rem 0.9rem 0.9rem 1.3rem;
  width: 100%;
  height: auto;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => {
    const backgroundColor = theme.colors.surface.interactiveDefault;
    const borderRadius = theme.borderRadius.medium;
    return theme.media.laptop`
      &:hover {
        background-color: ${backgroundColor as unknown as TemplateStringsArray};
        border-radius: ${borderRadius as unknown as TemplateStringsArray};
      }
    `;
  }}
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 0.7rem;
  background-color: ${({ theme }) => theme.colors.surface.disabled};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  margin-right: 1.7rem;
  width: 3.6rem;
  height: 3.6rem;
`;

export const NavTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const LogoutWrapper = styled.div`
  margin-top: 1.9rem;
  padding: 0 1.6rem;
`;

export const LogoutButton = styled(Button)`
  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;
