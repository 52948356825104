import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { meQuery } from '@libs/graphql';

import { TwoFactorAuthType } from 'src/constants';
import { getPhoneLastDigits } from 'src/helpers';
import { useApiResponse, useTranslation } from 'src/hooks';

import ToggleWithLabel from '../CustomToggler.tsx/CustomToggler';

type TwoFaActivatorProps = {
  title?: string;
  description?: string;
  testId?: string;
  type: TwoFactorAuthType;
  onChange?: () => void;
  className?: string;
  isToggleDisabled?: boolean;
};

const TwoFaActivator: React.FC<TwoFaActivatorProps> = ({
  type,
  title,
  description,
  onChange,
  className,
  isToggleDisabled,
}) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(meQuery);
  const { response } = useApiResponse({ data, loading });

  const twoFaData = useMemo(() => {
    if (type === TwoFactorAuthType.EMAIL) {
      return {
        isEnabled: response?.me.isEmailTwoFactorEnabled,
        title: title || t('two_fa_email_title'),
        description:
          description ||
          t('two_fa_email_desc', {
            email: response?.me.email || '',
          }),
      };
    } else if (type === TwoFactorAuthType.SMS) {
      return {
        isEnabled: response?.me.isSmsTwoFactorEnabled,
        title: title || t('two_fa_sms_title'),
        description:
          description ||
          t('two_fa_sms_desc', {
            lastDigits: getPhoneLastDigits(response?.me.phone),
          }),
      };
    }

    return {
      isEnabled: response?.me.isTwoFactorEnabled,
      title: title || t('two_fa_google_title'),
      description: description || t('two_fa_google_desc'),
    };
  }, [description, response, t, title, type]);

  const handleChange = () => {
    if (onChange) onChange();
  };

  return (
    <ToggleWithLabel
      disabled={isToggleDisabled}
      defaultValue={twoFaData.isEnabled}
      className={className}
      handleChange={handleChange}
      descriptionTitle={twoFaData.description}
      title={twoFaData.title}
      isContentReversed={false}
    />
  );
};

export default TwoFaActivator;
