import { createContext } from 'react';

type AppDisclaimerContextType = {
  isAppDisclaimerVisible: boolean;
  setIsAppDisclaimerVisible: (isVisible: boolean) => void;
};

export const AppDisclaimerContext = createContext<AppDisclaimerContextType>(
  {} as AppDisclaimerContextType
);

export const AppDisclaimerProvider = AppDisclaimerContext.Provider;
