import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type EditProfileArgs = {
  address: string;
  city: string;
  country: string;
  zip: string;
};

export type EditProfileResponse = {
  updateUser: DefaultApiResponse<boolean>;
};

export const editProfileMutation: TypedDocumentNode<
  EditProfileResponse,
  DefaultArg<EditProfileArgs>
> = gql`
  mutation EditProfile($record: UpdateUserRecord!) {
    updateUser(record: $record)
  }
`;
