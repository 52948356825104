import { MaxContentWrapper } from '@libs/components';
import styled from 'styled-components';

type ContainerProps = { hasBanner: boolean; shouldHeaderStick?: boolean };
export const Container = styled(MaxContentWrapper)<ContainerProps>`
  flex: 1;

  padding-top: ${({ shouldHeaderStick }) =>
    shouldHeaderStick ? '0' : '10rem'};

  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme, hasBanner }) => {
    const justify = (hasBanner
      ? 'space-evenly'
      : 'center') as unknown as TemplateStringsArray;

    return theme.media.tablet`
      flex-direction: row;
      justify-content: ${justify};
      background-color: unset;
  `;
  }};
`;

export const BannerWrapper = styled.div`
  margin-bottom: 4.4rem;

  ${({ theme }) => theme.media.tablet`
    order: 1;
    margin: unset;
    margin-left: 4.8rem;
  `};
`;

export const Banner = styled.img`
  width: 100%;
  max-width: 43.3rem;
  display: inline-block;

  ${({ theme }) => theme.media.tablet`
    order: 1;
    margin: unset;
  `};
`;

type TitleProps = { isHeadingCenter: boolean };
export const TitleWrapper = styled.div<TitleProps>`
  text-align: center;

  ${({ isHeadingCenter, theme }) => {
    const textAlign = (isHeadingCenter
      ? 'center'
      : 'left') as unknown as TemplateStringsArray;

    const width = (isHeadingCenter
      ? 'max-content'
      : '100%') as unknown as TemplateStringsArray;

    return theme.media.tablet`
      text-align: ${textAlign};
      width:${width}
    `;
  }};
`;
