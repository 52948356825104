import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { PartialAsset } from '@libs/types';
import { DefaultApiResponse, partialAssetFragment } from '@libs/graphql';

export type NestedAsset = PartialAsset & {
  pairedAssets: PartialAsset[];
};

export type ExchangeSettings = {
  baseAssets: NestedAsset[];
  quoteAssets: NestedAsset[];
};

export type GetExchangeSettingsResponse = {
  getExchangeSettings: DefaultApiResponse<ExchangeSettings>;
};

export const getExchangeSettings: TypedDocumentNode<GetExchangeSettingsResponse> = gql`
  query GetExchangeSettings {
    getExchangeSettings {
      baseAssets {
        ...partialAssetFields
        pairedAssets {
          ...partialAssetFields
        }
      }

      quoteAssets {
        ...partialAssetFields
        pairedAssets {
          ...partialAssetFields
        }
      }
    }
  }

  ${partialAssetFragment}
`;
