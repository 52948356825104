import { useTheme } from 'styled-components';

import { TransactionsIcon, Typography } from '@libs/components';

import {
  DashboardIcon,
  WalletIcon,
  SwapIcon,
  DashboardActiveIcon,
  WalletActiveIcon,
  TransactionsActiveIcon,
  SecondaryCloseIcon,
  MoreIcon,
  ActiveMoreIcon,
  OperationsIcon,
  ActiveOperationsIcon,
} from 'src/assets';
import {
  useTranslation,
  useModal,
  useNavigationItems,
  useJurisdictionDetector,
} from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import {
  ActiveLine,
  CustomCircledIcon,
  MobileIconWrapper,
  MobileNavContainer,
  ModalButton,
  ModalContent,
  NavItemWrapper,
  OperationsItemWrapper,
} from './styles';
import { MobileNavItem, ModalItem } from './components';

const DashboardMobileNav = ({ testId = 'mobileNav' }: { testId?: string }) => {
  const { Dialog, openModal, closeModal, isOpen: modalIsOpen } = useModal();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const navModalItems = useNavigationItems({
    color: colors.interactive.brandColor,
  });

  const handleClick = () => {
    if (modalIsOpen) {
      closeModal();
    } else {
      openModal();
    }
  };
  const { currentJurisdiction } = useJurisdictionDetector();

  const textColor = modalIsOpen
    ? colors.interactive.brandColor
    : colors.text.secondary;

  if (currentJurisdiction === JurisdictionsEnum.UA) {
    return (
      <>
        <MobileNavContainer data-cy={testId} isOpen={modalIsOpen}>
          <NavItemWrapper>
            <MobileNavItem
              item={uanNavigationItems[0]}
              onClick={closeModal}
              modalIsOpen={modalIsOpen}
            />
            <OperationsItemWrapper onClick={handleClick}>
              <MobileIconWrapper>
                {modalIsOpen ? <ActiveOperationsIcon /> : <OperationsIcon />}
              </MobileIconWrapper>

              <Typography
                fontFamily={modalIsOpen ? 'primaryBold' : 'primaryMedium'}
                type="caption"
                color={textColor}
              >
                {t('common_operations')}
              </Typography>
              {modalIsOpen && <ActiveLine />}
            </OperationsItemWrapper>
            <MobileNavItem
              modalIsOpen={modalIsOpen}
              item={uanNavigationItems[1]}
              onClick={closeModal}
            />
          </NavItemWrapper>
        </MobileNavContainer>
        <Dialog title={t('mobile_nav_title')}>
          <ModalContent data-cy="navModal">
            {navModalItems.map((item, i) => (
              <ModalItem
                item={item}
                key={i}
                testId={`modalItem${i + 1}`}
                closeModal={closeModal}
              />
            ))}
          </ModalContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <MobileNavContainer data-cy={testId} isOpen={modalIsOpen}>
        <NavItemWrapper>
          <MobileNavItem
            item={navigationItems[0]}
            testId="navItem1"
            onClick={closeModal}
          />
          <MobileNavItem
            item={navigationItems[1]}
            testId="navItem2"
            onClick={closeModal}
          />
        </NavItemWrapper>
        <ModalButton onClick={handleClick} data-cy="navButton">
          <CustomCircledIcon
            color={colors.interactive.brandColor}
            data-cy="closedModal"
          >
            {modalIsOpen ? (
              <SecondaryCloseIcon
                color={colors.surface.default}
                testId="openedModal"
              />
            ) : (
              <SwapIcon testId="closedModal" />
            )}
          </CustomCircledIcon>
        </ModalButton>
        <NavItemWrapper>
          <MobileNavItem
            item={navigationItems[2]}
            testId="navItem3"
            onClick={closeModal}
          />
          <MobileNavItem
            item={navigationItems[3]}
            testId="navItem4"
            onClick={closeModal}
          />
        </NavItemWrapper>
      </MobileNavContainer>
      <Dialog title={t('mobile_nav_title')}>
        <ModalContent data-cy="navModal">
          {navModalItems.map((item, i) => (
            <ModalItem
              item={item}
              key={i}
              testId={`modalItem${i + 1}`}
              closeModal={closeModal}
            />
          ))}
        </ModalContent>
      </Dialog>
    </>
  );
};

export type ModalItemType = {
  title: string;
  icon: JSX.Element;
  route: string;
};
type DashboardNavItemType = {
  title: string;
  route: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
};

const navigationItems: DashboardNavItemType[] = [
  {
    title: 'common_home',
    route: '/',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
  },
  {
    title: 'common_wallet',
    route: '/wallet',
    icon: <WalletIcon />,
    activeIcon: <WalletActiveIcon />,
  },
  {
    title: 'common_transactions',
    route: '/transactions',
    icon: <TransactionsIcon />,
    activeIcon: <TransactionsActiveIcon />,
  },
  {
    title: 'common_more',
    route: '/more-pages',
    icon: <MoreIcon />,
    activeIcon: <ActiveMoreIcon />,
  },
];

const uanNavigationItems: DashboardNavItemType[] = [
  {
    title: 'common_crexswap',
    route: '/',
    icon: <DashboardIcon />,
    activeIcon: <DashboardActiveIcon />,
  },

  {
    title: 'common_more',
    route: '/more-pages',
    icon: <MoreIcon />,
    activeIcon: <ActiveMoreIcon />,
  },
];

export default DashboardMobileNav;
