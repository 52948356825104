import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { CurrencyType } from '@libs/types';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

import { TradeType } from 'src/constants';

export type CalculateExchangeGeneral = {
  baseAssetAmount: number;
  quoteAssetAmount: number;
};
export type CalculateExchangeGeneralArgs = {
  amount: number;
  originalAmountAssetCode: CurrencyType;
  baseAssetCode: CurrencyType;
  quoteAssetCode: CurrencyType;
  action: TradeType;
};

export type CalculateExchangeGeneralResponse = {
  calculateExchangeGeneral: DefaultApiResponse<CalculateExchangeGeneral>;
};

export const calculateExchangeGeneralQuery: TypedDocumentNode<
  CalculateExchangeGeneralResponse,
  DefaultArg<CalculateExchangeGeneralArgs>
> = gql`
  query CalculateExchangeGeneral($record: ExchangeRequestRecord!) {
    calculateExchangeGeneral(record: $record) {
      baseAssetAmount
      quoteAssetAmount
      error {
        type
        details
      }
    }
  }
`;
