import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const ReferralsActiveIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
        fill={iconColor}
      />
      <path
        d="M14.08 14.15C11.29 12.29 6.73999 12.29 3.92999 14.15C2.65999 15 1.95999 16.15 1.95999 17.38C1.95999 18.61 2.65999 19.75 3.91999 20.59C5.31999 21.53 7.15999 22 8.99999 22C10.84 22 12.68 21.53 14.08 20.59C15.34 19.74 16.04 18.6 16.04 17.36C16.03 16.13 15.34 14.99 14.08 14.15Z"
        fill={iconColor}
      />
      <path
        d="M19.99 7.34001C20.15 9.28001 18.77 10.98 16.86 11.21C16.85 11.21 16.85 11.21 16.84 11.21H16.81C16.75 11.21 16.69 11.21 16.64 11.23C15.67 11.28 14.78 10.97 14.11 10.4C15.14 9.48001 15.73 8.10001 15.61 6.60001C15.54 5.79001 15.26 5.05001 14.84 4.42001C15.22 4.23001 15.66 4.11001 16.11 4.07001C18.07 3.90001 19.82 5.36001 19.99 7.34001Z"
        fill={iconColor}
      />
      <path
        d="M21.99 16.59C21.91 17.56 21.29 18.4 20.25 18.97C19.25 19.52 17.99 19.78 16.74 19.75C17.46 19.1 17.88 18.29 17.96 17.43C18.06 16.19 17.47 15 16.29 14.05C15.62 13.52 14.84 13.1 13.99 12.79C16.2 12.15 18.98 12.58 20.69 13.96C21.61 14.7 22.08 15.63 21.99 16.59Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default ReferralsActiveIcon;
