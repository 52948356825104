import styled from 'styled-components';
type AccordionType = {
  isOpen?: boolean;
};
export const AccordionCont = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.hover};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`;
export const AccordionHeader = styled.div<AccordionType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borders.default};
  border-width: ${({ isOpen }) =>
    isOpen ? ' 0px 0px 1px 0px' : '0px 0px 0px 0px'};
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.surface.default : theme.colors.surface.hover};
  padding: 0 2.4rem;
  height: 7rem;

  cursor: pointer;
`;
export const AccordionBody = styled.div<AccordionType>`
  height: ${({ isOpen }) => (isOpen ? 'calc( 100% - 1px)' : '0px')};
  overflow: hidden;
  padding: ${({ isOpen }) => (isOpen ? '1.4rem' : '0px')};
`;
export const ArrowIConCont = styled.div<AccordionType>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
`;
