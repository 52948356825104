import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';
import { BankRequisites } from 'src/types';

export type getValidBankRequisitesByUserRes = {
  getValidBankRequisitesByUser: DefaultApiResponse<BankRequisites[]>;
};

export const getValidBankRequisitesByUserQuery: TypedDocumentNode<getValidBankRequisitesByUserRes> = gql`
  query GetValidBankRequisitesByUser {
    getValidBankRequisitesByUser {
      id
      name
      bankName
      bankCode
      iban
      address
      isVerified
    }
  }
`;
