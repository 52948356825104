import { PropsWithChildren } from 'react';

import { JurisdictionsEnum } from '@libs/backend-common';

import { useJurisdictionDetector } from 'src/hooks';

type JurisdictionRendererProps = PropsWithChildren<{
  acceptedJurisdictions: JurisdictionsEnum[];
}>;

export const JurisdictionRenderer: React.FC<JurisdictionRendererProps> = ({
  acceptedJurisdictions,
  children,
}) => {
  const { currentJurisdiction } = useJurisdictionDetector();

  if (
    !currentJurisdiction ||
    !acceptedJurisdictions.includes(currentJurisdiction)
  )
    return null;

  return <>{children}</>;
};
