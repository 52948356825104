import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const SwapHistoryContainer = styled.div``;

export const HeaderLoader = styled(ContentLoader)`
  height: 3.2rem;
  width: 100%;

  ${({ theme }) => theme.media.laptop`
  height: 4.7rem;
`};
`;

export const ExchangeIdTitle = styled.rect`
  width: 9rem;
  height: 2rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;

  ${({ theme }) => theme.media.tablet`
    x: 1.6rem;
    display: block;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 2.4rem;
    display: block;
  `};
`;

export const ExchangeId = styled.rect`
  width: 9rem;
  height: 2rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    x: 1.6rem;
    display: block
  `};

  ${({ theme }) => theme.media.laptop`
    y: 2rem;
    x: 2.4rem;
    display: block;
  `};
`;

export const DateTitle = styled.rect`
  width: 7rem;
  height: 2rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;

  ${({ theme }) => theme.media.tablet`
    x: 20%;
    display:block
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 19%;
    display: block;
  `};
`;

export const Date = styled.rect`
  width: 9rem;
  height: 1.5rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;

  ${({ theme }) => theme.media.tablet`
    x: 20%;
    display:block
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 19%;
    display: block;
  `};
`;

export const DateTime = styled.rect`
  width: 5rem;
  height: 1.5rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 2.5rem;

  ${({ theme }) => theme.media.tablet`
    x: 20%;
    display:block
  `};

  ${({ theme }) => theme.media.laptop`
    y: 3.5rem;
    x: 19%;
    display: block;
  `};
`;

export const WhereFromTitle = styled.rect`
  width: 5rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;
  x: 4%;

  ${({ theme }) => theme.media.tablet`
    x: 36%;
  `}

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 36%;
  `};
`;

export const WhereFromAsset = styled.rect`
  width: 5rem;
  height: 1.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;
  x: 4%;

  ${({ theme }) => theme.media.tablet`
    x: 36%;
  `}

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 36%;
  `};
`;

export const WhereFromAmount = styled.rect`
  width: 3rem;
  height: 1.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 2.5rem;
  x: 4%;

  ${({ theme }) => theme.media.tablet`
    x: 36%;
  `}

  ${({ theme }) => theme.media.laptop`
     x: 36%;
     y: 3.5rem;
  `};
`;

export const WhereToTitle = styled.rect`
  width: 2rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;

  x: 28%;

  ${({ theme }) => theme.media.mobileL`
      x: 30%;
  `};

  ${({ theme }) => theme.media.tablet`
      x: 48%;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 50%;
  `};
`;

export const WhereToAsset = styled.rect`
  width: 5rem;
  height: 1.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;

  x: 28%;

  ${({ theme }) => theme.media.mobileL`
     x: 30%;
  `};

  ${({ theme }) => theme.media.tablet`
    x: 48%;
  `};

  ${({ theme }) => theme.media.laptop`
    width: 5rem;
    height: 1.5rem;
    y: 1.35rem;
    x: 50%;
  `};
`;

export const WhereToAmount = styled.rect`
  width: 3rem;
  height: 1.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 2.5rem;

  x: 28%;

  ${({ theme }) => theme.media.mobileL`
     x: 30%;
  `};

  ${({ theme }) => theme.media.tablet`
     x: 48%;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 3.5rem;
    x: 50%;
  `};
`;

export const ExchangeRateTitle = styled.rect`
  width: 9rem;
  height: 2rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;

  ${({ theme }) => theme.media.tablet`
     x: 60%;
     display: block;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 63%;
    display: block;
  `};
`;

export const ExchangeRate = styled.rect`
  width: 5rem;
  height: 2rem;
  display: none;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1rem;

  ${({ theme }) => theme.media.mobileL`
      x: calc(100% - 9rem);
  `};

  ${({ theme }) => theme.media.tablet`
    x: 60%;
    display: block;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 2rem;
    x: 63%;
    display: block;
  `};
`;

export const StatusTitle = styled.rect`
  width: 9rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.1rem;

  x: 55%;

  ${({ theme }) => theme.media.tablet`
     x: 78%;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 81%;
  `};
`;

export const Status = styled.rect`
  width: 9rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1rem;

  x: 55%;

  ${({ theme }) => theme.media.tablet`
    x: 78%;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 2rem;
    x: 81%;
  `};
`;

export const HeaderLine = styled.rect`
  height: 0.1rem;
  width: 100%;

  y: 3.1rem;
  ${({ theme }) => theme.media.laptop`
    y: 4.6rem;
  `};
`;

export const BodyLine = styled.rect`
  height: 0.1rem;
  width: 100%;

  y: 6rem;
`;

export const BodyLoaderWrapper = styled(ContentLoader)`
  height: 6.1rem;
  width: 100%;
`;
