import {
  DefaultApiResponse,
  DefaultArg,
  TwoFaTokenPayload,
} from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type EditEmailArgs = {
  email: string;
  code: string;
  tokenPayload: TwoFaTokenPayload;
};

export type EditEmailResponse = {
  changeEmail: DefaultApiResponse<{ accessToken: string }>;
};
export const editEmailMutation: TypedDocumentNode<
  EditEmailResponse,
  DefaultArg<EditEmailArgs>
> = gql`
  mutation EditEmail($record: UpdateEmailRecord!) {
    changeEmail(record: $record) {
      accessToken
    }
  }
`;
