import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useApiResponse, useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { CurrencyType } from '@libs/types';
import { AMOUNT_TO_SHOW_PER_PAGE } from '@libs/helpers';

import {
  BalanaceFinancialChart,
  Pagination,
  TransactionFilters,
  TransactionTable,
} from 'src/components';
import AssetPricesLoader from 'src/content-loaders/dashboard-asset-prices/AssetPricesLoader';
import {
  getExchangeRateHistoryForPeriod,
  getWalletInfo,
  HistoryPeriodEnumKeys,
} from 'src/gql';
import { PRICES_POLL_INTERVAL } from 'src/constants';
import { useTranslation } from 'src/hooks';
import { ExchangeTabItem, TransactionFiltersType } from 'src/types';

import { getUsdOrEurCurrencySymbol } from 'src/helpers';
import {
  useAssetTransactionFilters,
  useAssetTransactionPagination,
  useAssetDetailsTabsOptions,
} from './hooks';

import { AssetCurrentPrice } from './components/AssetCurrentPrice';
import { AssetDetailsHeader } from './components/AssetDetailsHeader';
import { AssetDetailsMobileHeader } from './components/AssetDetailsMobileHeader';
import { AssetUserBalance } from './components/AssetUserBalance';

import {
  AssetChart,
  AssetDetailsPageWrapper,
  AssetPriceBalanceWrapper,
  AssetPricesWrapper,
  ChartWrapper,
  CustomTabs,
  CustomTypography,
  TableContainer,
  TableWrapper,
  TabsAndTitleWrapper,
  TransactionsWrapper,
  CustomPageContainer,
} from './styles';

const AssetDetailsPage = () => {
  const [hasTransactions, setHasTransactions] = useState(false);
  const [searchParams] = useSearchParams();
  const assetCode = String(searchParams.get('assetCode'));
  const { activeTabObject, setActiveTabObject, tabItems } =
    useAssetDetailsTabsOptions();
  const { t } = useTranslation();

  const walletRes = useQuery(getWalletInfo, {
    variables: { record: { requestedAssetCode: assetCode } },
    fetchPolicy: 'network-only',
    pollInterval: PRICES_POLL_INTERVAL,
  });
  const { response, loading } = useApiResponse(walletRes);
  const asset = response?.getWalletInfo[0];

  const exchangeRateRes = useQuery(getExchangeRateHistoryForPeriod, {
    variables: {
      record: {
        baseAssetCode: assetCode,
        days: activeTabObject.dayEnum,
        quoteAssetCode: CurrencyType.USD,
      },
    },
  });
  const assetPrice = Number(asset?.price) * Number(asset?.amount);
  const { response: ExchangeRateResponse } = useApiResponse(exchangeRateRes);

  const {
    multiSelectData,
    setDateFilters,
    appliedFilters,
    transactionFilters,
    currentPage,
    setCurrentPage,
    setTransactionFilters,
    isFiltered,
  } = useAssetTransactionFilters(assetCode);

  const handleFilterRenewal = useCallback(
    (newValue?: TransactionFiltersType) => {
      setTransactionFilters(
        (prev) =>
          newValue || {
            ...prev,
            ASSET: [String(asset?.code)],
            TYPE: multiSelectData[0].selectedValues,
            STATUS: multiSelectData[1].selectedValues,
          }
      );
      setCurrentPage(1);
    },
    [setTransactionFilters, setCurrentPage, asset?.code, multiSelectData]
  );
  const totalPages = useAssetTransactionPagination(
    AMOUNT_TO_SHOW_PER_PAGE,
    appliedFilters
  );

  const { width } = useDimensions();

  const right = width < screenSizes.laptop ? 0 : 33;

  const customMargin = {
    top: 0,
    left: 0,
    right,
    bottom: 0,
  };

  const isMobile = width < screenSizes.laptop;

  const shouldHideXAxis =
    activeTabObject.dayEnum === HistoryPeriodEnumKeys.ONE_DAY;

  const handleTabClick = (id: string | number) => {
    setActiveTabObject(tabItems.find((e) => e.id === id) as ExchangeTabItem);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CustomPageContainer>
      <AssetDetailsPageWrapper>
        <AssetDetailsHeader
          assetName={asset?.name}
          assetCode={asset?.code}
          icon={asset?.iconUrl}
          loading={loading}
        />
        <AssetDetailsMobileHeader
          assetName={asset?.name}
          assetCode={asset?.code}
          icon={asset?.iconUrl}
          loading={loading}
        />
        {loading ? (
          <AssetPricesLoader />
        ) : (
          <AssetPricesWrapper>
            <AssetPriceBalanceWrapper>
              <AssetCurrentPrice
                rate={asset?.change ? asset.change : '0.00%'}
                price={asset?.price}
                assetCode={asset?.code}
                isFavorite={asset?.isFavorite}
              />
              <AssetUserBalance
                assetAmount={String(asset?.amount)}
                assetCode={asset?.code}
                assetPrice={assetPrice}
              />
            </AssetPriceBalanceWrapper>

            <AssetChart>
              <TabsAndTitleWrapper>
                <CustomTypography type="headline" fontFamily="primaryBold">
                  {t('common_asset_chart', { asset: assetCode })}
                </CustomTypography>
                <CustomTabs
                  activeTab={activeTabObject.id}
                  handleTabClick={handleTabClick}
                  tabs={tabItems}
                />
              </TabsAndTitleWrapper>
              <ChartWrapper>
                <BalanaceFinancialChart
                  shouldHideYAxis={isMobile}
                  shouldHideXAxis={shouldHideXAxis}
                  tickInterval={6}
                  currencySymbol={getUsdOrEurCurrencySymbol()}
                  xAxisMargin={35}
                  dateFormat={activeTabObject.dateFormat}
                  customMargin={customMargin}
                  data={ExchangeRateResponse?.getExchangeRateHistoryForPeriod}
                />
              </ChartWrapper>
            </AssetChart>
          </AssetPricesWrapper>
        )}
        <TableContainer>
          <TransactionsWrapper>
            <TransactionFilters
              title={t('asset_details_transactions_title', {
                assetCode: asset?.code || '',
              })}
              setDateFilters={setDateFilters}
              onFiltersSubmit={handleFilterRenewal}
              multiSelectData={multiSelectData}
              transactionFilterTypes={transactionFilters}
              isFiltered={isFiltered}
              appliedFilters={appliedFilters}
              hasTransactions={hasTransactions}
            />
            <TableWrapper hasTransactions={hasTransactions}>
              <TransactionTable
                isOneAssetTransactions={true}
                setHasTransactions={setHasTransactions}
                hasTransactions={hasTransactions}
                limit={AMOUNT_TO_SHOW_PER_PAGE}
                skip={(currentPage - 1) * AMOUNT_TO_SHOW_PER_PAGE}
                appliedFilters={appliedFilters}
              />
            </TableWrapper>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onClick={handlePageClick}
            />
          </TransactionsWrapper>
        </TableContainer>
      </AssetDetailsPageWrapper>{' '}
    </CustomPageContainer>
  );
};

export default AssetDetailsPage;
