import { Button, TransparentButton, Typography } from '@libs/components';
import styled from 'styled-components';

export const SwapDestinationDetailsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2.2rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const DestinationDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;

  `};
`;

export const QrBackgroundWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 0.8rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background: linear-gradient(180deg, #2869f4 0%, #3049b5 100%);

  ${({ theme }) => theme.media.tabletL`
     padding: 1.6rem;
     margin-bottom: 0;
     margin-right: 1.8rem;
  `};
`;

export const QrWrapper = styled.div`
  padding: 1rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.text.interactive};

  ${({ theme }) => theme.media.tabletL`
    padding: 2rem;
  `};
`;

export const DestinationDetails = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const SwapDetailsField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  ${({ theme }) => theme.media.tabletL`
    &:not(:last-child) {
      margin-bottom: 0.9rem;
    }
  `}
`;

export const LinkIconWrapper = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.8rem;
  height: 3.8rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const SwapStepContainer = styled.div`
  padding: 3rem 3.2rem;
  width: 100%;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  ${({ theme }) => theme.media.tabletL`
    padding: 2.6rem 9rem 3.6rem;
  `}
`;

export const SwapDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1.6rem;
  padding: 0 1.6rem 3rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tabletL`
    align-items: start;

    padding: 2rem 3.2rem 3.2rem;

    border: 0.1rem solid ${
      theme.colors.borders.default as unknown as TemplateStringsArray
    };
  `}
`;

export const DetailsHeader = styled.div`
  margin-bottom: 3.4rem;
  padding: 0.8rem 1.6rem;

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.disabled};

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 1.8rem;
    padding: 0;

    background-color:  initial;
  `};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 0 1.6rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tabletL`
    padding: 2.2rem;
  `};
`;

export const DetailsFIeldWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 1.2rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  }

  ${({ theme }) => theme.media.tabletL`
    &:not(:last-child) {
      margin-bottom: 2.5rem;
      border-bottom: none;
    }
  `};
`;

export const DetailsTitle = styled(Typography)`
  display: none;

  width: 30%;

  ${({ theme }) => theme.media.tabletL`
    display: initial;
  `};
`;

export const ProgressBarWrapper = styled.div`
  display: flex;

  width: 100%;

  padding: 4.2rem 3rem 3.8rem;

  ${({ theme }) => theme.media.tabletL`
    padding: 2.4rem 3.6rem 5.2rem;
  `};
`;

export const SwapsBySwapTaskContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.5rem 1.6rem 2rem;
  margin: 0 1.6rem 1.6rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};

  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};

  ${({ theme }) => theme.media.tabletL`
    padding: 3.2rem;
    margin: 0 0 2rem;
  `}
`;

export const SwapsBySwapTaskHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 100%;
  margin-bottom: 1.6rem;
  ${({ theme }) => theme.media.tablet`
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      margin-bottom: 2.2rem;
  `}
`;

export const ExportButton = styled(Button)`
  display: none;
  align-items: center;
  justify-content: center;

  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  width: 13rem;
  height: 4.8rem;
  padding: 0 2rem;

  margin-left: 1.1rem;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
      display: flex;
  `}
`;

export const MobileExportButtonWrapper = styled.div`
  margin: 1.2rem 0;

  ${({ theme }) => theme.media.tablet`
      display:none;
  `};
`;
export const MobileExportButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
`;
