import { FC, useCallback, useEffect, useState } from 'react';

import { useTheme } from 'styled-components';

import { useMutation } from '@apollo/client';

import { Typography, CodeInputs, ErrorMessage } from '@libs/components';
import { useApiResponse, useAuth, useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { meQuery } from '@libs/graphql';

import { useTranslation } from 'src/hooks';
import {
  generateQrCodeMutation,
  GoogleActiveTwoFaResponse,
  toggle2FaArgs,
  turnOnGoogle2FAMutation,
} from 'src/gql';
import { getTokenPayload } from 'src/helpers';

import GoogleAuthenticatorSteps from './GoogleAuthSteps';

import {
  ButtonAndTextWrapper,
  ButtonWrapper,
  HowToButton,
  Line,
  QrCodeWrapper,
  QrCodeContainer,
  CustomCopy,
  EnterGoogleContainer,
  InputsWrapper,
  Qr,
  ErrorMessageWrapper,
} from './styles';

type EnterGoogleProps = {
  onFinish?: () => void;
  activateCodes: Omit<toggle2FaArgs, 'twoFactorCode'>;
};

export const EnterGoogle: FC<EnterGoogleProps> = ({
  onFinish,
  activateCodes,
}) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const { login, decodeToken } = useAuth();

  const [isInfoOpened, setIsInfoOpened] = useState(false);

  const handleButtonClick = () => setIsInfoOpened((prev) => !prev);

  const buttonTextColor = isInfoOpened
    ? colors.interactive.brandColor
    : colors.text.default;

  const { width } = useDimensions();

  const [handleGetQrCode, getQrRes] = useMutation(generateQrCodeMutation);

  useEffect(() => {
    handleGetQrCode();
  }, [handleGetQrCode]);

  const [turnOnG2fa, turnOnG2faRes] = useMutation(turnOnGoogle2FAMutation, {
    refetchQueries: [
      {
        query: meQuery,
      },
    ],
  });

  const handleSuccess = useCallback(
    (data: GoogleActiveTwoFaResponse) => {
      onFinish?.();
      login(data.turnOnGoogle2FA.accessToken);
    },
    [onFinish, login]
  );

  const { errorMessage } = useApiResponse(turnOnG2faRes, handleSuccess);

  const handleInputsFinish = useCallback(
    (val: string) => {
      const token = decodeToken();

      turnOnG2fa({
        variables: {
          record: {
            twoFactorCode: val,
            tokenPayload: getTokenPayload(token),
            ...activateCodes,
          },
        },
      });
    },
    [decodeToken, turnOnG2fa, activateCodes]
  );

  const { response } = useApiResponse(getQrRes);

  const secret = response?.generateQrCode.secret;

  const qrCode = response?.generateQrCode.qrCode;

  return (
    <EnterGoogleContainer data-testid="googleTwoFa">
      <QrCodeContainer>
        <QrCodeWrapper>
          <Qr src={qrCode} />
        </QrCodeWrapper>

        <CustomCopy
          text={response?.generateQrCode.secret || '--'}
          buttonTextBeforeCopying={secret}
          buttonTextAfterCopying={secret}
        />
      </QrCodeContainer>
      <ButtonAndTextWrapper>
        <Typography
          type={width <= screenSizes.mobileM ? 'caption' : 'subHeadline'}
        >
          {t('activate_2fa_google_desc')}
        </Typography>
        <GoogleAuthenticatorSteps
          isOpen={isInfoOpened}
          setIsOpen={setIsInfoOpened}
        />
        <ButtonWrapper>
          <HowToButton onClick={handleButtonClick} isInfoOpened={isInfoOpened}>
            <Typography
              type="bodyButton"
              fontFamily="primaryBold"
              color={buttonTextColor}
            >
              {t('activate_2fa_how_to_scan')}
            </Typography>
          </HowToButton>
          <Line />
        </ButtonWrapper>
      </ButtonAndTextWrapper>
      <InputsWrapper>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {t('activate_google_enter_code')}
        </Typography>
        <CodeInputs onComplete={handleInputsFinish} />
      </InputsWrapper>
      <ErrorMessageWrapper>
        <ErrorMessage errorMessage={t(errorMessage)} errorTestId="auth-error" />
      </ErrorMessageWrapper>
    </EnterGoogleContainer>
  );
};

export default EnterGoogle;
