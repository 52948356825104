import styled from 'styled-components';

export const BeneficiaryItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const BeneficiaryItemTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.low};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  margin-bottom: 0.9rem;
  padding: 0 1rem;
`;
export const BeneficiaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  margin-bottom: 1.8rem;
  &:last-child {
    margin: 0;
    padding: 0;
    border: none;
  }
`;
