import styled from 'styled-components';

import { ToolTip } from '@libs/components';

import { InfoCircleIcon } from 'src/assets';

type TileProps = {
  color: string;
};

export const Tile = styled.div<TileProps>`
  height: 1rem;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme, color }) =>
    color || theme.colors.interactive.secondaryDisabled};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 0.8rem;
  height: 1.8rem;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const CustomToolTip = styled(ToolTip)`
  .tooltipbox {
    left: unset;
    right: -1.4rem;
    top: -25.5rem;
    z-index: ${({ theme }) => theme.zIndex.highest};
    ${({ theme }) => theme.media.tabletS`
      right: -1.6rem;
    `};
  }
  .tooltip {
    &:after {
      border-bottom-right-radius: 0.4rem;
      top: calc(100% - 0.85rem);
      height: 1.7rem;
      width: 1.7rem;
      left: unset;
      right: 1.5rem;
    }
  }
  height: 1.6rem;
  width: 1.6rem;
`;

export const CustomIcon = styled(InfoCircleIcon)`
  height: 1.6rem;
  width: 1.6rem;
`;

export const TileContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 30rem;
  gap: 0.8rem;
`;

export const StrengthInfoContainer = styled.div`
  padding: 2rem;
`;

export const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.borders.default};
  width: 100%;
  height: 0.1rem;
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomDiv = styled.div`
  width: 27rem;
`;
