import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type CheckPhoneResponse = {
  checkPhone: DefaultApiResponse<boolean>;
};

export type CheckPhoneArgs = {
  destination: string;
};

export const checkPhoneMutation: TypedDocumentNode<
  CheckPhoneResponse,
  DefaultArg<CheckPhoneArgs>
> = gql`
  mutation CheckPhone($record: PhoneVerificationRecord!) {
    checkPhone(record: $record)
  }
`;
