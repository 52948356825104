import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const UploadIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId = 'uploadIcon',
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M18.3122 5.50001C16.6383 3.8261 13.96 3.8261 12.2861 5.50001L5.00461 12.6978C4.00026 13.7022 4.00026 15.2924 5.00461 16.2967C6.00895 17.3011 7.59917 17.3011 8.60352 16.2967L14.6296 10.2707C14.9644 9.93588 14.9644 9.43371 14.6296 9.09892C14.2948 8.76414 13.7927 8.76414 13.4579 9.09892L7.43178 15.125C7.097 15.4598 6.59482 15.4598 6.26004 15.125C5.92526 14.7902 5.92526 14.2881 6.26004 13.9533L13.5416 6.67175C14.5459 5.6674 16.1361 5.6674 17.1405 6.67175C18.1448 7.6761 18.1448 9.26631 17.1405 10.2707L9.85895 17.4685C8.18504 19.1424 5.50678 19.1424 3.83287 17.4685C2.15895 15.7946 2.15895 13.1163 3.83287 11.4424L13.4579 1.9011C13.7927 1.56631 13.7927 1.06414 13.4579 0.729358C13.1231 0.394576 12.6209 0.394576 12.2861 0.729358L2.57743 10.3544C0.233955 12.6978 0.233955 16.4641 2.57743 18.8076C4.92091 21.1511 8.68722 21.1511 11.0307 18.8076L18.3122 11.6098C19.9024 9.93588 19.9024 7.17392 18.3122 5.50001Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default UploadIcon;
