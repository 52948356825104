import { useTheme } from 'styled-components';

import { BalanceType } from 'src/constants';
import {
  useCurrency,
  useJurisdictionDetector,
  useTranslation,
} from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import {
  CurrencySwitcherContainer,
  GelContainer,
  UsdContainer,
  AnimatedTypography,
  ActiveCurrencyUnderline,
} from './styles';

type CurrencySwitcherProps = {
  onClick?: (id: BalanceType) => void;
  className?: string;
  testId?: string;
};

const CurrencySwitcher: React.FC<CurrencySwitcherProps> = ({
  onClick,
  className,
  testId = 'currencySwitcher',
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { setCurrency, currency } = useCurrency();
  const { currentJurisdiction } = useJurisdictionDetector();

  const isSecondaryCurrencyActive = [BalanceType.GEL, BalanceType.EUR].includes(
    currency
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const elementId = e.currentTarget.id as BalanceType;
    setCurrency(elementId);
    if (onClick) onClick(elementId);
  };

  const secondaryCurrencyTabId =
    currentJurisdiction === JurisdictionsEnum.GE
      ? BalanceType.GEL
      : BalanceType.EUR;

  return (
    <CurrencySwitcherContainer className={className} data-cy={testId}>
      <UsdContainer
        onClick={handleClick}
        data-cy="currencyUsd"
        id={BalanceType.USD}
      >
        <AnimatedTypography
          type="caption"
          fontFamily="primaryBold"
          color={
            !isSecondaryCurrencyActive
              ? colors.text.default
              : colors.text.secondary
          }
        >
          {t('currency_usd')}
        </AnimatedTypography>
      </UsdContainer>
      <GelContainer
        onClick={handleClick}
        data-cy="currencyGel"
        id={secondaryCurrencyTabId}
        isActive={isSecondaryCurrencyActive}
      >
        <AnimatedTypography
          type="caption"
          fontFamily="primaryBold"
          color={
            isSecondaryCurrencyActive
              ? colors.text.default
              : colors.text.secondary
          }
        >
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
            {t('currency_gel')}
          </JurisdictionRenderer>
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
            {t('currency_eur')}
          </JurisdictionRenderer>
        </AnimatedTypography>
      </GelContainer>

      <ActiveCurrencyUnderline
        data-cy="currencyBackground"
        className="activeCurrencyUnderline"
        isGelActive={isSecondaryCurrencyActive}
      />
    </CurrencySwitcherContainer>
  );
};

export default CurrencySwitcher;
