import { gql, TypedDocumentNode } from '@apollo/client';
import { Loan, LoanListItem } from '@libs/types';
import {
  DefaultArg,
  loanFragment,
  assetFragment,
  DefaultApiResponse,
  loanPaymentFragment,
  loanListItemFragment,
} from '@libs/graphql';

type GetLoans = {
  activeLoans: LoanListItem[];
  oldLoans: LoanListItem[];
  pendingLoans: LoanListItem[];
};

export type getLoansByUserRes = {
  getLoansByUser: DefaultApiResponse<GetLoans>;
};

export const getLoansByUserQuery: TypedDocumentNode<getLoansByUserRes> = gql`
  query GetLoansByUser {
    getLoansByUser {
      activeLoans {
        ...loanListItemFields
        loanAsset {
          ...assetFields
        }
        collateralAsset {
          ...assetFields
        }
        currentCollateralPrice
        currentCollateralAmount
      }
      oldLoans {
        ...loanListItemFields
        loanAsset {
          ...assetFields
        }
        collateralAsset {
          ...assetFields
        }
        currentCollateralPrice
        currentCollateralAmount
      }
      pendingLoans {
        ...loanListItemFields
        loanAsset {
          ...assetFields
        }
        collateralAsset {
          ...assetFields
        }
        currentCollateralPrice
        currentCollateralAmount
      }
    }
  }

  ${loanListItemFragment}
  ${assetFragment}
`;

export type GetLoanByIdRes = { getLoanById: DefaultApiResponse<Loan> };
export type GetLoanByIdArgs = DefaultArg<{ id: number }>;

export const getLoanByIdQuery: TypedDocumentNode<
  GetLoanByIdRes,
  GetLoanByIdArgs
> = gql`
  query GetLoanById($record: LoanIdRecord!) {
    getLoanById(record: $record) {
      ...loanFields
      loanAsset {
        ...assetFields
      }
      collateralAsset {
        ...assetFields
      }

      payments {
        ...loanPaymentFields
      }
      accruedCommissionAmount
      minimumPaymentAmount
      liquidationCollateralPrice
      currentCollateralPrice
      currentCollateralAmount
      releasedCollateralAmount
      totalAccruedInterest
      liquidatedCollateralAmount
      totalPaidAmount
    }
  }

  ${assetFragment}
  ${loanFragment}
  ${loanPaymentFragment}
`;
