import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const StakingListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  margin-top: 7.6rem;
  padding: 2.8rem 1.6rem 1.4rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
      flex-direction: row;
      justify-content: space-between;

      height: 12.05rem;
      
      margin-top: 6.5rem;
      padding: 1.8rem 2rem 2.1rem;
  `}
`;

export const MainLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  ${({ theme }) => theme.media.tablet`
      flex-direction: row;

      width: 80%;
      height:100%;

      border: none;

      background-color: transparent;
  `};
`;

export const FirstBoxLoader = styled(ContentLoader)`
  height: 7.76rem;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
      width: 33%;
      height: 100%;

      border: none;

      background-color: transparent;
  `};
`;

export const IconLoader = styled.circle`
  cx: calc(50% - 6rem);
  cy: 50%;

  r: 1.7rem;

  ${({ theme }) => theme.media.tablet`
      r: 1.9rem;

      cx: 1.9rem;
  `};
`;

export const TitleLoader = styled.rect`
  x: calc(50% - 3.4rem);
  y: calc(50% - 2.6rem);

  height: 2.4rem;
  width: 13rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      x: 5.4rem;

      width: 11rem;
  `};
`;

export const DescLoader = styled.rect`
  x: calc(50% - 3.4rem);
  y: calc(50% + 0.2rem);

  height: 2.4rem;
  width: 11rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      x: 5.4rem;

      width: 10rem;
  `};
`;

export const Line = styled.rect`
  display: none;

  x: calc(100% - 0.2rem);
  y: 0;

  height: 100%;
  width: 0.2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      display: initial;
  `};
`;

export const SecondaryLoadersWrapper = styled.div`
  display: flex;

  width: 100%;
  height: 12.9rem;

  margin-top: 1.1rem;

  ${({ theme }) => theme.media.tablet`
      width: 66%;
      height: 100%;

      margin-top: 0;
  `};
`;

export const SecondBoxLoader = styled(ContentLoader)`
  width: calc((100% - 0.9rem) / 2);
  height: 100%;

  margin-right: 0.9rem;
  padding: 1.7rem 1.5rem 0;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${IconLoader} {
    cx: 1.9rem;
    cy: 1.7rem;
  }
  ${TitleLoader} {
    x: 0;
    y: 4.6rem;
  }
  ${DescLoader} {
    x: 0;
    y: 7.4rem;
  }

  ${({ theme }) => theme.media.tablet`
      width: 50%;

      padding: 0;

      border: none;

      background-color: transparent;

      ${IconLoader as unknown as TemplateStringsArray} {
        cx: 2.9rem;
        cy: 50%;
      }
      ${TitleLoader as unknown as TemplateStringsArray} {
        x: 5.8rem;
        y: calc(50% - 2.6rem);
      }
      ${DescLoader as unknown as TemplateStringsArray} {
        x: 5.8rem;
        y: calc(50% + 0.2rem);
      }
  `};
`;

export const ThirdBoxLoader = styled(ContentLoader)`
  width: calc((100% - 0.9rem) / 2);
  height: 100%;

  padding: 1.7rem 1.5rem 0;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${IconLoader} {
    cx: 1.9rem;
    cy: 1.7rem;
  }
  ${TitleLoader} {
    x: 0;
    y: 4.6rem;
  }
  ${DescLoader} {
    x: 0;
    y: 7.4rem;
  }
  ${Line} {
    x: 0.2rem;
  }

  ${({ theme }) => theme.media.tablet`
      width: 50%;

      padding: 0;

      border: none;

      background-color: transparent;

      ${IconLoader as unknown as TemplateStringsArray} {
        cx: 3.2rem;
        cy: 50%;
      }
      ${TitleLoader as unknown as TemplateStringsArray} {
        x: 6.1rem;
        y: calc(50% - 2.6rem);
      }
      ${DescLoader as unknown as TemplateStringsArray} {
        x: 6.1rem;
        y: calc(50% + 0.2rem);
      }
  `};
`;

export const ButtonWrapper = styled(ContentLoader)`
  height: 4.8rem;
  max-width: 40rem;
  width: calc(100% - 0.4rem);

  margin-top: 1.8rem;

  ${({ theme }) => theme.media.tablet`
      max-width: 16rem;
      width: 100%;

      margin-top: 0;
      margin-left: 2rem;
  `};
`;

export const ButtonLoader = styled.rect`
  x: 0;
  y: 0;

  height: 100%;
  width: 100%;

  rx: ${({ theme }) => theme.borderRadius.high};
  ry: ${({ theme }) => theme.borderRadius.high};
`;
