import { object, string } from 'yup';
import { ValidationErrorMessages } from './types';

export const toggleEmailTwoFaValidation = object({
  googleCode: string()
    .optional()
    .matches(/^[0-9]{6}$/, ValidationErrorMessages.TWO_FA_CODE),
  emailCode: string()
    .required(ValidationErrorMessages.REQUIRED)
    .matches(/^[0-9]{6}$/, ValidationErrorMessages.TWO_FA_CODE),
});
