import { TransactionStatusType } from '@libs/types';
import { CurrencyType, TransactionType } from 'src/constants';

export type TransactionFiltersType = {
  MINDATE?: string;
  MAXDATE?: string;
  ASSET: string[];
  TYPE: string[];
  STATUS: string[];
};

export type TransactionAppliedFilters = {
  assetCodes: CurrencyType[];
  types: TransactionType[];
  statuses: TransactionStatusType[];
  minDate?: string;
  maxDate?: string;
};

export enum TransactionMultiSelectFilterValues {
  ASSET = 'ASSET',
  STATUS = 'STATUS',
  TYPE = 'TYPE',
}
