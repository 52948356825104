import { TransparentButton, Typography } from '@libs/components';
import { DISCLAIMER_HEIGHT } from 'src/constants';
import styled from 'styled-components';

type MobileAppDisclaimerContainerProps = {
  position?: string;
};

export const MobileAppDisclaimerContainer = styled.div<MobileAppDisclaimerContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 1.6rem 1rem;

  position: ${({ position }) => position};
  top: 0;

  width: 100%;
  height: 100%;
  max-height: ${DISCLAIMER_HEIGHT}rem;

  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
  z-index: ${({ theme }) => theme.zIndex.highest};

  ${({ theme }) => theme.media.tabletS`
    justify-content: space-between;
    padding: 1.6rem 4.5rem;
    
  `};
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;

  ${({ theme }) => theme.media.tablet`
    flex-direction: row;
    gap: 0.8rem;
    
  `};
`;

export const WebImages = styled.div`
  display: none;
  ${({ theme }) => theme.media.tablet`
  display: flex;
  gap: 0.8rem;
    flex-direction: row;
    
  `};
`;

export const MobileImg = styled.img`
  max-width: 11rem;
  height: 3rem;
  cursor: pointer;
`;

export const IconForMobile = styled.img`
  max-width: 11rem;
  height: 3rem;
  cursor: pointer;
`;

export const MobileTransparentButton = styled(TransparentButton)`
  ${({ theme }) => theme.media.tablet`
  display: none;
    
  `};
`;

export const DownloadText = styled(Typography)`
  text-decoration: underline;

  cursor: pointer;
`;

export const CustomTypography = styled(Typography)`
  font-size: 1.2rem;
  ${({ theme }) => theme.media.mobileL`
   font-size: 1.4rem;
  `};
`;

export const CloseIconWrapper = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 0.8rem;
  cursor: pointer;

  width: 2.6rem;
  height: 2.6rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;
