import { useQuery } from '@apollo/client';
import { useApiResponse, useAssets as useNativeAssets } from '@libs/hooks';
import { getAllAssetsQuery } from 'src/gql';
import { useJurisdictionDetector } from './useJurisdictionDetector';

export const useAssets = (skip = false) => {
  const { currentJurisdiction } = useJurisdictionDetector();
  const { data, loading } = useQuery(getAllAssetsQuery, {
    skip,
  });

  const {
    response,
    errors,
    loading: assetsLoading,
  } = useApiResponse({ data, loading });

  return useNativeAssets(
    errors,
    assetsLoading,
    currentJurisdiction,
    response?.getAllAssets
  );
};
