import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const WalletContainer = styled.div``;

export const HeaderLoader = styled(ContentLoader)`
  height: 3.2rem;
  width: 100%;

  ${({ theme }) => theme.media.laptop`
    height: 4.7rem;
  `};
`;

export const HeaderLine = styled.rect`
  height: 0.1rem;
  width: 100%;

  y: 3.1rem;
  ${({ theme }) => theme.media.laptop`
    y: 4.6rem;
  `};
`;

export const Asset = styled.rect`
  width: 7rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;

  ${({ theme }) => theme.media.mobileL`
    x: 1.6rem;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 2.4rem;
  `};
`;

export const Amount = styled.rect`
  width: 7rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;
  x: 40%;

  ${({ theme }) => theme.media.tablet`
    x: 25%;
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: 20%;
  `};
`;

export const Value = styled.rect`
  display: none;

  width: 9rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1.35rem;
  x: 36%;

  ${({ theme }) => theme.media.laptop`
    display:initial;
  `};
`;

export const Action = styled.rect`
  width: 7rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 0.6rem;

  x: calc(100% - 7rem);

  ${({ theme }) => theme.media.mobileL`
      x: calc(72% + 1.8rem);
  `};

  ${({ theme }) => theme.media.tablet`
     x: calc(50% + 1.5rem);
  `};

  ${({ theme }) => theme.media.laptop`
    y: 1.35rem;
    x: calc(53% + 1rem);
  `};
`;

export const BodyLoaderWrapper = styled(ContentLoader)`
  height: 6.1rem;
  width: 100%;
`;

export const BodyLine = styled.rect`
  height: 0.1rem;
  width: 100%;

  y: 6rem;
`;

export const AssetIcon = styled.circle`
  cx: 2.4rem;
  cy: 3rem;

  r: 1.9rem;

  ${({ theme }) => theme.media.mobileL`
      cx: 3.5rem;
  `};

  ${({ theme }) => theme.media.laptop`
      cx: 4.3rem;
  `};
`;

export const AssetName = styled.rect`
  height: 1.8rem;
  width: 7rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1rem;
  x: 5.3rem;

  ${({ theme }) => theme.media.mobileL`
      x: 6.4rem;
  `};

  ${({ theme }) => theme.media.laptop`
      x: 7.2rem;
  `};
`;

export const AssetCode = styled.rect`
  height: 1.8rem;
  width: 3rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 3rem;
  x: 5.3rem;

  ${({ theme }) => theme.media.mobileL`
      x: 6.4rem;
  `};

  ${({ theme }) => theme.media.laptop`
      x: 7.2rem;
  `};
`;

export const AmountInUsd = styled.rect`
  height: 1.8rem;
  width: 8rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 1rem;
  x: 40%;

  ${({ theme }) => theme.media.tablet`
      x: 25%;
  `};

  ${({ theme }) => theme.media.laptop`
      display: none;
  `};
`;

export const AssetAmount = styled.rect`
  height: 1.8rem;
  width: 9rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  y: 3rem;
  x: 40%;
  ${({ theme }) => theme.media.tablet`
      x: 25%;
  `};

  ${({ theme }) => theme.media.laptop`
      height: 2rem;
      y: 2rem;
      x: 20%;
  `};
`;

export const AssetValue = styled.rect`
  display: none;

  width: 8rem;
  height: 2rem;

  x: 36%;
  y: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
      display: initial;
  `};
`;

export const ActionButton = styled.circle`
  r: 2rem;

  cy: 3rem;

  cx: calc(100% - 2rem);

  ${({ theme }) => theme.media.mobileL`
      cx: calc(72% + 3.8rem);
  `};

  ${({ theme }) => theme.media.tablet`
    display:none;
  `};
`;

export const FirstAction = styled.rect`
  display: none;

  height: 4rem;
  width: 10rem;

  y: 1rem;
  x: calc(50% + 1.5rem);

  rx: 2rem;
  ry: 2rem;

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};

  ${({ theme }) => theme.media.tablet`
    y: 0.8rem;
    x: calc(53% + 1.4rem);
  `};
`;

export const SecondAction = styled.rect`
  display: none;

  height: 4rem;
  width: 10rem;

  y: 1rem;
  x: calc(50% + 13rem);

  rx: 2rem;
  ry: 2rem;

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};

  ${({ theme }) => theme.media.tablet`
    y: 0.8rem;
    x: calc(53% + 12.9rem);
  `};
`;

export const ThirdAction = styled.rect`
  display: none;

  height: 4rem;
  width: 10rem;

  y: 1rem;
  x: calc(50% + 24.5rem);

  rx: 2rem;
  ry: 2rem;

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};

  ${({ theme }) => theme.media.tablet`
    y: 0.8rem;
    x: calc(53% + 24.4rem);
  `};
`;
