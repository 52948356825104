import { Typography } from '@libs/components';
import styled from 'styled-components';

export const PopularAssetListContainer = styled.div`
  padding: 2rem 0 2.4rem 2.2rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border: ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  margin-top: 5.8rem;
  ${({ theme }) => theme.media.laptop`
    margin-top: 1.8rem;
  `}
`;

export const PopularAssetsTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.default};
  font-weight: 700;
  line-height: 3rem;
  font-size: 2rem;
`;

export const PopularAssetsListWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 2rem;
  position: relative;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  max-width: calc(100vw - 3rem);
  ${({ theme }) => theme.media.laptop`
    max-width:unset;
  `}
`;
