import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CheckPasswordArgs = {
  password: string;
};

export type CheckPasswordResponse = {
  checkPassword: DefaultApiResponse<boolean>;
};

export const checkPasswordMutation: TypedDocumentNode<
  CheckPasswordResponse,
  DefaultArg<CheckPasswordArgs>
> = gql`
  mutation CheckPassword($record: CheckPasswordRecord!) {
    checkPassword(record: $record)
  }
`;
