import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const ChangeImageIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45457 13.0909C8.73536 13.0909 8.03231 12.8777 7.43431 12.4781C6.83632 12.0785 6.37023 11.5106 6.09501 10.8461C5.81978 10.1817 5.74777 9.45053 5.88808 8.74515C6.02839 8.03976 6.37472 7.39183 6.88327 6.88327C7.39183 6.37472 8.03976 6.02839 8.74515 5.88808C9.45054 5.74777 10.1817 5.81978 10.8461 6.09501C11.5106 6.37023 12.0785 6.83632 12.4781 7.43431C12.8777 8.03231 13.0909 8.73536 13.0909 9.45457C13.0909 9.9321 12.9969 10.405 12.8141 10.8461C12.6314 11.2873 12.3635 11.6882 12.0259 12.0259C11.6882 12.3635 11.2873 12.6314 10.8461 12.8141C10.405 12.9969 9.9321 13.0909 9.45457 13.0909ZM9.45457 7.27275C9.02305 7.27275 8.60121 7.40071 8.24242 7.64045C7.88362 7.8802 7.60397 8.22095 7.43883 8.61962C7.2737 9.0183 7.23049 9.45699 7.31467 9.88022C7.39886 10.3035 7.60666 10.6922 7.91179 10.9973C8.21692 11.3025 8.60569 11.5103 9.02892 11.5945C9.45215 11.6787 9.89084 11.6354 10.2895 11.4703C10.6882 11.3052 11.0289 11.0255 11.2687 10.6667C11.5084 10.3079 11.6364 9.88609 11.6364 9.45457C11.6364 8.87592 11.4065 8.32096 10.9973 7.91179C10.5882 7.50262 10.0332 7.27275 9.45457 7.27275Z"
        fill={iconColor}
      />
      <path
        d="M14.5455 7.27275H15.2728C15.4657 7.27275 15.6507 7.19613 15.7871 7.05974C15.9235 6.92335 16.0001 6.73836 16.0001 6.54548C16.0001 6.35259 15.9235 6.16761 15.7871 6.03122C15.6507 5.89483 15.4657 5.8182 15.2728 5.8182H14.5455C14.3527 5.8182 14.1677 5.89483 14.0313 6.03122C13.8949 6.16761 13.8183 6.35259 13.8183 6.54548C13.8183 6.73836 13.8949 6.92335 14.0313 7.05974C14.1677 7.19613 14.3527 7.27275 14.5455 7.27275Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7273 16H2.18182C1.60316 16 1.04821 15.7701 0.63904 15.361C0.22987 14.9518 0 14.3968 0 13.8182V5.09091C0 4.51226 0.22987 3.9573 0.63904 3.54813C1.04821 3.13896 1.60316 2.90909 2.18182 2.90909H5.25091L5.56364 1.65091C5.68197 1.17923 5.95448 0.760609 6.33791 0.461507C6.72134 0.162405 7.19371 -2.78421e-05 7.68 3.57957e-09H11.2291C11.7154 -2.78421e-05 12.1877 0.162405 12.5712 0.461507C12.9546 0.760609 13.2271 1.17923 13.3455 1.65091L13.6582 2.90909H16.7273C17.3059 2.90909 17.8609 3.13896 18.2701 3.54813C18.6792 3.9573 18.9091 4.51226 18.9091 5.09091V13.8182C18.9091 14.3968 18.6792 14.9518 18.2701 15.361C17.8609 15.7701 17.3059 16 16.7273 16ZM2.18182 4.36364C1.98893 4.36364 1.80395 4.44026 1.66756 4.57665C1.53117 4.71304 1.45455 4.89802 1.45455 5.09091V13.8182C1.45455 14.0111 1.53117 14.1961 1.66756 14.3324C1.80395 14.4688 1.98893 14.5455 2.18182 14.5455H16.7273C16.9202 14.5455 17.1051 14.4688 17.2415 14.3324C17.3779 14.1961 17.4545 14.0111 17.4545 13.8182V5.09091C17.4545 4.89802 17.3779 4.71304 17.2415 4.57665C17.1051 4.44026 16.9202 4.36364 16.7273 4.36364H13.0909C12.9248 4.36848 12.762 4.31627 12.6297 4.2157C12.4973 4.11513 12.4035 3.97227 12.3636 3.81091L11.9127 2C11.8715 1.83999 11.777 1.69878 11.6448 1.59964C11.5126 1.50049 11.3506 1.44932 11.1855 1.45455H7.68C7.51484 1.44932 7.35283 1.50049 7.22064 1.59964C7.08844 1.69878 6.99395 1.83999 6.95273 2L6.54545 3.81091C6.50564 3.97227 6.41175 4.11513 6.27942 4.2157C6.1471 4.31627 5.98432 4.36848 5.81818 4.36364H2.18182Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default ChangeImageIcon;
