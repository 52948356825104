import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export enum HistoryPeriodEnumKeys {
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
}

export type ExchangeRateHistory = {
  amount?: number;
  createdAt?: Date;
  sellRate?: number;
};

export type GetExchangeRateHistoryForPeriodArgs = {
  baseAssetCode: string;
  quoteAssetCode: string;
  days: HistoryPeriodEnumKeys;
};

export type GetExchangeRateHistoryForPeriodResponse = {
  getExchangeRateHistoryForPeriod: DefaultApiResponse<ExchangeRateHistory[]>;
};

export const getExchangeRateHistoryForPeriod: TypedDocumentNode<
  GetExchangeRateHistoryForPeriodResponse,
  DefaultArg<GetExchangeRateHistoryForPeriodArgs>
> = gql`
  query GetExchangeRateHistoryForPeriod(
    $record: GetExchangeRateHistoryRecord!
  ) {
    getExchangeRateHistoryForPeriod(record: $record) {
      amount: buyRate
      createdAt
    }
  }
`;
