import { useQuery } from '@apollo/client';
import { useApiResponse } from '@libs/hooks';

import { CurrencyType } from 'src/constants';
import { getUserBalances } from 'src/gql';

export const useBalanceInSelectedCurrency = (
  currency?: CurrencyType,
  shouldTakeFromCache = true
) => {
  const res = useQuery(getUserBalances, {
    fetchPolicy: shouldTakeFromCache ? 'cache-first' : 'network-only',
  });
  const { response, loading } = useApiResponse(res);

  const foundAsset = response?.getUserBalances.find(
    (i) => i.asset.code === currency
  );

  return { balance: foundAsset?.availableAmount || 0, loading };
};
