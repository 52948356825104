import styled from 'styled-components';

type ContainerType = {
  topRadiusFlat: boolean;
  bottomRadiusFlat: boolean;
};

export const Container = styled.div<ContainerType>`
  height: 6.8rem;
  width: 100%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  ${({ topRadiusFlat, bottomRadiusFlat }) => {
    const topRadius = !topRadiusFlat ? '1.2rem' : '0';
    const bottomRadius = !bottomRadiusFlat ? '1.2rem' : '0';
    return `border-radius:${topRadius} ${topRadius} ${bottomRadius} ${bottomRadius};`;
  }}
`;
