import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const BookIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const colorToApply = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M10 4.196V16.8864C9.86543 16.8864 9.72293 16.8627 9.61209 16.7993L9.58043 16.7835C8.06043 15.9523 5.40834 15.0814 3.69043 14.8518L3.46084 14.8202C2.70084 14.7252 2.08334 14.0127 2.08334 13.2527V3.68934C2.08334 2.74725 2.85126 2.03475 3.79334 2.11392C5.45584 2.2485 7.97334 3.08767 9.38251 3.96642L9.58043 4.08517C9.69918 4.15642 9.84959 4.196 10 4.196Z"
        fill={colorToApply}
      />
      <path
        d="M17.9167 3.69721V13.2526C17.9167 14.0126 17.2992 14.7251 16.5392 14.8201L16.2779 14.8518C14.5521 15.0814 11.8921 15.9601 10.3721 16.7993C10.2692 16.8626 10.1425 16.8864 10 16.8864V4.19596C10.1504 4.19596 10.3008 4.15637 10.4196 4.08512L10.5542 3.99804C11.9633 3.11137 14.4887 2.26429 16.1512 2.12179H16.1987C17.1408 2.04262 17.9167 2.74721 17.9167 3.69721Z"
        fill={colorToApply}
      />
      <path
        d="M6.63541 7.31494H4.85416C4.52957 7.31494 4.26041 7.04577 4.26041 6.72119C4.26041 6.39661 4.52957 6.12744 4.85416 6.12744H6.63541C6.95999 6.12744 7.22916 6.39661 7.22916 6.72119C7.22916 7.04577 6.95999 7.31494 6.63541 7.31494Z"
        fill={colorToApply}
      />
      <path
        d="M7.22916 9.68994H4.85416C4.52957 9.68994 4.26041 9.42077 4.26041 9.09619C4.26041 8.77161 4.52957 8.50244 4.85416 8.50244H7.22916C7.55374 8.50244 7.82291 8.77161 7.82291 9.09619C7.82291 9.42077 7.55374 9.68994 7.22916 9.68994Z"
        fill={colorToApply}
      />
    </CustomSvg>
  );
};
export default BookIcon;
