import { FeatureModuleEnum } from '@libs/backend-common';

import { useEnabledFeatures } from './useEnabledFeatures';

export const useCheckEnabledTwoFaFeatures = () => {
  const { checkFeature } = useEnabledFeatures();

  const isSms2faAvailable = checkFeature(FeatureModuleEnum.SMS_2FA).isAvailable;

  const isEmail2FaAvailabe = checkFeature(
    FeatureModuleEnum.EMAIL_2FA
  ).isAvailable;

  return { isSms2faAvailable, isEmail2FaAvailabe };
};
