import { ConfirmationModal as ConfirmationModalLibs } from '@libs/components';
import { ConfirmationDialogType } from '@libs/types';
import { DeleteWarning, EditWarning } from 'src/assets';
import { useTranslation } from 'src/hooks';

type ConfirmationModalProps = ConfirmationDialogType & {
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModalLibs
      {...props}
      DeleteWarning={DeleteWarning}
      EditWarning={EditWarning}
      deleteText={t('common_delete')}
      confirmText={t('common_confirm')}
      closeText={t('common_close')}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
    />
  );
};
