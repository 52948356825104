import { gql, TypedDocumentNode } from '@apollo/client';
import { WalletTypeEnum } from '@libs/backend-common';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { WalletInfoItem } from '@libs/types';

export type GetWalletInfoResponse = {
  getWalletInfo: DefaultApiResponse<WalletInfoItem[]>;
};

export type GetWalletInfoArgs = {
  isCrypto?: boolean;
  walletType?: WalletTypeEnum;
  requestedAssetCode?: string;
};
export const getWalletInfo: TypedDocumentNode<
  GetWalletInfoResponse,
  DefaultArg<GetWalletInfoArgs>
> = gql`
  query GetWalletInfo($record: GetWalletRecord!) {
    getWalletInfo(record: $record) {
      code
      amount
      value
      name
      isCrypto
      isFavorite
      isStable
      iconUrl
      isDisabled
      price
      change
      isMemoRequired
      hasNetworks
      roundingPrecision
      priceChange
    }
  }
`;
