import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import {
  DefaultArg,
  DefaultApiResponse,
  swapFragment,
  swapTaskFragment,
} from '@libs/graphql';
import { Swap } from '@libs/types';

export type GetSwapsForExpiredTasksArgs = {
  term?: string;
  take?: number;
  skip?: number;
  statuses?: string[];
  sourceAssetCodes?: string[];
  destinationAssetCodes?: string[];
  minDate?: string;
  maxDate?: string;
};

export type GetSwapsForExpiredTasksResponse = {
  getSwapsForExpiredTasks: DefaultApiResponse<{
    data: Swap[];
    totalAmount: number;
  }>;
};

export const getSwapsForExpiredTasksQuery: TypedDocumentNode<
  GetSwapsForExpiredTasksResponse,
  DefaultArg<GetSwapsForExpiredTasksArgs>
> = gql`
  query GetSwapsForExpiredTasks($record: SwapHistoryFiltersRecord!) {
    getSwapsForExpiredTasks(record: $record) {
      totalAmount
      data {
        ...swapFields
        swapTask {
          ...swapTaskFields
        }
        deposit {
          amount
        }
      }
    }
  }
  ${swapFragment}
  ${swapTaskFragment}
`;
