import { useState } from 'react';

import { Dayjs } from 'dayjs';

import { useLazyQuery } from '@apollo/client';

import { getDate } from '@libs/hooks';

import { FiatMethods } from 'src/constants';
import { getCardTransactionsInfoForUserQuery } from 'src/gql';

export const useCheckCardTransactionInfo = (
  nextStep: () => void,
  openModal: () => void,
  fiatMethod?: FiatMethods
) => {
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState<Dayjs>();

  const triggerLoad = () => setLoading(true);

  const cancelLoad = () => setLoading(false);

  const [getCardTransactionInfo] = useLazyQuery(
    getCardTransactionsInfoForUserQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleButtonClick = async () => {
    if (fiatMethod !== FiatMethods.CARD) {
      nextStep();
      return;
    }

    try {
      triggerLoad();

      const { data } = await getCardTransactionInfo();

      if (data) {
        const { cardTransactionAllowed, cardTransactionEnableDate } =
          data.getCardTransactionsInfoForUser;

        if (cardTransactionAllowed) {
          nextStep();
        } else {
          openModal();
        }

        if (cardTransactionEnableDate) {
          setDate(getDate(cardTransactionEnableDate));
        }
      }
    } finally {
      cancelLoad();
    }
  };

  return {
    loading,
    handleButtonClick,
    date,
  };
};
