import styled from 'styled-components';
import { MaxContentWrapper, Typography, Link } from '@libs/components';

export const Container = styled(MaxContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.8rem;
  max-width: 40rem;
  text-align: center;
  height: 100%;
  flex: 1;
  ${({ theme }) => theme.media.tablet`
    padding: 0;
  `}
`;
export const CustomTitle = styled(Typography)`
  margin-bottom: 2rem;
`;

export const Icon = styled.img`
  margin-top: 4rem;
  margin-bottom: 4.8rem;
  width: 6.1rem;
  height: 12.86rem;
`;

export const ResendWrapper = styled.div`
  display: flex;
  text-wrap: nowrap;
`;

export const CustomLink = styled(Link)`
  display: inline-block;
  margin-left: 0.8rem;
`;

export const AnimWrapper = styled.div`
  width: 18.7rem;
  height: 18.7rem;
  margin: 7.2rem 0 0;
`;

export const ResendContainer = styled.div`
  cursor: pointer;
  margin-left: 0.8rem;
`;
