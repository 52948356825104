import { useCallback, useMemo, useState } from 'react';

import {
  LoginWithSmsTwoFaResponse,
  LoginEmail2FAResponse,
  LoginWithG2FaResponse,
  meQuery,
} from '@libs/graphql';
import { useApiResponse, useAuth } from '@libs/hooks';
import { TwoFactorAuthType } from '@libs/types';

import {
  sendEmailVerificationCodeMutation,
  loginEmail2FAMutation,
} from '@libs/graphql';

import {
  generateQrCodeMutation,
  loginWithG2FaMutation,
  turnOffEmail2FAMutation,
  turnOffGoogle2FAMutation,
  turnOnEmail2FAMutation,
  turnOnGoogle2FAMutation,
  toggle2FaArgs,
  sendSmsTwoFaMutation,
  turnOffSms2FaMutation,
  turnOnSms2FaMutation,
  loginWithSmsTwoFaMutation,
  GoogleActiveTwoFaResponse,
  turnONSms2FaResponse,
  TurnOnEmail2FAResponse,
  GoogleDeactivateTwoFaResponse,
  TurnOffEmail2FAResponse,
  turnOffSms2FaResponse,
} from 'src/gql';
import { useCheckEnabledTwoFaFeatures } from 'src/hooks';
import { useQuery } from '@apollo/client';

export type LoginResponse =
  | LoginEmail2FAResponse
  | LoginWithG2FaResponse
  | LoginWithSmsTwoFaResponse;

export type TurnOnResponse =
  | GoogleActiveTwoFaResponse
  | turnONSms2FaResponse
  | TurnOnEmail2FAResponse;

export type TurnOffResponse =
  | GoogleDeactivateTwoFaResponse
  | TurnOffEmail2FAResponse
  | turnOffSms2FaResponse;

const DEFAULT_CODES = {
  googleTwoFactorCode: undefined,
  emailTwoFactorCode: undefined,
  smsTwoFactorCode: undefined,
};

export const ACTIVATE_OR_DEACTIVATE = {
  [TwoFactorAuthType.SMS]: {
    activate: turnOnSms2FaMutation,
    deactivate: turnOffSms2FaMutation,
  },
  [TwoFactorAuthType.EMAIL]: {
    activate: turnOnEmail2FAMutation,
    deactivate: turnOffEmail2FAMutation,
  },
  [TwoFactorAuthType.GOOGLE]: {
    activate: turnOnGoogle2FAMutation,
    deactivate: turnOffGoogle2FAMutation,
  },
};

export const SEND_CODE_MUTATIONS = {
  [TwoFactorAuthType.SMS]: sendSmsTwoFaMutation,
  [TwoFactorAuthType.EMAIL]: sendEmailVerificationCodeMutation,
  [TwoFactorAuthType.GOOGLE]: generateQrCodeMutation,
};

export const LOGIN_MUTATIONS = {
  [TwoFactorAuthType.SMS]: loginWithSmsTwoFaMutation,
  [TwoFactorAuthType.EMAIL]: loginEmail2FAMutation,
  [TwoFactorAuthType.GOOGLE]: loginWithG2FaMutation,
};

export const useActivationOptions = () => {
  const { login, decodeToken } = useAuth();

  const [activateCodes, setActivateCodes] =
    useState<Omit<toggle2FaArgs, 'twoFactorCode'>>(DEFAULT_CODES);

  const { isEmail2FaAvailabe, isSms2faAvailable } =
    useCheckEnabledTwoFaFeatures();

  const meRes = useQuery(meQuery);

  const { response } = useApiResponse(meRes);

  const enteredAuths = useMemo(() => {
    const entered: TwoFactorAuthType[] = [];

    if (!response) return entered;

    if (response.me.email && isEmail2FaAvailabe) {
      entered.push(TwoFactorAuthType.EMAIL);
    }

    if (response.me.phone && isSms2faAvailable) {
      entered.push(TwoFactorAuthType.SMS);
    }

    return entered;
  }, [isEmail2FaAvailabe, isSms2faAvailable, response]);

  const activatedTwoFaMethods = useMemo(() => {
    if (!response?.me) return [];

    const methods: TwoFactorAuthType[] = [];

    if (response.me.isEmailTwoFactorEnabled && isEmail2FaAvailabe) {
      methods.push(TwoFactorAuthType.EMAIL);
    }

    if (response.me.isSmsTwoFactorEnabled && isSms2faAvailable) {
      methods.push(TwoFactorAuthType.SMS);
    }

    if (response.me.isTwoFactorEnabled) {
      methods.push(TwoFactorAuthType.GOOGLE);
    }

    return methods;
  }, [isEmail2FaAvailabe, isSms2faAvailable, response]);

  const handleLoginSuccess = useCallback(
    (data?: LoginResponse) => {
      if (!data) return;

      let resObject;

      if ('loginSms2FA' in data) {
        resObject = data.loginSms2FA;
      } else if ('loginEmail2FA' in data) {
        resObject = data.loginEmail2FA;
      } else if ('loginGoogle2FA' in data) {
        resObject = data.loginGoogle2FA;
      }

      if (resObject) login(resObject.accessToken);
    },
    [login]
  );

  const handleActivationSucess = useCallback(
    (data?: TurnOnResponse) => {
      if (!data) return;

      let resObject;

      if ('turnOnEmail2FA' in data) {
        resObject = data.turnOnEmail2FA;
      } else if ('turnOnSms2FA' in data) {
        resObject = data.turnOnSms2FA;
      } else if ('turnOnGoogle2FA' in data) {
        resObject = data.turnOnGoogle2FA;
      }

      resObject && login(resObject.accessToken);
    },
    [login]
  );

  const handleDeactivationSucess = useCallback(
    (data?: TurnOffResponse) => {
      if (!data) return;

      let resObject;

      if ('turnOffGoogle2FA' in data) {
        resObject = data.turnOffGoogle2FA;
      } else if ('turnOffEmailFA' in data) {
        resObject = data.turnOffEmailFA;
      } else if ('turnOffSms2FA' in data) {
        resObject = data.turnOffSms2FA;
      }

      resObject && login(resObject.accessToken);
    },
    [login]
  );

  const handleSetCode = useCallback(
    (authType: TwoFactorAuthType, code: string) => {
      let key: keyof toggle2FaArgs;
      if (authType === TwoFactorAuthType.EMAIL) {
        key = 'emailTwoFactorCode';
      } else if (authType === TwoFactorAuthType.GOOGLE) {
        key = 'googleTwoFactorCode';
      } else {
        key = 'smsTwoFactorCode';
      }
      setActivateCodes((prev) => ({
        ...prev,
        [key]: code,
      }));
    },
    []
  );

  return {
    enteredAuths,
    handleLoginSuccess,
    activatedTwoFaMethods,
    handleSetCode,
    activateCodes,
    handleActivationSucess,
    handleDeactivationSucess,
    decodeToken,
  };
};
