import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

import { UserBalance } from 'src/types';

import { assetFragment } from '@libs/graphql';

export type GetTotalBalanceForUserType = {
  total: number;
  crypto: number;
  fiat: number;
  totalPercentageChange?: number;
  totalValueChange?: number;
};

export type GetTotalBalanceResponse = {
  getTotalBalanceForUser: DefaultApiResponse<GetTotalBalanceForUserType>;
};

export type GetTotalBalanceArgs = {
  assetCode: string | number;
  historyInterval?: number;
};

export const getTotalBalanceQuery: TypedDocumentNode<
  GetTotalBalanceResponse,
  DefaultArg<GetTotalBalanceArgs>
> = gql`
  query GetTotalBalance($record: TotalBalanceRecord!) {
    getTotalBalanceForUser(record: $record) {
      total
      crypto
      fiat
      totalPercentageChange
      totalValueChange
    }
  }
`;

// User Balances
export type GetUserBalancesRes = {
  getUserBalances: DefaultApiResponse<UserBalance[]>;
};

export const getUserBalances: TypedDocumentNode<GetUserBalancesRes> = gql`
  query GetDefaultAccountBalancesForUser {
    getUserBalances: getDefaultAccountBalancesForUser {
      id
      amount
      blockedAmount
      availableAmount
      asset {
        ...assetFields
      }
    }
  }

  ${assetFragment}
`;
