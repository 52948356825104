import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { CurrencyType } from '@libs/types';

export type AddFavoriteAssetArgs = {
  assetCode: CurrencyType;
};

export type AddFavoriteAssetResponse = {
  addFavoriteAsset: DefaultApiResponse<boolean>;
};

export const addFavoriteAssetMutation: TypedDocumentNode<
  AddFavoriteAssetResponse,
  DefaultArg<AddFavoriteAssetArgs>
> = gql`
  mutation AddFavoriteAsset($record: FavoriteAssetRecord!) {
    addFavoriteAsset(record: $record)
  }
`;
