import { JurisdictionsEnum } from '@libs/backend-common';
import { Typography } from '@libs/components';
import { landingUrl } from 'src/constants';
import {
  useJurisdictionDetector,
  useThemeMode,
  useTranslation,
} from 'src/hooks';
import { useTheme } from 'styled-components';
import { CustomButton, LinkWrapper } from './styles';

const FAQInfo: React.FC = () => {
  const { colors } = useTheme();
  const { t, selectedLanguageCode } = useTranslation();
  const { selectedTheme } = useThemeMode();
  const { currentJurisdiction } = useJurisdictionDetector();
  const uaJurisdiction = currentJurisdiction === JurisdictionsEnum.UA;

  const handleFAQClick = () => {
    if (uaJurisdiction) {
      const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}#faq-section`;
      window.open(landingUrl + additionalRouteQuery, '_blank');
    } else {
      const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}`;
      window.open(landingUrl + '/faq' + additionalRouteQuery, '_blank');
    }
  };

  return (
    <LinkWrapper>
      <Typography
        fontSize={1.4}
        type="primary"
        lineHeight={2.4}
        fontFamily="primary"
        testId="haveQuestions"
      >
        {t('password_recovery_have_questions')}
        <CustomButton onClick={handleFAQClick} type="button">
          <Typography
            testId="faq"
            fontSize={1.4}
            lineHeight={1.7}
            fontFamily="primary"
            color={colors.interactive.brandColor}
          >
            {t('password_recovery_faq_text')}
          </Typography>
        </CustomButton>
      </Typography>
    </LinkWrapper>
  );
};
export default FAQInfo;
