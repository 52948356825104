import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const AddressBookIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
  width,
  height,
}) => {
  const { colors } = useTheme();
  const colorToApply = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M27.5 20.925V5.83749C27.5 4.33749 26.275 3.22499 24.7875 3.34999H24.7125C22.0875 3.57499 18.1 4.91249 15.875 6.31249L15.6625 6.44999C15.3 6.67499 14.7 6.67499 14.3375 6.44999L14.025 6.26249C11.8 4.87499 7.825 3.54999 5.2 3.33749C3.7125 3.21249 2.5 4.33749 2.5 5.82499V20.925C2.5 22.125 3.475 23.25 4.675 23.4L5.0375 23.45C7.75 23.8125 11.9375 25.1875 14.3375 26.5L14.3875 26.525C14.725 26.7125 15.2625 26.7125 15.5875 26.525C17.9875 25.2 22.1875 23.8125 24.9125 23.45L25.325 23.4C26.525 23.25 27.5 22.125 27.5 20.925Z"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 6.86249V25.6125"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6875 10.6125H6.875"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 14.3625H6.875"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};

export default AddressBookIcon;
