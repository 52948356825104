import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type ChangePasswordArgs = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export type ChangePasswordResponse = {
  changePassword: DefaultApiResponse<boolean>;
};

export const changePasswordMutation: TypedDocumentNode<
  ChangePasswordResponse,
  DefaultArg<ChangePasswordArgs>
> = gql`
  mutation ChangePassowrd($record: ChangePasswordRecord!) {
    changePassword(record: $record)
  }
`;
