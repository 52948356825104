import { useTheme } from 'styled-components';
import {
  AssetPricesContainer,
  PricesLoader,
  Price,
  PriceHeading,
  AddToFavButton,
  PriceInAsset,
  ChartLoader,
  ChartHeading,
  Chart,
} from './styles';

const AssetPricesLoader = () => {
  const { colors } = useTheme();
  return (
    <AssetPricesContainer>
      <PricesLoader
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <PriceHeading />
        <Price />
        <AddToFavButton />
        <PriceInAsset />
      </PricesLoader>
      <ChartLoader
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <ChartHeading />
        <Chart />
      </ChartLoader>
    </AssetPricesContainer>
  );
};

export default AssetPricesLoader;
