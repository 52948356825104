import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type SaveBankRequisitesByUser = {
  name: string;
  iban: string;
  bankCode: string;
  googleCode?: string;
  smsCode?: string;
};
export type SaveBankRequisitesByUserResponse = {
  saveBankRequisitesByUser: DefaultApiResponse<boolean>;
};
export const saveBankRequisitesByUserMutation: TypedDocumentNode<
  SaveBankRequisitesByUserResponse,
  DefaultArg<SaveBankRequisitesByUser>
> = gql`
  mutation SaveBankRequisitesByUser($record: SaveBankRequisiteRecord!) {
    saveBankRequisitesByUser(record: $record)
  }
`;
