import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 2.2rem 2.2rem;
  ${({ theme }) => theme.media.tablet`
    padding: 0 3.7rem 3.7rem;
    min-width: 42.2rem;
  `};
`;

export const CustomQrContainer = styled.div`
  display: flex;
  padding-top: 1.6rem;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`;
export const CustomUl = styled.ul`
  list-style-type: disc;
`;
export const CustomLi = styled.li`
  margin-bottom: 0.5rem;
`;

export const CustomInputCont = styled.div`
  margin-bottom: 4rem;
`;

export const CustomForm = styled.form`
  margin-top: 2.4rem;
`;
export const CustomBtnCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.6rem;
`;

export const QrImg = styled.img`
  max-width: 13rem;
  margin-bottom: 2.4rem;
`;
export const MutationErrorContn = styled.div`
  margin: 0.8rem 0;
  text-align: center;
`;

export const QrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 3rem;
`;
