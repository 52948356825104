import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type LoanPaymentArgs = {
  loanId: number;
  amount: number;
};

export type LoanPaymentResponse = {
  makeLoanPayment: DefaultApiResponse<{
    releasedCollateral: number;
    totalAmountPaid: number;
    oldCommission: number;
    newCommission: number;
    oldPrincipal: number;
    newPrincipal: number;
    newLoanAmount: number;
    newCollateral: number;
    oldCollateral: number;
    commissionPaid: number;
    loanAssetCode: string;
    collateralAssetCode: string;
  }>;
};

export const makeLoanPaymentMutation: TypedDocumentNode<
  LoanPaymentResponse,
  DefaultArg<LoanPaymentArgs>
> = gql`
  mutation MakeLoanPayment($record: MakeLoanPaymentRecord!) {
    makeLoanPayment(record: $record) {
      releasedCollateral
      totalAmountPaid
      oldCommission
      newCommission
      newLoanAmount
      oldPrincipal
      newPrincipal
      loanAssetCode
      collateralAssetCode
      newCollateral
      oldCollateral
      commissionPaid
    }
  }
`;
