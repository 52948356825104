export const userMock = {
  id: '',
  name: '',
  idNumber: '',
  email: '',
  agreedToTerms: true,
  isActive: true,
  isTwoFactorEnabled: true,
  isSmsTwoFactorEnabled: true,
  frStatus: 'VERIFIED',
  status: 'ACTIVE',
  externalReferrerBalance: 0,
  internalReferrerCode: '',
  image: '',
  isZendeskUser: true,

  firstName: '',
  lastName: '',
  emailVerifiedAt: new Date(),
  country: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  referrerCode: '',

  __typename: 'UserEntity',
};
