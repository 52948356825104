import styled from 'styled-components';

export const Container = styled.section`
  max-width: 51.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: 3.2rem 4.8rem 1.6rem;
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.div`
  max-width: 30rem;
`;
export const DescriptionContainer = styled.div`
  max-width: 35rem;
  margin-top: 0.8rem;
`;
export const IconWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  margin-right: 0.6rem;
  background: none;
  cursor: pointer;
`;
