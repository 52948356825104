import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { PartialAsset } from '@libs/types';
import { DefaultApiResponse, partialAssetFragment } from '@libs/graphql';

export type GetSupportedAssetsForLoan = {
  loanAssets: PartialAsset[];
  collateralAssets: PartialAsset[];
};

export type GetSupportedAssetsForLoanResponse = {
  getSupportedAssetsForLoan: DefaultApiResponse<GetSupportedAssetsForLoan>;
};

export const getSupportedAssetsForLoanQuery: TypedDocumentNode<GetSupportedAssetsForLoanResponse> = gql`
  query LoanAssets {
    getSupportedAssetsForLoan {
      loanAssets {
        code
        name
        iconUrl
      }

      collateralAssets {
        code
        name
        iconUrl
      }
    }
  }
`;
