import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const PlusIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId = 'plusIcon',
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M1 8.5H16M8.5 1L8.5 16"
        stroke={iconColor}
        strokeWidth="1.7"
        strokeLinecap="round"
      />
    </CustomSvg>
  );
};
export default PlusIcon;
