import { useState } from 'react';

export const useFetchPriceQueriesContext = () => {
  const [fetchPriceQueriesStatus, setFetchPriceQueriesStatus] =
    useState<boolean>(false);

  const triggerRefetchForDashboardPrices = () => {
    setFetchPriceQueriesStatus((prev) => !prev);
  };

  return {
    fetchPriceQueriesStatus,
    triggerRefetchForDashboardPrices,
  };
};
