import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { useModal } from '@libs/components';
import { TwoFactorAuthType, TwoFaActions } from '@libs/types';

import {
  ActivateTwoFa,
  TwoFaSteps,
  WebsitePreviewLayout,
} from 'src/components';

export const ActivateTwoFaPage = () => {
  const { width } = useDimensions();

  const [type, setType] = useState<TwoFactorAuthType>();

  const [action, setAction] = useState<TwoFaActions>(TwoFaActions.ACTIVATE);

  const navigate = useNavigate();

  const clearType = useCallback(() => setType(undefined), []);

  const { openModal, Dialog, closeModal } = useModal(clearType);

  const handleClick = useCallback(
    (type: TwoFactorAuthType, isChecked: boolean) => {
      if (isChecked) {
        setAction(TwoFaActions.DEACTIVATE);
      } else {
        setAction(TwoFaActions.ACTIVATE);
      }
      setType(type);
      openModal();
    },
    [openModal]
  );

  useEffect(() => {
    if (width > screenSizes.laptop) {
      navigate('/', {
        replace: true,
      });
    }
  }, [width, navigate]);

  return (
    <WebsitePreviewLayout>
      {type && (
        <Dialog>
          <TwoFaSteps
            onFinish={closeModal}
            typeToActivate={type}
            action={action}
            closeModal={closeModal}
          />
        </Dialog>
      )}

      <ActivateTwoFa handleTogglerClick={handleClick} />
    </WebsitePreviewLayout>
  );
};

export default ActivateTwoFaPage;
