import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Typography } from '@libs/components';

import {
  useCurrency,
  useTranslation,
  useApiResponse,
  useNavigationItems,
} from 'src/hooks';
import { roundDown } from 'src/helpers';
import { getTotalBalanceQuery } from 'src/gql';
import { UABalance, DashboardWallet } from 'src/components';
import { PRICES_POLL_INTERVAL } from 'src/constants';

import {
  BalanceWrapper,
  NavigationItems,
  NavigationItem,
  IconWrapper,
  WalletWrapper,
} from './styles';

type WalletProps = {
  hasActionButtons?: boolean;
};
const Wallet: React.FC<WalletProps> = ({ hasActionButtons }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { currencySymbol, currency } = useCurrency();
  const { data: balanceData, loading: balanceLoading } = useQuery(
    getTotalBalanceQuery,
    {
      variables: { record: { assetCode: currency } },
      fetchPolicy: 'network-only',
      pollInterval: PRICES_POLL_INTERVAL,
    }
  );
  const { response, loading } = useApiResponse({
    data: balanceData,
    loading: balanceLoading,
  });

  const navItems = useNavigationItems();
  const navigate = useNavigate();

  const fixedCrypto = roundDown(response?.getTotalBalanceForUser.crypto || 0);
  const fixedFiat = roundDown(response?.getTotalBalanceForUser.fiat || 0);
  const totalAmount = roundDown(fixedCrypto + fixedFiat);

  return (
    <>
      <BalanceWrapper>
        <UABalance
          fiat={fixedFiat}
          loading={loading}
          crypto={fixedCrypto}
          totalAmount={totalAmount}
          currencySymbol={currencySymbol}
        />
        <NavigationItems>
          {navItems.map((item, i) => {
            const handleNavClick = () => {
              navigate(item.route);
            };

            return (
              <NavigationItem key={i} onClick={handleNavClick}>
                <IconWrapper>{item.icon}</IconWrapper>
                <Typography
                  type="bodyButton"
                  fontFamily="primaryMedium"
                  color={colors.text.interactive}
                >
                  {t(item.title)}
                </Typography>
              </NavigationItem>
            );
          })}
        </NavigationItems>
      </BalanceWrapper>
      <WalletWrapper>
        <DashboardWallet hasActionButtons={hasActionButtons} />
      </WalletWrapper>
    </>
  );
};

export default Wallet;
