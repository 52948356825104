import styled from 'styled-components';

const Container = styled.section`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.2rem;
  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;
    const radius = theme.borderRadius.high as unknown as TemplateStringsArray;
    const bgc = theme.colors.surface.default as unknown as TemplateStringsArray;

    return theme.media.tablet`
      max-width: 50rem;
      min-width: 50rem;
      padding: 4.1rem 3.7rem;
      box-shadow: ${shadow};
      border-radius: ${radius};
      background-color: ${bgc};
    `;
  }};
`;

type AuthContentContainerProps = React.PropsWithChildren & {
  className?: string;
};

export const AuthContentContainer: React.FC<AuthContentContainerProps> = ({
  children,
  className,
}) => {
  return <Container className={className}>{children}</Container>;
};
