import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';

export type WithdrawalAddresses = {
  id: string;
  name: string;
  address: string;
  asset: {
    name: string;
    code: string;
    iconUrl: string;
  };
  network: {
    name: string;
    code?: string;
  };
};

export type GetWithdrawalAddressesRes = {
  getCryptoWithdrawalAddresses: DefaultApiResponse<WithdrawalAddresses[]>;
};

export const getWithdrawalAddressesQuery: TypedDocumentNode<GetWithdrawalAddressesRes> = gql`
  query GetCryptoWithdrawalAddresses {
    getCryptoWithdrawalAddresses {
      id
      name
      address
      asset {
        name
        code
        iconUrl
      }
      network {
        name
        code
      }
    }
  }
`;
