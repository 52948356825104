import { useTheme } from 'styled-components';
import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

const ArrowRightIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.secondary;

  return (
    <CustomSvg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93974 13.781C5.81307 13.781 5.68641 13.7343 5.58641 13.6343C5.39307 13.441 5.39307 13.121 5.58641 12.9277L9.93307 8.58099C10.2531 8.26099 10.2531 7.74099 9.93307 7.42099L5.58641 3.07432C5.39307 2.88099 5.39307 2.56099 5.58641 2.36766C5.77974 2.17432 6.09974 2.17432 6.29307 2.36766L10.6397 6.71432C10.9797 7.05432 11.1731 7.51432 11.1731 8.00099C11.1731 8.48766 10.9864 8.94766 10.6397 9.28766L6.29307 13.6343C6.19307 13.7277 6.06641 13.781 5.93974 13.781Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default ArrowRightIcon;
