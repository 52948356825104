import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const SecondaryCloseIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const colorToApply = color || colors.text.default;

  return (
    <CustomSvg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      data-cy={testId}
      className={className}
    >
      <path
        d="M13 13L1 1"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13L13 1"
        stroke={colorToApply}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={colorToApply}
      />
    </CustomSvg>
  );
};

export default SecondaryCloseIcon;
