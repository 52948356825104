import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { StakingStatuses } from '@libs/backend-common';

export type StakingByUser = {
  pendingStaking: StakingByStatus[];
  activeStaking: StakingByStatus[];
  closedStaking: StakingByStatus[];
};
export type StakingByStatus = {
  id: number;
  status: StakingStatuses;
  totals: {
    totalRewards: number;
    totalPercentage: number;
  };
  asset: {
    code: string;
  };
  principalAmount: number;
};

export type GetStakingByUserResponse = {
  getStakingByUser: DefaultApiResponse<StakingByUser>;
};

export const getStakingByUserQuery: TypedDocumentNode<
  GetStakingByUserResponse,
  DefaultArg
> = gql`
  query GetStakingByUser {
    getStakingByUser {
      pendingStaking {
        status
        id
        totals {
          totalRewards
          totalPercentage
        }
        asset {
          code
        }
        principalAmount
      }
      activeStaking {
        status
        id
        totals {
          totalRewards
          totalPercentage
        }
        asset {
          code
        }
        principalAmount
      }
      closedStaking {
        status
        id
        totals {
          totalRewards
          totalPercentage
        }
        asset {
          code
        }
        principalAmount
      }
    }
  }
`;
