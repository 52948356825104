import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { CurrencyType } from 'src/constants';

export type withdrawWithCardArg = {
  amount: number;
  cardId: number;
  asset: CurrencyType | undefined;
};

export type WithdrawWithCardResponse = {
  withdrawWithCard: DefaultApiResponse<boolean>;
};

export const withdrawWithCardMutation: TypedDocumentNode<
  WithdrawWithCardResponse,
  DefaultArg<withdrawWithCardArg>
> = gql`
  mutation WithdrawWithCard($record: CardPaymentRecord!) {
    withdrawWithCard(record: $record)
  }
`;
