import { Typography } from '@libs/components';
import styled from 'styled-components';

export const Desc = styled(Typography)`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.text.default};
  line-break: anywhere;
  ${({ theme }) => theme.media.tabletL`
    font-size:1.6rem;
    line-height: 2.4rem;
  `}
`;
export const Wrapper = styled.div``;
