import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { useApiResponse } from '@libs/hooks';

import { swapClient } from 'src/setup';
import { getOngoingSwapsQuery } from 'src/gql';
import { ONGOING_SWAPS_COUNT_POLLING_INTERVAL } from 'src/constants';

export const useOngoingSwapsCount = () => {
  const res = useQuery(getOngoingSwapsQuery, {
    client: swapClient,
  });

  const { response, loading } = useApiResponse(res);

  useEffect(() => {
    if (res.error) {
      res.stopPolling();
    } else {
      res.startPolling(ONGOING_SWAPS_COUNT_POLLING_INTERVAL);
    }
    return () => res.stopPolling();
  }, [res]);

  return {
    response,
    loading,
  };
};
