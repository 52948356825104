import { useState } from 'react';
import { InputProps } from '@libs/components';

import { CustomInput, CustomPriceInput } from './style';

export type PriceInputProps = InputProps & {
  borderBottomFlat?: boolean;
  borderTopFlat?: boolean;
};
const PriceInput: React.FC<PriceInputProps> = ({
  borderBottomFlat,
  borderTopFlat,
  inputId,
  value,
  placeholder,
  labelTestId,
  disabled,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleOnFocus = () => {
    setIsFocused(true);
  };
  const handleOnBlur = () => {
    setIsFocused(false);
  };
  const handeOnMouseLeave = () => {
    setIsHovered(false);
  };
  const handeOnMouseEnter = () => {
    setIsHovered(true);
  };

  return (
    <CustomPriceInput
      focused={isFocused}
      borderTopFlat={borderTopFlat}
      borderBottomFlat={borderBottomFlat}
      hover={isHovered}
    >
      <CustomInput
        type="text"
        value={value}
        maxLength={11}
        hasBorder={false}
        inputId={inputId}
        disabled={disabled}
        placeholder={placeholder}
        labelTestId={labelTestId}
        onMouseEnter={handeOnMouseEnter}
        onMouseLeave={handeOnMouseLeave}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...restProps}
      />
    </CustomPriceInput>
  );
};
export default PriceInput;
