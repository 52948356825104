import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useQuery } from '@apollo/client';
import { Typography } from '@libs/components';
import { meQuery, FrStatuses } from '@libs/graphql';
import { JurisdictionsEnum } from '@libs/backend-common';

import { useTranslation, useApiResponse } from 'src/hooks';

import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';

import { KYCButton, Container, Description, TextWrapper } from './styles';

type RequireKycProps = {
  title?: string;
  description?: string;
  onClick?: () => void;
  className?: string;
};

const RequireKyc: React.FC<RequireKycProps> = ({
  title,
  onClick,
  description,
  className,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colors } = useTheme();

  const meRes = useQuery(meQuery);
  const { response, loading, errors } = useApiResponse(meRes);

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate('/kyc');
  };

  if (loading || response?.me.frStatus === FrStatuses.VERIFIED || errors)
    return null;

  return (
    <JurisdictionRenderer
      acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
    >
      <Container className={className}>
        <TextWrapper>
          <Typography
            fontSize={2}
            lineHeight={3}
            type="primary"
            fontFamily="primaryMedium"
            testId="KYC-title"
            color={colors.surface.black}
          >
            {title ? title : t('kyc_title')}
          </Typography>
          <Description>
            <Typography
              fontSize={1.4}
              lineHeight={2.4}
              type="secondary"
              fontFamily="primary"
              testId="KYC-desc"
              color={colors.text.secondary}
            >
              {description ? description : t('kyc_description')}
            </Typography>
          </Description>
        </TextWrapper>

        <KYCButton
          testId="KYC-button"
          fontSize={1.6}
          title={t('kyc_button')}
          onClick={handleClick}
        />
      </Container>
    </JurisdictionRenderer>
  );
};
export default RequireKyc;
