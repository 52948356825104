import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

/*export type GetSumsubAccessTokenType = {
    token: string;
}*/

export type getSumsubAccessTokenResponse = {
  getSumsubAccessToken: DefaultApiResponse<{ token: string }>;
};

export const getSumsubAccessToken: TypedDocumentNode<
  getSumsubAccessTokenResponse,
  DefaultArg
> = gql`
  query GetSumsubAccessToken {
    getSumsubAccessToken {
      token
    }
  }
`;
