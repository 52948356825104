import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';

import { TransparentButton, Typography } from '@libs/components';
import { CashTransactionType } from '@libs/backend-common';
import { ConfirmationModalType } from '@libs/types';

import { useApiResponse, useTranslation, useCardDetector } from 'src/hooks';
import { deleteCardMutation, getCardsQuery } from 'src/gql';
import { CurrencyType } from 'src/constants';
import { DeleteIcon } from 'src/assets';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { useAddNewCard } from 'src/hooks/useAddNewCard';

import {
  AddCard,
  Card,
  CardsContainer,
  CardWrapper,
  CustomIcon,
  DeleteIconWrapper,
} from './styles';

type CardsType = {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  assetCode?: CurrencyType;
  testId?: string;
  transaction?: CashTransactionType;
  activeCardId?: string;
};

const Cards: React.FC<CardsType> = ({
  onClick,
  assetCode,
  testId = 'cards',
  transaction,
  activeCardId,
}) => {
  const { t } = useTranslation();
  const { cardDetector } = useCardDetector();

  const { AddNewCardValue } = useAddNewCard();

  const { colors } = useTheme();

  const handleCardClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) onClick(e);
  };

  const handleNewCard = () => {
    if (assetCode) AddNewCardValue({ variables: { record: { assetCode } } });
  };

  const fee = transaction === CashTransactionType.DEPOSIT ? '2%' : '1%';

  const { data, loading } = useQuery(getCardsQuery);
  const { response } = useApiResponse({ data, loading });

  const shouldShowButton = response?.getCards && response.getCards.length < 2;
  const [selectedCardId, setSelectedCardId] = useState<number>();

  const [deleteCard] = useMutation(deleteCardMutation, {
    refetchQueries: [{ query: getCardsQuery }],
  });

  const handleDeleteConfirmation = useCallback(() => {
    if (selectedCardId) {
      deleteCard({
        variables: {
          record: {
            id: selectedCardId,
          },
        },
      });
    }
  }, [deleteCard, selectedCardId]);

  const { Dialog, openModal } = useConfirmationModal(
    undefined,
    handleDeleteConfirmation
  );

  return (
    <CardsContainer data-cy={testId} data-testid={testId}>
      {response?.getCards.map((option, i) => {
        const isChecked = activeCardId === option.id;
        const { cardProvider, cardIcon } = cardDetector(
          option.cardMask as string
        );

        const titleToDisplay = `${cardProvider}  ${option.cardMask}`;
        const descToDisplay = t('cards_card_fee', {
          fee: fee,
        });
        const handleDeleteClick = () => {
          setSelectedCardId(+option.id);
          openModal();
        };

        return (
          <CardWrapper key={i}>
            <Card
              id={option.id}
              checked={isChecked}
              testId={`card-${i}`}
              title={titleToDisplay}
              onClick={handleCardClick}
              description={descToDisplay}
              icon={cardIcon}
              alt={cardProvider}
            />
            <TransparentButton onClick={handleDeleteClick}>
              <DeleteIconWrapper>
                <DeleteIcon />
              </DeleteIconWrapper>
            </TransparentButton>
          </CardWrapper>
        );
      })}
      {shouldShowButton && (
        <AddCard data-cy="add-card" onClick={handleNewCard}>
          <CustomIcon testId="add-card-icon" />
          <Typography
            type="bodyButton"
            fontFamily="primaryMedium"
            testId="add-card-text"
            color={colors.interactive.brandColor}
          >
            {t('cards_add_card')}
          </Typography>
        </AddCard>
      )}
      <Dialog
        type={ConfirmationModalType.DELETE}
        title={t('delete_card_confirmation_title')}
        description={t('delete_card_confirmation_desc')}
        buttonText={t('common_delete')}
      />
    </CardsContainer>
  );
};
export default Cards;
