import { SwapTradePairSection, SwapTaskInfo } from '@libs/components';
import { PageContainer } from 'src/components';
import { useRenderObjectByTheme, useTranslation } from 'src/hooks';
import { useSwapDeatils } from './hooks/useSwapDetails';
import { CustomSwapTaskInfo } from './styles';

export const SwapDetailsPage = () => {
  const {
    resInfo,
    pairStatus,
    operationDetailsInfo,
    additionalInformation,
    loading,
  } = useSwapDeatils();
  const { t } = useTranslation();

  const timerBackground = useRenderObjectByTheme(
    'linear-gradient(90deg,rgba(213, 220, 229, 0) 0%,#d5dce5 50%, rgba(213, 220, 229, 0) 100%)',
    'linear-gradient(90deg, rgba(77, 84, 104, 0) 0%, #4d5468 50%, rgba(77, 84, 104, 0) 100%)'
  );
  const sourceAmount = resInfo?.swapTask?.swaps?.length
    ? ''
    : String(resInfo?.deposit.amount);

  const destinationAmount = resInfo?.swapTask?.swaps?.length
    ? ''
    : String(resInfo?.calculatedData?.receivedAmount);

  const formatDaysToString = (days: string) => {
    if (!days || +days <= 0) return '';

    return t('swap_day_left', { days });
  };

  return (
    <PageContainer>
      <SwapTradePairSection
        sourceAssetCode={resInfo?.swapTask.sourceAsset.code}
        sourceAmount={sourceAmount}
        sourceAssetIcon={resInfo?.swapTask.sourceAsset.iconUrl}
        destinationAssetCode={resInfo?.swapTask.destinationAsset.code}
        destinationAmount={destinationAmount}
        destinationAssetIcon={resInfo?.swapTask.destinationAsset.iconUrl}
        sourceTitle={t('swap_trade_pair_base_title')}
        destinationTitle={t('swap_trade_pair_quote_title')}
        pendingTitle={t('swap_trade_panding_title')}
        finishedTitle={t('swap_trade_finished_title')}
        expiredTitle={t('swap_trade_expired_title')}
        buttonText={t('swap_trade_expired_button_title')}
        expiredDescription={t('swap_trade_expired_description')}
        pendingDescription={t('swap_trade_panding_description')}
        firstStepTitle={t('swap_progress_bar_first_title')}
        secondStepTitle={t('swap_progress_bar_second_title')}
        thirdStepTitle={t('swap_progress_bar_third_title')}
        fourthStepTitle={t('swap_progress_bar_fourth_title')}
        failedTitle={t('swap_trade_failed_title')}
        expiredButWaitingTitle={''}
        pairType={pairStatus}
        timerBackground={timerBackground}
        loading={loading}
        formatDaysToString={formatDaysToString}
      />

      <CustomSwapTaskInfo
        shouldIconShow={false}
        isOpen={true}
        title={t('swap_operation_details')}
        rows={operationDetailsInfo}
        isNotCloseable={true}
        buttonTextBeforeCopying={t('common_copy')}
        buttonTextAfterCopying={t('common_copied')}
      />

      <SwapTaskInfo
        title={t('swap_additional_information')}
        rows={additionalInformation}
        isOpen={false}
        shouldIconShow={true}
        buttonTextBeforeCopying={t('common_copy')}
        buttonTextAfterCopying={t('common_copied')}
      />
    </PageContainer>
  );
};
export default SwapDetailsPage;
