import styled from 'styled-components';
import { Input } from '@libs/components';

type CustomInputProps = {
  isFocused: boolean;
  value: string;
};
export const CustomSearchInput = styled(Input)<CustomInputProps>`
  height: 5rem;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  width: auto;
`;
