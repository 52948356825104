import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CreateCryptoTransactionProps = {
  destinationAddress: string;
  assetId: string;
  amount: number;
  // smsCode: string;
  // googleCode: string;
  destinationAddressName: string;
  destinationTag?: string;
};

export type createCryptoTransactionResponse = {
  createCryptoTransaction: DefaultApiResponse<boolean>;
};

export const createCryptoTransactionMutation: TypedDocumentNode<
  createCryptoTransactionResponse,
  DefaultArg<CreateCryptoTransactionProps>
> = gql`
  mutation CreateCryptoTransaction($record: CreateCryptoTransactionRecord!) {
    createCryptoTransaction(record: $record)
  }
`;
