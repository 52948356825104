import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { WithdrawalMethods } from '@libs/types';

type ExtraConditions = {
  destinationBankCode: string | undefined;
  destinationNetwork?: number;
};

export type WithdrawalFee = {
  assetCode: string;
  assetName: string;
  extraConditions?: ExtraConditions;
  fixed: string;
  blockchainFee?: number;
  price?: number;
  greaterThan: string;
  iconUrl: string;
  id: string;
  isCrypto: string;
  method: WithdrawalMethods;
  rate: string;
};

export type GetAllWithdrawalFeesResponse = {
  getAllWithdrawalFees: DefaultApiResponse<WithdrawalFee[]>;
};

export const getAllWithdrawalFeesQuery: TypedDocumentNode<
  GetAllWithdrawalFeesResponse,
  DefaultArg
> = gql`
  query GetAllWithdrawalFees {
    getAllWithdrawalFees {
      assetCode
      assetName
      extraConditions {
        destinationBankCode
        destinationNetwork
      }
      blockchainFee
      price
      fixed
      greaterThan
      iconUrl
      id
      isCrypto
      method
      rate
    }
  }
`;
