import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { meQuery } from '@libs/graphql';
import { useMutation, useQuery } from '@apollo/client';

import {
  changePhoneMutation,
  ChangePhoneResponse,
  checkPhoneAndSendCodeMutation,
  CheckPhoneAndSendCodeResponse,
} from 'src/gql';
import { extractFullPhoneNumber, getTokenPayload } from 'src/helpers';
import { changePhoneValidation } from 'src/validations';
import { useApiResponse, useAuth, useCounter } from 'src/hooks';
import { RESEND_CODE_TIME, ResultFeedbackType } from 'src/constants';

export const useChangePhone = (
  onSuccess?: () => void,
  onFormSubmit?: () => void,
  initialValue?: string
) => {
  const { data } = useQuery(meQuery);
  const { response } = useApiResponse({ data });

  const { decodeToken } = useAuth();

  const [code, setCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [phoneToDisplay, setPhoneToDisplay] = useState('');
  const [feedbackType, setFeedbackType] = useState<
    ResultFeedbackType | undefined
  >();

  const onChangePhoneSuccess = (res: ChangePhoneResponse) => {
    if (!res.changePhone) {
      setFeedbackType(ResultFeedbackType.ERROR);
      return;
    }
    setFeedbackType(ResultFeedbackType.SUCCESS);
    onSuccess?.();
  };

  const [sendCode, sendCodeRes] = useMutation(checkPhoneAndSendCodeMutation);
  const [changePhone, changePhoneRes] = useMutation(changePhoneMutation, {
    refetchQueries: [{ query: meQuery, fetchPolicy: 'network-only' }],
  });
  const {
    errorMessage: changePhoneError,
    clearErrorMessage: clearPhoneError,
    loading,
  } = useApiResponse(changePhoneRes, onChangePhoneSuccess);

  const handlePhoneChange = (vals: { phone: string; code: string }) => {
    changePhone({
      variables: {
        record: {
          code: code || vals.code,
          formattedPhone: vals.phone,
          phone: extractFullPhoneNumber(values.phone),
          tokenPayload: getTokenPayload(decodeToken()),
        },
      },
      refetchQueries: [{ query: meQuery }],
    });
  };

  const isNewPhoneNumber = response?.me.phone !== initialValue;

  const {
    values,
    handleChange,
    handleSubmit,
    errors: formErrors,
    touched,
    dirty,
  } = useFormik({
    initialValues: {
      phone: isNewPhoneNumber ? initialValue || '' : '',
      code: '',
    },
    onSubmit: (vals) => {
      if (loading) return;
      if (onFormSubmit) {
        onFormSubmit();
      } else {
        handlePhoneChange(vals);
      }
    },
    validationSchema: changePhoneValidation,
  });

  const isValid = dirty && !formErrors.phone;

  const { countDown, counterInterval, isCounterRunning, clearCurrentInterval } =
    useCounter(RESEND_CODE_TIME);

  const onSendCodeSuccess = (res: CheckPhoneAndSendCodeResponse) => {
    if (!res?.checkPhoneAndSendCode) return;
    setIsCodeSent(true);
    counterInterval();
  };

  const { errorMessage: sendCodeError, clearErrorMessage: clearCodeError } =
    useApiResponse(sendCodeRes, onSendCodeSuccess, clearCurrentInterval);

  const phoneError = changePhoneError ? changePhoneError : '';

  const errorMessage = sendCodeError ? sendCodeError : phoneError;
  const isSameNumber =
    !!response?.me.phone &&
    response?.me.phone === extractFullPhoneNumber(values.phone);

  const isButtonActive = isValid && !isSameNumber && !isCounterRunning;

  const sendCodeHandler = (phone?: string, isFormValid?: boolean) => {
    if (isFormValid || isButtonActive) {
      setPhoneToDisplay(phone || values.phone);
      const unformattedPhone = extractFullPhoneNumber(phone || values.phone);
      sendCode({
        variables: {
          record: { destination: unformattedPhone },
        },
      });
    }
  };
  const clearCode = () => {
    setCode('');
    clearPhoneError();
  };

  useEffect(() => {
    clearCodeError();
    clearPhoneError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.phone]);

  useEffect(() => {
    if (isNewPhoneNumber) {
      sendCodeHandler(initialValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    code,
    values,
    touched,
    formErrors,
    clearCodeError,
    handlePhoneChange,
    isValid,
    phoneToDisplay,
    isSameNumber,
    isButtonActive,
    feedbackType,
    errorMessage,
    sendCodeHandler,
    handleSubmit,
    handleChange,
    clearCode,
    setCode,
    isCodeSent,
    setIsCodeSent,
    countDown,
    counterInterval,
    isCounterRunning,
  };
};
