import { useCallback, useState } from 'react';
import { useFormik } from 'formik';

import { ApolloError, useMutation } from '@apollo/client';

import { useApiResponse } from 'src/hooks';
import { changePasswordMutation, ChangePasswordResponse } from 'src/gql';
import { ResultFeedbackType } from 'src/constants';
import { changePasswordValidation } from 'src/validations';

type UseChangePasswordProps = {
  onSuccess?: () => void;
  onFormSubmit?: () => void;
};
export const useChangePassword = ({
  onSuccess,
  onFormSubmit,
}: UseChangePasswordProps) => {
  const [changePasswordError, setChangePasswordError] = useState<ApolloError>();
  const [feedbackType, setFeedbackType] = useState<
    ResultFeedbackType | undefined
  >();

  const handleSuccessChangePassword = useCallback(
    (data: ChangePasswordResponse) => {
      const status = data?.changePassword;
      onSuccess?.();
      if (!status) {
        setFeedbackType(ResultFeedbackType.ERROR);
        return;
      }
      setFeedbackType(ResultFeedbackType.SUCCESS);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleErrorChangePassword = useCallback(() => {
    onSuccess?.();
    setFeedbackType(ResultFeedbackType.ERROR);
  }, []);

  const [changePasswordValues, { data: changePasswordData, loading }] =
    useMutation(changePasswordMutation, {
      onError: setChangePasswordError,
    });

  const { errorMessage: changePasswordErrors, loading: changePassLoading } =
    useApiResponse<ChangePasswordResponse, ApolloError>(
      {
        loading,
        data: changePasswordData,
        errors: changePasswordError,
      },
      handleSuccessChangePassword,
      handleErrorChangePassword
    );

  const errorMessage = changePasswordErrors ? changePasswordErrors : '';
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    setTouched,
  } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validateOnMount: true,
    onSubmit: () => onFormSubmit?.(),
    validationSchema: changePasswordValidation,
  });

  const handlePasswordChange = () => {
    if (changePassLoading) return;
    changePasswordValues({
      variables: { record: values },
    });
  };

  return {
    handleSuccessChangePassword,
    errorMessage,
    changePasswordValues,
    values,
    handleChange,
    feedbackType,
    handleSubmit,
    errors,
    touched,
    setTouched,
    isFormValid: isValid,
    handlePasswordChange,
  };
};
