import { Typography } from '@libs/components';
import { useNavigate } from 'react-router-dom';
import { ConvertIcon, DepositIcon, WithdrawIcon } from 'src/assets';
import DetailsHeaderDesktopLoader from 'src/content-loaders/asset-details-header-desktop/DetailsHeaderDesktopLoader';
import { useTranslation } from 'src/hooks';
import { useTheme } from 'styled-components';
import {
  AssetDetailsHeaderNavWrapper,
  AssetDetailsHeaderWrapper,
  CustomButton,
  CustomTableCell,
  CustomTableCellLaptop,
} from './styles';

type AssetDetailsHeaderProps = {
  assetName?: string;
  assetCode?: string;
  icon?: string | JSX.Element;
  loading: boolean;
};
export const AssetDetailsHeader: React.FC<AssetDetailsHeaderProps> = ({
  assetName,
  assetCode,
  icon,
  loading,
}) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConvertClick = () => {
    navigate(`/convert/BUY?selectedCurrency=${assetCode}`);
  };
  const handleDepositClick = () => {
    navigate(`/deposit?selectedCurrency=${assetCode}`);
  };
  const handleWithdrawClick = () => {
    navigate(`/withdraw?selectedCurrency=${assetCode}`);
  };

  return (
    <AssetDetailsHeaderWrapper>
      {loading ? (
        <DetailsHeaderDesktopLoader />
      ) : (
        <>
          <CustomTableCell
            img={icon}
            titleType="primaryBold"
            title={assetName}
            description={assetCode}
            titleSize="titleOne"
          />
          <CustomTableCellLaptop
            img={icon}
            titleType="primaryBold"
            title={assetCode}
            titleSize="titleOne"
          />
        </>
      )}
      <AssetDetailsHeaderNavWrapper>
        <CustomButton
          onClick={handleConvertClick}
          size="sm"
          icon={<ConvertIcon color={colors.surface.default} />}
        >
          <Typography type="bodyButton" color={colors.text.interactive}>
            {t('dashboard_convert')}
          </Typography>
        </CustomButton>

        <CustomButton
          onClick={handleDepositClick}
          icon={<DepositIcon color={colors.surface.default} />}
          size="sm"
        >
          <Typography type="bodyButton" color={colors.text.interactive}>
            {t('dashboard_deposit')}
          </Typography>
        </CustomButton>
        <CustomButton
          onClick={handleWithdrawClick}
          size="sm"
          icon={<WithdrawIcon color={colors.surface.default} />}
        >
          <Typography type="bodyButton" color={colors.text.interactive}>
            {t('dashboard_withdraw')}
          </Typography>
        </CustomButton>
      </AssetDetailsHeaderNavWrapper>
    </AssetDetailsHeaderWrapper>
  );
};
