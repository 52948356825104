import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'src/hooks';
import { WebsitePreviewLayout } from 'src/components';

import LoginForm from './components/LoginForm';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <WebsitePreviewLayout
      heading={t('login_welcome')}
      highlightedHeading={t('login_welcome_highlighted')}
      headerButtonTitle={t('login_register_text')}
      onHeaderButtonClick={handleRegisterClick}
    >
      <LoginForm />
    </WebsitePreviewLayout>
  );
};

export default LoginPage;
