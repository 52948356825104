import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const TelegramIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.surface.default;

  return (
    <CustomSvg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      className={className}
      data-cy={testId}
    >
      <path
        d="M6.21853 9.06586L13.5931 15.5545C13.6784 15.63 13.7818 15.682 13.8931 15.7054C14.0045 15.7288 14.1201 15.7228 14.2285 15.6881C14.3369 15.6533 14.4344 15.591 14.5114 15.5072C14.5884 15.4233 14.6423 15.3209 14.6677 15.21L17.7521 1.74047C17.778 1.62447 17.7719 1.50362 17.7344 1.39083C17.697 1.27804 17.6296 1.17754 17.5394 1.10005C17.4493 1.02257 17.3399 0.971011 17.2227 0.950884C17.1056 0.930758 16.9852 0.942819 16.8744 0.985778L1.73142 6.93304C1.12439 7.17094 1.20642 8.05687 1.84627 8.18812L6.21853 9.06586Z"
        stroke={iconColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.21875 9.06611L17.3832 1.00244"
        stroke={iconColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.90195 12.3062L7.34258 14.8655C7.25127 14.9582 7.13446 15.0217 7.00699 15.0479C6.87952 15.0741 6.74715 15.0618 6.62669 15.0125C6.50624 14.9633 6.40314 14.8793 6.3305 14.7714C6.25787 14.6634 6.21897 14.5363 6.21875 14.4062V9.06592"
        stroke={iconColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};

export default TelegramIcon;
