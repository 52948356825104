import styled from 'styled-components';
import { RadioButton } from '@libs/components';

export const PeriodRadioBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.4rem;
  min-width: 6rem;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  cursor: pointer;
`;
export const CustomRadioBtn = styled(RadioButton)`
  display: none;
`;
