import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';

import { getTotalBalanceQuery } from 'src/gql';
import { useTheme } from 'styled-components';

import { useCurrency, useTranslation } from 'src/hooks';
import { JurisdictionRenderer } from 'src/components';
import { PRICES_POLL_INTERVAL } from 'src/constants';

import {
  Container,
  CustomTypography,
  CustomButton,
  CustomDepositIcon,
} from './styles';

const BuyCrypto = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { t } = useTranslation();

  const { data, loading } = useQuery(getTotalBalanceQuery, {
    variables: { record: { assetCode: currency } },
    fetchPolicy: 'network-only',
    pollInterval: PRICES_POLL_INTERVAL,
  });

  const handleButtonClick = () => navigate('/deposit');
  if (loading || data?.getTotalBalanceForUser?.total) return null;

  return (
    <JurisdictionRenderer
      acceptedJurisdictions={[JurisdictionsEnum.EU, JurisdictionsEnum.GE]}
    >
      <Container>
        <CustomTypography
          type={'headline'}
          fontFamily={'primaryBold'}
          color={colors.text.default}
        >
          {t('buy_crypto_title')}
        </CustomTypography>
        <Typography
          type={'subHeadline'}
          fontFamily={'primaryRegular'}
          color={colors.text.secondary}
        >
          {t('buy_crypto_desc')}
        </Typography>
        <CustomButton onClick={handleButtonClick}>
          <CustomDepositIcon color={colors.text.interactive} />
          {t('dashboard_deposit')}
        </CustomButton>
      </Container>
    </JurisdictionRenderer>
  );
};
export default BuyCrypto;
