import { FC } from 'react';

import QRCode from 'react-qr-code';

import { ModalHeader } from '@libs/components';
import { getJurisdictionBaseUrl } from '@libs/helpers';

import { useTranslation } from 'src/hooks';

import { G2FALinks } from './G2FALinks';

import {
  TutorialContainer,
  StepTypography,
  StepsWrapper,
  Li,
  LinksAndQr,
  QrWrapper,
} from './styles';

const googleSteps = [
  'activate_google_second_step',
  'activate_google_third_step',
  'activate_google_fourth_step',
  'activate_google_fifth_step',
  'activate_google_sixsth_step',
];

export const GoogleAuthenticatorSteps: FC<{
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();

  const { ENVIRONMENT } = process.env;

  const navigatorLink =
    getJurisdictionBaseUrl(ENVIRONMENT).dashboardUrl + '/authenticator-link';

  if (!isOpen) return null;

  const handleHeaderClick = () => setIsOpen(false);

  return (
    <TutorialContainer>
      <ModalHeader
        title={t('activate_google_hint')}
        onHeaderButtonClick={handleHeaderClick}
      />
      <StepsWrapper>
        <Li>
          <StepTypography type="subHeadline">
            {t('activate_google_first_step')}
          </StepTypography>
        </Li>
        <LinksAndQr>
          <G2FALinks />
          <QrWrapper>
            <QRCode value={navigatorLink} size={90} />
          </QrWrapper>
        </LinksAndQr>
        {googleSteps.map((element, i) => {
          return (
            <Li key={i}>
              <StepTypography type="subHeadline">{t(element)}</StepTypography>
            </Li>
          );
        })}
      </StepsWrapper>
    </TutorialContainer>
  );
};

export default GoogleAuthenticatorSteps;
