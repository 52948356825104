import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type SendEmailCodeArgs = {
  email: string;
};
export type SendEmailCodeResponse = {
  editEmailPreRequest: DefaultApiResponse<boolean>;
};
export const editEmailPreRequestMutation: TypedDocumentNode<
  SendEmailCodeResponse,
  DefaultArg<SendEmailCodeArgs>
> = gql`
  mutation EditEmailPreRequest($record: EmailRecord!) {
    editEmailPreRequest(record: $record)
  }
`;
