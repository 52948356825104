import styled from 'styled-components';
import { MaxContentWrapper } from '@libs/components';

export const DashboardPageWrapper = styled(MaxContentWrapper)`
  padding: 0;
  display: flex;
  flex: 1;
  width: unset;

  ${({ theme }) => theme.media.tablet`
    max-width: 112rem;
    margin: 1.7rem auto;
    border-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };
  `};
`;
