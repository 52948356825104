import { useTheme } from 'styled-components';
import { useEffect } from 'react';

import { TransactionAppliedFilters } from 'src/types';
import { DesertedIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';
import { TransactionLoader } from 'src/content-loaders';

import {
  EmptyStateWrapper,
  MobileTable,
  MobileTableWrapper,
  TableWrapper,
  TransactionTableContainer,
  WideTable,
  CustomEmpyState,
} from './styles';
import { useTransactionsModal } from './hooks/useTransactionsModal';
import { useTransactionsTable } from './hooks/useTransactionTable';

type TransactionTableType = {
  skip?: number;
  limit: number;
  modalLeftSpace?: number;
  appliedFilters?: TransactionAppliedFilters;
  setHasTransactions?: (n: boolean) => void;
  hasTransactions?: boolean;
  className?: string;
  isOneAssetTransactions?: boolean;
};

const TransactionTable: React.FC<TransactionTableType> = ({
  skip = 0,
  limit = 9,
  appliedFilters,
  setHasTransactions,
  modalLeftSpace,
  hasTransactions = true,
  className,
  isOneAssetTransactions,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { transactionModal, openModal, handleTransactionDetails } =
    useTransactionsModal(modalLeftSpace);
  const handleTransactionClick = (selectedId: number) => {
    handleTransactionDetails(selectedId);
    openModal();
  };

  const {
    columns,
    data,
    handleRowClick,
    mobileColumns,
    mobileData,
    mobileOneAssetData,
    mobileOneAssetColumns,
    dataIsLoaded,
    loading,
  } = useTransactionsTable({
    skip,
    limit,
    appliedFilters,
    onRowClick: handleTransactionClick,
    isOneAssetTransactions,
  });

  useEffect(() => {
    if (dataIsLoaded) {
      setHasTransactions?.(data.length !== 0);
    }
  }, [setHasTransactions, dataIsLoaded, data]);

  if (!dataIsLoaded || loading) {
    return <TransactionLoader />;
  }

  return (
    <TransactionTableContainer
      className={className}
      hasTransactions={hasTransactions}
    >
      {data.length ? (
        <>
          <TableWrapper>
            <WideTable
              columns={columns}
              data={data}
              onClick={handleRowClick}
              bodyHeight={6.8}
              headerHeight={4.1}
              headerColor={colors.surface.interactiveDefault}
              hasStickyHeader={false}
            />
          </TableWrapper>
          <MobileTableWrapper>
            <MobileTable
              columns={
                isOneAssetTransactions ? mobileOneAssetColumns : mobileColumns
              }
              data={isOneAssetTransactions ? mobileOneAssetData : mobileData}
              hasHeader={false}
              onClick={handleRowClick}
              bodyHeight={7.3}
              headerHeight={4.1}
              hasStickyHeader={false}
            />
          </MobileTableWrapper>
        </>
      ) : (
        <EmptyStateWrapper className="empty-state">
          <CustomEmpyState
            title={t('transaction_not_found_title')}
            description={t('dashboard_transaction_not_found_desc')}
            img={DesertedIcon}
          />
        </EmptyStateWrapper>
      )}
      {transactionModal}
    </TransactionTableContainer>
  );
};

export default TransactionTable;
