import { Typography } from '@libs/components';

import { PeriodSelectorItem, PeriodSelectorWrapper } from './styles';

type PeriodSelectorProps = {
  amounts: number[];
  selectedAmount?: number;
  onSelect: (selectedNum: number) => void;
};

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
  amounts,
  selectedAmount,
  onSelect,
}) => (
  <PeriodSelectorWrapper>
    {amounts.map((amount) => {
      const handleSelect = () => onSelect(amount);

      return (
        <PeriodSelectorItem
          key={amount}
          onClick={handleSelect}
          isSelected={selectedAmount === amount}
        >
          <Typography type="subHeadline">{amount}</Typography>
        </PeriodSelectorItem>
      );
    })}
  </PeriodSelectorWrapper>
);

export default PeriodSelector;
