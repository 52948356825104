import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const ActiveMoreIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0909 6.54546C11.0909 7.44446 10.8243 8.32328 10.3249 9.07077C9.8254 9.81827 9.1155 10.4009 8.28493 10.7449C7.45435 11.0889 6.54041 11.179 5.65868 11.0036C4.77695 10.8282 3.96703 10.3953 3.33133 9.75958C2.69564 9.12388 2.26273 8.31396 2.08734 7.43223C1.91195 6.5505 2.00197 5.63656 2.346 4.80599C2.69004 3.97541 3.27264 3.26551 4.02014 2.76605C4.76763 2.26659 5.64645 2 6.54546 2C7.75098 2 8.90714 2.4789 9.75958 3.33133C10.612 4.18377 11.0909 5.33993 11.0909 6.54546ZM17.4545 11.0909C18.3535 11.0909 19.2324 10.8243 19.9799 10.3249C20.7274 9.8254 21.31 9.1155 21.654 8.28493C21.998 7.45435 22.088 6.54041 21.9127 5.65868C21.7373 4.77695 21.3044 3.96703 20.6687 3.33133C20.033 2.69564 19.223 2.26273 18.3413 2.08734C17.4596 1.91195 16.5456 2.00197 15.7151 2.346C14.8845 2.69004 14.1746 3.27264 13.6751 4.02014C13.1757 4.76763 12.9091 5.64645 12.9091 6.54546C12.9091 7.75098 13.388 8.90714 14.2404 9.75958C15.0929 10.612 16.249 11.0909 17.4545 11.0909ZM6.54546 12.9091C5.64645 12.9091 4.76763 13.1757 4.02014 13.6751C3.27264 14.1746 2.69004 14.8845 2.346 15.7151C2.00197 16.5456 1.91195 17.4596 2.08734 18.3413C2.26273 19.223 2.69564 20.033 3.33133 20.6687C3.96703 21.3044 4.77695 21.7373 5.65868 21.9127C6.54041 22.088 7.45435 21.998 8.28493 21.654C9.1155 21.31 9.8254 20.7274 10.3249 19.9799C10.8243 19.2324 11.0909 18.3535 11.0909 17.4545C11.0909 16.249 10.612 15.0929 9.75958 14.2404C8.90714 13.388 7.75098 12.9091 6.54546 12.9091ZM17.4545 12.9091C16.5555 12.9091 15.6767 13.1757 14.9292 13.6751C14.1817 14.1746 13.5991 14.8845 13.2551 15.7151C12.9111 16.5456 12.821 17.4596 12.9964 18.3413C13.1718 19.223 13.6047 20.033 14.2404 20.6687C14.8761 21.3044 15.686 21.7373 16.5678 21.9127C17.4495 22.088 18.3634 21.998 19.194 21.654C20.0246 21.31 20.7345 20.7274 21.234 19.9799C21.7334 19.2324 22 18.3535 22 17.4545C22 16.249 21.5211 15.0929 20.6687 14.2404C19.8162 13.388 18.6601 12.9091 17.4545 12.9091Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default ActiveMoreIcon;
