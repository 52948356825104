import { useTheme } from 'styled-components';
import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

const BalanceTogglerIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.surface.black;

  return (
    <CustomSvg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="Group 48096191">
        <circle id="Ellipse 147" cx="12" cy="12" r="12" fill="#F5F5F5" />
        <path
          id="Polygon 2"
          d="M11.2054 8.53327C11.6057 8.01326 12.3899 8.01326 12.7902 8.53327L15.9546 12.6439C16.4608 13.3015 15.992 14.2539 15.1622 14.2539H8.83343C8.00359 14.2539 7.53483 13.3015 8.04102 12.6439L11.2054 8.53327Z"
          fill={iconColor}
        />
      </g>
    </CustomSvg>
  );
};

export default BalanceTogglerIcon;
