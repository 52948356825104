import { useTheme } from 'styled-components';
import { useCallback, useMemo, useState } from 'react';

import { Typography, TransparentButton, StateSetter } from '@libs/components';
import { handleExcelExport } from '@libs/helpers';
import { screenSizes } from '@libs/theme';

import {
  MultiSelectFilterDataType,
  TransactionFiltersDefaultValue,
} from 'src/constants';
import { useDimensions, usePageNameDetector, useTranslation } from 'src/hooks';
import {
  TransactionAppliedFilters,
  TransactionFiltersType,
  TransactionMultiSelectFilterValues,
} from 'src/types';
import { ExcelLogo } from 'src/assets';

import { TransactionMultiSelectFilter } from './components/TransactionMultiSelectFilter';
import {
  ClearAllFiltersBtn,
  DatePickerWrapper,
  FiltersContainer,
  FiltersHeader,
  FiltersWrapper,
  ExportAllButton,
  CustomDateFilter,
  MobileExportButton,
  MobileExportButtonWrapper,
  CustomTypography,
  ButtonWrapper,
  BtnWrapper,
} from './styles';

type TransactionFilterProps = {
  setDateFilters: StateSetter<Date[] | undefined>;
  isFiltered: boolean;
  multiSelectData: MultiSelectFilterDataType<TransactionMultiSelectFilterValues>[];
  transactionFilterTypes: TransactionFiltersType;
  onFiltersSubmit: (newValue?: TransactionFiltersType) => void;
  appliedFilters?: TransactionAppliedFilters;
  hasTransactions: boolean;
  title?: string;
};

const TransactionFilters: React.FC<TransactionFilterProps> = ({
  setDateFilters,
  transactionFilterTypes,
  isFiltered,
  onFiltersSubmit,
  multiSelectData,
  appliedFilters,
  hasTransactions,
  title,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { width } = useDimensions();
  const [clearDate, setClearDate] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const pageName = t(usePageNameDetector());

  const handleFilter = (dates: (Date | null)[]) => {
    setDateFilters(dates as Date[]);
  };

  const onDateClear = () => {
    setDateFilters(undefined);
    onFiltersSubmit({
      ...transactionFilterTypes,
      MAXDATE: undefined,
      MINDATE: undefined,
    });
  };

  const handleClearAll = useCallback(() => {
    setClearDate(true);
    multiSelectData.forEach((data) => data.handler([]));
    onFiltersSubmit(TransactionFiltersDefaultValue);
  }, [multiSelectData, onFiltersSubmit]);

  const filterStatusesForExcel = useMemo(() => {
    return appliedFilters?.statuses?.map((res) => res.toLowerCase());
  }, [appliedFilters]);

  const handleExcelExportBtnClick = async () => {
    setIsLoading(true);
    await handleExcelExport(
      'user-transactions',
      process.env.GENERATE_EXCEL_API_URL,
      {
        assetCodes: appliedFilters?.assetCodes,
        statuses: filterStatusesForExcel,
        types: appliedFilters?.types,
        minDate: appliedFilters?.minDate,
        maxDate: appliedFilters?.maxDate,
      }
    );
    setIsLoading(false);
  };

  const dateFilterBtnSize = width < screenSizes.tablet ? 'sm' : 'md';

  return (
    <FiltersContainer>
      <FiltersHeader>
        <CustomTypography type="titleTwo" fontFamily="primaryBold">
          {title || pageName}
        </CustomTypography>
        {isFiltered && (
          <ButtonWrapper>
            <TransparentButton onClick={handleClearAll}>
              <Typography
                type="bodyButton"
                fontFamily="primaryBold"
                color={colors.interactive.brandColor}
              >
                {t('filter_clear_all')}
              </Typography>
            </TransparentButton>
          </ButtonWrapper>
        )}
      </FiltersHeader>

      <FiltersWrapper>
        <DatePickerWrapper isFiltered={!clearDate}>
          <CustomDateFilter
            onFilter={handleFilter}
            onClear={onDateClear}
            clearDate={clearDate}
            setClearDate={setClearDate}
            buttonSize={dateFilterBtnSize}
          />
        </DatePickerWrapper>

        {multiSelectData.map((filter, i) => {
          const handleFilterClear = () => {
            filter.handler([]);
            onFiltersSubmit({
              ...transactionFilterTypes,
              [filter.filterType]: [],
            });
          };

          return (
            <TransactionMultiSelectFilter
              key={i}
              onFiltersSubmit={onFiltersSubmit}
              onFilterClear={handleFilterClear}
              isFocused={isFocused}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              {...filter}
              options={filter.options}
              JustifyToRight={
                filter.filterType === TransactionMultiSelectFilterValues.STATUS
              }
            />
          );
        })}
        {hasTransactions && (
          <ExportAllButton
            onClick={handleExcelExportBtnClick}
            isLoading={isLoading}
            icon={<img src={ExcelLogo} alt="excel icon" />}
            buttonType="secondary"
            size="sm"
          />
        )}
      </FiltersWrapper>
      <MobileExportButtonWrapper>
        <MobileExportButton
          title={t('common_download')}
          onClick={handleExcelExportBtnClick}
          isLoading={isLoading}
          icon={<img src={ExcelLogo} alt="excel icon" />}
          buttonType="secondary"
        />
      </MobileExportButtonWrapper>
      {isFiltered && (
        <BtnWrapper isFiltered={isFiltered}>
          <ClearAllFiltersBtn onClick={handleClearAll}>
            <Typography
              type="bodyButton"
              fontFamily="primaryBold"
              color={colors.interactive.brandColor}
            >
              {t('filter_clear_all')}
            </Typography>
          </ClearAllFiltersBtn>
        </BtnWrapper>
      )}
    </FiltersContainer>
  );
};

export default TransactionFilters;
