import { useTheme } from 'styled-components';
import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

const ActiveStarIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4026 2.02755L10.6671 4.55277C10.8375 4.902 11.2948 5.23333 11.6805 5.30497L13.9673 5.68106C15.4291 5.92284 15.7699 6.97949 14.7207 8.03615L12.936 9.81813C12.64 10.1136 12.4697 10.6957 12.5683 11.1166L13.0795 13.3194C13.4831 15.0566 12.5504 15.7372 11.0168 14.8238L8.87346 13.5522C8.48783 13.3194 7.84214 13.3194 7.45651 13.5522L5.31313 14.8238C3.77959 15.7282 2.84692 15.0566 3.25049 13.3194L3.76167 11.1166C3.86032 10.7046 3.68992 10.1226 3.39398 9.81813L1.60934 8.03615C0.560076 6.98845 0.900859 5.9318 2.36266 5.68106L4.6495 5.30497C5.03513 5.24228 5.49251 4.902 5.6629 4.55277L6.9274 2.02755C7.60001 0.657483 8.71206 0.657483 9.4026 2.02755Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};

export default ActiveStarIcon;
