import styled from 'styled-components';

import { Input, Typography } from '@libs/components';
type CustomInputProps = {
  isFocused: boolean;
};

export const DashboardWalletContainer = styled.div`
  width: 100%;

  padding: 0 1.1rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;

    return theme.media.laptop`
      padding:0;
      
      box-shadow: ${shadow};
  `;
  }};
`;

export const WalletHeader = styled.div`
  width: 100%;

  padding: 1.7rem 0.9rem 1.2rem;

  ${({ theme }) => {
    const radius = theme.colors.borders
      .default as unknown as TemplateStringsArray;

    return theme.media.laptop`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      height: 7.1rem;

      padding: 0.3rem 2.1rem 0 3.2rem;

      border-bottom: 0.1rem solid ${radius};
  `;
  }};
`;

export const WalletTypography = styled(Typography)`
  display: none;

  ${({ theme }) => theme.media.laptop`
    display:flex;
    margin-right: 50px;
  `};
`;

export const WalletMobileTypography = styled(Typography)`
  margin-left: 0.1rem;
  margin-right: 25px;
  ${({ theme }) => theme.media.laptop`
    display: none;
  `};
`;

export const TabsWrapper = styled.div`
  width: 100%;

  margin-top: 1rem;

  ${({ theme }) => theme.media.laptop`
    max-width: 30rem;

    margin-top:0;
  `};
`;

export const WideTableWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.media.laptop`
    display:flex;
  `};
`;

export const MobileTableWrapper = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};

  ${({ theme }) => theme.media.laptop`
    display:none;
  `};
`;
export const CustomSearchInput = styled(Input)<CustomInputProps>`
  height: 5rem;
  max-width: 28rem;
  margin-top: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  .input-placeholder {
    transform: translateY(
      ${({ isFocused }) => (isFocused ? 'calc(-100% + 0.6rem)' : '-0.3rem')}
    );
  }
  ${({ theme }) => theme.media.laptop`
    margin:0;
  `};
`;
export const InputAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
