import styled from 'styled-components';

import { Typography, Button } from '@libs/components';
import { DepositIcon } from 'src/assets';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 2rem auto;
  border: 0.2rem solid ${({ theme }) => theme.colors.borders.default};
  ${({ theme }) => theme.media.tabletL`
      max-width: 50rem;
      width:50rem;
      background-color:${
        theme.colors.surface.default as unknown as TemplateStringsArray
      };
  `}
`;
export const CustomTypography = styled(Typography)`
  margin-bottom: 1.4rem;
  display: block;
`;
export const CustomButton = styled(Button)`
  margin-top: 3.2rem;
`;
export const CustomDepositIcon = styled(DepositIcon)`
  margin-right: 1rem;
`;
