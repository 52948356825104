import styled from 'styled-components';

export const TitleCont = styled.div`
  margin-bottom: 1.2rem;
`;
export const AccordionCont = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 2.2rem;
  ${({ theme }) => theme.media.tablet`
            width: 50%;
              padding: 0 1.1rem;
              margin-bottom:2.2rem;
    `}
`;
export const FaqBody = styled.div`
  margin: 0;
  ${({ theme }) => theme.media.tablet`
          margin: 0 -1.1rem;
    `}
`;
export const Row = styled.div`
  flex-direction: column;
  ${({ theme }) => theme.media.tablet`
         display:flex;
         flex-direction: row;
    
    `}
`;
export const FaqContainer = styled.div`
  padding: 0 2.4rem;
  margin-top: 3rem;
  ${({ theme }) => theme.media.tablet`
        padding:0;
    
    `}
`;
