import { VisaIcon, MastercardIcon } from '../assets';

export const CardProviders: Record<string, RegExp> = {
  Electron: /^(4026|417500|4405|4508|4844|4913|4917)[0-9x]{10,13}$/,
  Maestro:
    /^(5018|5020|5038|5612|5893|6304|6759|676[1-3]|0604|6390)[0-9x]{8,15}$/,
  Dankort: /^(5019)[0-9x]{10}$/,
  Interpayment: /^(636)[0-9x]{10,13}$/,
  Unionpay: /^(62|88)[0-9x]{14,17}$/,
  Visa: /^4[0-9x]{12}(?:[0-9x]{3})?$/,
  Mastercard: /^5[1-5][0-9x]{2}(?:[0-9x]{12})$/,
  Amex: /^3[47][0-9x]{11,14}$/,
  Diners: /^3(?:0[0-5]|[68][0-9])[0-9x]{9,12}$/,
  Discover: /^6(?:011|5[0-9]{2})[0-9x]{12}$/,
  Jcb: /^(?:2131|1800|35\d{3})[0-9x]{11}$/,
};
export const CardIcons: Record<string, string> = {
  Electron: MastercardIcon,
  Maestro: MastercardIcon,
  Dankort: MastercardIcon,
  Interpayment: MastercardIcon,
  Unionpay: MastercardIcon,
  Visa: VisaIcon,
  Mastercard: MastercardIcon,
  Amex: MastercardIcon,
  Diners: MastercardIcon,
  Discover: MastercardIcon,
  Jcb: MastercardIcon,
};
