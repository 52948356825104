import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const DetailsHeaderLoader = styled(ContentLoader)`
  height: 3rem;
  width: 100%;
`;

export const AssetIcon = styled.circle`
  cx: calc(50% - 5.8rem);
  cy: 50%;

  r: 1.4rem;
`;

export const AssetName = styled.rect`
  width: 12rem;
  height: 2.5rem;

  x: calc(50% - 4rem);

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
