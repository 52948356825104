import { FC } from 'react';

import {
  resultFeedbackErrorAnimation,
  resultFeedbackPendingAnimation,
  resultFeedbackSuccessAnimation,
  ResultFeedback as NativeResultFeedback,
} from '@libs/components';

import { useTranslation } from 'src/hooks';
import { ResultFeedbackType } from '@libs/types';

export type ResultFeedbackProps = {
  type: ResultFeedbackType;
  onClick: () => void;

  title?: string;
  testId?: string;
  hasTitle?: boolean;
  className?: string;
  buttonText?: string;
  description?: string;
  isDisabled?: boolean;
  downloadTitle?: string;
  linkToDownload?: string;
};

const ResultFeedback: FC<ResultFeedbackProps> = ({
  type,
  title,
  buttonText,
  ...props
}) => {
  const { t } = useTranslation();

  const RESULT_FEEDBACKS = {
    [ResultFeedbackType.SUCCESS]: {
      textKey: 'result_feedback_success',
      animation: resultFeedbackSuccessAnimation,
      testId: 'success',
    },
    [ResultFeedbackType.ERROR]: {
      textKey: 'result_feedback_error',
      animation: resultFeedbackErrorAnimation,
      testId: 'error',
    },
    [ResultFeedbackType.PENDING]: {
      textKey: 'status_processing',
      animation: resultFeedbackPendingAnimation,
      testId: 'pending',
    },
  };

  const { textKey, animation, testId } = RESULT_FEEDBACKS[type];

  const titleToDisplay = title || t(textKey);
  const buttonTextToDisplay = buttonText || t('common_done');

  return (
    <NativeResultFeedback
      titleToDisplay={titleToDisplay}
      buttonTextToDisplay={buttonTextToDisplay}
      animation={animation}
      testId={testId}
      {...props}
    />
  );
};
export default ResultFeedback;
