import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CheckEmailArgs = DefaultArg<{ email: string }>;

export type CheckEmailReponse = DefaultApiResponse<{
  checkEmail: boolean;
}>;

export const checkEmailQuery: TypedDocumentNode<
  CheckEmailReponse,
  CheckEmailArgs
> = gql`
  query checkEmail($record: CheckEmailRecord!) {
    checkEmail(record: $record)
  }
`;
