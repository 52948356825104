import styled from 'styled-components';
import { Disclaimer } from '@libs/components';

export const BeneficiaryContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  padding: 1.6rem;
  margin-bottom: 1.8rem;
`;

export const DepositBankWireTitle = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 1.6rem;
  padding: 0 1.6rem;
  height: 5.2rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.medium};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.medium};
`;
export const BankIconContainer = styled.div`
  margin-right: 1.3rem;
`;
export const KYCDisclaimer = styled(Disclaimer)`
  margin-bottom: 1.6rem;
`;
