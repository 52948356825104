import { JurisdictionsEnum } from '@libs/backend-common';
import { useJurisdictionDetector } from 'src/hooks';
import { useTheme } from 'styled-components';

import {
  AllBalance,
  AllBalanceTitle,
  BalanceContainer,
  BalanceContainerUa,
  BalanceLoaderWrapper,
  CurrencySwitcher,
  DividedCurrency,
  UABalanceLoaderWrapper,
} from './styles';

export const BalanceLoader = () => {
  const { colors } = useTheme();
  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;
  return !IS_GLOBAL_JURISDICTION ? (
    <BalanceContainer>
      <BalanceLoaderWrapper
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <AllBalanceTitle />
        <AllBalance />
        <CurrencySwitcher />
        <DividedCurrency />
      </BalanceLoaderWrapper>
    </BalanceContainer>
  ) : (
    <BalanceContainerUa>
      <UABalanceLoaderWrapper
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <AllBalanceTitle />
        <AllBalance />
      </UABalanceLoaderWrapper>
    </BalanceContainerUa>
  );
};
