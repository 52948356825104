import styled from 'styled-components';

export const DashboardTransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  margin: 2.9rem 0 2.2rem;

  background-color: ${({ theme }) => theme.colors.surface.default};
  overflow: hidden;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;

    const color = theme.colors.surface
      .default as unknown as TemplateStringsArray;

    return theme.media.laptop`

      box-shadow: ${shadow};
      background-color: ${color};
      margin:1.9rem 0 0;
  `;
  }}
`;

export const Header = styled.div`
  margin: 2.1rem 0 1.7rem 2rem;

  ${({ theme }) => theme.media.laptop`
    margin: 1.7rem 0 1.5rem 2.1rem;
    `};
`;

export const TransactionWrapper = styled.div`
  width: 100%;

  .empty-state {
    padding: 2.1rem 2rem 3.1rem;

    ${({ theme }) => {
      return theme.media.laptop`
        padding-top:2.6rem;
        margin:0;
    `;
    }};
  }
`;
