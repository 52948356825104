import { ThemeMode } from '@libs/theme';

import { useThemeMode } from './useThemeMode';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseRenderObjectByTheme = <T = any>(
  forLightMode?: T,
  forDarkMode?: T
) => T | undefined;

/**
 * Returns relevant item provided by the user according to the theme mode
 *
 * @param forLightMode - any item, which is going to be rendered only for light mode
 * @param forDarkMode - any item, which is going to be rendered only for dark mode
 */
export const useRenderObjectByTheme: UseRenderObjectByTheme = (
  forLightMode,
  forDarkMode
) => {
  const { selectedTheme } = useThemeMode();

  return selectedTheme === ThemeMode.light ? forLightMode : forDarkMode;
};
