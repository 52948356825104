import { useCallback } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  createCryptoTransactionMutation,
  CreateCryptoTransactionProps,
  createCryptoTransactionResponse,
  getWithdrawalAddressesQuery,
} from 'src/gql';
import { useApiResponse, useTranslation } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import {
  NETWORK_TIMEOUT_ERROR_MESSAGES,
  ResultFeedbackType,
} from 'src/constants';

export const useCreateCryptoTransaction = (
  amount: string,
  currency: string
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCreateCryptoTransactionSuccess = useCallback(
    () => {
      navigate('/feedback', {
        replace: true,
        state: {
          type: ResultFeedbackType.PENDING,
          description: t('withdraw_status', { amount, currency }),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amount, currency]
  );
  const handleCreateCryptoTransactionError = (error?: ApolloError) => {
    let feedbackType = ResultFeedbackType.ERROR;
    let description = t('common_unexpected_error');
    let navigationPath = undefined;
    let buttonText = undefined;
    if (error?.networkError?.message) {
      const isNetworkError = NETWORK_TIMEOUT_ERROR_MESSAGES.includes(
        error.networkError.message
      );
      if (isNetworkError) {
        feedbackType = ResultFeedbackType.PENDING;
        description = t('error_crypto_withdrawal_timeout');
        navigationPath = '/transactions';
        buttonText = t('result_feedback_to_transactions');
      }
    }
    navigate('/feedback', {
      replace: true,
      state: {
        type: feedbackType,
        description,
        navigationPath,
        buttonText,
      },
    });
  };

  const [handleCreateCryptoTransaction, createCryptoTransactionRes] =
    useMutation(createCryptoTransactionMutation, {
      refetchQueries: [
        {
          query: getWithdrawalAddressesQuery,
        },
      ],
    });

  const {
    errors: createCryptoTransactionMessage,
    loading: creatingCryptoTransaction,
  } = useApiResponse<createCryptoTransactionResponse, ApolloError>(
    createCryptoTransactionRes,
    handleCreateCryptoTransactionSuccess,
    handleCreateCryptoTransactionError
  );

  const createCryptoTransaction = (record: CreateCryptoTransactionProps) => {
    handleCreateCryptoTransaction({
      variables: {
        record,
      },
    });
  };

  return {
    errorMessage: createCryptoTransactionMessage,
    createCryptoTransaction,
    creatingCryptoTransaction,
    loading: creatingCryptoTransaction,
  };
};
