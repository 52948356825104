import { useQuery } from '@apollo/client';
import { useApiResponse } from '@libs/hooks';

import { getUserTransactionsCountQuery } from 'src/gql';
import { TransactionAppliedFilters } from 'src/types';

export const useAssetTransactionPagination = (
  inEachPage: number,
  appliedFilters: TransactionAppliedFilters
) => {
  const { data: transactionsAmount, loading: amountLoading } = useQuery(
    getUserTransactionsCountQuery,
    {
      variables: {
        record: appliedFilters,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { response } = useApiResponse({
    data: transactionsAmount,
    loading: amountLoading,
  });

  const totalPages = Math.ceil(
    (response?.getUserTransactionsCount || 0) / inEachPage
  );

  return totalPages;
};
