import { ModalWithTwoFa, ResultFeedback } from 'src/components';
import styled from 'styled-components';

export const CustomResultFeedback = styled(ResultFeedback)`
  ${({ theme }) => theme.media.tablet`
    min-width: 50rem;
  `};
`;

export const CustomModalWithTwoFa = styled(ModalWithTwoFa)`
  padding: 2.2rem;

  ${({ theme }) => theme.media.tablet`
    padding: 3.7rem;
  `};
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  text-align: left;
  &:nth-child(3) {
    margin-bottom: 0;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 62rem;
  align-items: center;
  flex-direction: column;
  margin-top: 4.2rem;
  padding: 0 2.2rem 2.2rem 1rem;
  ${({ theme }) => theme.media.tablet`
    padding: 0 3.7rem 2.5rem;
    min-width: 42.2rem;
  `};
`;
export const ErrorMessageWrapper = styled.div`
  width: 100%;
  height: 2rem;
  margin: 0.5rem 0;
`;
