import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type DeleteCardRes = {
  deleteCard: DefaultApiResponse<boolean>;
};

export type DeleteCardArgs = {
  id: number;
};

export const deleteCardMutation: TypedDocumentNode<
  DeleteCardRes,
  DefaultArg<DeleteCardArgs>
> = gql`
  mutation DeleteCard($record: IdRecord!) {
    deleteCard(record: $record)
  }
`;
