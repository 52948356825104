import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

export const SmsAuthIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <CustomSvg
      width="44"
      height="41"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_26012_117840)">
        <path
          d="M41.3411 24.2676L38.2767 26.0699L38.1621 28.0027L41.2265 26.2004C42.7956 25.2775 43.6906 23.745 43.7858 22.1418L43.9004 20.209C43.8053 21.8123 42.9101 23.3447 41.3411 24.2676Z"
          fill="#B0C3FF"
        />
        <path
          d="M38.1622 28.0957C38.1455 28.0957 38.1289 28.0911 38.1141 28.0821C38.0853 28.0643 38.0685 28.0318 38.0705 27.9978L38.185 26.065C38.1869 26.0342 38.2039 26.0063 38.2304 25.9906L41.2948 24.1883C42.7724 23.3193 43.7122 21.8298 43.8088 20.2041C43.8117 20.153 43.8567 20.1145 43.9061 20.1173C43.9568 20.1204 43.9955 20.1641 43.9923 20.2152C43.9923 20.2157 43.9923 20.2161 43.9922 20.2167L43.8777 22.1481C43.7774 23.8356 42.8038 25.3807 41.273 26.281L38.2087 28.0832C38.1942 28.0915 38.1782 28.0957 38.1622 28.0957ZM38.3656 26.1253L38.2641 27.8361L41.1801 26.1211C42.6577 25.2521 43.5975 23.7626 43.694 22.1368L43.7301 21.5301C43.3308 22.6835 42.5086 23.6885 41.3876 24.3478L38.3656 26.1253Z"
          fill="#111111"
        />
        <path
          d="M37.938 30.949C37.678 31.1019 37.3372 31.138 37.0204 30.9956L33.0033 29.1719L32.8887 31.1047L36.9057 32.9284C37.2225 33.0708 37.5633 33.0347 37.8234 32.8818C38.0656 32.7394 38.2377 32.4955 38.2552 32.2002L38.3699 30.2673C38.3523 30.5626 38.1802 30.8065 37.938 30.949Z"
          fill="#B0C3FF"
        />
        <path
          d="M37.3214 33.109C37.1677 33.109 37.013 33.0767 36.8683 33.0117L32.851 31.1877C32.8162 31.172 32.7948 31.1362 32.797 31.0979L32.9117 29.1651C32.9135 29.1348 32.93 29.1072 32.9558 29.0915C32.9818 29.0757 33.0137 29.0738 33.0412 29.0863L37.0583 30.9101C37.3285 31.0314 37.6401 31.0161 37.8917 30.868C38.1224 30.7323 38.2633 30.511 38.2782 30.2607C38.2811 30.2097 38.3264 30.1714 38.3755 30.174C38.4262 30.177 38.4648 30.2209 38.4617 30.2718L38.347 32.2046C38.3284 32.5177 38.1544 32.7932 37.8698 32.9606C37.7022 33.059 37.5125 33.109 37.3214 33.109ZM32.9843 31.0454L36.9437 32.8429C37.2141 32.9643 37.5258 32.9487 37.777 32.8008C38.0078 32.6651 38.1487 32.4438 38.1636 32.1935L38.2457 30.8095C38.1743 30.8939 38.0866 30.9677 37.9847 31.0276C37.6819 31.2055 37.3077 31.2245 36.983 31.0788L33.0873 29.3101L32.9843 31.0454Z"
          fill="#111111"
        />
        <path
          d="M18.5609 37.666C15.9605 39.1955 12.4928 38.4719 10.8173 36.0464L1.09901 21.9787C0.435049 21.0176 0.152399 19.9341 0.214924 18.8809L0.100265 20.8137C0.0377396 21.8669 0.320389 22.9505 0.984349 23.9116L10.7027 37.9793C12.3781 40.4047 15.8459 41.1283 18.4464 39.5989L32.8892 31.1041L33.0038 29.1713L18.5609 37.666Z"
          fill="#B0C3FF"
        />
        <path
          d="M15.4744 40.4914C13.584 40.4915 11.7245 39.6208 10.6274 38.0327L0.909109 23.9649C0.253884 23.0164 -0.0575451 21.925 0.00874968 20.8088L0.123409 18.876C0.126352 18.825 0.169935 18.7859 0.220691 18.7893C0.271446 18.7922 0.310065 18.8361 0.306938 18.8871C0.243218 19.9615 0.543246 21.0126 1.17475 21.9267L10.8931 35.9944C12.5387 38.3766 15.9578 39.0909 18.5148 37.587L32.9576 29.0923C32.9869 29.0752 33.0233 29.0757 33.0521 29.0933C33.081 29.1112 33.0978 29.1436 33.0958 29.1777L32.9811 31.1105C32.9793 31.1413 32.9622 31.1692 32.9358 31.1848L18.4931 39.6795C17.5584 40.2292 16.5117 40.4913 15.4744 40.4914ZM0.227955 20.2188L0.192279 20.8198C0.128558 21.8942 0.428586 22.9453 1.06009 23.8594L10.7784 37.9272C12.4239 40.3093 15.8429 41.0237 18.4002 39.5198L32.8004 31.0499L32.9019 29.3391L18.6075 37.7465C15.9696 39.2979 12.4411 38.5595 10.742 36.0998L1.02377 22.0321C0.635194 21.4698 0.367624 20.8572 0.227955 20.2188Z"
          fill="#111111"
        />
        <path
          d="M25.5566 1.42023C28.157 -0.109218 31.6208 0.616484 33.2965 3.04206L43.0149 17.1098C44.6905 19.5352 43.9413 22.7391 41.3408 24.2686L38.2763 26.0709L38.3722 30.201C38.3784 30.526 38.1992 30.7959 37.9391 30.9489C37.679 31.1018 37.3382 31.1379 37.0214 30.9955L33.0043 29.1718L18.5615 37.6665C15.961 39.1959 12.4933 38.4723 10.8179 36.0469L1.09955 21.9791C-0.57603 19.5536 0.176936 16.3476 2.77742 14.8182L25.5566 1.42023Z"
          fill="white"
        />
        <path
          d="M15.5884 38.558C13.698 38.558 11.8386 37.6874 10.7414 36.0992L1.02312 22.0315C0.213242 20.8593 -0.068856 19.4667 0.228781 18.1103C0.537636 16.7027 1.42595 15.505 2.72996 14.738L25.5093 1.34013C28.1452 -0.210217 31.6719 0.529448 33.3711 2.98905L43.0894 17.0568C43.9 18.2302 44.1825 19.6234 43.8848 20.9799C43.5764 22.3857 42.689 23.582 41.3862 24.3482L38.3686 26.1229L38.4632 30.1985C38.4696 30.5383 38.2907 30.8483 37.9846 31.0284C37.682 31.2064 37.3074 31.2254 36.983 31.0797L33.0088 29.2754L18.6069 37.746C17.6723 38.2957 16.6258 38.558 15.5884 38.558ZM25.5557 1.41993L25.6021 1.49973L2.82283 14.8976C1.5636 15.6383 0.706086 16.7934 0.408356 18.1501C0.121937 19.4556 0.393829 20.7965 1.1741 21.926L10.8924 35.9938C12.538 38.3758 15.9571 39.0903 18.5141 37.5864L32.9569 29.0917C32.9826 29.0765 33.014 29.0747 33.0411 29.0872L37.0583 30.911C37.3288 31.0324 37.6401 31.0168 37.8916 30.8688C38.1394 30.723 38.2843 30.4739 38.2792 30.2026L38.1834 26.0729C38.1826 26.0393 38.2001 26.0079 38.2289 25.9909L41.2932 24.1886C42.5513 23.4486 43.4078 22.295 43.7051 20.9401C43.9916 19.6346 43.7192 18.293 42.9382 17.1623L33.2201 3.09456C31.5746 0.712445 28.157 -0.00280774 25.6021 1.49982L25.5557 1.41993Z"
          fill="#111111"
        />
        <path
          d="M13.7165 22.4905C12.8296 23.0121 12.5728 24.1103 13.1442 24.9374C13.718 25.768 14.903 26.0133 15.7899 25.4917C16.6802 24.9681 16.9358 23.8754 16.3619 23.0448C15.7905 22.2178 14.6069 21.9669 13.7165 22.4905Z"
          fill="#111111"
        />
        <path
          d="M14.7763 25.8561C14.1108 25.8561 13.4558 25.5493 13.0691 24.9894C12.7831 24.5753 12.6836 24.0823 12.7891 23.6013C12.8984 23.1032 13.2115 22.6801 13.6706 22.41C14.5982 21.8647 15.8396 22.1254 16.438 22.9914C16.7253 23.4074 16.8255 23.9007 16.7202 24.3805C16.6112 24.8771 16.2975 25.2998 15.8368 25.5708C15.5084 25.764 15.1407 25.8561 14.7763 25.8561ZM13.7634 22.5697C13.349 22.8134 13.0668 23.194 12.9686 23.6411C12.8743 24.0712 12.9636 24.5127 13.2201 24.8839C13.765 25.6726 14.8971 25.9093 15.7438 25.4112C16.1598 25.1665 16.4428 24.7864 16.5406 24.3407C16.6346 23.9117 16.5445 23.4701 16.2868 23.097C15.7419 22.3084 14.6098 22.0718 13.7634 22.5697Z"
          fill="#111111"
        />
        <path
          d="M21.1558 18.1156C20.2691 18.6371 20.0123 19.7353 20.5836 20.5623C21.1574 21.393 22.3424 21.6383 23.229 21.1168C24.1195 20.593 24.3751 19.5004 23.8013 18.6698C23.2299 17.8428 22.0463 17.5918 21.1558 18.1156Z"
          fill="#111111"
        />
        <path
          d="M22.2157 21.4811C21.5503 21.4812 20.8952 21.1743 20.5086 20.6145C20.2224 20.2003 20.123 19.7072 20.2286 19.226C20.3378 18.728 20.6508 18.305 21.1098 18.0351C22.0375 17.4897 23.2788 17.7502 23.8773 18.6164C24.1646 19.0323 24.2649 19.5256 24.1596 20.0054C24.0506 20.502 23.7367 20.9248 23.2759 21.1959C22.9477 21.389 22.58 21.4811 22.2157 21.4811ZM22.1721 17.934C21.8389 17.934 21.5029 18.0181 21.2026 18.1947C20.7884 18.4384 20.5062 18.8187 20.408 19.2659C20.3136 19.696 20.4029 20.1375 20.6595 20.5089C21.2043 21.2975 22.3363 21.5342 23.1829 21.0362C23.599 20.7915 23.8821 20.4112 23.9798 19.9655C24.074 19.5365 23.9839 19.0949 23.726 18.7218C23.3745 18.213 22.7782 17.934 22.1721 17.934Z"
          fill="#111111"
        />
        <path
          d="M28.5934 13.7406C27.7068 14.2621 27.4497 15.3604 28.0211 16.1874C28.5949 17.0181 29.78 17.2633 30.6667 16.7418C31.5572 16.218 31.8127 15.1254 31.239 14.2948C30.6676 13.4678 29.4839 13.2168 28.5934 13.7406Z"
          fill="#111111"
        />
        <path
          d="M29.6532 17.1063C28.9877 17.1063 28.3327 16.7994 27.9461 16.2397C27.66 15.8255 27.5605 15.3324 27.6661 14.8513C27.7754 14.3532 28.0885 13.9302 28.5475 13.6602C29.4753 13.1146 30.7166 13.3752 31.3151 14.2415C31.6024 14.6574 31.7026 15.1507 31.5973 15.6305C31.4883 16.1271 31.1745 16.5499 30.7137 16.821C30.3854 17.0142 30.0177 17.1063 29.6532 17.1063ZM29.6099 13.5591C29.2766 13.5591 28.9406 13.6433 28.6403 13.8199C28.226 14.0636 27.9438 14.4441 27.8456 14.8913C27.7512 15.3213 27.8404 15.7629 28.0969 16.1342C28.6418 16.9228 29.7739 17.1595 30.6207 16.6614C31.0367 16.4167 31.3198 16.0364 31.4176 15.5907C31.5116 15.1617 31.4215 14.7201 31.1638 14.347C30.8124 13.838 30.2161 13.5591 29.6099 13.5591Z"
          fill="#111111"
        />
      </g>
      <defs>
        <clipPath id="clip0_26012_117840">
          <rect
            width="44"
            height="40"
            fill="white"
            transform="translate(0 0.509766)"
          />
        </clipPath>
      </defs>
    </CustomSvg>
  );
};

export default SmsAuthIcon;
