import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const DeleteIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.interactive.critical;
  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5 4.98335C14.725 4.70835 11.9333 4.56668 9.15 4.56668C7.5 4.56668 5.85 4.65001 4.2 4.81668L2.5 4.98335"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08334 4.14166L7.26668 3.04999C7.40001 2.25832 7.50001 1.66666 8.90834 1.66666H11.0917C12.5 1.66666 12.6083 2.29166 12.7333 3.05832L12.9167 4.14166"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7083 7.61667L15.1667 16.0083C15.075 17.3167 15 18.3333 12.675 18.3333H7.32499C4.99999 18.3333 4.92499 17.3167 4.83332 16.0083L4.29166 7.61667"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60834 13.75H11.3833"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91666 10.4167H12.0833"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};
export default DeleteIcon;
