import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BalanceKycBackground, ExclamationSignGlass } from 'src/assets';

import { useTranslation } from 'src/hooks';

import {
  Container,
  Img,
  CustomButton,
  CustomTypography,
  Wrapper,
  Description,
} from './styles';

const BalanceKyc = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  const { t } = useTranslation();
  const handleButtonClick = () => navigate('/kyc');

  return (
    <Container imgUrl={BalanceKycBackground}>
      <CustomTypography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.interactive}
      >
        {t('require_kyc_title')}
      </CustomTypography>
      <Wrapper>
        <Img src={ExclamationSignGlass} />
        <Description
          type="bodyButton"
          fontFamily="primaryMedium"
          color={colors.text.interactive}
        >
          {t('require_kyc_desc')}
        </Description>
      </Wrapper>
      <CustomButton onClick={handleButtonClick}>
        {t('require_kyc_button_text')}
      </CustomButton>
    </Container>
  );
};

export default BalanceKyc;
