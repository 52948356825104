import {
  Button,
  DropdownIcon,
  TransparentButton,
  Typography,
} from '@libs/components';
import styled from 'styled-components';

export const CashTradeContainer = styled.div`
  position: relative;

  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  overflow: hidden;

  ${({ theme }) => theme.media.tablet`
    border-bottom-left-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };
    border-bottom-right-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };

    background-color: ${
      theme.colors.surface.interactiveDefault as unknown as TemplateStringsArray
    };
  `};
`;

export const CashTradeHeader = styled.div`
  z-index: ${({ theme }) => theme.zIndex.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-height: 7rem;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};

  box-shadow: ${({ theme }) => theme.shadows.lg};
  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const AddressWrapper = styled.div`
  padding: 4.2rem 1.5rem 3.9rem;

  background-color: ${({ theme }) => theme.colors.surface.default};

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.high};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.high};

  ${({ theme }) => theme.media.tablet`
    padding: 4.2rem 3.8rem 2.6rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `};
`;

export const AddressBox = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 2.2rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  &:first-child {
    margin-bottom: 3.8rem;
  }

  ${({ theme }) => theme.media.tablet`
    padding-top: 2rem;
  `};
`;

export const AddressIconWrapper = styled.div`
  position: absolute;
  top: -2.4rem;
  left: calc(50% - 2.4rem);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 5rem;
  height: 5rem;

  padding: 0.3rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
     width: 4.4rem;
     height: 4.4rem;
  `};
`;

export const AddressIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;

export const Address = styled(Typography)`
  margin-top: -0.3rem;
  margin-bottom: 1.4rem;

  ${({ theme }) => theme.media.tablet`
     margin-bottom: 1.2rem;
  `};
`;

export const DetailsButtonWrapper = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 4.6rem;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const CustomDropdownIcon = styled(DropdownIcon)`
  width: 1.2rem;
  height: 1.2rem;

  margin-left: 0.6rem;

  transform: rotate(-90deg);
`;

export const ContactUsWrapper = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  width: 100%;

  padding: 2.2rem 2rem 1.7rem;

  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tabletL`
  position: relative;
  
  `};

  ${({ theme }) => theme.media.tablet`
    padding: 1.2rem 3rem;
    
    border-top-left-radius: initial;
    border-top-right-radius: initial;

    border-bottom-left-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };
    border-bottom-right-radius: ${
      theme.borderRadius.high as unknown as TemplateStringsArray
    };
    
  `};
`;

export const ContactUsButton = styled(Button)`
  width: 40%;

  margin-left: 1rem;
`;
