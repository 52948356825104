import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { BankCode } from '@libs/backend-common';

import { CurrencyType } from 'src/constants';

export type WithdrawBankWireEntity = {
  id: string | number;
  pdf: string;
  iban: string;
  amount: number;
};
export type WithdrawBankWireResponse = {
  requestBankWireWithdrawal: DefaultApiResponse<WithdrawBankWireEntity>;
};
export type BankWireWithdrawalArgs = {
  iban: string;
  amount: number;
  bankCode: BankCode;
  assetCode: CurrencyType;

  emailCode?: string;
  smsCode?: string;
  googleCode?: string;
  address?: string;
  bankRequisiteId?: number;
  bankRequisiteName?: string;
};

export const withdrawBankWireMutation: TypedDocumentNode<
  WithdrawBankWireResponse,
  DefaultArg<BankWireWithdrawalArgs>
> = gql`
  mutation RequestBankWireWithdrawal(
    $record: RequestBankWireWithdrawalRecord!
  ) {
    requestBankWireWithdrawal(record: $record) {
      id
      pdf
      iban
      amount
    }
  }
`;
