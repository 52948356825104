import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { CurrencyType } from 'src/constants';

export type payWithCardsArg = {
  amount: number;
  cardId: number;
  asset: CurrencyType | undefined;
};

export type PayWithCardResponse = {
  payWithCard: DefaultApiResponse<{ redirectUrl: string }>;
};

export const payWithCardMutation: TypedDocumentNode<
  PayWithCardResponse,
  DefaultArg<payWithCardsArg>
> = gql`
  mutation PayWithCard($record: CardPaymentRecord!) {
    payWithCard(record: $record) {
      redirectUrl
    }
  }
`;
