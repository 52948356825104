import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';
import { CurrencyType } from '@libs/types';

export type StakingTermPeriod = {
  assetCode: CurrencyType;
  assetName: string;
  durations: {
    min: number;
    duration: number;
    interestRate: number;
    totalInterestRate: number;
  }[];
};

export type StakingTermsResponse = {
  getStakingTerms: DefaultApiResponse<StakingTermPeriod[]>;
};

export const getStakingTermsQuery: TypedDocumentNode<StakingTermsResponse> = gql`
  query GetStakingTerms {
    getStakingTerms {
      assetCode
      assetName
      durations {
        totalInterestRate
        duration
        min
        interestRate
      }
    }
  }
`;
