import styled from 'styled-components';

import { SwapTaskInfo } from '@libs/components';

export const CustomSwapTaskInfo = styled(SwapTaskInfo)`
  margin-top: 1.6rem;

  ${({ theme }) => theme.media.tabletL`
    margin-top: 2rem;
  `}
`;
