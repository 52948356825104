import { RegexValidators } from '@libs/validations';
import { object, string } from 'yup';

import { ValidationErrorMessages } from './types';

export const changePhoneValidation = object({
  phone: string()
    .required(ValidationErrorMessages.REQUIRED)
    .min(11, ValidationErrorMessages.PHONE)
    .matches(RegexValidators.IS_PHONE_VALID, ValidationErrorMessages.PHONE),
});
