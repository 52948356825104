import { useQuery } from '@apollo/client';
import { meQuery } from '@libs/graphql';
import {
  useApiResponse,
  useEnabledFeatures as useNativeEnabledFeatures,
} from '@libs/hooks';
import { getEnabledFeaturesQuery } from 'src/gql';

export const useEnabledFeatures = () => {
  const featureQueryResponse = useQuery(getEnabledFeaturesQuery);
  const { response, loading } = useApiResponse(featureQueryResponse);

  const userQueryResponse = useQuery(meQuery);
  const { response: userResponse, loading: userLoading } =
    useApiResponse(userQueryResponse);

  return useNativeEnabledFeatures(
    response?.getEnabledFeatures,
    loading,
    userResponse?.me,
    userLoading
  );
};
