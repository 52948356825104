import styled from 'styled-components';
import { Typography, TransparentButton, Button } from '@libs/components';

export const Wrapper = styled.div``;

export const ChooseAddressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem auto 0;
`;

export const SaveAddressText = styled(Typography)`
  margin-left: 1rem;
  text-align: center;
`;

export const ModalContent = styled.div`
  margin: 0 auto;
  padding: 2rem 2.2rem;

  ${({ theme }) => theme.media.tablet`
    width: 42.6rem;
    padding: 3rem 2.8rem;
  `}
`;

export const Separator = styled.div`
  height: 2rem;
`;

export const CustomButton = styled(Button)`
  margin-top: 0.5rem;
`;

export const SelectAddressText = styled(Typography)`
  margin-left: 0.6rem;
`;

export const TrButton = styled(TransparentButton)`
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
`;
