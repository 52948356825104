import { SelectData, StateSetter } from '@libs/components';
import { TransactionStatusType } from '@libs/types';

export type MultiSelectFilterDataType<T> = {
  filterType: T;
  placeholder?: string;
  textAfterSelection?: string;
  options: SelectData<string>[];

  selectedValues: string[];
  handler: StateSetter<string[]>;
  renderOptionHeader: (closeSelect: () => void) => JSX.Element;
  searchInputValue?: string;
};

export const TRANSACTION_STATUS_FILTER_TYPES = [
  TransactionStatusType.PENDING,
  TransactionStatusType.REJECTED,
  TransactionStatusType.SUCCEEDED,
];
