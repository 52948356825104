import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { SwapAsset } from '@libs/types';

type GetSwapResponse = {
  getSwapSettings: SwapAsset[];
};

export const GetSwapSettingsQuery: TypedDocumentNode<
  DefaultApiResponse<GetSwapResponse>,
  DefaultArg
> = gql`
  query GetSwapSettings {
    getSwapSettings {
      code
      iconUrl
      name
      roundingPrecision
      pairedAssets {
        roundingPrecision
        code
        iconUrl
        name
        supportedNetworks {
          fireblocksId
          networkId
          networkName
          networkCode
        }
      }
      supportedNetworks {
        fireblocksId
        networkId
        networkName
        networkCode
      }
    }
  }
`;
