import { Typography } from '@libs/components';
import { formatBalance } from '@libs/helpers';
import { getUsdOrEurCurrencySymbol } from 'src/helpers';

import { useTranslation } from 'src/hooks';

import { AssetPriceWrapper } from '../styles';
import { AssetChangeRate, AssetUserBalanceWrapper } from './styles';

type AssetUserbalanceProps = {
  assetCode?: string;
  assetAmount?: string;
  assetPrice?: number;
};
export const AssetUserBalance: React.FC<AssetUserbalanceProps> = ({
  assetAmount,
  assetCode,
  assetPrice,
}) => {
  const { t } = useTranslation();
  // const { colors } = useTheme();
  // const amount = 1.3;
  const BalanceAmountinAsset = assetAmount + ' ' + assetCode;
  // const [pnl, setPnl] = useState<string | number>();

  const BalanceAmountinUsd = formatBalance(Number(assetPrice), false);
  // const UserAmountRateColor =
  //   Number(amount) < 0 ? colors.text.critical : colors.text.success;

  return (
    <AssetUserBalanceWrapper>
      <Typography type="bodyButton" fontFamily="primaryMedium">
        {t('common_my_asset_balance', { asset: String(assetCode) })}
      </Typography>
      <AssetPriceWrapper>
        <Typography type="titleOne" fontFamily="primaryBold">
          {BalanceAmountinAsset}
        </Typography>
        <AssetChangeRate fontFamily="primaryBold" type="subHeadline">
          {getUsdOrEurCurrencySymbol() + BalanceAmountinUsd}
        </AssetChangeRate>
      </AssetPriceWrapper>
      {/* <AssetUserBalanceRatePnlWrapper>
        <AssetChangeRate
          type="headline"
          fontFamily="primaryMedium"
          color={UserAmountRateColor}
        >
          {amount}
        </AssetChangeRate>
        <CustomSelectModal
          placeholder={pnl ? '' : "Today's PNL"}
          selectedValue={pnl}
          onChange={setPnl}
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
          ]}
        />
      </AssetUserBalanceRatePnlWrapper> */}
    </AssetUserBalanceWrapper>
  );
};
