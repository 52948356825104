import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type SubmitLoanRequestValue = {
  id: number;
  loanAsset: {
    name: string;
  };
  initialLoanAmount: number;
};
export type SubmitLoanRequestArgs = {
  loanRequestId: number;
};

export type SubmitLoanRequestResponse = {
  submitLoanRequest: DefaultApiResponse<SubmitLoanRequestValue>;
};

export const submitLoanRequestMutation: TypedDocumentNode<
  SubmitLoanRequestResponse,
  DefaultArg<SubmitLoanRequestArgs>
> = gql`
  mutation SubmitLoanRequest($record: SubmitLoanRequestRecord!) {
    submitLoanRequest(record: $record) {
      id
      loanAsset {
        name
      }
      initialLoanAmount
    }
  }
`;
