import { useTheme } from 'styled-components';
import { Typography, DropdownIcon } from '@libs/components';
import { convertExponentialToDecimal, formatBalance } from '@libs/helpers';

import { getAlternativeAssetLabel } from 'src/helpers';

import { CurrencyType, TradeType } from 'src/constants';
import { useTranslation } from 'src/hooks';

import {
  ExchangeCurrencyOption,
  ReviewTransaction as ReviewTransactionProps,
} from 'src/types';
import {
  CurrenciesDetailsWrapper,
  TradeIconWrapper,
  DetailsWrapper,
  DummyContent,
  FeesWrapper,
  IconWrapper,
  Separator,
  Wrapper,
  FeeItem,
} from './styles';

export const ReviewTransaction: React.FC<ReviewTransactionProps> = ({
  rate,
  toAmount,
  fromAmount,
  transactionFee,
  tradeType,
  baseAsset,
  quoteAsset,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const fromAsset = getAlternativeAssetLabel(baseAsset?.value as CurrencyType);
  const toAsset = getAlternativeAssetLabel(quoteAsset?.value as CurrencyType);

  const isBuyTradeType = tradeType === TradeType.BUY;

  const firstAsset = isBuyTradeType ? quoteAsset : baseAsset;
  const secondAsset = isBuyTradeType ? baseAsset : quoteAsset;

  const formattedRate =
    rate > 1
      ? formatBalance(convertExponentialToDecimal(rate))
      : convertExponentialToDecimal(rate);

  const transactionFeeAmount = convertExponentialToDecimal(transactionFee);

  return (
    <Wrapper data-cy="review-transaction">
      <CurrenciesDetailsWrapper>
        <Details
          label={t('trade_from')}
          currency={firstAsset}
          amount={fromAmount}
          testId="from-wrapper"
        />
        <TradeIconWrapper>
          <DummyContent>
            <DropdownIcon />
          </DummyContent>
        </TradeIconWrapper>
        <Details
          label={t('trade_receive')}
          currency={secondAsset}
          amount={toAmount}
          testId="to-wrapper"
        />
      </CurrenciesDetailsWrapper>

      <FeesWrapper>
        <FeeItem>
          <Typography color={colors.text.secondary} type="bodyButton">
            {t('trade_transaction_fees')}
          </Typography>
          <Typography
            testId="transaction-fee"
            fontFamily="primaryBold"
            type="subHeadline"
          >
            {transactionFeeAmount} {isBuyTradeType ? toAsset : fromAsset}
          </Typography>
        </FeeItem>
        <Separator />
        <FeeItem>
          <Typography color={colors.text.secondary} type="bodyButton">
            {t('trade_rate')}
          </Typography>
          <Typography
            testId="transaction-rate"
            fontFamily="primaryBold"
            type="subHeadline"
          >
            {'1'} {fromAsset} = {formattedRate} {toAsset}
          </Typography>
        </FeeItem>
      </FeesWrapper>
    </Wrapper>
  );
};

type DetailsProps = {
  testId?: string;
  currency?: ExchangeCurrencyOption;
  amount?: string | number;
  label: string;
};
const Details: React.FC<DetailsProps> = ({
  currency,
  amount,
  label,
  testId,
}) => {
  const { colors } = useTheme();

  let IconImg: JSX.Element | null = null;

  if (currency?.icon) {
    IconImg =
      typeof currency.icon === 'string' ? (
        <img src={currency.icon} className="currency_icon" />
      ) : (
        currency.icon
      );
  }

  return (
    <DetailsWrapper data-cy={testId}>
      <IconWrapper>{IconImg}</IconWrapper>
      <Typography color={colors.text.secondary} type="bodyButton">
        {label}
      </Typography>
      <Typography fontFamily="primaryBold" type="subHeadline">
        {amount} {getAlternativeAssetLabel(currency?.value as CurrencyType)}
      </Typography>
    </DetailsWrapper>
  );
};
