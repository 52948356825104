import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type ToggleStakingAutoSubscriptionArgs = {
  id: string;
};

export type ToggleStakingAutoSubscriptionResponse = {
  toggleStakingAutoSubscription: DefaultApiResponse<boolean>;
};

export const toggleStakingAutoSubscriptionMutation: TypedDocumentNode<
  ToggleStakingAutoSubscriptionResponse,
  DefaultArg<ToggleStakingAutoSubscriptionArgs>
> = gql`
  mutation ToggleStakingAutoSubscription($record: StakingIdRecord!) {
    toggleStakingAutoSubscription(record: $record)
  }
`;
