import { useCallback, useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import {
  resendEmailMutation,
  resendEmailResponse,
  verifyEmailMutation,
  VerifyEmailResponse,
} from 'src/gql';

import { useApiResponse, useCounter } from 'src/hooks';
import { RESEND_CODE_TIME, TOKEN_KEY } from 'src/constants';

export const useVerifyEmail = () => {
  const [isVerifyEmailSuccess, setIsVerifyEmailSuccess] = useState(false);

  const { isCounterRunning, countDown, counterInterval } =
    useCounter(RESEND_CODE_TIME);

  const handleSuccessResendEmail = useCallback((data: resendEmailResponse) => {
    const status = data?.resendEmail;
    if (status) {
      counterInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleErrorsResendEmail = useCallback(
    (error: ApolloError | undefined) => {
      const status = error?.message;
      if (status) {
        counterInterval();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSuccessConfirmEmail = useCallback((data: VerifyEmailResponse) => {
    const accessToken = data?.verifyEmail.accessToken;
    if (accessToken) {
      setIsVerifyEmailSuccess(true);
      localStorage.setItem(TOKEN_KEY, accessToken);
    }
  }, []);

  const [
    handleResendEmail,
    {
      data: resendEmailData,
      loading: resendEmailLoading,
      error: emailResendErrors,
    },
  ] = useMutation(resendEmailMutation);

  const [
    handleConfirmEmail,
    {
      data: confirmEmailData,
      loading: confirmEmailLoading,
      error: confirmEmailErrors,
    },
  ] = useMutation(verifyEmailMutation);

  const { errors: emailResendError } = useApiResponse<
    resendEmailResponse,
    ApolloError
  >(
    { data: resendEmailData, errors: emailResendErrors },
    handleSuccessResendEmail,
    handleErrorsResendEmail
  );

  const { errors: confirmEmailError } = useApiResponse<
    VerifyEmailResponse,
    ApolloError
  >(
    { data: confirmEmailData, errors: confirmEmailErrors },
    handleSuccessConfirmEmail
  );

  return {
    handleConfirmEmail,
    handleResendEmail,
    isVerifyEmailSuccess,
    confirmEmailLoading,
    countDown,
    isCounterRunning,
    resendEmailLoading,
    confirmEmailError,
    emailResendError,
  };
};
