import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useApiResponse } from '@libs/hooks';
import { MappedWalletInfo } from '@libs/types';
import { filterData } from '@libs/helpers';
import { getWalletInfo, GetWalletInfoArgs } from 'src/gql';
import { getAlternativeAssetLabel } from 'src/helpers';

type UseWalletInfoProps = GetWalletInfoArgs & {
  pollInterval?: number;
};
export const useWalletInfo = ({
  pollInterval,
  ...walletInfoRecord
}: UseWalletInfoProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const res = useQuery(getWalletInfo, {
    variables: { record: walletInfoRecord },
    fetchPolicy: 'network-only',
    pollInterval,
  });

  const { response, loading } = useApiResponse(res);

  const walletInfoItems: MappedWalletInfo[] = useMemo(() => {
    if (!response?.getWalletInfo) return [];
    return response?.getWalletInfo.map((item) => ({
      ...item,
      codeLabel: getAlternativeAssetLabel(item.code),
    }));
  }, [response]);

  const filteredData = useMemo(() => {
    return filterData(walletInfoItems, searchInputValue, ['code', 'name']);
  }, [walletInfoItems, searchInputValue]);

  return {
    loading,
    response,
    filteredData,
    walletInfoItems,
    searchInputValue,
    setSearchInputValue,
  };
};
