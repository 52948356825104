import styled from 'styled-components';

import { Button } from '@libs/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.pending};
  border: 0.1rem solid ${({ theme }) => theme.colors.text.pending};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  padding: 2.4rem 1.6rem;
  align-items: center;
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 3.3rem 3.7rem 2.9rem 2.4rem;
  `};
`;

export const CustomKYCIcon = styled.img`
  width: 2.6rem;
  height: 2.7rem;
  margin-right: 1.6rem;
  ${({ theme }) => theme.media.tablet`
    width:4.3rem;
    height:5rem;
    margin-right:3.2rem;
  `};
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.media.tablet`
    // margin-right: 4.7rem; 
  `};
`;
export const Description = styled.div`
  margin: 1.6rem 0 1.4rem 0;
  ${({ theme }) => theme.media.tabletL`
    margin: 1.6rem 0.8rem 0  0; 
    max-width: none;

  `};
`;

export const KYCButton = styled(Button)`
  max-height: 5.6rem;
  width: 100%;
  ${({ theme }) => theme.media.tabletL`
    max-height: 5.6rem;
    max-width: 20rem;
  `};
`;
