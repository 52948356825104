import { useTheme } from 'styled-components';
import {
  SwapPairStatus,
  SwapTaskDetails,
  SwapTaskInfo,
  SwapTradePairSection,
  Typography,
  useNetworkIcons,
  AssetNetworkIcon,
} from '@libs/components';
import { screenSizes } from '@libs/theme';
import { RateTypes, SwapStatusEnumKeys } from '@libs/types';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { NetworksType } from 'src/constants';
import {
  useDimensions,
  useTranslation,
  useRenderObjectByTheme,
} from 'src/hooks';

import { SwapsBySwapTaskSection } from './components/SwapsBySwapTaskSection';

import { TaskId, CustomPageContainer, CustomCopyButton } from './styles';
import { useSwapTaskDetails } from './hooks/useSwapTaskDetails';

const SwapTaskDetailsPage = () => {
  const { t } = useTranslation();
  const { width } = useDimensions();
  const { colors } = useTheme();
  const networkIcons = useNetworkIcons();
  const navigate = useNavigate();

  const {
    swapTask,
    swapPairStatus,
    expiredButWaitingTitle,
    totals,
    additionalInformation,
    operationDetailsInfo,
    handleTimeOut,
    rateType,
    loading,
  } = useSwapTaskDetails();

  const amount = useMemo(() => {
    if (!swapTask) return '';
    const {
      originalNeededAmount,
      sourceAsset,
      originalReceivedAmount,
      destinationAsset,
      rateType,
    } = swapTask;

    if (
      !originalNeededAmount ||
      !sourceAsset.code ||
      !originalReceivedAmount ||
      !destinationAsset.code ||
      !rateType
    )
      return '';
    let tempAmount = originalNeededAmount + ' ' + sourceAsset.code;

    tempAmount += rateType === RateTypes.FIXED ? ' =' : ' ≈';
    tempAmount += ' ' + originalReceivedAmount + ' ' + destinationAsset.code;

    return tempAmount;
  }, [swapTask]);

  const handleNewExchange = () => {
    navigate('/swap');
  };
  const destinationNetworkIcon = useMemo(() => {
    if (!swapTask?.destinationAsset.assetSupportedNetworks) return undefined;

    const filteredDestinationNetwork =
      swapTask?.destinationAsset.assetSupportedNetworks.filter((res) => {
        return res.network.id === swapTask?.destinationNetwork.id;
      });

    return networkIcons[
      filteredDestinationNetwork[0]?.fireblocksId as NetworksType
    ];
  }, [swapTask, networkIcons]);

  const sourceNetworkIcon = useMemo(() => {
    if (!swapTask?.sourceAsset.assetSupportedNetworks) return undefined;

    const filteredSourceNetwork =
      swapTask?.sourceAsset.assetSupportedNetworks.filter((res) => {
        return res.network.id === swapTask?.sourceNetwork.id;
      });

    return networkIcons[filteredSourceNetwork[0]?.fireblocksId as NetworksType];
  }, [networkIcons, swapTask]);

  const receivedAssetIcon = (
    <AssetNetworkIcon
      baseUrl={swapTask?.destinationAsset.iconUrl}
      networkIcon={destinationNetworkIcon}
    />
  );
  const originalAssetIcon = (
    <AssetNetworkIcon
      baseUrl={swapTask?.sourceAsset.iconUrl}
      networkIcon={sourceNetworkIcon}
    />
  );

  const timerBackground = useRenderObjectByTheme(
    'linear-gradient(90deg, rgba(213, 220, 229, 0) 0%,#d5dce5 50%, rgba(213, 220, 229, 0) 100%)',
    'linear-gradient(90deg, rgba(77, 84, 104, 0) 0%, #4d5468 50%, rgba(77, 84, 104, 0) 100%)'
  );

  const shouldHideAmount = swapTask?.swaps?.some((swap) => {
    return swap?.status === SwapStatusEnumKeys.WITHDRAW_COMPLETED;
  });

  useEffect(() => {
    window.history.replaceState({}, '');
  }, []);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleScrollTo = () => {
    if (selectRef.current) {
      window.scroll({
        left: selectRef.current.offsetLeft,
        top: selectRef.current.offsetTop - 83,
      });
    }
  };

  const tooltipText =
    rateType === RateTypes.FIXED
      ? t('swap_calculator_fixed_rate_desc')
      : t('swap_calculator_floating_rate_desc');

  const formatDaysToString = (days: string) => {
    if (!days || +days <= 0) return '';

    return t('swap_day_left', { days });
  };

  return (
    <CustomPageContainer>
      <SwapTradePairSection
        formatDaysToString={formatDaysToString}
        sourceAssetCode={swapTask?.sourceAsset.code || '--'}
        sourceAmount={
          !shouldHideAmount ? swapTask?.originalNeededAmount.toString() : ''
        }
        sourceAssetIcon={originalAssetIcon}
        destinationAssetCode={swapTask?.destinationAsset.code || '--'}
        destinationAmount={
          !shouldHideAmount ? swapTask?.originalReceivedAmount.toString() : ''
        }
        destinationAssetIcon={receivedAssetIcon}
        expiresAt={swapTask?.expiresAt}
        sourceTitle={t('swap_trade_pair_base_title')}
        destinationTitle={t('swap_trade_pair_quote_title')}
        pendingTitle={t('swap_trade_panding_title')}
        finishedTitle={t('swap_trade_finished_title')}
        expiredTitle={t('swap_trade_expired_title')}
        buttonText={t('swap_trade_expired_button_title')}
        expiredDescription={t('swap_trade_expired_description')}
        pendingDescription={t('swap_trade_panding_description')}
        failedTitle={t('swap_trade_failed_title')}
        firstStepTitle={t('swap_progress_bar_first_title')}
        secondStepTitle={t('swap_progress_bar_second_title')}
        thirdStepTitle={t('swap_progress_bar_third_title')}
        fourthStepTitle={t('swap_progress_bar_fourth_title')}
        expiredButWaitingTitle={expiredButWaitingTitle}
        pairType={swapPairStatus}
        onClick={handleNewExchange}
        onTimeOut={handleTimeOut}
        // shouldShowProgressBar={!swapTask?.swaps?.length}
        loading={loading}
        timerBackground={timerBackground}
      />

      <SwapTaskDetails
        summaryTitle={t('swap_task_summary_title')}
        waitingTitle={t('swap_task_summary_waiting_title')}
        waitingAmount={totals?.stillToReceive || '0'}
        receivedAmount={totals?.totalReceived || '0'}
        sentAmount={totals?.totalDeposited || '0'}
        receivedTitle={t('swap_task_summary_received_title')}
        sentTitle={t('swap_task_summary_sent_title')}
        originalAssetIcon={originalAssetIcon}
        receivedAssetIcon={receivedAssetIcon}
        destinationAddress={swapTask?.destinationAddress}
        rateType={swapTask?.rateType}
        sourceAddress={swapTask?.accountCopy.address}
        amount={amount}
        swapsAmount={swapTask?.swaps?.length}
        firstStepTitle={t('swap_progress_bar_first_title')}
        secondStepTitle={t('swap_progress_bar_second_title')}
        thirdStepTitle={t('swap_progress_bar_third_title')}
        fourthStepTitle={t('swap_progress_bar_fourth_title')}
        swapStatus={SwapStatusEnumKeys.DRAFT}
        depositAddressTitle={t('swap_task_details_source_title')}
        rateTitle={
          swapTask?.rateType === RateTypes.FIXED
            ? t('common_fixed_rate')
            : t('common_float_rate')
        }
        recipientTitle={t('swap_task_details_recipient_title')}
        buttonTextBeforeCopying={t('common_copy')}
        buttonTextAfterCopying={t('common_copied')}
        loading={loading}
        onScrollToClick={handleScrollTo}
        tooltipText={tooltipText}
        destinationAssetCode={swapTask?.destinationAsset.code || '--'}
        originalAssetCode={swapTask?.sourceAsset.code || '--'}
      />

      <SwapsBySwapTaskSection
        swaps={swapTask?.swaps}
        originalAssetIcon={originalAssetIcon}
        receivedAssetIcon={receivedAssetIcon}
        originalAssetCode={swapTask?.sourceAsset.code}
        receivedAssetCode={swapTask?.destinationAsset.code}
        rateType={swapTask?.rateType}
        ref={selectRef}
      />
      {swapPairStatus === SwapPairStatus.FINISHED && (
        <SwapTaskInfo
          shouldIconShow={false}
          isOpen={true}
          title={t('swap_operation_details')}
          rows={operationDetailsInfo}
          buttonTextBeforeCopying={t('common_copy')}
          buttonTextAfterCopying={t('common_copied')}
        />
      )}
      <SwapTaskInfo
        title={t('swap_additional_information')}
        rows={additionalInformation}
        isOpen={false}
        shouldIconShow={true}
        buttonTextBeforeCopying={t('common_copy')}
        buttonTextAfterCopying={t('common_copied')}
      />
      <TaskId>
        <Typography
          type={width > screenSizes.tabletL ? 'titleTwo' : 'subHeadline'}
          fontFamily="primaryBold"
        >
          {t('swap_details_task_id')}
          <Typography
            type={width > screenSizes.tabletL ? 'headline' : 'bodyButton'}
            fontFamily="primaryBold"
            color={colors.text.secondary}
          >
            {swapTask?.uuid}
          </Typography>
        </Typography>

        <CustomCopyButton text={swapTask?.uuid || ''} />
      </TaskId>
    </CustomPageContainer>
  );
};

export default SwapTaskDetailsPage;
