import styled, { css } from 'styled-components';

import { GelIcon, TransparentButton, UsdIcon, EurIcon } from '@libs/components';

import { CurrencyType } from 'src/constants';

type CurrencyWrapperType = {
  isActive: boolean;
  activeCurrency: CurrencyType | undefined;
};

type IconType = {
  isActive: boolean;
};

const commonIconStyles = css<IconType>`
  margin-right: 1.2rem;

  path:first-child {
    fill: ${({ theme, isActive }) =>
      isActive
        ? theme.colors.interactive.brandColor
        : theme.colors.surface.interactiveDefault};
  }
`;

export const CustomGelIcon = styled(GelIcon)<IconType>`
  ${commonIconStyles}

  path:last-child {
    fill: ${({ theme, isActive }) =>
      isActive ? theme.colors.surface.default : theme.colors.text.default};
  }
`;

export const CustomEurIcon = styled(EurIcon)<IconType>`
  ${commonIconStyles}

  path:last-child {
    fill: ${({ theme, isActive }) =>
      isActive ? theme.colors.surface.default : theme.colors.text.default};
  }
`;

export const CustomUsdIcon = styled(UsdIcon)<IconType>`
  ${commonIconStyles}
`;

export const CurrencyWrapper = styled(TransparentButton)<CurrencyWrapperType>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 50%;

  border: ${({ theme }) => `1px solid ${theme.colors.borders.default};`};

  &:hover {
    ${({ isActive, theme }) =>
      !isActive &&
      `
    border: 1px solid ${theme.colors.borders.hover};

    background-color: ${theme.colors.surface.interactiveDefault};

    ${CustomUsdIcon} {
      path:first-child {
        fill: ${theme.colors.surface.default};
      }
    }
    ${CustomGelIcon} {
      path:first-child {
        fill: ${theme.colors.surface.default};
      }
    }
    `}
  }

  ${({ activeCurrency }) =>
    activeCurrency === CurrencyType.BGEL || activeCurrency === CurrencyType.EUR
      ? `
    &:last-child{
      border-left: 1px solid transparent;

  }
  `
      : `&:first-child{
    border-right: 1px solid transparent;
  }`}

  ${({ isActive, theme }) =>
    isActive &&
    `
    background-color: ${theme.colors.surface.pressed};
    border:1px solid ${theme.colors.borders.pressed};
  `}

  &:first-child {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius.mediumHigh;
      return `${radius} 0 0 ${radius};`;
    }};
  }
  &:last-child {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius.mediumHigh;
      return `0 ${radius} ${radius} 0;`;
    }};
  }
`;

export const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;

  height: 6.4rem;
  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
`;
