import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type GenerateQrCodeResponse = {
  generateQrCode: DefaultApiResponse<{ qrCode: string; secret: string }>;
}; // NOTE: always wrap with DefaultApiResponse;

export const generateQrCodeMutation: TypedDocumentNode<
  GenerateQrCodeResponse,
  DefaultArg
> = gql`
  mutation generateQrCode {
    generateQrCode {
      qrCode
      secret
    }
  }
`;
