import { Input, InputProps } from '@libs/components';

import { useTranslation } from 'src/hooks';

import { PasswordInputContainer } from './styles';

const PasswordInput: React.FC<InputProps> = ({
  name,
  value,
  onFocus,
  onBlur,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const handlePasswordFocus = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    onFocus?.(e);
  };
  const handlePasswordBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    onBlur?.(e);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    onChange?.({
      ...e,
      target: {
        ...e.target,
        name,
        value: value.replace(/\s/g, ''),
      },
    });
  };

  return (
    <PasswordInputContainer>
      <Input
        name={name}
        value={value}
        label={t('register_password')}
        placeholder={t('register_password')}
        onFocus={handlePasswordFocus}
        onChange={handleInputChange}
        onBlur={handlePasswordBlur}
        {...props}
      />
    </PasswordInputContainer>
  );
};
export default PasswordInput;
