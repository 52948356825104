import styled from 'styled-components';
import { Typography } from '@libs/components';

export type NotificationAlertProps = {
  isHidden?: boolean;
};

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2.2rem;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  border-radius: 0 0 2rem 2rem;
  width: 100vw;
  height: auto;
  overflow: auto;
  right: -2.5rem;
  top: 5.8rem;
  z-index: 1;
  ${({ theme }) => {
    const borderColor = theme.colors.borders.default;
    const borderRadius = theme.borderRadius.medium;
    const boxShadow = theme.shadows.xl2;
    return theme.media.tablet`
  border: 1px solid ${borderColor as unknown as TemplateStringsArray};
  border-radius: ${borderRadius as unknown as TemplateStringsArray};
  box-shadow: ${boxShadow as unknown as TemplateStringsArray};
  top: 5.5rem;
  right: 0;
  width: 39rem;
  padding-left: 0;
  `;
  }}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1.6rem 1.2rem;
  width: 100%;
  height: auto;
  border-style: none none solid none;
  border-color: ${({ theme }) => theme.colors.borders.default};
  border-width: 0.1rem;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const ViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomTypography = styled(Typography)`
  margin-right: 1rem;
`;

// Notification styles
export const NotificationsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotificationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 0;
  width: 100%;
  height: auto;
  cursor: pointer;
  border-style: none none solid none;
  border-color: ${({ theme }) => theme.colors.borders.default};
  border-width: 0.1rem;

  &:last-child {
    border: none;
  }

  ${({ theme }) => theme.media.tablet`
    padding: 1.6rem 1.2rem;
  `}

  ${({ theme }) => {
    const backgroundColor = theme.colors.surface.interactiveDefault;
    return theme.media.laptop`
            &:hover {
                background-color: ${
                  backgroundColor as unknown as TemplateStringsArray
                }
              }
            `;
  }}
`;

export const UnreadNotificationIcon = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.colors.text.default};
  border-radius: 50%;
`;

export const NotificationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 3rem 0 2.6rem;
`;

export const NotificationTitle = styled.div`
  margin-bottom: 0.6rem;
`;

export const NotificationDescription = styled.div`
  margin-bottom: 0.6rem;
`;

export const NotificationDate = styled.div``;

export const NotificationAlert = styled.div<NotificationAlertProps>`
  position: absolute;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  padding: 0.8rem;
`;
