import styled from 'styled-components';
import { TransparentButton } from '@libs/components';

export const PeriodSelectorWrapper = styled.div`
  height: 5.4rem;

  max-width: 28rem;

  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
    max-width: 24rem;
  `};
`;

type PeriodState = { isSelected: boolean };
export const PeriodSelectorItem = styled(TransparentButton)<PeriodState>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};

  &:first-child {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius.medium;
      return `${radius} 0 0 ${radius}`;
    }};
  }
  &:last-child {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius.medium;
      return `0 ${radius} ${radius} 0`;
    }};
  }
`;
