import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { ErrorMessageTexts, useApiResponse, useAuth } from '@libs/hooks';
import { TwoFactorAuthType } from '@libs/types';

import {
  sendRegistrationCodeMudation,
  registerUserMutation,
  RegisterUserArgs,
  RegisterUser,
} from 'src/gql';
import {
  useAppDisclaimer,
  useJurisdictionDetector,
  useTranslation,
} from 'src/hooks';
import { JurisdictionsEnum } from '@libs/backend-common';
import { RegexValidators } from '@libs/validations';

export const DEFAULT_REGISTER_VALUES: RegisterUserArgs = {
  agreedToTerms: true,
  confirmationCode: '',
  emailOrPhone: '',
  password: '',
  referrerCode: '',
};
const additionalRoute = '?isNewlyRegistered=true';

export const useRegister = (handleSendCodeSucess: () => void) => {
  const [args, setArgs] = useState<RegisterUserArgs>(DEFAULT_REGISTER_VALUES);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleLogin = () => navigate(`/${additionalRoute}`, { replace: true });

  const { login } = useAuth(undefined, handleLogin);

  const { setIsAppDisclaimerVisible } = useAppDisclaimer();

  const { currentJurisdiction } = useJurisdictionDetector();

  const isJurisdictionGe = currentJurisdiction === JurisdictionsEnum.GE;
  const isNotMobileApp = !window.isFromMobileApp;

  const handleRegisterUserSuccess = (data: RegisterUser) => {
    login(data.registerUser.accessToken);
    if (isJurisdictionGe && isNotMobileApp) {
      setIsAppDisclaimerVisible(true);
    }
  };

  const [sendCode, sendCodeRes] = useMutation(sendRegistrationCodeMudation);
  const [registerUser, registerUserRes] = useMutation(registerUserMutation);

  const { errorMessage: registerErrorMessage, loading } = useApiResponse(
    registerUserRes,
    handleRegisterUserSuccess
  );
  const {
    clearErrorMessage: clearSendCodeErrorMessage,
    response,
    errorMessage: sendCodeErrorMessage,
  } = useApiResponse(sendCodeRes, handleSendCodeSucess);

  const handleAuthComplete = useCallback(
    (_: TwoFactorAuthType, val: string) => {
      registerUser({
        variables: {
          record: {
            ...args,
            confirmationCode: val,
          },
        },
      });
    },
    [args, registerUser]
  );

  const handleFormSubmit = useCallback(
    (vals: RegisterUserArgs) => {
      setArgs(vals);

      const { emailOrPhone, referrerCode } = vals;

      const formatedEmailOrPhone = emailOrPhone.replace(
        RegexValidators.HAS_GEORGIAN_PHONE_PREFIX,
        ''
      );

      sendCode({
        variables: {
          record: {
            emailOrPhone: formatedEmailOrPhone,
            referrerCode,
          },
        },
      });
    },

    [sendCode]
  );

  const errorMessage = registerErrorMessage ? t(registerErrorMessage) : '';

  const isRefererCodeError =
    sendCodeErrorMessage === ErrorMessageTexts.REFERRER_CODE_DOES_NOT_EXIST;

  const refererCodeInputErrorMessage = isRefererCodeError
    ? sendCodeErrorMessage
    : '';

  const emailOrPhoneInputErrorMessage = !isRefererCodeError
    ? sendCodeErrorMessage
    : '';

  return {
    handleAuthComplete,
    response,
    handleFormSubmit,
    errorMessage,
    loading,
    refererCodeInputErrorMessage,
    emailOrPhoneInputErrorMessage,
    clearSendCodeErrorMessage,
  };
};
