import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const DotsIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="4"
      height="17"
      viewBox="0 0 4 17"
      fill="none"
    >
      <circle cx="1.67671" cy="1.67671" r="1.67671" fill={iconColor} />
      <circle cx="1.67671" cy="8.45405" r="1.67671" fill={iconColor} />
      <circle cx="1.67671" cy="15.2275" r="1.67671" fill={iconColor} />
    </CustomSvg>
  );
};
export default DotsIcon;
