import { useTheme } from 'styled-components';
import { Typography } from '@libs/components';

type PageHeadingProps = {
  text?: string;
  isPrimary?: boolean;
  highlightedText?: string;
};

const PageHeading: React.FC<PageHeadingProps> = ({
  text,
  highlightedText,
  isPrimary = true,
}) => {
  const { colors } = useTheme();

  const headingType = isPrimary ? 'largeTitle' : 'titleOne';

  if (!text || !highlightedText) return null;

  return (
    <header>
      <Typography
        as="h1"
        type={headingType}
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {text}
        {highlightedText && (
          <Typography
            type={headingType}
            fontFamily="primaryBold"
            color={colors.interactive.brandColor}
          >
            {' '}
            {highlightedText}
          </Typography>
        )}
      </Typography>
    </header>
  );
};

export default PageHeading;
