import { gql, TypedDocumentNode } from '@apollo/client';
import { StakingHarvestModes } from '@libs/backend-common';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

import { NewStakingRecord } from 'src/types';

type CalculateStakingValuesTerms = {
  harvestMode: StakingHarvestModes;
  interestRate: number;
};

export type CalculateStakingValuesResponse = {
  calculateStakingValues: DefaultApiResponse<{
    min: number;
    max: number;
    totalRewards: number;
    accrualInterval: number;
    totalPercentage: number;
    autoInvestAvailable: boolean;
    autoSubscriptionAvailable: boolean;
    rewardsPerAccrualInterval?: number;

    startDate: Date;
    nextRewardDate: Date;
    endDate: Date;

    terms: CalculateStakingValuesTerms[];
  }>;
};

export type CalculateStakingValuesArgs = DefaultArg<NewStakingRecord>;

export const calculateStakingValuesQuery: TypedDocumentNode<
  CalculateStakingValuesResponse,
  CalculateStakingValuesArgs
> = gql`
  query CalculateStakingValues($record: NewStakingRecord!) {
    calculateStakingValues(record: $record) {
      min
      max
      totalPercentage
      totalRewards
      rewardsPerAccrualInterval
      startDate
      nextRewardDate
      endDate
      accrualInterval
      autoSubscriptionAvailable
      autoInvestAvailable
      rewardsPerAccrualInterval

      terms {
        harvestMode
        interestRate
      }
    }
  }
`;
