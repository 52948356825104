import { useTheme } from 'styled-components';
import {
  Desc,
  DescHeader,
  HeaderLoader,
  Line,
  Loader,
  StakingRewardsContainer,
  Title,
  TitleHeader,
} from './styles';

export const StakingRewardsLoader = () => {
  const { colors } = useTheme();

  return (
    <StakingRewardsContainer>
      <HeaderLoader
        backgroundColor={colors.surface.default}
        foregroundColor={colors.surface.pressed}
      >
        <TitleHeader />
        <DescHeader />
        <Line />
      </HeaderLoader>
      {[...Array(4)].map((i, index) => (
        <Loader
          key={index}
          backgroundColor={colors.surface.interactiveDefault}
          foregroundColor={colors.surface.pressed}
        >
          <Line />
          <Title />
          <Desc />
        </Loader>
      ))}
    </StakingRewardsContainer>
  );
};
