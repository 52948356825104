import { PropsWithChildren, useEffect } from 'react';

import { Container, JustifiedContent } from './styles';

type PageContainerProps = PropsWithChildren & {
  className?: string;
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  className,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <JustifiedContent className={className}>{children}</JustifiedContent>
    </Container>
  );
};

export default PageContainer;
