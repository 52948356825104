import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

type ContainerProps = {
  isOpened: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: ${({ isOpened }) => (isOpened ? '29rem' : '21.5rem')};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.surface.default};
  ${({ theme }) => theme.media.laptop`
      height: 31rem;
      width: 40%;
  `}
`;

export const Wrapper = styled(ContentLoader)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DefaultRect = styled.rect`
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
export const AllBalanceTitle = styled(DefaultRect)`
  width: 8rem;
  height: 2rem;
  x: calc((100% - 8rem) / 2);
  y: 2.9rem;
  ${({ theme }) => theme.media.laptop`
      y: 9rem;
      x: 13rem;
  `};
`;
export const CurrencyToggler = styled(DefaultRect)`
  rx: ${({ theme }) => theme.borderRadius.highM};
  ry: ${({ theme }) => theme.borderRadius.highM};
  width: 19.2rem;
  height: 3.8rem;
  y: 6.6rem;
  x: calc((100% - 19.2rem) / 2);
  ${({ theme }) => theme.media.laptop`
      rx:${theme.borderRadius.medium as unknown as TemplateStringsArray};
      ry:${theme.borderRadius.medium as unknown as TemplateStringsArray};
      y: 2rem;
      x: 2rem;
      height: 3.8rem;
      width: 14rem;
  `};
`;
export const BalanceAmount = styled(DefaultRect)`
  width: 24rem;
  height: 4rem;
  y: 12.6rem;
  x: calc((100% - 24rem) / 2);
  ${({ theme }) => theme.media.laptop`
      height: 4.4rem;
      width: 20rem;
      x: 7rem;
      y: 11.6rem;
  `};
`;
export const AssetPrices = styled(DefaultRect)`
  width: 35rem;
  height: 7.5rem;
  y: 20rem;
  x: calc((100% - 35rem) / 2);
  ${({ theme }) => theme.media.laptop`
      width: 29rem;
      x: 3rem;
  `};
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 23.2rem;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
     height: 100%;
     width: 60%;
  `};
`;
export const Chart = styled(DefaultRect)`
  width: 90%;
  height: 70%;
  y: 1rem;
  x: 5%;
  ${({ theme }) => theme.media.laptop`
      width: 45rem;
      height:15rem;
      x: 5rem;
      y: 8rem;
  `};
`;
