import { useCallback, useState } from 'react';
import { Portal } from '@libs/components';
import { PopUp } from 'src/components';

export const POP_UP_MODAL_PARENT_ID = 'pop-up-modal-portal';

type CallbackProps = {
  children: React.ReactNode;
};

type UseModalReturnType = {
  closeModal: () => void;
  openModal: () => void;
  PopUpModal: ({ children }: CallbackProps) => JSX.Element | null;
};

type UseModalHook = (
  isOpenByDefault?: boolean,
  onClose?: () => void,
  shouldPopTop?: boolean
) => UseModalReturnType;

export const usePopUpModal: UseModalHook = (
  isOpenByDefault = false,
  onClose,
  shouldPopTop = false
) => {
  const [isOpen, setIsOpen] = useState(!!isOpenByDefault);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  const openModal = useCallback(() => setIsOpen(true), []);

  const PopUpModal = useCallback(
    ({ children }: CallbackProps) => {
      return (
        <Portal ignoreBodyOverflow wrapperId={POP_UP_MODAL_PARENT_ID}>
          <PopUp isOpen={isOpen} shouldPopTop={shouldPopTop}>
            {children}
          </PopUp>
        </Portal>
      );
    },
    [isOpen, shouldPopTop]
  );

  return {
    openModal,
    closeModal,
    PopUpModal,
  };
};
