import { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Checked } from '@libs/components';
import { useOutsideClick } from '@libs/hooks';
import { useMutation } from '@apollo/client';

import {
  getCryptoAddressesByAssetQuery,
  updateCryptoAddressNameMutation,
  Address,
} from 'src/gql';
import { ADDRESS_NAME_MAX_LENGTH } from 'src/constants';
import { useTranslation } from 'src/hooks';

import {
  CustomEditIcon,
  EditButton,
  EditContainer,
  EditInput,
  SaveButton,
} from './styles';

type AddressNameEditProps = {
  setAddressName: (val: string) => void;
  addressName: string;

  selectedAddress: Address;
  assetCode: string;
  networkId: number;
};
export const AddressNameEdit: React.FC<AddressNameEditProps> = ({
  setAddressName,
  addressName,
  selectedAddress,
  assetCode,
  networkId,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const navRef = useRef<HTMLDivElement | null>(null);

  const handleEditClick = () => {
    setIsEditOpen(true);
    const input = navRef.current?.children[0] as HTMLInputElement;
    input.focus();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setAddressName(e.target.value);
  };

  const handleSaveName = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (selectedAddress) {
      changeName({
        variables: {
          record: { id: Number(selectedAddress.id), name: addressName },
        },
      });

      setIsEditOpen(false);
    }
  };

  const handleOutsideClick = useCallback(() => {
    setIsEditOpen(false);
    setAddressName(selectedAddress.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  const [changeName] = useMutation(updateCryptoAddressNameMutation, {
    refetchQueries: [
      {
        query: getCryptoAddressesByAssetQuery,
        variables: {
          record: {
            assetCode,
            networkId,
          },
        },
      },
    ],
  });
  useOutsideClick(navRef, handleOutsideClick);

  const placeholderText = t('edit_wallet');

  return (
    <EditContainer>
      <EditButton
        isEditOpen={isEditOpen}
        ref={navRef}
        onClick={handleEditClick}
        length={placeholderText.length}
      >
        <EditInput
          type="text"
          value={isEditOpen ? addressName : placeholderText}
          onChange={handleChange}
          onClick={handleEditClick}
          maxLength={ADDRESS_NAME_MAX_LENGTH}
        />

        <SaveButton onClick={handleSaveName}>
          <Checked />
        </SaveButton>
        <CustomEditIcon color={colors.interactive.brandColor} />
      </EditButton>
    </EditContainer>
  );
};
