import gql from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type SaveNationalityArgs = {
  nationality: string;
  hasGeorgianResidency: boolean;
};

export type SaveNationalityResponse = {
  saveNationality: DefaultApiResponse<boolean>;
};

export const saveNationalityMutation: TypedDocumentNode<
  SaveNationalityResponse,
  DefaultArg<SaveNationalityArgs>
> = gql`
  mutation SaveNationality($record: SaveNationalityRecord!) {
    saveNationality(record: $record)
  }
`;
