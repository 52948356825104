import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEFAULT_DATE_FILTER_FORMAT, useStatusInfo } from '@libs/components';
import { formatDate, useDimensions } from '@libs/hooks';
import { TransactionStatusType } from '@libs/types';

import {
  TransactionAppliedFilters,
  TransactionFiltersType,
  TransactionMultiSelectFilterValues,
} from 'src/types';
import {
  TRANSACTION_STATUS_FILTER_TYPES,
  MultiSelectFilterDataType,
  TransactionFiltersDefaultValue,
  TransactionType,
  TRANSACTION_LABELS,
} from 'src/constants';
import { useTranslation } from 'src/hooks';
import { screenSizes } from '@libs/theme';
import { SelectModalHeaderRenderOption } from 'src/components';

export const useAssetTransactionFilters = (assetCode?: string) => {
  const { t } = useTranslation();
  const { getStatusData } = useStatusInfo();

  const { width } = useDimensions();

  const [currentPage, setCurrentPage] = useState(1);
  const [dateFilters, setDateFilters] = useState<Date[]>();

  //General
  const [transactionFilters, setTransactionFilters] =
    useState<TransactionFiltersType>(TransactionFiltersDefaultValue);

  const appliedFilters = useMemo(
    () =>
      ({
        assetCodes: [assetCode],
        types: transactionFilters.TYPE,
        statuses: transactionFilters.STATUS,
        minDate: transactionFilters.MINDATE,
        maxDate: transactionFilters.MAXDATE,
      } as TransactionAppliedFilters),
    [transactionFilters, assetCode]
  );

  //Date Filter
  useEffect(() => {
    setCurrentPage(1);
    setTransactionFilters((prev) => ({
      ...prev,
      MINDATE: dateFilters?.[0]
        ? formatDate(DEFAULT_DATE_FILTER_FORMAT, dateFilters?.[0])
        : undefined,
      MAXDATE: dateFilters?.[1]
        ? formatDate(DEFAULT_DATE_FILTER_FORMAT, dateFilters?.[1])
        : undefined,
    }));
  }, [dateFilters]);

  //Multi Select Filter
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [typeSearchValue, setTypeSearchValue] = useState('');
  const [statusSearchValue, setStatusSearchValue] = useState('');

  const typeHeaderRenderOption = useCallback(
    (closeSelect: () => void) => {
      return (
        <SelectModalHeaderRenderOption
          selectOptionsLabel={t('filter_select_type_placeholder')}
          searchInputValue={typeSearchValue}
          setSearchInputValue={setTypeSearchValue}
          closeSelect={closeSelect}
        />
      );
    },
    [typeSearchValue, t]
  );

  const statusHeaderRenderOption = useCallback(
    (closeSelect: () => void) => {
      return (
        <SelectModalHeaderRenderOption
          selectOptionsLabel={t('filter_select_status_placeholder')}
          searchInputValue={statusSearchValue}
          setSearchInputValue={setStatusSearchValue}
          closeSelect={closeSelect}
        />
      );
    },
    [statusSearchValue, t]
  );

  const multiSelectData: MultiSelectFilterDataType<TransactionMultiSelectFilterValues>[] =
    useMemo(() => {
      const typeFilterValues = Object.keys(TransactionType).map((type) => ({
        value: type,
        label: t(TRANSACTION_LABELS[type]),
      }));

      const status = TRANSACTION_STATUS_FILTER_TYPES.map(
        (status: TransactionStatusType) => {
          const transactionStatus = getStatusData(status);
          return {
            value: status,
            label: t(transactionStatus.text),
          };
        }
      );

      return [
        {
          filterType: TransactionMultiSelectFilterValues.TYPE,

          placeholder:
            width <= screenSizes.tablet
              ? t('filter_type_placeholder_mobile')
              : t('filter_type_placeholder'),
          options: typeFilterValues,
          selectedValues: typeFilter,
          handler: setTypeFilter,
          renderOptionHeader: typeHeaderRenderOption,
          searchInputValue: typeSearchValue,
        },
        {
          filterType: TransactionMultiSelectFilterValues.STATUS,

          placeholder:
            width <= screenSizes.tablet
              ? t('filter_status_placeholder_mobile')
              : t('filter_status_placeholder'),
          options: status,
          selectedValues: statusFilter,
          handler: setStatusFilter,
          renderOptionHeader: statusHeaderRenderOption,
          searchInputValue: statusSearchValue,
        },
      ];
    }, [
      width,
      t,
      typeFilter,
      typeHeaderRenderOption,
      typeSearchValue,
      statusFilter,
      statusHeaderRenderOption,
      statusSearchValue,
      getStatusData,
    ]);

  return {
    multiSelectData,
    transactionFilters,
    appliedFilters,
    setTransactionFilters,
    dateFilters,
    setDateFilters,
    currentPage,
    setCurrentPage,
    isFiltered: Boolean(
      appliedFilters.types.length ||
        appliedFilters.statuses.length ||
        appliedFilters.maxDate ||
        appliedFilters.minDate
    ),
  };
};
