import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type SendRegistrationCodeArgs = {
  emailOrPhone: string;
  referrerCode?: string;
};

export type SendRegistrationCodeResponse = {
  sendRegistrationCode: DefaultApiResponse<boolean>;
};

export const sendRegistrationCodeMudation: TypedDocumentNode<
  SendRegistrationCodeResponse,
  DefaultArg<SendRegistrationCodeArgs>
> = gql`
  mutation SendRegistrationCode($record: SendRegistrationCodeRecord!) {
    sendRegistrationCode(record: $record)
  }
`;
