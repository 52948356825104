import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetReferralsExchangeSumResponse = {
  getReferralsExchangeSum: DefaultApiResponse<number>;
};

export const getReferralsExchangeSumQuery: TypedDocumentNode<
  GetReferralsExchangeSumResponse,
  DefaultArg
> = gql`
  query GetReferralsExchangeSum {
    getReferralsExchangeSum
  }
`;
