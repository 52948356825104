import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type DeleteCryptoAddressArgs = {
  id: number;
};
export type DeleteCryptoAddressResponse = {
  deleteCryptoAddress: DefaultApiResponse<boolean>;
};
export const deleteCryptoAddressMutation: TypedDocumentNode<
  DeleteCryptoAddressResponse,
  DefaultArg<DeleteCryptoAddressArgs>
> = gql`
  mutation DeleteCryptoAddress($record: IdRecord!) {
    deleteCryptoAddress(record: $record)
  }
`;
