import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CalculateLoanPaymentDetails = {
  newLtv: number;
  oldLtv: number;
  newPrincipal: number;
  oldPrincipal: number;
  newCommission: number;
  oldCommission: number;
  oldCollateral: number;
  newCollateral: number;
  newLoanAmount: number;
  releasedCollateral: number;
};
export type CalculateLoanPaymentDetailsArgs = {
  amount: number;
  loanId: number;
};

export type CalculateLoanPaymentDetailsResponse = {
  calculateLoanPaymentDetails: DefaultApiResponse<CalculateLoanPaymentDetails>;
};

export const calculateLoanPaymentDetailsQuery: TypedDocumentNode<
  CalculateLoanPaymentDetailsResponse,
  DefaultArg<CalculateLoanPaymentDetailsArgs>
> = gql`
  query CalculateLoanPaymentDetails($record: CalculateLoanPaymentRecord!) {
    calculateLoanPaymentDetails(record: $record) {
      newLtv
      oldLtv
      newPrincipal
      oldPrincipal
      newCommission
      oldCommission
      oldCollateral
      newCollateral
      newLoanAmount
      releasedCollateral
    }
  }
`;
