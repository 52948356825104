import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  ClosedLockIcon,
  OpenedLockIcon,
  SwapPairStatus,
  SWAP_BLOCK_DATE_FORMAT,
} from '@libs/components';

import { hasAnyFieldEmpty } from '@libs/helpers';

import { formatDate, getDate, useApiResponse } from '@libs/hooks';

import { RateTypes, SwapStatusEnumKeys } from '@libs/types';

import { getSwapQuery, getOngoingSwapsQuery } from 'src/gql';
import { useTranslation } from 'src/hooks';
import { swapClient } from 'src/setup';

import { Desc, Wrapper } from './styles';

export type SwapUrlInfo = {
  id: number;
};

export const useSwapDeatils = () => {
  const info = useLocation().state as SwapUrlInfo | undefined;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const swapId = info?.id;

  useEffect(() => {
    if (!swapId) {
      navigate('/', {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapId, navigate, useLocation]);

  const res = useQuery(getSwapQuery, {
    variables: { record: { id: swapId ? +swapId : undefined } },
    client: swapClient,
  });

  useQuery(getOngoingSwapsQuery, {
    client: swapClient,
  });

  const { response, loading } = useApiResponse(res);

  const resInfo = response?.getSwap;

  const pairStatus = useMemo(
    () => {
      if (resInfo) {
        return resInfo.status === SwapStatusEnumKeys.WITHDRAW_COMPLETED
          ? SwapPairStatus.FINISHED
          : SwapPairStatus.FAILED;
      }
      return SwapPairStatus.FINISHED;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response]
  );

  const amount = useMemo(() => {
    if (hasAnyFieldEmpty(resInfo?.swapTask as Record<string, unknown>))
      return '';

    let rateFieldText = `${resInfo?.swapTask?.originalNeededAmount} ${resInfo?.swapTask?.sourceAsset.code} `;
    const isExactRate =
      resInfo?.swapTask?.rateType === RateTypes.FIXED ? '=' : '≈';

    const isRateDetermined =
      !!resInfo?.status &&
      [
        SwapStatusEnumKeys.WITHDRAW_IN_PROGRESS,
        SwapStatusEnumKeys.WITHDRAW_FAILED,
        SwapStatusEnumKeys.WITHDRAW_COMPLETED,
        SwapStatusEnumKeys.EXCHANGE_COMPLETED,
      ].includes(resInfo?.status);

    rateFieldText += isRateDetermined ? '=' : isExactRate;
    rateFieldText += ` ${resInfo?.swapTask?.originalReceivedAmount} ${resInfo?.swapTask?.destinationAsset.code}`;

    return rateFieldText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const operationDetailsRateComponent = useMemo(() => {
    if (!resInfo) return null;

    return (
      <Wrapper>
        {resInfo?.swapTask.rateType === RateTypes.FIXED ? (
          <>
            <ClosedLockIcon />
            <Desc fontFamily="primaryBold">
              {t('common_fixed_rate')} {amount}
            </Desc>
          </>
        ) : (
          <>
            <OpenedLockIcon />
            <Desc fontFamily="primaryBold">
              {t('common_float_rate')} {amount}
            </Desc>
          </>
        )}
      </Wrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, t, amount]);

  const date = resInfo
    ? formatDate(SWAP_BLOCK_DATE_FORMAT, getDate(resInfo?.swapTask?.expiresAt))
    : '';

  const operationDetailsInfo = [
    {
      title: t('swap_details_recipient_address'),
      desc: resInfo?.swapTask?.destinationAddress,
      shouldCopyButtonRender: true,
      // shouldExportButtonRender: true,
    },
    {
      title: t('swap_details_deposit_address'),
      desc: resInfo?.swapTask?.accountCopy?.address,
      shouldCopyButtonRender: true,
      // shouldExportButtonRender: true,
    },
    {
      title: t('trade_rate'),
      desc: operationDetailsRateComponent,
    },
    {
      title: t('swap_info_date'),
      desc: date,
    },
  ];

  const additionalInformation = [
    {
      title: t('swap_info_refund_address'),
      desc: resInfo?.swapTask?.refundAddress || '--',
      shouldCopyButtonRender: !!resInfo?.swapTask?.refundAddress,
    },
    {
      title: t('swap_info_email'),
      desc: resInfo?.swapTask?.email || '--',
    },
  ];

  return {
    operationDetailsInfo,
    additionalInformation,
    pairStatus,
    info,
    resInfo,
    loading,
  };
};
