import { object, string } from 'yup';

import { RegexValidators } from './regexValidators';
import { ValidationErrorMessages } from './types';

export const editProfileValidation = object({
  country: string().required(ValidationErrorMessages.REQUIRED),
  city: string()
    .required(ValidationErrorMessages.REQUIRED)
    .matches(RegexValidators.HAS_NO_SPACE, ValidationErrorMessages.REQUIRED)
    .matches(
      RegexValidators.HAS_LATIN_CHARACTERS_AND_SPACES,
      ValidationErrorMessages.LATIN_CHARACTERS_AND_SPACES
    )
    .matches(
      RegexValidators.HAS_NO_SPACE_AT_START_AND_AT_END,
      ValidationErrorMessages.NO_SPACE_AT_START_AND_END
    ),
  address: string()
    .required(ValidationErrorMessages.REQUIRED)
    .matches(RegexValidators.HAS_NO_SPACE, ValidationErrorMessages.REQUIRED)
    .matches(
      RegexValidators.HAS_NO_SPACE_AT_START_AND_AT_END,
      ValidationErrorMessages.NO_SPACE_AT_START_AND_END
    ),

  zip: string()
    .required(ValidationErrorMessages.REQUIRED)
    .matches(RegexValidators.HAS_NO_SPACE, ValidationErrorMessages.REQUIRED)
    .matches(
      RegexValidators.HAS_NO_SPACE_AT_START_AND_AT_END,
      ValidationErrorMessages.NO_SPACE_AT_START_AND_END
    )
    .min(3, ValidationErrorMessages.MIN_LENGTH)
    .max(12, ValidationErrorMessages.MAX_LENGTH),
});
