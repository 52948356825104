import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { AuthPageLayout } from 'src/components';
import { EmailVerificationImg } from 'src/assets';

import {
  Container,
  Wrapper,
  ImageContainer,
  SuccessTextContainer,
  ButtonContainer,
  Img,
} from './styles';

export const VerifyEmailSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginNavigation = () => {
    navigate('/login');
  };

  return (
    <>
      <AuthPageLayout
        heading=""
        headerButtonTitle={t('register_login')}
        onHeaderButtonClick={handleLoginNavigation}
      >
        <Container>
          <Wrapper>
            <ImageContainer>
              <Img
                src={EmailVerificationImg}
                alt="Email Verified Successfully"
              />
            </ImageContainer>
            <SuccessTextContainer>
              <Typography
                fontSize={2}
                lineHeight={3}
                type={'headline'}
                fontFamily={'primaryMedium'}
                fontWeight={50}
              >
                {t('verify_email_hint_after')}
              </Typography>
            </SuccessTextContainer>
          </Wrapper>
          <ButtonContainer>
            <Button
              type="button"
              title={t('common_continue')}
              onClick={handleLoginNavigation}
            />
          </ButtonContainer>
        </Container>
      </AuthPageLayout>
    </>
  );
};
