import { forwardRef, ForwardRefRenderFunction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Typography, TransparentButton } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { ViewAllArrowIcon } from 'src/assets';

import NotificationsList from './NotificationsList';

import {
  Container,
  TitleWrapper,
  BodyWrapper,
  ViewAllWrapper,
  CustomTypography,
} from './styles';

export type NotificationsDropdownProps = {
  isShown: boolean;
  setIsShown: (val: boolean) => void;
};

const NotificationsDropdown: ForwardRefRenderFunction<
  HTMLDivElement,
  NotificationsDropdownProps
> = ({ isShown, setIsShown }, ref) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate('/notifications');
    setIsShown(false);
  };

  if (!isShown) return null;

  return (
    <Container ref={ref}>
      <TitleWrapper>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {t('notifications_dropdown_title')}
        </Typography>
        <ViewAllWrapper>
          <TransparentButton onClick={handleClick}>
            <CustomTypography
              type="caption"
              fontFamily="primaryBold"
              color={colors.interactive.brandColor}
            >
              {t('notifications_view_all')}
            </CustomTypography>
            <ViewAllArrowIcon color={colors.interactive.brandColor} />
          </TransparentButton>
        </ViewAllWrapper>
      </TitleWrapper>
      <BodyWrapper>
        <NotificationsList />
      </BodyWrapper>
    </Container>
  );
};

export default forwardRef(NotificationsDropdown);
