import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetReferralUsersListArgs = {
  skip?: number;
  limit?: number;
};

export type ReferralUser = {
  bitnetId: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type GetReferralUsersListResponse = {
  getReferralUsersList: DefaultApiResponse<ReferralUser[]>;
};

export const getReferralUsersListQuery: TypedDocumentNode<
  GetReferralUsersListResponse,
  DefaultArg<GetReferralUsersListArgs>
> = gql`
  query GetReferralUsersList($record: GetReferralUsersListRecord!) {
    getReferralUsersList(record: $record) {
      bitnetId
      email
      firstName
      lastName
    }
  }
`;
