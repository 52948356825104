import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useTranslation } from 'src/hooks';
import { ResultFeedbackType } from 'src/constants';
import { AuthPageLayout, VerifyEmail } from 'src/components';

import { useVerifyEmail } from './hooks/useVerifyEmail';
import { VerifyEmailSuccess } from './components/VerifyEmailSuccess';
import { Container, CustomResult } from './styles';

type LocationStateProps = {
  options?: { isVerified?: boolean };
};

const VerifyEmailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationStateProps;
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email') || '';
  const verifyToken = searchParams.get('token') || '';
  const {
    isCounterRunning,
    confirmEmailError,
    resendEmailLoading,
    confirmEmailLoading,
    isVerifyEmailSuccess,
    countDown,
    handleConfirmEmail,
    handleResendEmail,
  } = useVerifyEmail();

  useEffect(() => {
    if (verifyToken) {
      handleConfirmEmail({
        variables: {
          record: {
            token: verifyToken,
          },
        },
      });
    }
  }, [verifyToken, handleConfirmEmail]);

  const { titleText, secondaryText, highlightedText } = useMemo(() => {
    if (isVerifyEmailSuccess) {
      return {
        titleText: t('verify_email_title_after'),
        highlightedText: 'Bitnet',
        secondaryText: t('verify_email_hint_after', { email }),
      };
    } else {
      return {
        titleText: t('verify_email_title_before'),
        highlightedText: t('verify_email_title_before_highlighted'),
        secondaryText: t('verify_email_hint_before', { email }),
      };
    }
  }, [isVerifyEmailSuccess, t, email]);

  const handleClick = () => {
    if (isVerifyEmailSuccess) {
      navigate('/login');
    } else {
      handleResendEmail({
        variables: {
          record: {
            email,
          },
        },
      });
    }
  };
  const buttonText = isVerifyEmailSuccess
    ? t('verify_email_log_in')
    : t('verify_email_resend_text');
  const buttonType = isVerifyEmailSuccess ? 'primary' : 'tertiary';
  const shouldShowVerifyEmail =
    !confirmEmailError &&
    ((isVerifyEmailSuccess && !confirmEmailLoading) || email);

  useEffect(() => {
    if (shouldShowVerifyEmail && locationState?.options?.isVerified) {
      handleClick();
    }
  }, []);
  if (resendEmailLoading) {
    return null;
  }

  const handleLoginClick = () => {
    navigate('/login');
  };

  if (isVerifyEmailSuccess) {
    return <VerifyEmailSuccess />;
  }

  return (
    <AuthPageLayout
      isHeadingCenter
      heading={titleText}
      headerButtonTitle={t('register_login')}
      highlightedHeading={highlightedText}
      onHeaderButtonClick={handleLoginClick}
    >
      <Container data-testid="verifyEmailContainer">
        {shouldShowVerifyEmail && (
          <VerifyEmail
            testId="verifyEmailTestId"
            verifyEmailStatus={isVerifyEmailSuccess}
            isDisabled={isCounterRunning}
            countDown={countDown}
            buttonType={buttonType}
            buttonText={buttonText}
            titleText={titleText}
            handleClick={handleClick}
            secondaryText={secondaryText}
          />
        )}
        {confirmEmailError && (
          <CustomResult
            onClick={handleClick}
            isDisabled={isCounterRunning}
            buttonText={t('two_fa_resend')}
            title={t('email_verify_wrong_token_title')}
            description={t('email_verify_wrong_token_descr')}
            type={ResultFeedbackType.ERROR}
          />
        )}
      </Container>
    </AuthPageLayout>
  );
};

export default VerifyEmailPage;
