import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg, Feature } from '@libs/graphql';

export type GetEnabledFeaturesResponse = {
  getEnabledFeatures: DefaultApiResponse<Feature[]>;
};

export const getEnabledFeaturesQuery: TypedDocumentNode<
  GetEnabledFeaturesResponse,
  DefaultArg
> = gql`
  query GetEnabledFeatures {
    getEnabledFeatures {
      feature
      id
      kycRequired
    }
  }
`;
