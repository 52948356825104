import styled from 'styled-components';

import { Typography } from '@libs/components';

type Props = {
  isComplete?: boolean;
  isActive?: boolean;
};

export const HeaderContainer = styled.div<{ shouldRenderSteps: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${({ theme, shouldRenderSteps }) =>
    shouldRenderSteps && theme.shadows.lg};
  position: fixed;
  background-color: ${({ theme }) => theme.colors.surface.default};
  z-index: ${({ theme }) => theme.zIndex.high};
  .modal-header {
    background-color: ${({ theme }) => theme.colors.surface.default};
    border-bottom: none;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 6.6rem 4rem;
`;

export const Indicator = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 2rem;

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }

  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 0.1rem solid
    ${({ theme, isComplete, isActive }) =>
      isComplete || isActive
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};
  background-color: ${({ theme, isComplete }) =>
    isComplete
      ? theme.colors.interactive.brandColor
      : theme.colors.surface.default};
`;

export const CustomTypography = styled(Typography)`
  position: absolute;
  width: max-content;
  top: 2.7rem;
`;

export const Line = styled.div<Props>`
  flex: 1;
  height: 0.1rem;
  border: 0.1rem solid
    ${({ theme, isActive, isComplete }) =>
      isActive || isComplete
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};
`;

export const Circle = styled.div`
  height: 0.4rem;
  width: 0.4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
`;
