import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const EditIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.interactive.brandColor;
  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.61666 16.2667C4.10832 16.2667 3.63332 16.0917 3.29166 15.7667C2.85832 15.3583 2.64999 14.7417 2.72499 14.075L3.03332 11.375C3.09166 10.8667 3.39999 10.1917 3.75832 9.82499L10.6 2.58332C12.3083 0.77499 14.0917 0.72499 15.9 2.43332C17.7083 4.14166 17.7583 5.92499 16.05 7.73332L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2417C4.87499 16.25 4.74999 16.2667 4.61666 16.2667ZM13.275 2.42499C12.6333 2.42499 12.075 2.82499 11.5083 3.42499L4.66666 10.675C4.49999 10.85 4.30832 11.2667 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9917 4.52499 15.0417 4.79999 15L7.48332 14.5417C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86666C16.1667 5.76666 16.5417 4.74999 15.0333 3.33332C14.3667 2.69166 13.7917 2.42499 13.275 2.42499Z"
        fill={iconColor}
      />
      <path
        d="M14.45 9.12501C14.4333 9.12501 14.4083 9.12501 14.3917 9.12501C11.7917 8.86667 9.69999 6.89167 9.29999 4.30834C9.24999 3.96667 9.48332 3.65001 9.82499 3.59167C10.1667 3.54167 10.4833 3.77501 10.5417 4.11667C10.8583 6.13334 12.4917 7.68334 14.525 7.88334C14.8667 7.91667 15.1167 8.22501 15.0833 8.56667C15.0417 8.88334 14.7667 9.12501 14.45 9.12501Z"
        fill={iconColor}
      />
      <path
        d="M17.5 18.9583H2.5C2.15833 18.9583 1.875 18.675 1.875 18.3333C1.875 17.9917 2.15833 17.7083 2.5 17.7083H17.5C17.8417 17.7083 18.125 17.9917 18.125 18.3333C18.125 18.675 17.8417 18.9583 17.5 18.9583Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default EditIcon;
