import { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { LanguageSwitcher, LogoutIcon, Typography } from '@libs/components';
import { Environment } from '@libs/types';
import { FeatureModuleEnum } from '@libs/backend-common';

import {
  useAuth,
  useEnabledFeatures,
  useJurisdictionDetector,
  useTranslation,
} from 'src/hooks';
import {
  AccountIcon,
  SecurityIcon,
  AddressBookIcon,
  DropdownIcon,
  NotificationSettingsIcon,
} from 'src/assets';

import UserDetails from '../user-details/UserDetails';

import {
  Container,
  JustifiedContent,
  UserDetailsWrapper,
  TitleWrapper,
  NavSectionWrapper,
  IconWrapper,
  NavButton,
  NavTitleWrapper,
  LogoutButton,
  LogoutWrapper,
} from './styles';

export type AccountDropdownProps = {
  isShown: boolean;
  setIsShown: (val: boolean) => void;
};

const enableNotificationButton = process.env.ENVIRONMENT === Environment.dev;

const AccountDropdown: ForwardRefRenderFunction<
  HTMLDivElement,
  AccountDropdownProps
> = ({ isShown, setIsShown }, ref) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { currentJurisdiction } = useJurisdictionDetector();
  const { t, changeLanguage, selectedLanguageCode } = useTranslation();

  const navigateToPersonal = () => {
    navigate('/account-information/personal');
    setIsShown(false);
  };

  const navigateToSecurity = () => {
    navigate('/account-information/security');
    setIsShown(false);
  };

  const navigateToAddressBook = () => {
    navigate('/account-information/address-book');
    setIsShown(false);
  };

  const navigateToNotifications = () => {
    navigate('/account-information/notifications');
    setIsShown(false);
  };

  const onLogout = useCallback(() => {
    navigate('/login', {
      replace: true,
    });
  }, [navigate]);

  const { logout } = useAuth(onLogout);

  const { checkMultipleFeatures } = useEnabledFeatures();

  const { isLoading, isAvailable } = checkMultipleFeatures([
    FeatureModuleEnum.BANK_WIRE_WITHDRAWAL,
    FeatureModuleEnum.CARD_WITHDRAWAL,
    FeatureModuleEnum.CRYPTO_WITHDRAWAL,
  ]);

  if (!isShown) return null;
  return (
    <Container ref={ref}>
      <JustifiedContent>
        <UserDetailsWrapper>
          <UserDetails showGreeting />
          <LanguageSwitcher
            changeLanguage={changeLanguage}
            jurisdictionId={currentJurisdiction}
            selectedLanguage={selectedLanguageCode}
          />
        </UserDetailsWrapper>
        <TitleWrapper>
          <Typography
            type="subHeadline"
            fontFamily="primaryBold"
            color={colors.text.default}
          >
            {t('account_dropdown_title')}
          </Typography>
        </TitleWrapper>
        <NavSectionWrapper>
          <NavButton onClick={navigateToPersonal}>
            <IconWrapper>
              <AccountIcon color={colors.text.default} width={22} height={22} />
            </IconWrapper>
            <NavTitleWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primary"
                color={colors.text.default}
              >
                {t('account_info_personal')}
              </Typography>
              <DropdownIcon
                width={26}
                height={26}
                color={colors.text.default}
              />
            </NavTitleWrapper>
          </NavButton>

          <NavButton onClick={navigateToSecurity}>
            <IconWrapper>
              <SecurityIcon
                color={colors.text.default}
                width={22}
                height={22}
              />
            </IconWrapper>
            <NavTitleWrapper>
              <Typography
                type="bodyButton"
                fontFamily="primary"
                color={colors.text.default}
              >
                {t('account_info_security')}
              </Typography>
              <DropdownIcon
                width={26}
                height={26}
                color={colors.text.default}
              />
            </NavTitleWrapper>
          </NavButton>

          {!isLoading && isAvailable && (
            <NavButton onClick={navigateToAddressBook}>
              <IconWrapper>
                <AddressBookIcon
                  color={colors.text.default}
                  width={22}
                  height={22}
                />
              </IconWrapper>
              <NavTitleWrapper>
                <Typography
                  type="bodyButton"
                  fontFamily="primary"
                  color={colors.text.default}
                >
                  {t('account_info_address_book')}
                </Typography>
                <DropdownIcon
                  width={26}
                  height={26}
                  color={colors.text.default}
                />
              </NavTitleWrapper>
            </NavButton>
          )}

          {enableNotificationButton && (
            <NavButton onClick={navigateToNotifications}>
              <IconWrapper>
                <NotificationSettingsIcon width={22} height={22} />
              </IconWrapper>
              <NavTitleWrapper>
                <Typography
                  type="bodyButton"
                  fontFamily="primary"
                  color={colors.text.default}
                >
                  {t('account_info_notifications')}
                </Typography>
                <DropdownIcon
                  width={26}
                  height={26}
                  color={colors.text.default}
                />
              </NavTitleWrapper>
            </NavButton>
          )}
          <LogoutWrapper>
            <LogoutButton
              size="md"
              onClick={logout}
              buttonType="secondary"
              title={t('common_logout')}
              icon={<LogoutIcon />}
              testId="logoutButton"
            />
          </LogoutWrapper>
        </NavSectionWrapper>
      </JustifiedContent>
    </Container>
  );
};

export default forwardRef(AccountDropdown);
