import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetIsEligibleForAccountDeleteResponse = {
  getIsEligibleForAccountDelete: DefaultApiResponse<boolean>;
};
export const getIsEligibleForAccountDeleteQuery: TypedDocumentNode<
  GetIsEligibleForAccountDeleteResponse,
  DefaultArg
> = gql`
  query GetIsEligibleForAccountDelete {
    getIsEligibleForAccountDelete
  }
`;
