import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const PngIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId = 'PngIcon',
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.interactive;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M6.125 0.5H1.625C1.32663 0.5 1.04048 0.615892 0.829505 0.822183C0.618526 1.02847 0.5 1.30826 0.5 1.6V10.4C0.5 10.6917 0.618526 10.9715 0.829505 11.1778C1.04048 11.3841 1.32663 11.5 1.625 11.5H8.375C8.67337 11.5 8.95952 11.3841 9.17049 11.1778C9.38147 10.9715 9.5 10.6917 9.5 10.4V3.8L6.125 0.5ZM3.32937 8.25775C3.15331 8.4189 2.894 8.49205 2.59081 8.49205C2.52331 8.49205 2.462 8.4882 2.41531 8.4816V9.27635H1.90569V7.0835C2.13557 7.0496 2.36792 7.03434 2.60038 7.03785C2.91706 7.03785 3.14375 7.0967 3.29506 7.2155C3.44019 7.3277 3.53806 7.5125 3.53806 7.7303C3.53806 7.9481 3.46437 8.13235 3.32937 8.25775ZM5.74925 9.2769H5.20925L4.72381 8.4189C4.58881 8.1813 4.44031 7.89475 4.3295 7.63405L4.31881 7.63735C4.33231 7.93105 4.33906 8.24455 4.33906 8.607V9.2769H3.86656V7.0538H4.46675L4.93925 7.86835C5.07425 8.10265 5.20869 8.37985 5.30994 8.6301H5.32062C5.28793 8.32226 5.27328 8.01283 5.27675 7.70335V7.05325H5.74925V9.2769ZM8.09431 9.1746C7.84936 9.25387 7.59349 9.29616 7.3355 9.3C6.92094 9.3 6.62056 9.1977 6.41075 8.9997C6.20206 8.8083 6.08731 8.51845 6.09069 8.19175C6.09406 7.45255 6.64362 7.0307 7.3895 7.0307C7.68256 7.0307 7.90925 7.08735 8.02006 7.1396L7.91262 7.54165C7.78775 7.48885 7.6325 7.44595 7.38331 7.44595C6.95469 7.44595 6.63069 7.68355 6.63069 8.16535C6.63069 8.6235 6.92431 8.8941 7.34563 8.8941C7.46375 8.8941 7.55825 8.8809 7.59875 8.86055V8.39525H7.24775V8.0031H8.09431V9.1746ZM6.125 4.35H5.5625V1.6L8.375 4.35H6.125Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default PngIcon;
