import styled from 'styled-components';

import { Button, Typography } from '@libs/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.media.laptop`
    flex-direction: row;
    padding: 6rem 4rem 6rem 2.85rem;
  `}
  ${({ theme }) => theme.media.laptopM`
    padding: 6rem 9rem 6rem 3.85rem;
  `}
`;

export const TextAndImgContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0 2.2rem;
  justify-content: center;
  ${({ theme }) => theme.media.laptop`
      padding: unset;
      gap: 3.2rem;
  `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

  ${({ theme }) => theme.media.laptop`
      width: 38rem;
  `}
`;

export const Line = styled.div`
  flex: 1;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;

export const Arrow = styled.div`
  height: 3rem;
  width: 3rem;
  margin: 0 0.4rem;

  transform: rotate(45deg);

  border: 0.2rem solid ${({ theme }) => theme.colors.borders.default};
  border-top-style: none;
  border-left-style: none;
  border-radius: 0.3rem;

  ${({ theme }) => theme.media.laptop`
      transform: rotate(315deg);
      margin: 0.4rem 0rem;
  `}
`;

export const LineWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
    flex-direction: column;
     width: unset;
     margin:0 6rem 0 2rem;
  `}
`;

export const TextAndLineWrapper = styled(LineWrapper)`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
    flex-direction: row;
    margin:2.8rem 0;
  `}
`;
export const SkipButton = styled(Button)`
  margin-bottom: 2.2rem;
  ${({ theme }) => theme.media.laptop`
      margin-bottom: initial;
  `}
`;

export const Img = styled.img`
  height: 11rem;

  ${({ theme }) => theme.media.laptop`
      height: 14.3rem;
  `}
`;

export const OptionAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.6rem;
`;

export const CustomTypography = styled(Typography)`
  margin: 0 1.5rem;
`;

export const Title = styled(Typography)`
  display: block;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 3.2rem;
  height: 3.2rem;

  position: absolute;
  top: 2rem;
  right: 2rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;
