import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const CopyIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      data-testid={testId}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M9.33366 8.02484V10.4748C9.33366 12.5165 8.51699 13.3332 6.47533 13.3332H4.02533C1.98366 13.3332 1.16699 12.5165 1.16699 10.4748V8.02484C1.16699 5.98317 1.98366 5.1665 4.02533 5.1665H6.47533C8.51699 5.1665 9.33366 5.98317 9.33366 8.02484Z"
        stroke={iconColor}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8337 4.52484V6.97484C12.8337 9.0165 12.017 9.83317 9.97533 9.83317H9.33366V8.02484C9.33366 5.98317 8.51699 5.1665 6.47533 5.1665H4.66699V4.52484C4.66699 2.48317 5.48366 1.6665 7.52533 1.6665H9.97533C12.017 1.6665 12.8337 2.48317 12.8337 4.52484Z"
        stroke={iconColor}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};
export default CopyIcon;
