import { useState, memo } from 'react';
import { useMutation } from '@apollo/client';

import { WalletTypeEnum } from '@libs/backend-common';
import { useApiResponse } from '@libs/hooks';
import { CurrencyType } from '@libs/types';
import { Typography } from '@libs/components';
import { useTheme } from 'styled-components';

import { ActiveStarIcon, StarIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';

import {
  addFavoriteAssetMutation,
  getWalletInfo,
  removeFavoriteAssetMutation,
} from 'src/gql';

import { StarIconWrapper } from './styles';

type FavoriteAssetIndicatorProps = {
  walletType?: WalletTypeEnum;
  isFavorite: boolean;
  assetCode: CurrencyType;
  shouldHaveLabel?: boolean;
};

const FavoriteAssetIndicator: React.FC<FavoriteAssetIndicatorProps> = ({
  isFavorite,
  assetCode,
  shouldHaveLabel,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [isFavoriteAsset, setIsFavoriteAsset] = useState(isFavorite);

  const addFavoriteLabel = !isFavoriteAsset
    ? t('common_add_favorite')
    : t('common_remove_favorite');

  const additionAndDeletionArgs = {
    variables: {
      record: {
        assetCode,
      },
    },
  };

  const [handleRemoveFromFavorite, removeFromFavoriteRes] = useMutation(
    removeFavoriteAssetMutation,
    {
      refetchQueries: [
        {
          query: getWalletInfo,
          variables: {
            record: { walletType: WalletTypeEnum.FAVORITE_ASSETS },
          },
        },
      ],
    }
  );

  const [handleAddToFavorite, addToFavoritesRes] = useMutation(
    addFavoriteAssetMutation
  );
  const handleError = () => setIsFavoriteAsset((prev) => !prev);

  const { loading: addingResponseLoading } = useApiResponse(
    addToFavoritesRes,
    undefined,
    handleError
  );
  const { loading: removeResponseLoading } = useApiResponse(
    removeFromFavoriteRes,
    undefined,
    handleError
  );

  const handleStarClick = () => {
    if (addingResponseLoading || removeResponseLoading) return;
    setIsFavoriteAsset((prev) => !prev);
    if (isFavoriteAsset) {
      handleRemoveFromFavorite(additionAndDeletionArgs);
    } else {
      handleAddToFavorite(additionAndDeletionArgs);
    }
  };

  return (
    <StarIconWrapper
      shouldHaveLabel={shouldHaveLabel}
      isActive={isFavoriteAsset}
      onClick={handleStarClick}
    >
      {isFavoriteAsset ? <ActiveStarIcon /> : <StarIcon />}
      {shouldHaveLabel && (
        <Typography
          color={colors.text.secondary}
          type="bodyButton"
          fontFamily="primaryMedium"
        >
          {addFavoriteLabel}
        </Typography>
      )}
    </StarIconWrapper>
  );
};
export default memo(FavoriteAssetIndicator);
