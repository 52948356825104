import styled from 'styled-components';

export const Container = styled.div`
  padding: 3.6rem;
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.tabletL`
    padding: unset;
    .content{
      padding: 3.6rem;
      padding-top: 2.4rem;
    }
  `};
`;
