import { useTheme } from 'styled-components';
import { AssetCode, AssetIcon, AssetName, DetailsHeaderLoader } from './styles';

const DetailsHeaderDesktopLoader = () => {
  const { colors } = useTheme();
  return (
    <DetailsHeaderLoader
      backgroundColor={colors.surface.interactiveDefault}
      foregroundColor={colors.surface.pressed}
    >
      <AssetIcon />
      <AssetName />
      <AssetCode />
    </DetailsHeaderLoader>
  );
};

export default DetailsHeaderDesktopLoader;
