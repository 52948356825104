import { isPasswordValid } from '@libs/validations';
import { object, string } from 'yup';

import { ValidationErrorMessages } from './types';

export const resetPasswordValidation = object({
  password: string()
    .required(ValidationErrorMessages.REQUIRED)
    .min(8, ValidationErrorMessages.LENGTH)
    .test({
      test: (value) => isPasswordValid(value),
    }),
});
