import { CloseIcon, Typography } from '@libs/components';
import { SearchIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';

import {
  SearchInputContainer,
  CustomSearchInputTitle,
  CustomSearchInput,
  CloseIconWrapper,
  EmptyDiv,
} from './styles';

type SelectModalHeaderRenderOptionProps = {
  selectOptionsLabel: string;
  searchInputValue: string;
  setSearchInputValue: (val: string) => void;
  closeSelect: () => void;
};

const SelectModalHeaderRenderOption: React.FC<
  SelectModalHeaderRenderOptionProps
> = ({
  selectOptionsLabel,
  searchInputValue,
  setSearchInputValue,
  closeSelect,
}) => {
  const { t } = useTranslation();

  return (
    <SearchInputContainer>
      <CustomSearchInputTitle>
        <EmptyDiv />
        <Typography type={'subHeadline'} fontFamily={'primaryBold'}>
          {selectOptionsLabel}
        </Typography>
        <CloseIconWrapper onClick={closeSelect}>
          <CloseIcon />
        </CloseIconWrapper>
      </CustomSearchInputTitle>

      <CustomSearchInput
        icon={<SearchIcon />}
        placeholder={t('common_search')}
        value={searchInputValue}
        onChangeText={setSearchInputValue}
      />
    </SearchInputContainer>
  );
};

export default SelectModalHeaderRenderOption;
