import { DropdownIcon, Typography } from '@libs/components';
import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

export const Container = styled.div`
  margin: 1.9rem 2rem 1.8rem 2.2rem;
  background: linear-gradient(128deg, #506bf9 -15.85%, #77b4fb 130.96%);
  padding: 1.2rem 1.5rem 1.3rem 1.8rem;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
`;
export const CustomButton = styled.div`
  background: none;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.text.interactive};
  padding: 1rem 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  width: fit-content;
  cursor: pointer;
`;
export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.interactive};
  font-size: 1.4rem;
  line-height: 2rem;
`;
export const CustomDropDownIcon = styled(DropdownIcon)`
  transform: rotate(270deg);
  position: relative;
  width: 1rem;
  top: 0.1rem;
`;

export const Loader = styled.div`
  display: block;
  margin: 1.3rem 0 1.9rem;
  width: 20rem;
  height: 0.6rem;
  border-radius: 4rem;
  background-color: ${({ theme }) => `${theme.colors.text.interactive}33`};
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.text.interactive};
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 4rem;
    animation: ${loaderAnimation} 1.5s ease-in-out infinite;
  }
`;
