import { useTheme } from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Typography } from '@libs/components';

type CircularProgressBarProps = {
  value: number;
  maxValue: number;
};
const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  value,
  maxValue,
}) => {
  const { colors } = useTheme();

  const styles = buildStyles({
    trailColor: colors.surface.default,
    pathColor: colors.interactive.brandColor,
  });

  return (
    <CircularProgressbarWithChildren
      styles={styles}
      strokeWidth={1.5}
      value={value}
      maxValue={maxValue}
    >
      <Typography type="bodyButton" fontFamily="primaryBold">
        {value}
      </Typography>
    </CircularProgressbarWithChildren>
  );
};
export default CircularProgressBar;
