import styled from 'styled-components';

import {
  Button,
  Input,
  MultiSelect,
  TransparentButton,
  Typography,
} from '@libs/components';
import DateFilter from '../date-filter/DateFilter';

type FiltersType = {
  isFiltered: boolean;
};
type CustomInputProps = {
  isFocused: boolean;
};

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  padding: 2.2rem 0;

  ${({ theme }) => theme.media.tablet`
    padding: 2.6rem 2.4rem 1.9rem;
    box-shadow: initial;
  `};
`;

export const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2.1rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.tablet`
   padding: unset;
  `};

  ${({ theme }) => theme.media.laptop`
    margin-bottom: 3rem;
  `};
`;
export const ButtonWrapper = styled.div`
  width: min-content;
  ${({ theme }) => theme.media.laptop`
      display: none;
  `};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  max-width: 98vw;
  width: 100%;
  margin-bottom: 1.2rem;
  overflow: auto;
  padding: 0 2.1rem;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ theme }) => theme.media.tablet`
    overflow: visible;
    width: initial;
    gap: 1.6rem;
    padding: 0;
    margin-bottom: 1.8rem;
  `};
`;

export const ClearAllFiltersBtn = styled(TransparentButton)`
  max-width: 15rem;
`;

export const BtnWrapper = styled.div<FiltersType>`
  display: none;
  width: 100%;
  visibility: ${({ isFiltered }) => (!isFiltered ? 'hidden' : 'visible')};

  margin-right: 0.7rem;
  margin-top: 1.9rem;
  justify-content: end;

  ${({ theme }) => theme.media.laptop`
    display: flex;
    align-self: initial;
  `};
  ${({ theme }) => theme.media.laptopM`
    margin-top: unset;
  `};
`;

export const DatePickerWrapper = styled.div<FiltersType>`
  flex: 1;
  ${({ theme }) => theme.media.tablet`
    min-width: initial;
  `}
`;

export const CustomFilter = styled(MultiSelect)`
  flex: 1;

  ${({ theme }) => theme.media.tablet`
      width: 100%;
      max-width: 22rem;
      text-overflow: ellipsis;
      white-space: normal;
  `}
` as typeof MultiSelect;

export const SearchInputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 1rem 1.6rem 1.8rem;
`;
export const CustomSearchInputTitle = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CustomSearchInput = styled(Input)<CustomInputProps>`
  height: 5.4rem;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  .input-placeholder {
    transform: translateY(
      ${({ isFocused }) => (isFocused ? 'calc(-100% + 0.6rem)' : '-0.3rem')}
    );
  }
`;
export const ExportAllButton = styled(Button)`
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  margin-left: 1.8rem;
  background-color: none;
  margin-left: 1.6rem;
  width: 5.4rem;
  height: 5.4rem;
  padding: 0 2rem;
  ${({ theme }) => theme.media.laptopM`
      display: flex;
  `}
`;
export const MobileExportButtonWrapper = styled.div`
  margin: 0 2.1rem;
  ${({ theme }) => theme.media.tablet`
      margin: unset;
  `};
  ${({ theme }) => theme.media.laptopM`
      display:none;
  `};
`;
export const MobileExportButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
`;

export const CustomDateFilter = styled(DateFilter)`
  .calendar-wrapper {
    top: -0.1rem;
    left: -0.1rem;
  }
`;
export const CustomTypography = styled(Typography)`
  display: block;
  white-space: break-spaces;
  ${({ theme }) => theme.media.laptop`
      margin-left: unset;
  `}
`;
