import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const TransactionContainer = styled.div``;

export const HeaderLoader = styled(ContentLoader)`
  display: none;

  height: 4.1rem;
  width: 100%;

  ${({ theme }) => theme.media.laptop`
    display: initial;
  `};
`;

export const Coin = styled.rect`
  height: 2rem;
  width: 5rem;

  y: 1rem;
  x: 2.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const DateHeader = styled.rect`
  height: 2rem;
  width: 5rem;

  y: 1rem;
  x: 21%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Type = styled.rect`
  height: 2rem;
  width: 5rem;

  y: 1rem;
  x: 36%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Amount = styled.rect`
  height: 2rem;
  width: 7rem;

  y: 1rem;
  x: 49%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Fee = styled.rect`
  height: 2rem;
  width: 5rem;

  y: 1rem;
  x: 61%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const OperationId = styled.rect`
  height: 2rem;
  width: 9rem;

  y: 1rem;
  x: 72%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Status = styled.rect`
  height: 2rem;
  width: 7rem;

  y: 1rem;
  x: 86%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const BodyLoader = styled(ContentLoader)`
  height: 6.8rem;
  width: 100%;
`;

export const BodyLine = styled.rect`
  height: 0.1rem;
  width: 100%;
`;

export const AssetIcon = styled.circle`
  r: 1.7rem;
  cx: 2.7rem;
  cy: 3.4rem;

  ${({ theme }) => theme.media.mobileL`
      cx: 3.8rem;
  `};

  ${({ theme }) => theme.media.laptop`
      cx: 4.1rem;
  `};
`;

export const AssetName = styled.rect`
  height: 1.8rem;
  width: 7rem;

  x: 5.4rem;
  y: 1.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.mobileL`
      x: 6.5rem;
  `};

  ${({ theme }) => theme.media.laptop`
    x: 6.8rem;
  `};
`;

export const AssetCode = styled.rect`
  height: 1.6rem;
  width: 4rem;

  x: 5.4rem;
  y: 3.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.mobileL`
      x: 6.5rem;
  `};

  ${({ theme }) => theme.media.laptop`
    x: 6.8rem;
  `};
`;

export const DateBody = styled.rect`
  height: 1.8rem;
  width: 9rem;

  y: 1.4rem;
  x: 40%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
      width: 10rem;
      x: 21%;
  `};
`;

export const TimeBody = styled.rect`
  height: 1.6rem;
  width: 9rem;

  y: 3.5rem;
  x: 40%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
        width: 4rem;
        x: 21%;
  `};
`;

export const TransactionType = styled.rect`
  height: 1.8rem;
  width: 9rem;

  x: calc(100% - 8rem);
  y: 1.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.mobileL`
      x: calc(100% - 10.1rem);
  `};

  ${({ theme }) => theme.media.laptop`
      x: 36%;
      y: 2.3rem;
  `};
`;

export const AmountBody = styled.rect`
  height: 1.6rem;
  width: 8rem;

  x: calc(100% - 8rem);
  y: 3.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.mobileL`
      x: calc(100% - 9.1rem);
  `};

  ${({ theme }) => theme.media.laptop`
      height: 1.8rem;
      x: 49%;
      y: 2.3rem;
  `};
`;

export const FeeBody = styled.rect`
  display: none;
  height: 1.8rem;
  width: 5rem;

  x: 61%;
  y: 2.3rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
    display: initial;
  `};
`;

export const OperationIdBody = styled.rect`
  display: none;

  height: 1.8rem;
  width: 8rem;

  x: 72%;
  y: 2.3rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
    display: initial;
  `};
`;

export const StatusBody = styled.rect`
  display: none;

  height: 1.8rem;
  width: 9rem;

  x: 86%;
  y: 2.3rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.laptop`
    display: initial;
  `};
`;
