import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type resendEmailArgs = {
  email: string;
};

export type resendEmailResponse = { resendEmail: DefaultApiResponse<boolean> }; // NOTE: always wrap with DefaultApiResponse;

export const resendEmailMutation: TypedDocumentNode<
  resendEmailResponse,
  DefaultArg<resendEmailArgs>
> = gql`
  mutation ResendEmail($record: EmailRecord!) {
    resendEmail(record: $record)
  }
`;
