import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

type DropdownIconProps = SvgIconDefaultProps & {
  color?: string;
};
const DropdownIcon: React.FC<DropdownIconProps> = ({
  color,
  className,
  testId,
  width,
  height,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27036 18.8235C8.90988 18.4924 8.90988 17.9556 9.27036 17.6245L14.7715 12.5718L9.27036 7.51917C8.90988 7.18807 8.90988 6.65126 9.27036 6.32016C9.63085 5.98907 10.2153 5.98907 10.5758 6.32016L16.7296 11.9723C16.9027 12.1313 17 12.347 17 12.5718C17 12.7967 16.9027 13.0123 16.7296 13.1713L10.5758 18.8235C10.2153 19.1546 9.63085 19.1546 9.27036 18.8235Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default DropdownIcon;
