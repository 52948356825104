import { useTheme } from 'styled-components';
import { RadioButton, Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';

import {
  Title,
  TextWrapper,
  TitleWrapper,
  CustomTooltip,
  OptItemWrapper,
  RecommendedWrapper,
} from './styles';

export type VerticalRadioSelectorOption = {
  title: string;
  description: string;
  tooltipText?: string;
  isDisabled?: boolean;
  isRecommended?: boolean;
};
type VerticalRadioSelectorProps = {
  selectedOptionIndex?: number;

  options: VerticalRadioSelectorOption[];
  onSelection: (index: number) => void;
};

const VerticalRadioSelector: React.FC<VerticalRadioSelectorProps> = ({
  options,
  selectedOptionIndex,
  onSelection,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      {options.map((item, index) => {
        const handleSelection = () => onSelection(index);

        return (
          <OptItemWrapper
            key={item.title}
            isActive={index === selectedOptionIndex}
            onClick={handleSelection}
          >
            <RadioButton
              isDisabled={item.isDisabled}
              checked={index === selectedOptionIndex}
            />

            <TextWrapper>
              <TitleWrapper>
                <Title
                  color={colors.text.default}
                  type="subHeadline"
                  fontFamily="primaryBold"
                >
                  {item.title}
                </Title>

                {!!item.tooltipText && (
                  <CustomTooltip desc={item.tooltipText} />
                )}

                {item.isRecommended && (
                  <RecommendedWrapper>
                    <Typography
                      type="caption"
                      fontFamily="primaryBold"
                      color={colors.interactive.brandColor}
                    >
                      {t('common_recommended')}
                    </Typography>
                  </RecommendedWrapper>
                )}
              </TitleWrapper>

              <Typography
                as="p"
                color={colors.text.secondary}
                type="bodyButton"
              >
                {item.description}
              </Typography>
            </TextWrapper>
          </OptItemWrapper>
        );
      })}
    </>
  );
};
export default VerticalRadioSelector;
