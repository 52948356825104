import styled from 'styled-components';
import { Button, Typography } from '@libs/components';

export const Container = styled.section`
  width: 100%;
  max-width: 51.2rem;
  display: flex;
  padding: 8rem 0;
  align-items: center;
  justify-content: center;
  background-color: red;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Resend = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 3rem;
`;
export const ResendWrapper = styled.div`
  margin: 2.4rem 0 4.8rem;
  display: flex;
  align-items: center;
`;
export const CustomButton = styled(Button)`
  margin-top: 3.6rem;
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 5.6rem;
`;

export const AuthWrapper = styled.div`
  padding: 0rem 2.2rem 4.5rem;
`;

export const Description = styled(Typography)`
  display: inline-block;
  margin-bottom: 2.8rem;
  max-width: 33.5rem;
`;

export const ErrorWrapper = styled.div`
  height: 4.8rem;
  padding-top: 0.8rem;
`;

export const AnimWrapper = styled.div`
  width: 8.6rem;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
`;
