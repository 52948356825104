import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const DashboardPopularAssetsContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const PopularAssetsLoader = styled(ContentLoader)`
  width: 22rem;
  height: 21rem;

  background-color: #f5f7fd;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
`;

export const AssetIcon = styled.circle`
  cx: 4rem;
  cy: 4rem;

  r: 2.1rem;
`;

export const AssetCode = styled.rect`
  width: 12rem;
  height: 2.5rem;

  x: 1.8rem;
  y: 8rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const AssetPrice = styled.rect`
  width: 10rem;
  height: 2rem;

  x: 1.8rem;
  y: 11rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const BuyButton = styled.rect`
  width: 18rem;
  height: 4rem;

  x: 1.8rem;
  y: 15rem;

  rx: ${({ theme }) => theme.borderRadius.high};
  ry: ${({ theme }) => theme.borderRadius.high};
`;
