import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useJurisdictionDetector,
  useRenderObjectByTheme,
  useTranslation,
} from 'src/hooks';
import { AuthHeader, AuthPageLayout, ResultFeedback } from 'src/components';
import { ResultFeedbackType } from 'src/constants';
import {
  bitnetLogo,
  bitnetLogoSmall,
  CrexoneLogo,
  CrexoneLogoBlack,
  CrexoneMobileLogo,
} from 'src/assets';

import { JurisdictionsEnum } from '@libs/backend-common';
import ResetPasswordForm from './components/ResetPasswordForm';
import { ResultContainer } from './styles';

const ResetPasswordPage = () => {
  const [hasChangedSuccessfully, setHasChangedSuccessfully] = useState(false);
  const { t, changeLanguage, selectedLanguageCode } = useTranslation();
  const navigate = useNavigate();
  const crexonelogo = useRenderObjectByTheme(CrexoneLogoBlack, CrexoneLogo);

  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;

  const handleRegisterClick = () => {
    navigate('/register');
  };
  const handleLoginNavigation = () => {
    navigate('/login');
  };

  if (hasChangedSuccessfully) {
    return (
      <>
        <AuthHeader
          jurisdictionId={currentJurisdiction}
          changeLanguage={changeLanguage}
          selectedLanguage={selectedLanguageCode}
          logo={IS_GLOBAL_JURISDICTION ? crexonelogo : bitnetLogo}
          mobileLogo={
            IS_GLOBAL_JURISDICTION ? CrexoneMobileLogo : bitnetLogoSmall
          }
          onButtonClick={handleRegisterClick}
          buttonTitle={t('login_register_text')}
        />
        <ResultContainer>
          <ResultFeedback
            onClick={handleLoginNavigation}
            type={ResultFeedbackType.SUCCESS}
            title={t('reset_password_succ_title')}
            description={t('reset_password_succ_desc')}
            buttonText={t('reset_password_succ_buttonText')}
          />
        </ResultContainer>
      </>
    );
  }

  return (
    <AuthPageLayout
      isHeadingCenter
      isHeadingPrimary={false}
      heading={t('reset_password_title')}
      headerButtonTitle={t('login_register_text')}
      onHeaderButtonClick={handleRegisterClick}
    >
      <ResetPasswordForm onChangeSuccess={setHasChangedSuccessfully} />
    </AuthPageLayout>
  );
};
export default ResetPasswordPage;
