import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type RedeemStakingRewardArgs = {
  id: string;
};

export type RedeemStakingRewardResponse = {
  redeemStakingReward: DefaultApiResponse<boolean>;
};

export const redeemStakingRewardMutation: TypedDocumentNode<
  RedeemStakingRewardResponse,
  DefaultArg<RedeemStakingRewardArgs>
> = gql`
  mutation RedeemStakingReward($record: StakingIdRecord!) {
    redeemStakingReward(record: $record)
  }
`;
