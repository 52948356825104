import { FC, useMemo } from 'react';

import Lottie from 'lottie-react';

import { useTheme } from 'styled-components';

import { Typography, Input, Button } from '@libs/components';
import { isValidEmail } from '@libs/helpers';

import { emailSentAnimation } from 'src/animations';
import { useTranslation } from 'src/hooks';

import { Container, LottieWrapper, TextAndInputWrapper } from './styles';

type EnterEmailProps = {
  handleButtonClick: () => void;
  setAddress: (val: string) => void;
  address?: string;
  errorMessage?: string;
  loading: boolean;
};

export const EnterEmail: FC<EnterEmailProps> = ({
  address,
  handleButtonClick,
  setAddress,
  loading,
  errorMessage,
}) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const isEmailValid = useMemo(() => isValidEmail(address || ''), [address]);

  return (
    <Container>
      <LottieWrapper>
        <Lottie animationData={emailSentAnimation} />
      </LottieWrapper>
      <TextAndInputWrapper>
        <Typography type="bodyButton" color={colors.text.secondary}>
          {t('activate_2fa_enter_mail_desc')}
        </Typography>
        <Input
          placeholder={t('activate_2fa_mail_input_placeholder')}
          value={address}
          onChangeText={setAddress}
          errorMessage={errorMessage}
        />
      </TextAndInputWrapper>
      <Button
        title={t('activate_2fa_button_title')}
        onClick={handleButtonClick}
        disabled={!isEmailValid}
        isLoading={loading}
      />
    </Container>
  );
};

export default EnterEmail;
