import { Typography } from '@libs/components';
import { useTheme } from 'styled-components';

import { Resend } from './styles';

type ResendSmsProps = {
  onResend: () => void;
  text?: string;
  buttonText: string;
};

const ResendSms: React.FC<ResendSmsProps> = ({
  onResend,
  text,
  buttonText,
}) => {
  const { colors } = useTheme();

  return (
    <>
      {text && (
        <Typography
          fontSize={1.4}
          type="primary"
          fontFamily="primary"
          lineHeight={2.4}
        >
          {text}
        </Typography>
      )}
      <Resend onClick={onResend} type="button">
        <Typography
          fontSize={1.4}
          lineHeight={1.7}
          color={colors.interactive.brandColor}
          fontFamily="primaryMedium"
        >
          {buttonText}
        </Typography>
      </Resend>
    </>
  );
};
export default ResendSms;
