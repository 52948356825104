import { useTheme } from 'styled-components';

import { useMutation } from '@apollo/client';

import { OuterLink, Typography } from '@libs/components';
import { meQuery } from '@libs/graphql';

import { saveAgreedToMarketingMessagesMutation } from 'src/gql';
import { AdvertismentDisclaimerPicture } from 'src/assets';
import { landingUrl } from 'src/constants';
import { useApiResponse, useThemeMode, useTranslation } from 'src/hooks';

import {
  Container,
  Img,
  ImgAndTextWrapper,
  TextContainer,
  ButtonsWrapper,
  RegretButton,
  CloseIconWrapper,
  CustomCloseIcon,
  AgreeButton,
} from './styles';

type AdvertismentNotificationsProps = {
  closeModal: () => void;
};

export const AdvertismentNotifications: React.FC<
  AdvertismentNotificationsProps
> = ({ closeModal }) => {
  const { colors } = useTheme();

  const { t, selectedLanguageCode } = useTranslation();

  const { selectedTheme } = useThemeMode();

  const [saveAgreedMessage, saveAgreedMessageRes] = useMutation(
    saveAgreedToMarketingMessagesMutation
  );

  useApiResponse(saveAgreedMessageRes, closeModal);

  const handleClick = (val: boolean) => {
    saveAgreedMessage({
      variables: { record: { agreed: val } },
      refetchQueries: [{ query: meQuery }],
    });
  };

  const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}`;

  const handleCancel = () => handleClick(false);

  const handleAgree = () => handleClick(true);

  return (
    <Container>
      <ImgAndTextWrapper>
        <Img src={AdvertismentDisclaimerPicture} />
        <TextContainer>
          <Typography type="caption" fontFamily="primaryMedium">
            {t('advertisment_disclaimer_part_one')}{' '}
            <OuterLink
              to={`${landingUrl}/legal/personal-data${additionalRouteQuery}`}
            >
              <Typography
                color={colors.interactive.brandColor}
                fontFamily="primary"
                type={'caption'}
              >
                {t('advertisment_disclaimer_part_two')}{' '}
              </Typography>
            </OuterLink>
            {t('advertisment_disclaimer_part_three')}
          </Typography>
        </TextContainer>
      </ImgAndTextWrapper>
      <ButtonsWrapper>
        <AgreeButton title={t('common_approval')} onClick={handleAgree} />
        <RegretButton onClick={handleCancel}>
          <Typography type="bodyButton" fontFamily="primaryBold">
            {t('common_regret')}
          </Typography>
        </RegretButton>
        <CloseIconWrapper onClick={closeModal}>
          <CustomCloseIcon />
        </CloseIconWrapper>
      </ButtonsWrapper>
    </Container>
  );
};

export default AdvertismentNotifications;
