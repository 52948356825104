import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const CardIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const iconColor = color ? color : '#7D99D6';

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M24.1905 10.2619V18.2548C24.1905 20.7083 22.1976 22.6905 19.744 22.6905H7.20833C4.75476 22.6905 2.7619 20.7083 2.7619 18.2548V10.2619H24.1905Z"
        fill={iconColor}
      />
      <path
        d="M24.1905 8.69762V10.2619H2.7619V8.69762C2.7619 6.24404 4.75476 4.2619 7.20833 4.2619H19.744C22.1976 4.2619 24.1905 6.24404 24.1905 8.69762Z"
        fill={iconColor}
      />
      <path
        d="M9.19048 19.1012H7.04762C6.60833 19.1012 6.24405 18.7369 6.24405 18.2976C6.24405 17.8583 6.60833 17.494 7.04762 17.494H9.19048C9.62976 17.494 9.99405 17.8583 9.99405 18.2976C9.99405 18.7369 9.62976 19.1012 9.19048 19.1012Z"
        fill={iconColor}
      />
      <path
        d="M16.1548 19.1012H11.8691C11.4298 19.1012 11.0655 18.7369 11.0655 18.2976C11.0655 17.8583 11.4298 17.494 11.8691 17.494H16.1548C16.5941 17.494 16.9583 17.8583 16.9583 18.2976C16.9583 18.7369 16.5941 19.1012 16.1548 19.1012Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default CardIcon;
