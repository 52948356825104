import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetReferralUsersCountResponse = {
  getReferralUsersCount: DefaultApiResponse<number>;
};

export const getReferralUsersCountQuery: TypedDocumentNode<
  GetReferralUsersCountResponse,
  DefaultArg
> = gql`
  query GetReferralUsersCount {
    getReferralUsersCount
  }
`;
