import styled from 'styled-components';

type IconWrapperProps = { color: string };
export const IconWrapper = styled.div<IconWrapperProps>`
  width: 4rem;
  height: 4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
