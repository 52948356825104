import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const DepositIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId = 'depositIcon',
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.32031 11.6797L11.8803 14.2397L14.4403 11.6797"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.88 4V14.17"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12.1797C20 16.5997 17 20.1797 12 20.1797C7 20.1797 4 16.5997 4 12.1797"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};
export default DepositIcon;
