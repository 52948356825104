import { useTheme } from 'styled-components';

import { NegativeIcon, PositiveIcon, Typography } from '@libs/components';
import { formatPrice } from '@libs/helpers';
import { CurrencyType } from '@libs/types';

import { FavoriteAssetIndicator } from 'src/components';
import { useTranslation } from 'src/hooks';
import { getUsdOrEurCurrencySymbol } from 'src/helpers';
import { AssetPriceWrapper, ChangesWrapper } from '../styles';
import { AssetCurrentPriceWrapper, AssetChangeRate } from './styles';

type AssetCurrentPriceProps = {
  rate?: string;
  price?: string;
  assetCode?: CurrencyType;
  isFavorite?: boolean;
};
export const AssetCurrentPrice: React.FC<AssetCurrentPriceProps> = ({
  rate,
  price,
  assetCode,
  isFavorite = false,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const day = t('time_one_day');

  const RatePrefix = rate?.includes('-') ? <NegativeIcon /> : <PositiveIcon />;

  const assetPrice = formatPrice(Number(price));

  const AssetRate = rate?.replace('-', '') + ` (${day})`;
  const AssetRateColor = rate?.includes('-')
    ? colors.text.critical
    : colors.text.success;

  return (
    <AssetCurrentPriceWrapper>
      <Typography type="bodyButton" fontFamily="primaryMedium">
        {t('table_price')}
      </Typography>
      <AssetPriceWrapper>
        <Typography type="titleOne" fontFamily="primaryBold">
          {getUsdOrEurCurrencySymbol()}
          {assetPrice}
        </Typography>
        <ChangesWrapper>
          {RatePrefix}
          <AssetChangeRate
            fontFamily="primaryBold"
            type="subHeadline"
            color={AssetRateColor}
          >
            {AssetRate}
          </AssetChangeRate>
        </ChangesWrapper>
      </AssetPriceWrapper>
      {assetCode && (
        <FavoriteAssetIndicator
          shouldHaveLabel={true}
          isFavorite={isFavorite}
          assetCode={assetCode}
        />
      )}
    </AssetCurrentPriceWrapper>
  );
};
