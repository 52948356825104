import { useTheme } from 'styled-components';

import { Button, Input, Typography } from '@libs/components';
import { ResultFeedbackType } from '@libs/types';

import { useTranslation } from 'src/hooks';

import ResultFeedback from '../result-feedback/ResultFeedback';
import { useEmailTwoFa } from './hooks/useEmailTwoFa';
import {
  CustomBtnCont,
  CustomForm,
  CustomInputCont,
  MutationErrorContn,
} from './styles';

type EmailTwoFaProps = {
  isGoogleEnabled: boolean;
  isEmailEnabled: boolean;
  onClose: () => void;
};

const EmailTwoFa: React.FC<EmailTwoFaProps> = ({
  onClose,
  isGoogleEnabled,
  isEmailEnabled,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const {
    handleEmailSend,
    values,
    touched,
    errors,
    setFieldValue,
    isEmailActivated,
    countDown,
    isLoading,
    errorMessage,
    handleSubmit,
    isCounterRunning,
  } = useEmailTwoFa(isEmailEnabled);

  const handleFieldChange = (field: string) => (value: string) =>
    setFieldValue(field, value);

  const sendEmailCodeBtnText = isCounterRunning
    ? `${countDown}`
    : t('input_button');

  if (isEmailActivated) {
    return (
      <ResultFeedback
        onClick={onClose}
        type={ResultFeedbackType.SUCCESS}
        description={t(
          isEmailEnabled
            ? 'success_turnoff_email_2fa'
            : 'success_turnon_email_2fa'
        )}
      />
    );
  }

  return (
    <CustomForm onSubmit={handleSubmit}>
      <CustomInputCont data-testid="twoFaInputCont">
        <Input
          onClick={handleEmailSend}
          type="number"
          pattern="[0-9]{6}"
          name="emailTwoFactorCode"
          onChangeText={handleFieldChange('emailCode')}
          value={values.emailCode || ''}
          placeholder={t('google_2fa_email_input_placeholder')}
          label={t('two_fa_email_input_label')}
          errorMessage={touched.emailCode ? t(errors.emailCode) : ''}
          valid={!isCounterRunning}
          sendCode={sendEmailCodeBtnText}
        />
      </CustomInputCont>

      {isGoogleEnabled && (
        <CustomInputCont>
          <Input
            type="number"
            pattern="[0-9]{6}"
            name="googleTwoFactorCode"
            testId="googleTwoFaInput"
            onChangeText={handleFieldChange('googleCode')}
            value={values.googleCode || ''}
            placeholder={t('google_2fa_input_placeholder')}
            label={t('google_2fa_scan_qr_input_label')}
            errorMessage={touched.googleCode ? t(errors.googleCode) : ''}
          />
        </CustomInputCont>
      )}
      <CustomBtnCont>
        <MutationErrorContn>
          <Typography
            fontSize={1.4}
            lineHeight={2.1}
            color={colors.text.critical}
            fontFamily="primary"
          >
            {t(errorMessage)}
          </Typography>
        </MutationErrorContn>
        <Button testId="googleTwoFaBtn" type="submit" isLoading={isLoading}>
          {t(isEmailEnabled ? 'deactivate' : 'activate')}
        </Button>
      </CustomBtnCont>
    </CustomForm>
  );
};

export default EmailTwoFa;
