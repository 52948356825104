import { FC, PropsWithChildren } from 'react';

import { IconWrapper } from './styles';

type CircledIconProps = PropsWithChildren & {
  color: string;
  className?: string;
  testId?: string;
};

const CircledIcon: FC<CircledIconProps> = ({
  color,
  children,
  className,
  testId = 'circle-icon-wrapper',
}) => {
  return (
    <IconWrapper data-cy={testId} color={color} className={className}>
      {children}
    </IconWrapper>
  );
};

export default CircledIcon;
