import styled from 'styled-components';

import { AuthHeader } from '@libs/components';
import { DISCLAIMER_HEIGHT } from 'src/constants';

export const ThemeTogglerWrapper = styled.div`
  ${({ theme }) => theme.media.tablet`
    margin-left: 1.6rem;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

type CustomAuthHeaderProps = {
  isJurisdictionUa?: boolean;
  isAppDisclaimerVisible?: boolean;
};

export const CustomAuthHeader = styled(AuthHeader)<CustomAuthHeaderProps>`
  .header-button {
    padding: 0 2rem;
    color: ${({ theme }) => theme.colors.interactive.brandColor};
    display: ${({ isJurisdictionUa }) => (isJurisdictionUa ? 'none' : 'block')};
    top: ${({ isAppDisclaimerVisible }) =>
      isAppDisclaimerVisible ? `${DISCLAIMER_HEIGHT}rem` : '0'};
    ${({ theme }) => theme.media.tablet`
    padding: 0 4rem;
    display: block;
  `};
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2.2rem 1.6rem 0 1.6rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  ${({ theme }) => theme.media.tabletL`
    padding: 0;
    justify-content: center;
    padding-top: unset;
    background: none;
  `}
`;

export const TitleAndContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 42.6rem;

  ${({ theme }) => theme.media.tabletL`
    padding: 4rem 3.7rem;
    margin: 2rem 0;

    border-radius: ${
      theme.borderRadius.mediumHigh as unknown as TemplateStringsArray
    };

    background-color: ${
      theme.colors.surface.default as unknown as TemplateStringsArray
    };

    box-shadow: ${theme.shadows.sm as unknown as TemplateStringsArray};
  `}
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
`;

type BannerProps = {
  BannerBackground: string;
};

export const BannerForEu = styled.div<BannerProps>`
  display: none;

  ${({ theme, BannerBackground }) => theme.media.tabletL`
    display: block;
    flex: 1;
    background-size: cover;
    background-image: url(${
      BannerBackground as unknown as TemplateStringsArray
    });
  `}
`;

export const BannerFOrGe = styled.div<BannerProps>`
  display: none;

  ${({ theme, BannerBackground }) => theme.media.tabletL`
   display: block;
    flex: 1;

    background: 
        url(${
          BannerBackground as unknown as TemplateStringsArray
        }) no-repeat center,
        linear-gradient(47deg, rgba(213, 231, 255, 0.8) 0%, rgba(234, 252, 255, 0.8) 100%);
    background-size: contain, cover;

  `}
`;
