import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const ActiveOperationsIcon: React.FC<SvgIconDefaultProps> = () => {
  return (
    <CustomSvg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 15.2197C23.4142 15.2197 23.75 15.5555 23.75 15.9697C23.75 20.2539 20.2842 23.7197 16 23.7197C15.7298 23.7197 15.4805 23.5744 15.3474 23.3393C15.2142 23.1041 15.2179 22.8156 15.3569 22.5839L16.4069 20.8339C16.62 20.4787 17.0807 20.3635 17.4359 20.5766C17.7911 20.7897 17.9062 21.2504 17.6931 21.6056L17.4218 22.0578C20.1909 21.4154 22.25 18.9363 22.25 15.9697C22.25 15.5555 22.5858 15.2197 23 15.2197Z"
        fill="#506BF9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5782 1.88166C3.8091 2.5241 1.75 5.00315 1.75 7.96973C1.75 8.38394 1.41421 8.71973 1 8.71973C0.585786 8.71973 0.25 8.38394 0.25 7.96973C0.25 3.68551 3.71579 0.219727 8 0.219727C8.2702 0.219727 8.51952 0.365071 8.65265 0.600202C8.78578 0.835334 8.78214 1.1239 8.64312 1.3556L7.59312 3.1056C7.38001 3.46078 6.91931 3.57596 6.56413 3.36285C6.20894 3.14974 6.09377 2.68904 6.30688 2.33385L6.5782 1.88166Z"
        fill="#506BF9"
      />
      <path
        d="M14.85 15.33C14.85 18.74 12.09 21.5 8.68001 21.5C5.27001 21.5 2.51001 18.74 2.51001 15.33C2.51001 11.92 5.27001 9.15997 8.68001 9.15997C8.84001 9.15997 8.99001 9.16999 9.16001 9.17999C12.19 9.40999 14.61 11.83 14.84 14.86C14.84 15.01 14.85 15.16 14.85 15.33Z"
        fill="#506BF9"
      />
      <path
        d="M21.4999 8.66998C21.4999 12.08 18.7399 14.84 15.3299 14.84H14.8399C14.6099 11.81 12.1899 9.38997 9.15991 9.15997V8.66998C9.15991 5.25998 11.9199 2.5 15.3299 2.5C18.7399 2.5 21.4999 5.25998 21.4999 8.66998Z"
        fill="#233057"
      />
      <path
        d="M11.1201 15.01C11.2401 14.76 11.3101 14.49 11.3101 14.2C11.3101 13.2 10.4301 12.32 9.43012 12.32H9.17012V11.94C9.17012 11.53 8.83012 11.19 8.42012 11.19C8.01012 11.19 7.67012 11.53 7.67012 11.94V12.32H6.62012C6.21012 12.32 5.87012 12.66 5.87012 13.07V15.32V17.57C5.87012 17.98 6.21012 18.32 6.62012 18.32H7.67012V18.7C7.67012 19.11 8.01012 19.45 8.42012 19.45C8.83012 19.45 9.17012 19.11 9.17012 18.7V18.32H9.83012C10.9501 18.32 11.8701 17.48 11.8701 16.44C11.8701 15.87 11.5701 15.35 11.1201 15.01ZM7.37012 13.82H9.43012C9.64012 13.82 9.81012 14.05 9.81012 14.2C9.81012 14.41 9.64012 14.58 9.43012 14.58H7.37012V13.82ZM9.83012 16.83H7.36012V16.08H9.42012H9.82012C10.1401 16.08 10.3601 16.28 10.3601 16.46C10.3601 16.64 10.1501 16.83 9.83012 16.83Z"
        fill="white"
      />
    </CustomSvg>
  );
};
export default ActiveOperationsIcon;
