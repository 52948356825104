import { useMemo } from 'react';

import {
  TwoFactorAuth as NativeTwoFactorAuth,
  TwoFactorAuthProps as NativeTwoFactorAuthProps,
} from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';
import { useTwoFaDetection } from '@libs/hooks';

import { useTranslation } from 'src/hooks';

type TwoFactorAuthProps = Omit<
  NativeTwoFactorAuthProps,
  | 'resendText'
  | 'description'
  | 'resendSmsCountdownText'
  | 'resendEmailCountdownText'
  | 'incorrectCodeErrMessage'
> & {
  phone?: string;
};
const TwoFactorAuth: React.FC<TwoFactorAuthProps> = ({
  type,
  phone,
  ...props
}) => {
  const { t } = useTranslation();
  const { decodedToken } = useTwoFaDetection();

  const twoFaData = useMemo(() => {
    if (type === TwoFactorAuthType.EMAIL) {
      return t('two_fa_email_desc', {
        email: decodedToken?.email || '',
      });
    } else if (type === TwoFactorAuthType.SMS) {
      return t('two_fa_sms_desc', {
        lastDigits: phone || decodedToken?.phone || '',
      });
    }
    return t('two_fa_google_desc');
  }, [decodedToken?.email, decodedToken?.phone, phone, t, type]);

  return (
    <NativeTwoFactorAuth
      description={twoFaData}
      resendSmsCountdownText={t('two_fa_sms_resend_countdown')}
      resendEmailCountdownText={t('two_fa_email_resend_countdown')}
      resendText={t('two_fa_resend')}
      incorrectCodeErrMessage={t('two_fa_invalid_code_error')}
      type={type}
      {...props}
    />
  );
};

export default TwoFactorAuth;
