import styled from 'styled-components';

export const CustomAssetTitle = styled.div`
  margin: 2rem 3.7rem 1.2rem;
`;

export const CustomAssetItemCont = styled.div`
  margin: 0 2.2rem 1.2rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.media.tablet`
    margin: 0 3.6rem 1.2rem;
  `}
`;

export const CustomAssetCont = styled.div`
  height: 100%;

  ${({ theme }) => theme.media.tablet`
    height: initial;
    margin-bottom: 1.6rem;
  `}
`;
export const CustomSearchInputCont = styled.div`
  margin: 0 2.2rem 1.2rem;

  ${({ theme }) => theme.media.tablet`
    margin: 0 3.6rem 1.2rem;
  `}
`;
