import React from 'react';

import { Typography } from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';

import { useTranslation } from 'src/hooks';

import TwoFaAuth from '../two-fa-auth/TwoFaAuth';

import { Container } from './styles';

type ExcludedTwoFa = Exclude<TwoFactorAuthType, TwoFactorAuthType.GOOGLE>;

type CompleteRegistrationProps = {
  handleAuthComplete: (twoFaType: TwoFactorAuthType, val: string) => void;
  authAddress?: string;
  type: ExcludedTwoFa;
  title?: string;
  onCloseIconClick?: () => void;
  errorMessage?: string;
  onResend: () => void;
  loading: boolean;
};

export const CompleteRegistration: React.FC<CompleteRegistrationProps> = ({
  handleAuthComplete,
  type,
  authAddress,
  title,
  onCloseIconClick,
  errorMessage,
  onResend,
  loading,
}) => {
  const { t } = useTranslation();

  const text =
    type === TwoFactorAuthType.EMAIL
      ? t('register_to_compete_enter_email_code')
      : t('register_to_compete_enter_sms_code');

  const desc = (
    <div>
      <Typography type="subHeadline">{text} </Typography>
      <Typography type="subHeadline" fontFamily="primaryBold">
        {authAddress}
      </Typography>
      <Typography type="subHeadline"> {t('register_one_time_code')}</Typography>
    </div>
  );

  return (
    <Container>
      <TwoFaAuth
        type={type}
        description={desc}
        onComplete={handleAuthComplete}
        title={title}
        onCloseIconClick={onCloseIconClick}
        errorMessage={errorMessage}
        onResend={onResend}
        loading={loading}
      />
    </Container>
  );
};

export default CompleteRegistration;
