import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const StakingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  z-index: ${({ theme }) => theme.zIndex.medium};

  padding: 1.8rem 1.6rem 2.1rem;

  ${({ theme }) => theme.media.tablet`
      padding: 1.9rem 3rem 2.9rem;
  `}
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 25rem;

  padding: 1.7rem 1.2rem 1.4rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
      flex-direction: row;

      height: 14.75rem;

      padding: 0;

      border: none;

      background-color: ${
        theme.colors.surface.default as unknown as TemplateStringsArray
      };
  `}
`;

export const AssetIconLoader = styled.circle`
  cx: 1.7rem;
  cy: 1.7rem;

  r: 1.7rem;

  ${({ theme }) => theme.media.tablet`
    r: 1.9rem;

    cx: 1.9rem;
    cy: 1.9rem;
`};
`;

export const AssetTitleLoader = styled.rect`
  x: 0;
  y: 5rem;

  width: 12rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    y: 6.2rem;
  `}
`;

export const AssetDescLoader = styled.rect`
  x: 0;
  y: 7.5rem;

  width: 8rem;
  height: 2.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    y: 8.6rem;

    height: 3.2rem;
    width: 12rem;
  `}
`;

export const MainLoader = styled(ContentLoader)`
  padding: 1.4rem 0;
  margin-bottom: 1.1rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.surface.disabled};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${AssetIconLoader} {
    cx: calc(50% - 6.3rem);
    cy: 2.1rem;
  }

  ${AssetTitleLoader} {
    x: calc(50% - 3.5rem);
    y: 0;
  }

  ${AssetDescLoader} {
    x: calc(50% - 3.5rem);
    y: 50%;
  }

  ${({ theme }) => theme.media.tablet`
      width: calc(33% - 0.3rem);
      
      margin-right: 2.3rem;
      margin-bottom: 0;
      padding: 1.7rem 1.7rem 1.3rem;

      background-color: ${
        theme.colors.surface
          .interactiveDefault as unknown as TemplateStringsArray
      };

      ${AssetIconLoader as unknown as TemplateStringsArray} {
        cx: 1.9rem;
        cy: 1.9rem;
      }

      ${AssetTitleLoader as unknown as TemplateStringsArray} {
        x: 0;
        y: 6.2rem;
      }

      ${AssetDescLoader as unknown as TemplateStringsArray} {
        x: 0;
        y: 8.6rem;
      }
  `}
`;

export const SecondaryContentWrapper = styled.div`
  display: flex;

  height: 12.8rem;

  ${({ theme }) => theme.media.tablet`
      width: 66%;
      height: initial;
  `}
`;

export const SecondaryLoader = styled(ContentLoader)`
  width: 50%;

  padding: 1.7rem 1.7rem 1.3rem;
  margin-right: 0.9rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.surface.disabled};

  background-color: ${({ theme }) => theme.colors.surface.default};

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => theme.media.tablet`
      width: 100%;

      margin-right: 2.3rem;
      

      background-color: ${
        theme.colors.surface
          .interactiveDefault as unknown as TemplateStringsArray
      };

      &:last-child {
        margin-right: 0;
      }
  `}
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  ${({ theme }) => theme.media.tablet`
      flex-direction: row;

      margin-top: 2.3rem;
  `}
`;

export const ActionLoader = styled(ContentLoader)`
  width: 100%;
  height: 19.2rem;

  margin-top: 1.5rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.tablet`
      width: calc(50% - 2rem);
      height: 19.3rem;

      margin-right: 1.4rem;
      margin-top: 0;
      padding: 1rem 2.5rem;

      &:last-child {
        margin-right: 0;

        width: calc(50% + 0.6rem);
      }
  `}
`;

export const ToggleLoader = styled.rect`
  height: 3.4rem;
  width: 6.4rem;

  x: 2.9rem;
  y: calc(50% - 1.7rem);

  rx: ${({ theme }) => theme.borderRadius.high};
  ry: ${({ theme }) => theme.borderRadius.high};

  ${({ theme }) => theme.media.tablet`
    x: calc(100% - 6.4rem);
    y: calc(50% - 1.7rem);
  `}
`;

export const ToggleTitle = styled.rect`
  height: 3rem;
  width: 18rem;

  x: 32%;
  y: 1rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: 0;
    y: 0.5rem;
  `}
`;

export const RewardTitle = styled.rect`
  height: 2.4rem;
  width: min(20rem, 40%);

  x: 1.8rem;
  y: calc(50% - 2.8rem);

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const RewardDesc = styled.rect`
  height: 3.2rem;
  width: min(18rem, 40%);

  x: 1.8rem;
  y: calc(50% - 0.2rem);

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const RewardButton = styled.rect`
  height: 4rem;
  width: 12rem;

  x: calc(100% - 14.1rem);
  y: calc(50% - 2rem);

  rx: 2rem;
  ry: 2rem;
`;

export const ToggleDescOne = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 4.2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;
  
    x: 0;
    y: 3.8rem;
  `}
`;

export const ToggleDescTwo = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 5.8rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;

    x: 0;
    y: 5.6rem;
  `}
`;

export const ToggleDescThree = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 7.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;

    x: 0;
    y: 7.4rem;
  `}
`;

export const ToggleDescFour = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 9rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;

    x: 0;
    y: 9.2rem;
  `}
`;

export const ToggleDescFive = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 10.6rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;

    x: 0;
    y: 11rem;
  `}
`;

export const ToggleDescSix = styled.rect`
  height: 1.4rem;
  width: 60%;

  x: 32%;
  y: 12.2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 32rem;
    
    x: 0;
    y: 12.8rem;
  `}
`;
