import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const MessageQuestionIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const colorToApply = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke={colorToApply}
        opacity="0.4"
        d="M17 18.43H13L8.54999 21.39C7.88999 21.83 7 21.36 7 20.56V18.43C4 18.43 2 16.43 2 13.43V7.42999C2 4.42999 4 2.42999 7 2.42999H17C20 2.42999 22 4.42999 22 7.42999V13.43C22 16.43 20 18.43 17 18.43Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        d="M12.0001 12.11C11.5901 12.11 11.2501 11.77 11.2501 11.36V11.15C11.2501 9.99002 12.1 9.42001 12.42 9.20001C12.79 8.95001 12.91 8.78002 12.91 8.52002C12.91 8.02002 12.5001 7.60999 12.0001 7.60999C11.5001 7.60999 11.0901 8.02002 11.0901 8.52002C11.0901 8.93002 10.7501 9.27002 10.3401 9.27002C9.93009 9.27002 9.59009 8.93002 9.59009 8.52002C9.59009 7.19002 10.6701 6.10999 12.0001 6.10999C13.3301 6.10999 14.41 7.19002 14.41 8.52002C14.41 9.66002 13.5701 10.23 13.2601 10.44C12.8701 10.7 12.7501 10.87 12.7501 11.15V11.36C12.7501 11.78 12.4101 12.11 12.0001 12.11Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        d="M12 14.6C11.58 14.6 11.25 14.26 11.25 13.85C11.25 13.44 11.59 13.1 12 13.1C12.41 13.1 12.75 13.44 12.75 13.85C12.75 14.26 12.42 14.6 12 14.6Z"
        fill="#286AF6"
      />
    </CustomSvg>
  );
};
export default MessageQuestionIcon;
