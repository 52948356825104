import { useMemo, useState } from 'react';

import {
  AMOUNT_OF_DAYS_IN_MONTH,
  AMOUNT_OF_DAYS_IN_WEEK,
  ONE_DAY,
} from '@libs/types';

import { HistoryPeriodEnumKeys } from 'src/gql';
import { useTranslation } from 'src/hooks';

import { ExchangeTabItem } from 'src/types';
import { formatChartDate } from 'src/helpers';

export const useAssetDetailsTabsOptions = () => {
  const { t } = useTranslation();

  const tabItems: ExchangeTabItem[] = useMemo(() => {
    return [
      {
        id: 0,
        amountOfDays: ONE_DAY,
        dayEnum: HistoryPeriodEnumKeys.ONE_DAY,
        dateFormat: 'h:mmA',
        title: t('time_24_hours'),
      },
      {
        id: 1,
        dayEnum: HistoryPeriodEnumKeys.ONE_WEEK,
        amountOfDays: AMOUNT_OF_DAYS_IN_WEEK,
        dateFormat: formatChartDate,
        title: t('time_one_week'),
      },
      {
        id: 2,
        dayEnum: HistoryPeriodEnumKeys.ONE_MONTH,
        amountOfDays: AMOUNT_OF_DAYS_IN_MONTH,
        dateFormat: formatChartDate,
        title: t('time_one_month'),
      },
    ];
  }, [t]);

  const [activeTabObject, setActiveTabObject] = useState<ExchangeTabItem>(
    tabItems[0]
  );

  return { activeTabObject, tabItems, setActiveTabObject };
};
