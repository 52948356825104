import { useTheme } from 'styled-components';
import {
  SwapHistoryContainer,
  HeaderLoader,
  DateTitle,
  Date,
  DateTime,
  ExchangeId,
  ExchangeIdTitle,
  WhereFromTitle,
  WhereFromAsset,
  WhereFromAmount,
  WhereToTitle,
  WhereToAsset,
  WhereToAmount,
  ExchangeRateTitle,
  ExchangeRate,
  StatusTitle,
  Status,
  HeaderLine,
  BodyLoaderWrapper,
  BodyLine,
} from './styles';

export const SwapHistoryLoader = () => {
  const { colors } = useTheme();
  return (
    <SwapHistoryContainer>
      <HeaderLoader
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <ExchangeIdTitle />
        <DateTitle />
        <WhereFromTitle />
        <WhereToTitle />
        <ExchangeRateTitle />
        <StatusTitle />
        <HeaderLine />
      </HeaderLoader>
      {[...Array(3)].map((item, i) => {
        return (
          <BodyLoaderWrapper
            key={i}
            backgroundColor={colors.surface.interactiveDefault}
            foregroundColor={colors.surface.pressed}
          >
            <ExchangeId />
            <Date />
            <DateTime />
            <WhereFromAsset />
            <WhereFromAmount />
            <WhereToAsset />
            <WhereToAmount />
            <ExchangeRate />
            <Status />
            <BodyLine />
          </BodyLoaderWrapper>
        );
      })}
    </SwapHistoryContainer>
  );
};
