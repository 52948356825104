import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

import { CurrencyType } from 'src/constants';

export type AddNewCardArgs = {
  assetCode: CurrencyType;
};

export type AddNewCardResponse = {
  addNewCard: DefaultApiResponse<{ redirectUrl: string }>;
};

export const addNewCardMutation: TypedDocumentNode<
  AddNewCardResponse,
  DefaultArg<AddNewCardArgs>
> = gql`
  mutation AddNewCard($record: AddNewCardRecord!) {
    addNewCard(record: $record) {
      redirectUrl
    }
  }
`;
