import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { WalletTypeEnum } from '@libs/backend-common';
import { useApiResponse } from '@libs/hooks';

import { getWalletInfo } from 'src/gql';
import { ExchangeBalanceMap } from 'src/types';

export const useExchangeBalance = () => {
  const walletInfoRes = useQuery(getWalletInfo, {
    variables: {
      record: {
        walletType: WalletTypeEnum.ALL_ASSETS,
      },
    },
  });

  const { response } = useApiResponse(walletInfoRes);

  const assetCodeBalanceMap: ExchangeBalanceMap = useMemo(() => {
    if (!response?.getWalletInfo) return {};

    return response.getWalletInfo.reduce((acc, { amount, code, value }) => {
      return {
        ...acc,
        [code]: {
          amount: +amount,
          value: +value,
        },
      };
    }, {} as ExchangeBalanceMap);
  }, [response]);

  return assetCodeBalanceMap;
};
