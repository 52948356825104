import styled from 'styled-components';

type ContainerProps = { isFilled: boolean };
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  border-radius: 1.4rem;
  padding: 1.4rem 1rem 2rem 2rem;
  background-color: ${({ isFilled, theme }) =>
    isFilled
      ? theme.colors.surface.interactiveDefault
      : theme.colors.surface.default};
  ${({ theme }) => theme.media.tablet`
    padding: 1.7rem 1.6rem 2.7rem 3.3rem;
  `};
`;

export const TextWrapper = styled.div`
  flex: 1;
  margin-left: 3rem;
`;
