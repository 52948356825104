import { AuthHeader } from '@libs/components';

import {
  bitnetLogo,
  bitnetLogoSmall,
  CrexoneLogo,
  CrexoneLogoBlack,
  CrexoneMobileLogo,
} from 'src/assets';
import {
  useJurisdictionDetector,
  useRenderObjectByTheme,
  useTranslation,
} from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import PageHeading from '../page-heading/PageHeading';
import { AuthContentContainer } from '../auth-content-container/AuthContentContainer';
import { Banner, BannerWrapper, Container, TitleWrapper } from './styles';

type AuthPageLayoutProps = React.PropsWithChildren & {
  banner?: string;
  heading: string;
  className?: string;
  isHeadingCenter?: boolean;
  headerButtonTitle?: string;
  isHeadingPrimary?: boolean;
  highlightedHeading?: string;
  onHeaderButtonClick?: () => void;
  hasHeader?: boolean;
  shouldHeaderStick?: boolean;
};

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  banner,
  heading,
  children,
  className,
  headerButtonTitle,
  highlightedHeading,
  onHeaderButtonClick,
  isHeadingPrimary = true,
  isHeadingCenter = false,
  hasHeader = true,
  shouldHeaderStick = true,
}) => {
  const { changeLanguage, selectedLanguageCode } = useTranslation();

  const { currentJurisdiction } = useJurisdictionDetector();
  const IS_GLOBAL_JURISDICTION = currentJurisdiction === JurisdictionsEnum.UA;

  const crexonelogo = useRenderObjectByTheme(CrexoneLogoBlack, CrexoneLogo);

  return (
    <>
      {hasHeader && (
        <AuthHeader
          jurisdictionId={currentJurisdiction}
          changeLanguage={changeLanguage}
          shouldStick={shouldHeaderStick}
          selectedLanguage={selectedLanguageCode}
          logo={IS_GLOBAL_JURISDICTION ? crexonelogo : bitnetLogo}
          mobileLogo={
            IS_GLOBAL_JURISDICTION ? CrexoneMobileLogo : bitnetLogoSmall
          }
          onButtonClick={onHeaderButtonClick}
          buttonTitle={headerButtonTitle}
        />
      )}
      <Container
        hasBanner={!!banner}
        className="auth-container"
        shouldHeaderStick={shouldHeaderStick}
      >
        {!!banner && (
          <BannerWrapper className="auth-banner">
            <Banner src={banner} alt="Banner" />
          </BannerWrapper>
        )}

        <AuthContentContainer className={className}>
          <TitleWrapper isHeadingCenter={isHeadingCenter}>
            <PageHeading
              text={heading}
              isPrimary={isHeadingPrimary}
              highlightedText={highlightedHeading}
            />
          </TitleWrapper>

          {children}
        </AuthContentContainer>
      </Container>
    </>
  );
};
export default AuthPageLayout;
