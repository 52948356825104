import { gql, TypedDocumentNode } from '@apollo/client';
import { BogPaymentStatusEnum } from '@libs/backend-common';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetBogPaymentRes = {
  getBogPayment: DefaultApiResponse<{
    amount: number;
    id: number | string;
    status: BogPaymentStatusEnum;
  }>;
};

export const getBogPaymentQuery: TypedDocumentNode<
  GetBogPaymentRes,
  DefaultArg<{ paymentId: string }>
> = gql`
  query GetBogPayment($record: GetPaymentRecord!) {
    getBogPayment(record: $record) {
      id
      amount
      status
    }
  }
`;
