import { useEffect, useMemo, useState } from 'react';

import {
  useAuth,
  useTwoFa,
  TwoFactorCodeParams,
  useTranslation,
} from 'src/hooks';
import { TwoFactorAuthType } from 'src/constants';
import { detectIfHasNoTwoFa, detectTwoFaTypeFromToken } from 'src/helpers';

import { CustomTwoFaAuth } from './styles';

type AuthHandler = (token: string, codes: TwoFactorCodeParams) => void;
type TwoFaProps = {
  title?: string;
  forcedSmsAuth?: boolean;
  forceEmailAuth?: boolean;
  onModalClose?: () => void;
  className?: string;
  loading?: boolean;
  onAuthFinish?: AuthHandler;
  shouldAutomaticallySendSms?: boolean;
  shouldAutomaticallySendEmail?: boolean;
};

const TwoFa: React.FC<TwoFaProps> = ({
  onAuthFinish,
  loading,
  forcedSmsAuth = false,
  forceEmailAuth = false,
  shouldAutomaticallySendSms = true,
  shouldAutomaticallySendEmail = true,
  title,
  onModalClose,
}) => {
  const { t } = useTranslation();
  const { decodeToken } = useAuth();
  const decodedToken = decodeToken();

  const hasNoactiveTwoFa = useMemo(
    () => detectIfHasNoTwoFa(decodedToken),
    [decodedToken]
  );

  const [faType, setFaType] = useState<TwoFactorAuthType | undefined>(() => {
    if (forcedSmsAuth) return TwoFactorAuthType.SMS;
    if (forceEmailAuth) return TwoFactorAuthType.EMAIL;

    return detectTwoFaTypeFromToken(decodedToken);
  });

  const {
    handleTwoFaLogin,
    sendCode,
    errorMessage,
    loading: twoFaLoading,
  } = useTwoFa(
    faType,
    setFaType,
    onAuthFinish,
    shouldAutomaticallySendSms,
    shouldAutomaticallySendEmail
  );

  useEffect(() => {
    if (!hasNoactiveTwoFa) return;

    onAuthFinish?.('', {
      twoFactorCode: '',
      googleTwoFactorCode: '',
      emailTwoFactorCode: '',
    });
  }, [onAuthFinish, hasNoactiveTwoFa]);

  if (hasNoactiveTwoFa) return null;

  return (
    <>
      {faType && (
        <CustomTwoFaAuth
          type={faType}
          title={title}
          loading={loading || twoFaLoading}
          onResend={sendCode}
          onComplete={handleTwoFaLogin}
          onCloseIconClick={onModalClose}
          errorMessage={t(errorMessage)}
        />
      )}
    </>
  );
};

export default TwoFa;
