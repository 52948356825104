import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { Asset, CurrencyType } from '@libs/types';
import { DefaultApiResponse, DefaultArg, assetFragment } from '@libs/graphql';

export type CreateLoanRequestValue = {
  details: {
    loanAmount: number;
    collateralAmount: number;
    interestRate: number;
    minimumInterest: number;
    initialLtv: number;
    liquidationLtv: number;
    marginCallLtv: number;
    interestPerMinute: number;
    collateralAssetPrice: number;
    minLoanAmount: number;
  };
  loan: {
    id: number;
    loanAsset: Asset;
    collateralAsset: Asset;
  };
};
export type CreateLoanRequestArgs = {
  collateralAssetCode: CurrencyType;
  loanAssetCode: CurrencyType;
  originalAmount: number;
  originalAmountAssetCode: CurrencyType;
  loanTypeId: number;
};

export type CreateLoanRequestResponse = {
  createLoanRequest: DefaultApiResponse<CreateLoanRequestValue>;
};

export const createLoanRequestMutation: TypedDocumentNode<
  CreateLoanRequestResponse,
  DefaultArg<CreateLoanRequestArgs>
> = gql`
  mutation CreateLoanRequest($record: CalculateLoanValuesRecord!) {
    createLoanRequest(record: $record) {
      details {
        loanAmount
        collateralAmount
        interestRate
        minimumInterest
        initialLtv
        liquidationLtv
        marginCallLtv
        interestPerMinute
        collateralAssetPrice
      }
      loan {
        id
        loanAsset {
          ...assetFields
        }
        collateralAsset {
          ...assetFields
        }
      }
    }
  }

  ${assetFragment}
`;
