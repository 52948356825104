import { useTheme } from 'styled-components';
import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const FeesIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const colorToApply = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke={colorToApply}
        d="M22 5.99999V8.41999C22 9.99999 21 11 19.42 11H16V4.00999C16 2.89999 16.91 1.98999 18.02 1.99999C19.11 2.00999 20.11 2.44999 20.83 3.16999C21.55 3.89999 22 4.89999 22 5.99999Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        opacity="0.4"
        d="M2 7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        d="M11.4299 12.08C10.8799 12.08 10.4299 12.53 10.4299 13.08C10.4299 13.63 10.8799 14.08 11.4299 14.08C11.9799 14.08 12.4299 13.63 12.4299 13.08C12.4299 12.53 11.9799 12.08 11.4299 12.08Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        d="M6.57007 10.03C7.13007 10.03 7.57007 9.58 7.57007 9.03C7.57007 8.48 7.12007 8.03 6.57007 8.03C6.02007 8.03 5.57007 8.48 5.57007 9.03C5.57007 9.58 6.02007 10.03 6.57007 10.03Z"
        fill="#286AF6"
      />
      <path
        stroke={colorToApply}
        d="M12.26 7.73999C11.97 7.44999 11.49 7.44999 11.2 7.73999L5.73996 13.2C5.44996 13.49 5.44996 13.97 5.73996 14.26C5.88996 14.41 6.07996 14.48 6.26996 14.48C6.45996 14.48 6.64996 14.41 6.79996 14.26L12.26 8.79999C12.55 8.50999 12.55 8.02999 12.26 7.73999Z"
        fill="#286AF6"
      />
    </CustomSvg>
  );
};
export default FeesIcon;
