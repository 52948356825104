import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

type VerifyResetPasswordMutationProps = {
  code: string;
  emailOrPhone: string;
};

export type VerifyResetPasswordMutationRes = {
  verifyResetPasswordCode: string;
};

export const verifyResetPasswordMutation: TypedDocumentNode<
  DefaultApiResponse<VerifyResetPasswordMutationRes>,
  DefaultArg<VerifyResetPasswordMutationProps>
> = gql`
  mutation VerifyResetPasswordCode($record: ResetPasswordCodeRecord!) {
    verifyResetPasswordCode(record: $record)
  }
`;
