import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type BankRequisites = {
  iban?: string;
  bankAddress: string;
  bankCode: string;
  bankName: string;
  recipientName: string;
  reference: string;
  visibleFields: string[];
  address: string;
};

export type GetBitnetBankRequisiteArgs = DefaultArg<{ assetCode: string }>;

export type GetBitnetBankRequisiteResponseType = {
  getBitnetBankRequisites: DefaultApiResponse<BankRequisites[]>;
};
export const getBitnetBankRequisitesQuery: TypedDocumentNode<
  GetBitnetBankRequisiteResponseType,
  GetBitnetBankRequisiteArgs
> = gql`
  query GetBitnetBankRequisites($record: GetBitnetBankRequisiteRecord!) {
    getBitnetBankRequisites(record: $record) {
      recipientName
      bankAddress
      bankName
      iban
      bankCode
      reference
      visibleFields
      address
    }
  }
`;
