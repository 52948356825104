import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { TransparentButton } from '@libs/components';

import { DepositIcon } from 'src/assets';

import CircledIcon from '../circled-icon/CircledIcon';

type NavType = {
  isOpen: boolean;
};

export const MobileNavContainer = styled.div<NavType>`
  display: flex;
  align-items: start;

  position: fixed;
  left: 0;
  bottom: -0.1rem;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};

  padding: 1.2rem 0 2rem;

  background-color: ${({ theme }) => theme.colors.surface.default};
  z-index: ${({ isOpen, theme }) =>
    isOpen ? `${theme.zIndex.highest + 1}` : `${theme.zIndex.high - 1}`};

  ${({ theme }) => theme.media.laptop`
    display: none;
  `};
`;

export const NavItemWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 100%;

  margin-top: 0.1rem;
`;

export const ModalButton = styled(TransparentButton)`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export const CustomCircledIcon = styled(CircledIcon)`
  width: 4.9rem;
  height: 4.9rem;
`;

export const ModalContent = styled.div`
  padding: 0 2.1rem;
  padding-bottom: 10.2rem;
`;

export const WithdrawIcon = styled(DepositIcon)`
  transform: rotate(180deg);
`;

//MobileNavItem
export const MobileNavItemContainer = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
`;

export const OperationsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileIconWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 3rem;

  margin-bottom: 0.4rem;
`;

//ModalItem
export const ModalItemContainer = styled(TransparentButton)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.8rem 0 1.8rem 0.2rem;
  :not(: last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7rem;
  height: 4.8rem;

  border-radius: 3.8rem;

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 1.7rem;
`;

export const ActiveLine = styled.div`
  position: absolute;

  height: 0.2rem;
  width: 6rem;

  top: 0;

  background-color: ${({ theme }) => theme.colors.interactive.brandColor};
`;
