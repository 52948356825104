import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const BalanceContainer = styled.div`
  width: 100%;
  min-height: 20rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const BalanceLoaderWrapper = styled(ContentLoader)`
  width: 100%;
  height: 18rem;
`;

export const AllBalanceTitle = styled.rect`
  width: 10rem;
  height: 2rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  x: 2.2rem;
  y: 2.9rem;

  ${({ theme }) => theme.media.tablet`
    y: 1.7rem;
  `};
  ${({ theme }) => theme.media.laptop`
    x: 2.9rem;
  `};
`;

export const AllBalance = styled.rect`
  width: 15rem;
  height: 2.4rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  x: 2.2rem;
  y: 5.4rem;

  ${({ theme }) => theme.media.tablet`
    width: 20rem;
    height: 3.4rem;
    y: 4.2rem;
  `};
  ${({ theme }) => theme.media.laptop`
    x: 2.9rem;
  `};
`;

export const CurrencySwitcher = styled.rect`
  width: 14rem;
  height: 3.78rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  x: calc(100% - 15.7rem);
  y: 4rem;

  ${({ theme }) => theme.media.tablet`
    y: 3.75rem;
    x: calc(100% - 16.4rem);
  `};
`;

export const DividedCurrency = styled.rect`
  width: calc(100% - 3.4rem);
  height: 7.2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  x: 1.7rem;
  y: 10.1rem;
  ${({ theme }) => theme.media.laptop`
    x: 2.4rem;
    width: calc(100% - 4.8rem);
    y: 9.9rem;
  `};
`;
export const BalanceContainerUa = styled.div`
  width: 100%;
  height: 10rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};
  ${({ theme }) => theme.media.laptop`
    height: 12rem;
  `}
`;
export const UABalanceLoaderWrapper = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`;
