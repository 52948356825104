import { Balance, PageContainer } from 'src/components';

import { CustomAllAssetsTable } from './styles';

const UserWallet = () => {
  return (
    <PageContainer>
      <Balance />
      <CustomAllAssetsTable hasActions />
    </PageContainer>
  );
};
export default UserWallet;
