import React from 'react';
import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';
import { formatDate } from '@libs/hooks';
import { CurrencySymbol } from '@libs/types';
import { formatPrice } from '@libs/helpers';

import { TextWrapper, Container } from './styles';

type CustomToolTipProps = {
  active?: boolean;
  tooltipPrecision?: number;
  payload?: { value: number }[];
  label?: Date;
  currencySymbol?: CurrencySymbol;
};

const CustomToolTip: React.FC<CustomToolTipProps> = ({
  active,
  payload,
  label,
  tooltipPrecision,
  currencySymbol,
}) => {
  const { colors } = useTheme();

  if (!(active && payload && payload.length)) return null;

  const value = payload[0]?.value;

  const formattedDate = formatDate('DD/MM/YYYY', label);

  const formattedTime = formatDate('hh:mm A', label);

  const formatedPrice = formatPrice(
    tooltipPrecision ? (+value).toFixed(tooltipPrecision) : value
  );

  return (
    <Container>
      <TextWrapper>
        <Typography
          type="caption"
          color={colors.text.secondary}
          fontFamily="primaryMedium"
        >
          {formattedDate}
        </Typography>
        <Typography
          type="caption"
          color={colors.text.secondary}
          fontFamily="primaryMedium"
        >
          {formattedTime}
        </Typography>
      </TextWrapper>
      <Typography type="subHeadline" fontFamily="primaryBold">
        {currencySymbol}
        {formatedPrice}
      </Typography>
    </Container>
  );
};

export default CustomToolTip;
