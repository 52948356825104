import styled from 'styled-components';
import { Button } from '@libs/components';

export const Wrapper = styled.div`
  display: none;
  padding-top: 1rem;
  margin-right: -2rem; // TMP solution for table actions
  ${({ theme }) => theme.media.tablet`
    display: flex;
  `}
`;

export const ActionBtn = styled(Button)`
  width: 12rem;
`;

export const Separator = styled.div`
  margin: 0 1.5rem;
`;

export const ActionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  width: 4rem;
  height: 4rem;
  padding: 1.1rem 0;
  border-radius: 50%;

  ${({ theme }) => theme.media.tablet`
    display: none;
  `};
`;

export const ModalWrapper = styled.div`
  padding: 0 2.1rem 1.3rem;
`;
