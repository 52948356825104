import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type DeleteBankRequisitesByUser = {
  id: number;
};
export type DeleteBankRequisitesByUserResponse = {
  deleteBankRequisitesByUser: DefaultApiResponse<boolean>;
};
export const deleteBankRequisitesByUserMutation: TypedDocumentNode<
  DeleteBankRequisitesByUserResponse,
  DefaultArg<DeleteBankRequisitesByUser>
> = gql`
  mutation DeleteBankRequisitesByUser($record: IdRecord!) {
    deleteBankRequisitesByUser(record: $record)
  }
`;
