import { TypedDocumentNode, gql } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type CustomExchangeRate = {
  id: string;
  baseAssetCode: string;
  quoteAssetCode: string;
  buyRate: number;
  sellRate: number;
};

export type GetExchangeRatesResponse = {
  getExchangeRates: DefaultApiResponse<CustomExchangeRate[]>;
};

export const getExchangeRatesQuery: TypedDocumentNode<
  GetExchangeRatesResponse,
  DefaultArg
> = gql`
  query GetExchangeRates {
    getExchangeRates {
      baseAssetCode
      buyRate
      id
      quoteAssetCode
      sellRate
    }
  }
`;
