import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import {
  useModal,
  useTwoFa,
  DialogProps,
  TwoFactorCodeParams,
  useTranslation,
  useTwoFaDetection,
} from 'src/hooks';
import { detectIfHasNoTwoFa, detectTwoFaTypeFromToken } from 'src/helpers';
import { TwoFactorAuthType } from 'src/constants';

import { CustomTwoFaAuth } from './styles';

type AuthHandler = (token: string, codes: TwoFactorCodeParams) => void;
export type ModalWithTwoFaProps = PropsWithChildren &
  DialogProps & {
    forcedSmsAuth?: boolean;
    forceEmailAuth?: boolean;
    onModalClose?: () => void;
    className?: string;
    loading?: boolean;
    onAuthFinish?: AuthHandler;
    shouldAutomaticallySendSms?: boolean;
    shouldAutomaticallySendEmail?: boolean;
    shouldShowContent: boolean;
    canCloseViaLayer?: boolean;
  };

export const ModalWithTwoFa: React.FC<ModalWithTwoFaProps> = ({
  children,
  onModalClose,
  onAuthFinish,
  loading,
  forcedSmsAuth = false,
  forceEmailAuth = false,
  shouldAutomaticallySendSms = true,
  shouldAutomaticallySendEmail = true,
  shouldShowContent,
  canCloseViaLayer = true,
  ...dialogProps
}) => {
  const { t } = useTranslation();
  const { decodedToken } = useTwoFaDetection();

  const [faType, setFaType] = useState<TwoFactorAuthType | undefined>(() => {
    if (forcedSmsAuth) return TwoFactorAuthType.SMS;
    if (forceEmailAuth) return TwoFactorAuthType.EMAIL;

    return detectTwoFaTypeFromToken(decodedToken);
  });

  const hasNoActiveTwoFa = useMemo(
    () => detectIfHasNoTwoFa(decodedToken),
    [decodedToken]
  );

  const isOpenByDefault = !!children || !hasNoActiveTwoFa || shouldShowContent;

  const { Dialog, isOpen, openModal } = useModal(
    onModalClose,
    isOpenByDefault,
    canCloseViaLayer
  );

  useEffect(() => {
    if (!isOpen && shouldShowContent) {
      openModal();
    }
  }, [isOpen, openModal, shouldShowContent]);

  const {
    handleTwoFaLogin,
    sendCode,
    loading: twoFaLoading,
    errorMessage,
  } = useTwoFa(
    faType,
    setFaType,
    onAuthFinish,
    shouldAutomaticallySendSms && !shouldShowContent,
    shouldAutomaticallySendEmail && !shouldShowContent,
    false
  );

  useEffect(() => {
    if (!hasNoActiveTwoFa) return;

    if (!shouldShowContent) {
      onAuthFinish?.('', {
        twoFactorCode: '',
        googleTwoFactorCode: '',
        emailTwoFactorCode: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken, shouldShowContent]);

  return (
    <Dialog {...dialogProps}>
      <>
        {!shouldShowContent && faType && (
          <CustomTwoFaAuth
            type={faType}
            loading={loading || twoFaLoading}
            onResend={sendCode}
            errorMessage={t(errorMessage)}
            onComplete={handleTwoFaLogin}
          />
        )}
        {(shouldShowContent || !faType) && children}
      </>
    </Dialog>
  );
};
