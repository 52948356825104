import { isValidEmail } from '@libs/helpers';
import { UserToken } from '@libs/hooks';
import { TwoFactorAuthType } from '@libs/types';

export const detectIfHasNoTwoFa = (decodedToken: UserToken | null) => {
  if (!decodedToken) return false;
  const { isEmailTwoFactorEnabled, isSmsTwoFactorEnabled, isTwoFactorEnabled } =
    decodedToken;

  return (
    !isEmailTwoFactorEnabled && !isSmsTwoFactorEnabled && !isTwoFactorEnabled
  );
};

export const getEmailOrPhone2FaType = (emailOrNumber: string) =>
  isValidEmail(emailOrNumber) ? TwoFactorAuthType.EMAIL : TwoFactorAuthType.SMS;
