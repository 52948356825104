import { CalculationDetailsBackground } from 'src/assets';
import styled from 'styled-components';

export const CalculationDetailsBlock = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  padding: 3.3rem 1.8rem;
  background: linear-gradient(144.31deg, #3d62d7 -16.06%, #191c7a 121.84%);
  ${({ theme }) => theme.media.tablet`
    border-radius: ${
      theme.borderRadius.mediumHigh as unknown as TemplateStringsArray
    };
    padding: 3.3rem;
  `};
`;

export const DescContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  display: flex;
  position: relative;
`;

export const CalculationDetailsDescription = styled.div`
  flex: 1;
  z-index: 1;
  padding: 1.6rem 2.6rem;
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CalculationDetailsDesc: React.FC<
  React.PropsWithChildren & { className?: string }
> = ({ children, className }) => (
  <DescContainer>
    <Img src={CalculationDetailsBackground} />
    <CalculationDetailsDescription className={className}>
      {children}
    </CalculationDetailsDescription>
  </DescContainer>
);
