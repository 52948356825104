import { CustomSvg, SvgIconDefaultProps } from '@libs/components';
import { useTheme } from 'styled-components';

const ChatIcon: React.FC<SvgIconDefaultProps> = ({
  color,
  className,
  testId,
}) => {
  const { colors } = useTheme();

  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.98999 22.78C9.38999 22.78 8.81999 22.48 8.42999 21.95L7.23001 20.35C7.23001 20.36 7.18 20.33 7.16 20.33H6.79001C3.37001 20.33 1.25 19.4 1.25 14.79V10.79C1.25 6.58001 3.82001 5.48001 5.98001 5.29001C6.22001 5.26001 6.50001 5.25 6.79001 5.25H13.19C16.81 5.25 18.73 7.17001 18.73 10.79V14.79C18.73 15.08 18.72 15.36 18.68 15.63C18.5 17.76 17.4 20.33 13.19 20.33H12.79L11.55 21.95C11.16 22.48 10.59 22.78 9.98999 22.78ZM6.79001 6.75C6.56001 6.75 6.34 6.76 6.13 6.78C3.81 6.98 2.75 8.25001 2.75 10.79V14.79C2.75 18.22 3.81001 18.83 6.79001 18.83H7.19C7.64 18.83 8.14999 19.08 8.42999 19.44L9.63 21.05C9.85001 21.35 10.13 21.35 10.35 21.05L11.55 19.45C11.84 19.06 12.3 18.83 12.79 18.83H13.19C15.73 18.83 17 17.76 17.19 15.48C17.22 15.24 17.23 15.02 17.23 14.79V10.79C17.23 8.00001 15.98 6.75 13.19 6.75H6.79001Z"
        fill={iconColor}
      />
      <path
        d="M9.99023 14.19C9.43023 14.19 8.99023 13.74 8.99023 13.19C8.99023 12.64 9.44023 12.19 9.99023 12.19C10.5402 12.19 10.9902 12.64 10.9902 13.19C10.9902 13.74 10.5502 14.19 9.99023 14.19Z"
        fill={iconColor}
      />
      <path
        d="M13.1899 14.19C12.6299 14.19 12.1899 13.74 12.1899 13.19C12.1899 12.64 12.6399 12.19 13.1899 12.19C13.7399 12.19 14.1899 12.64 14.1899 13.19C14.1899 13.74 13.7399 14.19 13.1899 14.19Z"
        fill={iconColor}
      />
      <path
        d="M6.7998 14.19C6.2398 14.19 5.7998 13.74 5.7998 13.19C5.7998 12.64 6.2498 12.19 6.7998 12.19C7.3498 12.19 7.7998 12.64 7.7998 13.19C7.7998 13.74 7.3498 14.19 6.7998 14.19Z"
        fill={iconColor}
      />
      <path
        d="M17.9401 16.29C17.7401 16.29 17.5401 16.21 17.4001 16.06C17.2401 15.9 17.1701 15.67 17.2001 15.45C17.2301 15.24 17.2401 15.02 17.2401 14.79V10.79C17.2401 8.00001 15.9901 6.75 13.2001 6.75H6.80012C6.57012 6.75 6.35015 6.76 6.14015 6.78C5.92015 6.81 5.69013 6.72999 5.53013 6.57999C5.37013 6.41999 5.28012 6.20001 5.30012 5.98001C5.48012 3.82001 6.59012 1.25 10.8001 1.25H17.2001C20.8201 1.25 22.7401 3.17001 22.7401 6.79001V10.79C22.7401 15 20.1701 16.1 18.0101 16.29C17.9801 16.29 17.9601 16.29 17.9401 16.29ZM6.92015 5.25H13.1901C16.8101 5.25 18.7301 7.17001 18.7301 10.79V14.66C20.4301 14.24 21.2301 12.99 21.2301 10.79V6.79001C21.2301 4.00001 19.9801 2.75 17.1901 2.75H10.7901C8.59014 2.75 7.35015 3.55 6.92015 5.25Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default ChatIcon;
