import { useCallback } from 'react';

import {
  Button,
  Input,
  resultFeedbackSuccessAnimation,
  Typography,
  useModal,
} from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';
import { screenSizes } from '@libs/theme';

import Lottie from 'lottie-react';

import { useNavigate } from 'react-router-dom';

import { useDimensions, useTranslation } from 'src/hooks';
import { FAQInfo, PasswordStrengthIndicator, TwoFaAuth } from 'src/components';

import {
  Form,
  ButtonWrapper,
  InputContainer,
  RecoveryInfo,
  FAQWrapper,
  IndicatorWrapper,
  CustomInput,
  ChangeSuccessContainer,
  LottieWrapper,
  CustomTypography,
} from '../styles';
import { usePasswordRecoveryForm } from '../hooks/usePasswordRecoveryForm';

type PasswordRecoveryFormProps = {
  twoFaType?: TwoFactorAuthType;
  setTwoFaType: (val?: TwoFactorAuthType) => void;
  setHasVerified: (val: boolean) => void;
  hasVerified: boolean;
  setHasChanged: (val: boolean) => void;
  hasChanged: boolean;
};

export const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  setTwoFaType,
  twoFaType,
  setHasVerified,
  hasVerified,
  hasChanged,
  setHasChanged,
}) => {
  const { t } = useTranslation();

  const { width } = useDimensions();

  const navigate = useNavigate();

  const handleNavigation = useCallback(() => navigate('/login'), [navigate]);

  const { Dialog, openModal } = useModal(handleNavigation);

  const handleSuccess = useCallback(() => {
    setHasChanged(true);
    if (width < screenSizes.tablet) openModal();
  }, [openModal, setHasChanged, width]);

  const {
    values,
    errors,
    touched,
    emailSentErrorText,
    handleSubmit,
    onInputChange,
    description,
    handleComplete,
    verifyLoading,
    verifyError,
    handleNewPasswordInputChange,
    newPasswordValues,
    handleNewPasswordSubmit,
    errorMessage,
  } = usePasswordRecoveryForm(
    setTwoFaType,
    setHasVerified,
    handleSuccess,
    twoFaType
  );

  const inputErrorMessage = touched.emailOrPhone
    ? t(errors.emailOrPhone) || t(emailSentErrorText) || t(errorMessage)
    : '';

  if (hasChanged && width > screenSizes.tablet)
    return <ChangeSuccess handleNavigation={handleNavigation} />;

  if (hasVerified) {
    return (
      <Form onSubmit={handleNewPasswordSubmit}>
        <Dialog title={t('reset_password_succ_title')}>
          <ChangeSuccess handleNavigation={handleNavigation} />;
        </Dialog>
        <InputContainer>
          <CustomInput
            name="password"
            value={newPasswordValues.password}
            onChange={handleNewPasswordInputChange}
            label={t('register_password_minimum_symbols')}
            placeholder={t('register_password_minimum_symbols')}
            testId="newPassword"
          />
        </InputContainer>
        <IndicatorWrapper>
          <PasswordStrengthIndicator password={newPasswordValues.password} />
        </IndicatorWrapper>
        <ButtonWrapper>
          <Button
            type="submit"
            testId="submitButton"
            title={t('common_approval')}
            disabled={newPasswordValues.password.length < 8}
          />
        </ButtonWrapper>

        <FAQWrapper>
          <FAQInfo />
        </FAQWrapper>
      </Form>
    );
  }

  if (twoFaType && !inputErrorMessage) {
    return (
      <TwoFaAuth
        shouldHideAnimation
        type={twoFaType}
        loading={verifyLoading}
        description={description}
        onComplete={handleComplete}
        onResend={handleSubmit}
        errorMessage={t(verifyError)}
      />
    );
  }

  return (
    <Form onSubmit={handleSubmit} data-cy="passwordRecovery">
      <RecoveryInfo type="bodyButton">
        {t('account_recovery_desc')}
      </RecoveryInfo>

      <InputContainer>
        <Input
          name="emailOrPhone"
          testId="email"
          value={values.emailOrPhone}
          onChange={onInputChange}
          label={t('register_phone_or_email')}
          labelTestId="emailLabel"
          errorTestId="emailError"
          placeholder={t('register_phone_or_email')}
          errorMessage={inputErrorMessage}
        />
      </InputContainer>
      <ButtonWrapper>
        <Button
          type="submit"
          testId="submitButton"
          title={t('account_recovery_button_text')}
          disabled={!values.emailOrPhone || !!inputErrorMessage}
        />
      </ButtonWrapper>

      <FAQWrapper>
        <FAQInfo />
      </FAQWrapper>
    </Form>
  );
};

const ChangeSuccess: React.FC<{ handleNavigation: () => void }> = ({
  handleNavigation,
}) => {
  const { t } = useTranslation();

  return (
    <ChangeSuccessContainer>
      <LottieWrapper>
        <Lottie animationData={resultFeedbackSuccessAnimation} loop={false} />
      </LottieWrapper>
      <CustomTypography type="titleOne">
        {t('password_has_changed')}
      </CustomTypography>
      <Typography type="subHeadline">
        {t('reset_password_succ_desc')}
      </Typography>
      <Button onClick={handleNavigation} title={t('verify_email_log_in')} />
    </ChangeSuccessContainer>
  );
};

export default PasswordRecoveryForm;
