import { Fragment } from 'react';

import { Radio } from '@libs/components';
import { shortenAddress } from '@libs/hooks';

import { ModalContent, Separator } from '../styles';

export type AddressOption = {
  title: string;
  icon?: string | JSX.Element;
  id: string | number;
  description?: string;
};

export type AddressOptionsContentProps = {
  options?: AddressOption[];
  selectedOptionId?: string | number;
  onOptionSelection?: (id: string | number) => void;
  closeModal?: () => void;
};
const AddressOptionsContent: React.FC<AddressOptionsContentProps> = ({
  options,
  selectedOptionId,
  onOptionSelection,
  closeModal,
}) => {
  return (
    <ModalContent>
      {options?.map((opt, index) => {
        const isLast = index === options.length - 1;
        const isSelected = selectedOptionId === opt.id;

        const handleSelection = () => {
          onOptionSelection?.(opt.id);
          closeModal?.();
        };

        return (
          <Fragment key={opt.id}>
            <Radio
              icon={opt.icon}
              title={shortenAddress(opt.title)}
              disabled={false}
              checked={isSelected}
              onClick={handleSelection}
              description={opt.description}
            />
            {!isLast && <Separator />}
          </Fragment>
        );
      })}
    </ModalContent>
  );
};
export default AddressOptionsContent;
