import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const StakingListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const HeaderLoader = styled(ContentLoader)`
  display: none;

  height: 5.4rem;
  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};
`;

export const AssetHeaderLoader = styled.rect`
  x: 2.2rem;
  y: calc(50% - 1rem);

  width: 5rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const PercentageHeaderLoader = styled.rect`
  x: calc(15% + 2.2rem);
  y: calc(50% - 1rem);

  width: 10rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const AmountHeaderLoader = styled.rect`
  x: calc(32% + 2.2rem);
  y: calc(50% - 1rem);

  width: 10rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const PeriodHeaderLoader = styled.rect`
  x: calc(55% + 2.2rem);
  y: calc(50% - 1rem);

  width: 16rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const BodyLoader = styled(ContentLoader)`
  width: 100%;
  height: 26.4rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
    height: 10.2rem;

    border: none;
  `};
`;

export const AsetIconLoader = styled.circle`
  r: 1.9rem;

  cx: 3.5rem;
  cy: 3.5rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    cx: 4.3rem;
    cy: 50%;
  `};
`;

export const AssetTitleLoader = styled.rect`
  x: 6rem;
  y: 1.5rem;

  width: 5rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: 6.6rem;
    y: calc(50% - 2rem);
  `};
`;

export const AssetDescLoader = styled.rect`
  x: 6rem;
  y: 3.7rem;

  width: 6rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: 6.6rem;
    y: calc(50% + 0.2rem);
  `};
`;

export const PercentageLoader = styled.rect`
  x: calc(100% - 17.2rem);
  y: 1.5rem;

  width: 16rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: calc(15% + 1.8rem);
    y: calc(50% - 1rem);

    width: 8rem;
  `};
`;

export const AmountLoader = styled.rect`
  x: 1.2rem;
  y: 8.2rem;

  width: 14rem;
  height: 2rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: calc(32% + 1.8rem);
    y: calc(50% - 1rem);
  `};
`;

export const PeriodLoader = styled.rect`
  x: 1.6rem;
  y: 10.8rem;

  width: min(28.2rem, calc(100% - 3.2rem));
  height: 5.6rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: calc(55% + 4rem);
    y: calc(50% - 2.7rem);

    width: 21.2rem;
    height: 5.4rem;

  `};
`;

export const ButtonLoader = styled.rect`
  x: 1.6rem;
  y: calc(100% - 7.2rem);

  width: calc(100% - 3.2rem);
  height: 4.8rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    x: calc(82% + 3.4rem);
    y: calc(50% - 2.4rem);

    width: 9rem;
  `};
`;
