import { DropdownIcon } from '@libs/components';
import { NavLink } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

type NavItemProps = {
  $isNew?: boolean;
  backgroundColor?: string;
};

const circularAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;

  margin-right: 1.8rem;
`;

export const LottieWrapper = styled.div`
  width: 5.4rem;
  margin-left: 1rem;
`;

export const NavItem = styled(NavLink)<NavItemProps>`
  display: flex;
  align-items: center;

  width: 100%;
  height: 4.8rem;

  border-radius: ${({ theme }) => theme.borderRadius.highL};

  text-decoration: none;

  margin-bottom: 0.9rem;

  ${({ $isNew }) =>
    $isNew
      ? css`
          position: relative;

          padding: 0 2rem;

          border: 1px solid transparent;

          background-color: transparent;
          overflow: hidden;

          z-index: 1;

          &::before {
            content: '';

            position: absolute;
            top: calc(50% - 2.1rem);
            left: 0;

            width: 100%;
            height: 4.2rem;

            border-radius: ${({ theme }) => theme.borderRadius.highL};

            z-index: -2;

            ${({ theme }) => {
              const brandColor = theme.colors.interactive.brandColor;
              const secondary = theme.colors.surface.default;

              return css`
                background: linear-gradient(
                      to top,
                      ${brandColor + '80'},
                      ${secondary}
                    )
                    right,
                  linear-gradient(to top, ${secondary}, ${brandColor + '80'})
                    left;
                background-size: 50% 100%, 50% 100%;
                background-repeat: no-repeat;
              `;
            }};

            animation: ${circularAnimation} 3s
              cubic-bezier(0.52, 0.14, 0.46, 0.75) infinite;
          }

          ${({ theme }) => {
            const backgroundColor = theme.colors.surface.interactiveDefault;
            return theme.media.laptop`
              &:hover {
                background-color: ${
                  backgroundColor as unknown as TemplateStringsArray
                }
              }
            `;
          }}

          &::after {
            content: '';

            position: absolute;
            top: 0.2rem;
            left: 0.2rem;

            width: calc(100% - 0.4rem);
            height: calc(100% - 0.4rem);

            border-radius: ${({ theme }) => theme.borderRadius.highL};

            background-color: ${({ theme }) => theme.colors.surface.default};

            z-index: -1;
          }

          ${({ theme }) => {
            const backgroundColor = theme.colors.surface.interactiveDefault;
            return theme.media.laptop`
              &:hover::after {
                background-color: ${
                  backgroundColor as unknown as TemplateStringsArray
                }
              }
            `;
          }}

          &.active {
            border: 1px solid
              ${({ theme }) => theme.colors.interactive.brandColor};

            background-color: ${({ theme }) =>
              theme.colors.interactive.secondary};
            &::after {
              display: none;
            }

            &::before {
              display: none;
            }
          }
        `
      : css`
          padding: 0 2rem;

          border: 1px solid transparent;

          overflow: hidden;

          &.active {
            border: 1px solid
              ${({ theme }) => theme.colors.interactive.brandColor};

            background-color: ${({ theme }) =>
              theme.colors.interactive.secondary};
          }

          ${({ theme }) => {
            const backgroundColor = theme.colors.surface.interactiveDefault;
            return theme.media.laptop`
              &:hover {
                background-color: ${
                  backgroundColor as unknown as TemplateStringsArray
                }
              }
            `;
          }}
        `};
`;

export const CustomDropDownIcon = styled(DropdownIcon)`
  transform: rotate(-90deg);
  margin-left: 3.2rem;
`;
