import { useRef } from 'react';
import { useTheme } from 'styled-components';

import {
  APP_LINKS,
  CloseIcon,
  IS_ANDROID,
  IS_IOS,
  QrModal,
  TransparentButton,
} from '@libs/components';
import { redirectToAppDownload } from '@libs/helpers';
import { AppLangCode } from '@libs/types';

import { AppStoreBlue, GooglePlayBlue } from 'src/assets';
import { useAppDisclaimer, useModal, useTranslation } from 'src/hooks';
import { dashboardUrl } from 'src/constants';

import {
  CloseIconWrapper,
  CustomTypography,
  DownloadText,
  IconForMobile,
  MainContent,
  MobileAppDisclaimerContainer,
  MobileImg,
  MobileTransparentButton,
  WebImages,
} from './styles';

type MobileAppDisclaimerProps = {
  position?: string;
};

const MobileAppDisclaimer: React.FC<MobileAppDisclaimerProps> = ({
  position = 'static',
}) => {
  const { t, selectedLanguageCode } = useTranslation();
  const { colors } = useTheme();

  const { Dialog: QrDialog, openModal: openQrModal } = useModal();

  const handleAppNavigation = () => {
    redirectToAppDownload(
      APP_LINKS.appStore,
      APP_LINKS.googlePlay,
      openQrModal
    );
  };

  const isUaOrRuLang = [AppLangCode.RU, AppLangCode.UA].includes(
    selectedLanguageCode
  );

  let initialIcon = '';
  if (IS_ANDROID) {
    initialIcon = GooglePlayBlue;
  } else if (IS_IOS) {
    initialIcon = AppStoreBlue;
  }

  const storeIconRef = useRef<string>(initialIcon);

  const { setIsAppDisclaimerVisible } = useAppDisclaimer();

  const handleClose = () => {
    setIsAppDisclaimerVisible(false);
  };

  return (
    <MobileAppDisclaimerContainer position={position}>
      <div />
      <MainContent>
        <WebImages>
          <TransparentButton onClick={handleAppNavigation}>
            <MobileImg src={GooglePlayBlue} />
          </TransparentButton>
          <TransparentButton onClick={handleAppNavigation}>
            <MobileImg src={AppStoreBlue} />
          </TransparentButton>
        </WebImages>
        <MobileTransparentButton onClick={handleAppNavigation}>
          <IconForMobile src={storeIconRef.current} />
        </MobileTransparentButton>
        <div>
          <CustomTypography
            type="bodyButton"
            fontFamily="primaryRegular"
            color={colors.text.interactive}
          >
            {t('download_bitnet_application')}
          </CustomTypography>
          {!isUaOrRuLang && (
            <TransparentButton onClick={handleAppNavigation}>
              <DownloadText
                type="bodyButton"
                fontFamily="primaryRegular"
                color={colors.text.interactive}
              >
                {t('common_app')}
              </DownloadText>
            </TransparentButton>
          )}
        </div>
      </MainContent>
      <CloseIconWrapper onClick={handleClose}>
        <CloseIcon color={colors.text.interactive} />
      </CloseIconWrapper>
      <QrDialog leftSpace={0} title={t('download_app_title')}>
        <QrModal
          dashboardUrl={dashboardUrl}
          scanQrCodeTitle={t('scan_qr_code_title')}
        />
      </QrDialog>
    </MobileAppDisclaimerContainer>
  );
};

export default MobileAppDisclaimer;
