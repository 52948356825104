import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';
import { shortenAddress } from '@libs/hooks';

import {
  CustomCopyButton,
  CustomLink,
  DataText,
  DescriptionContainer,
} from '../styles';

type DescripionType = {
  data: string;
  linkTo?: string;
  shouldShorten?: boolean;
  shouldCopyButtonHide?: boolean;
};

export const InfoFieldDescription: React.FC<DescripionType> = ({
  data,
  linkTo,
  shouldCopyButtonHide,
  shouldShorten = true,
}) => {
  const { colors } = useTheme();

  return (
    <DescriptionContainer>
      {linkTo ? (
        <CustomLink to={linkTo}>
          <Typography
            type="subHeadline"
            fontFamily="primaryMedium"
            color={colors.borders.pressed}
          >
            {shortenAddress(data, 6, -6)}
          </Typography>
        </CustomLink>
      ) : (
        <DataText
          type="subHeadline"
          fontFamily="primaryMedium"
          color={colors.text.default}
          shouldCopyButtonHide={shouldCopyButtonHide}
        >
          {shouldShorten ? shortenAddress(data, 6, -6) : data}
        </DataText>
      )}
      {!shouldCopyButtonHide && (
        <CustomCopyButton text={data} shouldDisplayText={false} />
      )}
    </DescriptionContainer>
  );
};
