import styled from 'styled-components';

import { MaxContentWrapper, ToolTip } from '@libs/components';
import { RequireKyc } from 'src/components';

export const LoansPageContainer = styled.div`
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.laptop`  
      justify-content:center;

      margin-bottom: 6rem;
      
      height: calc(100% - 6rem);
    `};
`;

export const JustifiedContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 113.7rem;

  background-color: transparent;

  ${({ theme }) => theme.media.tablet`
    padding: 3.2rem 2rem 0;
    justify-content: center;
  `};
`;

export const LoanContainer = styled.div`
  &:first-child {
    margin-top: 0;
  }
  box-shadow: ${({ theme }) => theme.shadows.sm};

  margin-top: 1.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  background-color: ${({ theme }) => theme.colors.surface.default};
  padding: 2.4rem 0;
  ${({ theme }) => theme.media.tablet`
    margin-top: 3rem;
    padding: 2.4rem;
  `};
`;
export const MaxContentWrapperLoan = styled(MaxContentWrapper)`
  padding: 0;
`;

export const CustomToolTip = styled(ToolTip)`
  margin-left: 0.5rem;

  .tooltipbox {
    width: 18rem;
    left: -8rem;
  }

  ${({ theme }) => theme.media.tablet`
    .tooltipbox {
      width: 25rem;
      left: -11.6rem;
    }
  `}
`;

export const CalculateDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  ${({ theme }) => theme.media.mobileM`
  font-size: 1.5rem;
  `};
  ${({ theme }) => theme.media.mobileL`
  font-size: 1.6rem;
  `}
`;

export const CustomKYC = styled(RequireKyc)`
  margin-top: 1.2rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};
  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tablet`
    margin-top: 3rem;
  `}
`;
