import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type UpdateCryptoWithdrawalAddressArgs = {
  id: number;
  destinationAddressName: string;
  googleCode?: string;
  smsCode?: string;
  assetCode?: string;
  networkId?: number;
};
export type UpdateCryptoWithdrawalAddressResponse = {
  updateCryptoWithdrawalAddress: DefaultApiResponse<boolean>;
};
export const updateCryptoWithdrawalAddressMutation: TypedDocumentNode<
  UpdateCryptoWithdrawalAddressResponse,
  DefaultArg<UpdateCryptoWithdrawalAddressArgs>
> = gql`
  mutation UpdateCryptoWithdrawalAddress(
    $record: UpdateCryptoWithdrawalAddressRecord!
  ) {
    updateCryptoWithdrawalAddress(record: $record)
  }
`;
