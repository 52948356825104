import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';
import { BankRequisitesStatus } from 'src/constants';

export type GetAllBankRequisites = {
  id: string;
  name: string;
  iban: string;
  address: string;
  bankName: string;
  bankCode: string;
  createdAt: string;
  isVerified: boolean;
  recipientName: string;
  status: BankRequisitesStatus;
  updatedAt: string;
};

export type GetAllBankRequisitesByUserRes = {
  getAllBankRequisitesByUser: DefaultApiResponse<GetAllBankRequisites[]>;
};

export const getAllBankRequisitesByUserQuery: TypedDocumentNode<GetAllBankRequisitesByUserRes> = gql`
  query GetAllBankRequisitesByUser {
    getAllBankRequisitesByUser {
      address
      bankCode
      bankName
      createdAt
      iban
      id
      isVerified
      name
      recipientName
      status
      updatedAt
    }
  }
`;
