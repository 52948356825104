import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const LoanViewContainer = styled.div`
  width: 100%;
  height: 50.4rem;

  border-radius: ${({ theme }) => theme.borderRadius.high};
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  background-color: ${({ theme }) => theme.colors.surface.default};

  box-shadow: ${({ theme }) => theme.shadows.xs};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;
    const radius = theme.borderRadius.high as unknown as TemplateStringsArray;
    return theme.media.tablet`
      height: 27.7rem;

      border-radius: ${radius};

      box-shadow: ${shadow};
  `;
  }};
`;

export const CustomContentLoader = styled(ContentLoader)`
  width: 100%;
  height: 20.6rem;

  ${({ theme }) => theme.media.tablet`
      height: 12.65rem;
  `};
`;

export const Title = styled.rect`
  x: 2.4rem;
  y: 2.5rem;

  width: 20rem;
  height: 3.4rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      x: 2.8rem;
      y: 3.3rem;
  `};
`;

export const Switcher = styled.rect`
  x: 2.4rem;
  y: 8.8rem;

  width: calc(100% - 4.8rem);
  height: 5rem;

  rx: 2.5rem;
  ry: 2.5rem;

  ${({ theme }) => theme.media.tablet`
    width: 50rem;

    x: calc(100% - 52.4rem);
    y: 2.4rem;
  `};
`;

export const ActiveLoanContainer = styled.div`
  height: 27.4rem;

  margin: 0 2.4rem 2.7rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};

  ${({ theme }) => theme.media.tablet`
    padding-right:2.7rem;
    padding-left: 1.5rem;
    height: 12.6rem;
  `};
`;

export const CustomActiveLoanLoader = styled(ContentLoader)`
  width: 100%;
  height: 27.4rem;

  ${({ theme }) => theme.media.tablet`
    height: 12.8rem;
  `};
`;

export const DebtIcon = styled.circle`
  cx: 5.1rem;
  cy: 6.5rem;

  r: 1.9rem;

  ${({ theme }) => theme.media.tablet`
    cx: 1.9rem;
    cy: 50%;
  `};
`;

export const DebtTitle = styled.rect`
  x: 2.9rem;
  y: 10.8rem;

  height: 2rem;
  width: 10rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 8rem;

    x: 5.6rem;
    y: calc(50% - 2.5rem);
  `};
`;

export const Debt = styled.rect`
  x: 2.9rem;
  y: 13rem;

  height: 2.5rem;
  width: 10rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    width: 15rem;

    x: 5.6rem;
    y: calc(50% - 0.2rem);
  `};
`;

export const FirstLine = styled.rect`
  display: none;

  x: calc((100% - 2px) / 3 + 0.7rem);
  y: calc(50% - 4rem);

  height: 8rem;
  width: 0.1rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};
`;

export const CollateralIcon = styled.circle`
  cx: calc(50% + 4.4rem);
  cy: 6.5rem;

  r: 1.9rem;

  ${({ theme }) => theme.media.tablet`
    cx: calc(1.1 * (100% - 2px) / 3 + 2.7rem);
    cy: 50%;
  `};
`;

export const CollateralTitle = styled.rect`
  x: calc(50% + 2.3rem);
  y: 10.8rem;

  height: 2rem;
  width: 8rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      x: calc(1.1 * (100% - 2px) / 3 + 6.4rem);
      y: calc(50% - 2.5rem);
  `};
`;

export const Collateral = styled.rect`
  x: calc(50% + 2.3rem);
  y: 13rem;

  height: 2.5rem;
  width: 10rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
      x: calc(1.1 * (100% - 2px) / 3 + 6.4rem);
      y: calc(50% - 0.2rem);
  `};
`;

export const SecondLine = styled.rect`
  display: none;

  x: calc(2 * (100% - 2px) / 3);
  y: calc(50% - 4rem);

  height: 8rem;
  width: 0.1rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    display: initial;
  `};
`;

export const ThirdLine = styled.rect`
  x: 0;
  y: 18.7rem;

  height: 0.1rem;
  width: 100%;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};

  ${({ theme }) => theme.media.tablet`
    display: none;
  `};
`;

export const RepayButton = styled.rect`
  height: 4.6rem;
  width: calc(50% - 2rem);

  x: 1.3rem;
  y: 20.6rem;

  rx: ${({ theme }) => theme.borderRadius.mediumHigh};
  ry: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.tablet`
    width: calc(100% / 6 - 1.9rem);

    x: calc(2 * 100% / 3 + 2rem);
    y: calc(50% - 2.4rem);
  `};
`;

export const DetailsButton = styled.rect`
  height: 4.6rem;
  width: calc(50% - 2rem);

  x: calc(50% + 0.7rem);
  y: 20.6rem;

  rx: ${({ theme }) => theme.borderRadius.mediumHigh};
  ry: ${({ theme }) => theme.borderRadius.mediumHigh};

  ${({ theme }) => theme.media.tablet`
    width: calc(100% / 6 - 1.9rem);

    x: calc(5 * 100% / 6 + 1.6rem);
    y: calc(50% - 2.5rem);
  `};
`;
