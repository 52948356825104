import { FC, Fragment, useEffect, useMemo } from 'react';

import { useTheme } from 'styled-components';

import { TwoFactorAuthType, TwoFaActions } from '@libs/types';
import { ModalHeader, SwapSuccessIcon } from '@libs/components';

import { TWO_FA_TITLE_KEYS } from 'src/types';
import { useTranslation } from 'src/hooks';

import {
  Circle,
  CustomTypography,
  HeaderContainer,
  Indicator,
  Line,
  StepsContainer,
} from './styles';

type AuthHeaderStepsProps = {
  activatedMethods: TwoFactorAuthType[];
  methodToActivate: TwoFactorAuthType;
  completedTwoFaSteps: number;
  hasEnteredAddress: boolean;
  alreadyHasAddress: boolean;
  action: TwoFaActions;
  setShouldRenderSteps: (val: boolean) => void;
  closeModal: () => void;
  shouldRenderSteps: boolean;
};

type Step = {
  isActive: boolean;
  isComplete: boolean;
  title: string;
};

const ACTIVATION_NAMES = {
  [TwoFactorAuthType.EMAIL]: 'activate_2fa_enter_mail',
  [TwoFactorAuthType.SMS]: 'activate_2fa_enter_sms',
  [TwoFactorAuthType.GOOGLE]: 'activate_2fa_enter_google',
};

const ACTIVATION_TITLES = {
  [TwoFactorAuthType.EMAIL]: 'activate_2fa_mail_title',
  [TwoFactorAuthType.SMS]: 'activate_2fa_sms_title',
  [TwoFactorAuthType.GOOGLE]: 'activate_2fa_google_title',
};

export const AuthHeaderSteps: FC<AuthHeaderStepsProps> = ({
  activatedMethods,
  completedTwoFaSteps,
  methodToActivate,
  hasEnteredAddress,
  alreadyHasAddress,
  action,
  setShouldRenderSteps,
  closeModal,
  shouldRenderSteps,
}) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const activatedTwoFaSteps: Step[] = useMemo(() => {
    return activatedMethods.map((e, index) => {
      return {
        isActive: index === completedTwoFaSteps,
        isComplete: index < completedTwoFaSteps,
        title: t(TWO_FA_TITLE_KEYS[e]),
      };
    });
  }, [activatedMethods, completedTwoFaSteps, t]);

  const methodToActivateSteps: Step[] = useMemo(() => {
    const steps: Step[] = [];

    if (action === TwoFaActions.DEACTIVATE) return steps;

    if (!alreadyHasAddress) {
      steps.push({
        isActive:
          !hasEnteredAddress && completedTwoFaSteps === activatedMethods.length,
        isComplete: hasEnteredAddress,
        title: t(ACTIVATION_NAMES[methodToActivate]),
      });
    }

    if (methodToActivate !== TwoFactorAuthType.GOOGLE) {
      const isActive = alreadyHasAddress
        ? completedTwoFaSteps === activatedMethods.length
        : hasEnteredAddress;

      steps.push({
        isActive,
        isComplete: false,
        title: t(TWO_FA_TITLE_KEYS?.[methodToActivate]),
      });
    }

    return steps;
  }, [
    activatedMethods.length,
    completedTwoFaSteps,
    alreadyHasAddress,
    hasEnteredAddress,
    methodToActivate,
    action,
    t,
  ]);

  useEffect(() => {
    if (methodToActivateSteps.length + activatedMethods.length < 2) {
      setShouldRenderSteps(false);
    } else {
      setShouldRenderSteps(true);
    }
  }, [setShouldRenderSteps, methodToActivateSteps, activatedMethods]);

  const steps = activatedTwoFaSteps.concat(methodToActivateSteps);

  const shouldHideSteps = steps.length < 2;

  const headerCloseButtonClick = () => {
    closeModal();
  };

  return (
    <HeaderContainer shouldRenderSteps={shouldRenderSteps}>
      <ModalHeader
        title={t(ACTIVATION_TITLES[methodToActivate])}
        onHeaderButtonClick={headerCloseButtonClick}
      />
      {!shouldHideSteps && (
        <StepsContainer>
          {steps.map(({ isActive, isComplete, title }, index) => {
            return (
              <Fragment key={index}>
                {!!index && (
                  <Line isComplete={isComplete} isActive={isActive} />
                )}
                <Indicator isActive={isActive} isComplete={isComplete}>
                  {isActive && <Circle />}
                  {isComplete && (
                    <SwapSuccessIcon color={colors.text.interactive} />
                  )}
                  <CustomTypography
                    color={colors.text.secondary}
                    type="caption"
                  >
                    {title}
                  </CustomTypography>
                </Indicator>
              </Fragment>
            );
          })}
        </StepsContainer>
      )}
    </HeaderContainer>
  );
};

export default AuthHeaderSteps;
