import styled from 'styled-components';

import { Button, CloseIcon } from '@libs/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.2rem;
  gap: 2.4rem;
  ${({ theme }) => theme.media.laptop`
     padding:1.2rem 6rem;
     gap: unset;
     justify-content:space-between;
     flex-direction: row;
  `}
`;

export const ImgAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  ${({ theme }) => theme.media.laptop`
     flex-direction: row;
     margin-right: 2rem;
  `}
`;

export const Img = styled.img`
  height: 11rem;
  width: 11rem;
  ${({ theme }) => theme.media.laptop`
      height: 7rem;
      width: 7rem;
  `};
`;

export const TextContainer = styled.div`
  text-align: center;
  max-width: 45rem;
  ${({ theme }) => theme.media.laptop`
     text-align: unset;
     max-width: 73rem;
  `}
`;

export const AgreeButton = styled(Button)`
  border-radius: 4.8rem;
  height: 4rem;
  ${({ theme }) => theme.media.laptop`
      width: 16.6rem;
      border-radius: ${
        theme.borderRadius.medium as unknown as TemplateStringsArray
      };
  `}
`;

export const RegretButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: 4.8rem;
  height: 4rem;
  cursor: pointer;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
      width: 16.6rem;
      border-radius: ${
        theme.borderRadius.medium as unknown as TemplateStringsArray
      };
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 0.8rem;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
      flex-direction: row;
      width: unset;
  `}
`;

export const CloseIconWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  height: 3.2rem;
  width: 3.2rem;

  background-color: ${({ theme }) => theme.colors.surface.default};
  position: absolute;
  right: -1.6rem;
  top: -3.9rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};

  cursor: pointer;

  ${({ theme }) => theme.media.laptop`
       display: flex;
  `}
`;

export const CustomCloseIcon = styled(CloseIcon)``;
