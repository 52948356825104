import { useTheme } from 'styled-components';

import { useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';

import {
  ActionLoader,
  ActionWrapper,
  AssetDescLoader,
  AssetIconLoader,
  AssetTitleLoader,
  MainContentWrapper,
  MainLoader,
  RewardButton,
  RewardDesc,
  RewardTitle,
  SecondaryContentWrapper,
  SecondaryLoader,
  StakingDetailsContainer,
  ToggleDescFive,
  ToggleDescFour,
  ToggleDescOne,
  ToggleDescSix,
  ToggleDescThree,
  ToggleDescTwo,
  ToggleLoader,
  ToggleTitle,
} from './styles';

export const StakingDetailsLoader = () => {
  const { colors } = useTheme();
  const { width } = useDimensions();

  return (
    <StakingDetailsContainer>
      <MainContentWrapper>
        <MainLoader
          foregroundColor={colors.surface.pressed}
          backgroundColor={
            width > screenSizes.tablet
              ? colors.surface.default
              : colors.surface.interactiveDefault
          }
        >
          <AssetIconLoader />
          <AssetTitleLoader />
          <AssetDescLoader />
        </MainLoader>
        <SecondaryContentWrapper>
          <SecondaryLoader
            foregroundColor={colors.surface.pressed}
            backgroundColor={
              width > screenSizes.tablet
                ? colors.surface.default
                : colors.surface.interactiveDefault
            }
          >
            <AssetIconLoader />
            <AssetTitleLoader />
            <AssetDescLoader />
          </SecondaryLoader>
          <SecondaryLoader
            foregroundColor={colors.surface.pressed}
            backgroundColor={
              width > screenSizes.tablet
                ? colors.surface.default
                : colors.surface.interactiveDefault
            }
          >
            <AssetIconLoader />
            <AssetTitleLoader />
            <AssetDescLoader />
          </SecondaryLoader>
        </SecondaryContentWrapper>
      </MainContentWrapper>

      <ActionWrapper>
        <ActionLoader
          foregroundColor={colors.surface.pressed}
          backgroundColor={colors.surface.interactiveDefault}
        >
          <RewardTitle />
          <RewardDesc />
          <RewardButton />
        </ActionLoader>
        <ActionLoader
          foregroundColor={colors.surface.pressed}
          backgroundColor={colors.surface.interactiveDefault}
        >
          <ToggleTitle />

          <ToggleDescOne />
          <ToggleDescTwo />
          <ToggleDescThree />
          <ToggleDescFour />
          <ToggleDescFive />
          <ToggleDescSix />

          <ToggleLoader />
        </ActionLoader>
      </ActionWrapper>
    </StakingDetailsContainer>
  );
};
