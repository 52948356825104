import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

export const AssetPricesContainer = styled.div`
  height: 85rem;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-top: 1.6rem;
  gap: 1.6rem;
  ${({ theme }) => theme.media.tablet`
    flex-direction:row;
    height: 40rem;
`};
`;

export const PricesLoader = styled(ContentLoader)`
  height: 100%;
  min-width: 38rem;
  padding: 1.6rem;
  margin-top: 15rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.xs};
  ${({ theme }) => theme.media.tablet`
    margin-top: 0;
    padding-top: 7rem;
`};
`;

export const ChartLoader = styled(ContentLoader)`
  height: 100%;
  width: 100%;
  padding: 1.6rem;
  padding-top: 3rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.xs};
`;

export const PriceHeading = styled.rect`
  width: 6rem;
  height: 2rem;
  x: 2rem;
  y: 2rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.tablet`
  y:0;
  x: 1rem;
`};
`;

export const Price = styled.rect`
  width: 25rem;
  height: 3rem;
  y: 6rem;
  x: 2rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.tablet`
  y: 3rem;
  x: 1rem;
`};
`;

export const AddToFavButton = styled.rect`
  width: 30rem;
  height: 3.5rem;
  y: 10.5rem;
  x: 2rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.tablet`
  y: 7rem;
  x: 1rem;
`};
`;

export const PriceInAsset = styled.rect`
  width: 33rem;
  height: 11rem;
  y: 18rem;
  x: 1rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
  ${({ theme }) => theme.media.tablet`
    width: 35rem;
    y: 17rem;
    x: 1rem;
`};
`;

export const ChartHeading = styled.rect`
  width: 9rem;
  height: 3rem;
  x: 1rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const Chart = styled.rect`
  width: 40rem;
  height: 30rem;
  y: 5rem;
  x: 1rem;
  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
