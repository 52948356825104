import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

type ErrorException<T> = {
  response?: {
    errorDetails?: T;
  };
};

export const useErrorDetails = <T = unknown>(
  error?: ApolloError
): {
  errorDetails?: T;
} => {
  const errorDetails = useMemo(() => {
    return (
      error?.graphQLErrors?.[0]?.extensions?.exception as ErrorException<T>
    )?.response?.errorDetails;
  }, [error]);

  return { errorDetails };
};
