import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse } from '@libs/graphql';

export type GetCardTransactionsInfoForUserRes = {
  getCardTransactionsInfoForUser: {
    cardTransactionAllowed: boolean;
    cardTransactionEnableDate: string;
  };
};

export const getCardTransactionsInfoForUserQuery: TypedDocumentNode<
  DefaultApiResponse<GetCardTransactionsInfoForUserRes>
> = gql`
  query GetCardTransactionsInfoForUser {
    getCardTransactionsInfoForUser {
      cardTransactionAllowed
      cardTransactionEnableDate
    }
  }
`;
