import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type DeleteCryptoWithdrawalAddressArgs = {
  id: number;
};
export type DeleteCryptoWithdrawalAddressResponse = {
  deleteCryptoWithdrawalAddress: DefaultApiResponse<boolean>;
};
export const deleteCryptoWithdrawalAddressMutation: TypedDocumentNode<
  DeleteCryptoWithdrawalAddressResponse,
  DefaultArg<DeleteCryptoWithdrawalAddressArgs>
> = gql`
  mutation DeleteCryptoWithdrawalAddress($record: IdRecord!) {
    deleteCryptoWithdrawalAddress(record: $record)
  }
`;
