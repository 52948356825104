import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { CurrencyType } from '@libs/types';

export type RemoveFavoriteAssetArgs = {
  assetCode: CurrencyType;
};

export type RemoveFavoriteAssetResponse = {
  removeFavoriteAsset: DefaultApiResponse<boolean>;
};

export const removeFavoriteAssetMutation: TypedDocumentNode<
  RemoveFavoriteAssetResponse,
  DefaultArg<RemoveFavoriteAssetArgs>
> = gql`
  mutation RemoveFavoriteAsset($record: FavoriteAssetRecord!) {
    removeFavoriteAsset(record: $record)
  }
`;
