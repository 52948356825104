import styled, { css } from 'styled-components';

type StarIconWrapperProps = {
  isActive: boolean;
  shouldHaveLabel?: boolean;
};

export const StarIconWrapper = styled.div<StarIconWrapperProps>`
  height: 4rem;
  width: ${({ shouldHaveLabel }) => (shouldHaveLabel ? '100%' : '4rem')};

  border: ${({ theme, shouldHaveLabel }) =>
    shouldHaveLabel ? 'none' : '0.1rem solid' + theme.colors.borders.default};
  border-radius: ${({ theme, shouldHaveLabel }) =>
    shouldHaveLabel ? theme.borderRadius.medium : theme.borderRadius.highest};
  display: flex;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;

  ${({ theme, shouldHaveLabel, isActive }) => {
    if (shouldHaveLabel) {
      return css`
        padding-left: 1.6rem;
        background-color: ${theme.colors.interactive.secondaryDisabled};
        justify-content: flex-start;
      `;
    } else {
      return css`
        background-color: ${isActive
          ? theme.colors.interactive.secondaryDisabled
          : theme.colors.text.interactive};
      `;
    }
  }}
`;
