import { Typography } from '@libs/components';

import { Copy, Qr } from 'src/components';
import { useTranslation } from 'src/hooks';

import {
  CopyContainer,
  CustomAddressCont,
  QrContainer,
  QrViewContainer,
  WalletAddress,
  WalletAddressCont,
} from '../style';

type AddressDetailProps = {
  address: string;
  addressTitle: string;
  isBnbMemo?: boolean;
  scanQrTitle?: string;
  bottomBorderShouldhave?: boolean;
};
export const AddressDetail: React.FC<AddressDetailProps> = ({
  address,
  isBnbMemo = false,
  scanQrTitle,
  addressTitle,
  bottomBorderShouldhave = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <QrViewContainer>
        {scanQrTitle && (
          <Typography type="subHeadline" fontFamily="primaryBold">
            {t('scan_qr')}
          </Typography>
        )}
        <QrContainer>
          <Qr value={address} size={110} />
        </QrContainer>
      </QrViewContainer>

      <WalletAddressCont
        bottomBorderShouldhave={bottomBorderShouldhave}
        isBnbMemo={isBnbMemo}
      >
        <CustomAddressCont>
          <Typography type="subHeadline" fontFamily="primaryBold">
            {addressTitle}
          </Typography>
          <WalletAddress type="bodyButton" fontFamily="primaryMedium">
            {address}
          </WalletAddress>
        </CustomAddressCont>
        <CopyContainer>
          <Copy text={address} />
        </CopyContainer>
      </WalletAddressCont>
    </>
  );
};
