import styled from 'styled-components';
import { MaxContentWrapper } from '@libs/components';

export const Container = styled(MaxContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  ${({ theme }) => theme.media.tablet`
    max-width: 50rem;
    max-height: 58.2rem;
    height: 100%;
    margin-top: 0;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ImageContainer = styled.div`
  margin-bottom: 5.2rem;

  ${({ theme }) => theme.media.tablet`
    margin: 0 14.1rem 6.2rem 15.2rem;
  `}
`;

export const SuccessTextContainer = styled.div`
  margin-bottom: 6.3rem;

  ${({ theme }) => theme.media.tablet`
    margin: 0 9.6rem 5rem 11.2rem;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;
export const Img = styled.img`
  width: 30rem;
`;
