import { useCallback, useMemo, useRef } from 'react';
import { useFormik } from 'formik';
import { object } from 'yup';
import { useTheme } from 'styled-components';
import { useMutation } from '@apollo/client';

import { Typography } from '@libs/components';
import { TwoFactorAuthType } from '@libs/types';
import { getAssetUrl } from '@libs/helpers';

import { useApiResponse, useError, useTranslation } from 'src/hooks';
import {
  resetPasswordMutation,
  SendResetPasswordMessageMutation,
  verifyResetPasswordMutation,
  VerifyResetPasswordMutationRes,
} from 'src/gql';
import { emailOrPhoneValidation } from 'src/validations';
import { getEmailOrPhone2FaType } from 'src/helpers';

import { Img, Wrapper } from '../styles';

export const usePasswordRecoveryForm = (
  setTwoFaType: (val?: TwoFactorAuthType) => void,
  setHasVerified: (val: boolean) => void,
  onSuccess: () => void,
  twoFaType?: TwoFactorAuthType
) => {
  const { colors } = useTheme();

  const token = useRef<string>('');

  const { t } = useTranslation();

  const [sendResetPasswordMessage, sendPasswordRes] = useMutation(
    SendResetPasswordMessageMutation
  );

  const [resetPassword, resetResponse] = useMutation(resetPasswordMutation);

  useApiResponse(resetResponse, onSuccess);

  const handleVerifySuccess = (data: VerifyResetPasswordMutationRes) => {
    if (data?.verifyResetPasswordCode) {
      token.current = data.verifyResetPasswordCode;
    }

    setHasVerified(true);
    setTwoFaType(undefined);
  };

  const [verifyResetPassword, verifyResetPasswordRes] = useMutation(
    verifyResetPasswordMutation
  );

  const { loading: verifyLoading, errorMessage: verifyError } = useApiResponse(
    verifyResetPasswordRes,
    handleVerifySuccess
  );

  const {
    values: newPasswordValues,
    handleChange: handleNewPasswordInputChange,
    dirty: newPasswordDirty,
    handleSubmit: handleNewPasswordSubmit,
  } = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (vals) => {
      resetPassword({
        variables: {
          record: {
            password: vals.password,
            token: token.current,
          },
        },
      });
    },
  });

  const {
    dirty,
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      emailOrPhone: '',
    },

    onSubmit: (vals) => {
      if (isEmailSending) return;
      sendResetPasswordMessage({
        variables: { record: { email: vals.emailOrPhone } },
      });
    },
    validationSchema: object().shape(emailOrPhoneValidation),
  });

  const handleSendSuccess = () => {
    setTwoFaType(getEmailOrPhone2FaType(values.emailOrPhone));
  };

  const {
    errors: emailSentError,
    loading: isEmailSending,
    errorMessage,
    clearErrorMessage: clearEmailSendErr,
  } = useApiResponse(sendPasswordRes, handleSendSuccess);

  const { errorMessage: emailSentErrorText, clearErrorMessage } =
    useError(emailSentError);

  const onInputChange = (e: React.ChangeEvent) => {
    handleChange(e);
    clearErrorMessage();
    clearEmailSendErr();
  };

  const handleComplete = useCallback(
    (_: TwoFactorAuthType, code: string) => {
      verifyResetPassword({
        variables: {
          record: {
            code,
            emailOrPhone: values.emailOrPhone,
          },
        },
      });
    },
    [values.emailOrPhone, verifyResetPassword]
  );

  const description = useMemo(() => {
    if (!twoFaType) return '';

    const text =
      twoFaType === TwoFactorAuthType.EMAIL
        ? 'password_recovery_email_code_desc'
        : 'password_recovery_sms_code_desc';

    const link =
      twoFaType === TwoFactorAuthType.EMAIL
        ? 'account-recovery-mail.png'
        : 'account-recovery-phone.png';

    return (
      <Wrapper>
        <Typography type="bodyButton" color={colors.text.secondary}>
          {t(text, {
            address: values.emailOrPhone,
          })}
        </Typography>
        <Img src={getAssetUrl(link)} />
      </Wrapper>
    );
  }, [colors.text.secondary, t, twoFaType, values.emailOrPhone]);

  return {
    dirty,
    values,
    errors,
    touched,
    isValid,
    emailSentErrorText,
    isFormButtonEnabled: dirty && isValid,
    handleSubmit,
    onInputChange,
    description,
    handleComplete,
    loading: isEmailSending,
    verifyError,
    verifyLoading,
    handleNewPasswordInputChange,
    newPasswordValues,
    newPasswordDirty,
    handleNewPasswordSubmit,
    errorMessage,
  };
};
