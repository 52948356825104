import styled from 'styled-components';

import { TransparentButton, Typography } from '@libs/components';

type ButtonType = {
  isActive: boolean;
};

type UnderlineType = {
  isGelActive: boolean;
};

export const CurrencySwitcherContainer = styled.div`
  display: flex;

  position: relative;

  height: 3.8rem;
  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  padding: 0.5rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const AnimatedTypography = styled(Typography)`
  transition: color 0.2s ease;

  z-index: ${({ theme }) => theme.zIndex.medium};
`;
export const ActiveCurrencyUnderline = styled.div<UnderlineType>`
  position: absolute;
  top: 0.5rem;
  left: ${({ isGelActive }) => (!isGelActive ? '0.5rem' : '50%;')};

  height: 2.8rem;
  width: calc((100% - 1rem) / 2);

  border-radius: ${({ theme }) => theme.borderRadius.low};

  transition: all 0.2s ease;
  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

export const GelContainer = styled(TransparentButton)<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
`;

export const UsdContainer = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;

  margin-right: 0.2rem;
`;
