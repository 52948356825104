import { useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { useTheme } from 'styled-components';

import {
  ButtonLoader,
  ButtonWrapper,
  DescLoader,
  FirstBoxLoader,
  IconLoader,
  Line,
  MainLoaderWrapper,
  SecondaryLoadersWrapper,
  SecondBoxLoader,
  StakingListContainer,
  ThirdBoxLoader,
  TitleLoader,
} from './styles';

export const StakingListLoader = () => {
  const { colors } = useTheme();
  const { width } = useDimensions();

  return (
    <StakingListContainer>
      <MainLoaderWrapper>
        <FirstBoxLoader
          foregroundColor={colors.surface.pressed}
          backgroundColor={
            width > screenSizes.tablet
              ? colors.surface.default
              : colors.surface.interactiveDefault
          }
        >
          <TitleLoader />
          <DescLoader />
          <Line />
          <IconLoader />
        </FirstBoxLoader>
        <SecondaryLoadersWrapper>
          <SecondBoxLoader
            foregroundColor={colors.surface.pressed}
            backgroundColor={
              width > screenSizes.tablet
                ? colors.surface.default
                : colors.surface.interactiveDefault
            }
          >
            <IconLoader />
            <TitleLoader />
            <DescLoader />
          </SecondBoxLoader>
          <ThirdBoxLoader
            foregroundColor={colors.surface.pressed}
            backgroundColor={
              width > screenSizes.tablet
                ? colors.surface.default
                : colors.surface.interactiveDefault
            }
          >
            <IconLoader />
            <TitleLoader />
            <DescLoader />
            <Line />
          </ThirdBoxLoader>
        </SecondaryLoadersWrapper>
      </MainLoaderWrapper>
      <ButtonWrapper
        foregroundColor={colors.surface.pressed}
        backgroundColor={colors.surface.default}
      >
        <ButtonLoader />
      </ButtonWrapper>
    </StakingListContainer>
  );
};
