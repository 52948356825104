import { useQuery } from '@apollo/client';
import { useApiResponse } from '@libs/hooks';

import { getStakingAssetsQuery } from 'src/gql';
import { useCurrencyOptions } from './useCurrencyOptions';

export const useStakingAssetOptions = () => {
  const assetRes = useQuery(getStakingAssetsQuery);

  const { response, loading } = useApiResponse(assetRes);
  const stakingAssetOptions = useCurrencyOptions(
    response?.getStakingAssets,
    false
  );

  if (!response)
    return {
      assetOptions: {
        stableCurrencyOptions: [],
        fiatOptions: [],
        cryptoOptions: [],
        enabledCryptoOptions: [],
      },
      loading,
    };

  return { assetOptions: stakingAssetOptions, loading };
};
