import { useState } from 'react';
import { LiveTicketsType } from 'src/types';

const { BACKEND_TICKET_URL } = process.env;
export const useGetTicketLiveData = () => {
  const [liveData, setliveData] = useState<LiveTicketsType>();

  const getTicketLiveData = (email: string) => {
    const eventSource = new EventSource(BACKEND_TICKET_URL + `/sse/${email}`);
    eventSource.onmessage = (e) => {
      setliveData(JSON.parse(e.data));
    };

    return eventSource;
  };
  const closeTicketLiveData = (source: EventSource) => {
    source.close();
  };
  return { getTicketLiveData, liveData, closeTicketLiveData };
};
export default useGetTicketLiveData;
