import styled from 'styled-components';
import { TransparentButton, Typography, ToolTip } from '@libs/components';

type OptionItemState = {
  isActive: boolean;
};
export const OptItemWrapper = styled(TransparentButton)<OptionItemState>`
  display: flex;
  align-items: center;

  padding: 1.6rem 2rem;

  &:first-of-type {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius
        .mediumHigh as unknown as TemplateStringsArray;
      return `${radius} ${radius} 0 0`;
    }};
  }

  &:last-of-type {
    border-radius: ${({ theme }) => {
      const radius = theme.borderRadius
        .mediumHigh as unknown as TemplateStringsArray;
      return `0 0 ${radius} ${radius}`;
    }};
  }

  border: 1px solid
    ${({ isActive, theme }) =>
      !isActive
        ? theme.colors.borders.default
        : theme.colors.interactive.brandColor};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.interactive.secondaryDisabled : 'transparent'};
`;

export const TextWrapper = styled.div`
  flex: 1;
  text-align: left;
  margin-left: 2rem;
  margin-bottom: 0.8rem;
`;

export const Title = styled(Typography)`
  line-height: 3.4rem;
  display: inline-block;
  margin-right: 1.2rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const CustomTooltip = styled(ToolTip)`
  .tooltipbox {
    width: 20rem;
    left: -9rem;
  }
  margin-right: 1.2rem;

  ${({ theme }) => theme.media.tablet`
    .tooltipbox {
      width: 21rem;
      left: -9.7rem;
    }
    margin-right: 1.6rem;
  `}
`;

export const RecommendedWrapper = styled.div`
  border-radius: 0.6rem;
  padding: 0.3rem 0.9rem;
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;
