import { useTheme } from 'styled-components';

import { FeatureModuleEnum } from '@libs/backend-common';

import {
  ConvertIcon,
  DepositIcon,
  MinusIcon,
  PlusIcon,
  WithdrawIcon,
} from 'src/assets';
import { useEnabledFeatures } from './useEnabledFeatures';

type UseNavigationProps = {
  color?: string;
  isCombined?: boolean;
  additionalRouteQuery?: string;
};

export const useNavigationItems = ({
  color,
  isCombined,
  additionalRouteQuery = '',
}: UseNavigationProps = {}) => {
  const { colors } = useTheme();
  const { checkFeature, checkMultipleFeatures } = useEnabledFeatures();
  const iconColor = color || colors.text.interactive;

  const isConvertAvailable = checkFeature(FeatureModuleEnum.EXCHANGE);

  const isDepositAvailable = checkMultipleFeatures([
    FeatureModuleEnum.BANK_WIRE_DEPOSIT,
    FeatureModuleEnum.CARD_DEPOSIT,
    FeatureModuleEnum.CASH_DEPOSIT,
    FeatureModuleEnum.CRYPTO_DEPOSIT,
  ]);

  const isWithdrawalAvailable = checkMultipleFeatures([
    FeatureModuleEnum.BANK_WIRE_WITHDRAWAL,
    FeatureModuleEnum.CARD_WITHDRAWAL,
    FeatureModuleEnum.CASH_WITHDRAWAL,
    FeatureModuleEnum.CRYPTO_WITHDRAWAL,
  ]);
  const tempRoutes = [];

  if (isCombined) {
    if (isConvertAvailable.isAvailable) {
      tempRoutes.push({
        title: 'dashboard_convert',
        route: '/convert' + additionalRouteQuery,
        className: 'nav_conversion',
        icon: <ConvertIcon testId="dashboard_transfer" color={iconColor} />,
      });
    }

    if (isDepositAvailable.isAvailable) {
      tempRoutes.push({
        title: 'dashboard_deposit',
        route: '/deposit' + additionalRouteQuery,
        className: 'nav_deposit',
        icon: <DepositIcon testId="dashboard_deposit" color={iconColor} />,
      });
    }

    if (isWithdrawalAvailable.isAvailable) {
      tempRoutes.push({
        title: 'dashboard_withdraw',
        route: '/withdraw' + additionalRouteQuery,
        className: 'nav_withdraw',
        icon: <WithdrawIcon testId="dashboard_withdraw" color={iconColor} />,
      });
    }
    return tempRoutes;
  } else {
    if (isDepositAvailable.isAvailable) {
      tempRoutes.push({
        title: 'dashboard_deposit',
        route: '/deposit' + additionalRouteQuery,
        className: 'nav_deposit',
        icon: <DepositIcon testId="dashboard_deposit" color={iconColor} />,
      });
    }
    if (isWithdrawalAvailable.isAvailable) {
      tempRoutes.push({
        title: 'dashboard_withdraw',
        route: '/withdraw' + additionalRouteQuery,
        className: 'nav_withdraw',
        icon: <WithdrawIcon testId="dashboard_withdraw" color={iconColor} />,
      });
    }
    if (isConvertAvailable.isAvailable) {
      tempRoutes.push(
        {
          title: 'dashboard_buy',
          className: 'nav_buy',
          route: '/convert/BUY' + additionalRouteQuery,
          icon: <PlusIcon testId="dashboard_buy" color={iconColor} />,
        },
        {
          title: 'dashboard_sell',
          className: 'nav_sell',
          route: '/convert/SELL' + additionalRouteQuery,
          icon: <MinusIcon testId="dashboard_sell" color={iconColor} />,
        }
      );
    }

    return tempRoutes;
  }
};
