import styled from 'styled-components';

export const LinkWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const CustomButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  margin-left: 0.6rem;
`;
