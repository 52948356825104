type ParseWalletAddressType = {
  amount?: number;
  parsedAddress: string;
};

// function to handle unordinary wallet addresses like:
// "tron:TFtoWgqxugNfgw2cR3A12WLA6KZ6D9DPjf?amount=3.56585056"
export const parseWalletAddress = (address: string): ParseWalletAddressType => {
  const addressWithQueryParam = address.split(':').reverse()[0];
  const parsedWalletAddress = addressWithQueryParam.split('?')[0];
  if (
    address.split(':').length > 1 &&
    addressWithQueryParam.split('?').length > 1
  ) {
    const url = new URL(address);
    const amount = +(url.searchParams.get('amount') || 0);

    return { parsedAddress: parsedWalletAddress, amount };
  }

  return { parsedAddress: parsedWalletAddress, amount: 0 };
};
