import { useTheme } from 'styled-components';
import { Desc, Line, Loader, OptionListContainer, Title } from './styles';

type OptionListLoaderProps = {
  length?: number;
  optionHeight?: number;
};
export const OptionListLoader: React.FC<OptionListLoaderProps> = ({
  length = 6,
  optionHeight = 5,
}) => {
  const { colors } = useTheme();

  return (
    <OptionListContainer height={optionHeight * length}>
      {[...Array(length)].map((i, index) => (
        <Loader
          key={index}
          height={optionHeight}
          backgroundColor={colors.surface.interactiveDefault}
          foregroundColor={colors.surface.pressed}
        >
          <Line />
          <Title />
          <Desc />
        </Loader>
      ))}
    </OptionListContainer>
  );
};
