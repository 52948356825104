import { Copy, PageContainer } from 'src/components';
import styled from 'styled-components';

export const TaskId = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.8rem 2.2rem;
  margin: 0 1.6rem;

  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.sm};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tabletL`
    padding: 2.4rem 4rem;
    margin: 0;
  `}
`;

export const CustomCopyButton = styled(Copy)`
  padding: 0.7rem;
  height: min-content;
`;

export const CustomPageContainer = styled(PageContainer)`
  ${({ theme }) => theme.media.laptop`
    padding: 2rem 9rem 5rem;
  `};
`;
