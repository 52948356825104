import { useCallback, useMemo, useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { BankCode } from '@libs/backend-common';

import { useApiResponse } from 'src/hooks';
import {
  GetWithdrawalFeesArgs,
  getWithdrawalFeesQuery,
  GetWithdrawalFeesResponse,
  GetWithdrawalFeesType,
} from 'src/gql';
import {
  getRoundedFee,
  getWithdrawalAmountWithFee,
  sortWithdrawalFees,
} from 'src/helpers';

export const useWithdrawalFees = () => {
  const [feesData, setFeesData] = useState<GetWithdrawalFeesType[]>();

  const handleGetWithdrawalFeesSuccess = useCallback(
    (data: GetWithdrawalFeesResponse) => {
      setFeesData(data.getWithdrawalFees);
    },
    []
  );
  const [
    handelWitdhrawalFees,
    { data: getWithdrawalFeesData, error: getWithdrawalFeesError, loading },
  ] = useLazyQuery(getWithdrawalFeesQuery);

  const {
    loading: feesLoading,
    errors: confirmCreateAssetForUserErrorMessage,
  } = useApiResponse<GetWithdrawalFeesResponse, ApolloError>(
    { data: getWithdrawalFeesData, errors: getWithdrawalFeesError, loading },
    handleGetWithdrawalFeesSuccess
  );

  const getWithdrawalFees = (record: GetWithdrawalFeesArgs) => {
    handelWitdhrawalFees({
      variables: {
        record,
      },
    });
  };

  const sortedWithdrawalFees = useMemo(
    () => sortWithdrawalFees(feesData),
    [feesData]
  );

  const getAmountFeeData = useCallback(
    (amount: number, bankCode?: BankCode) => {
      const filteredFeesByBankCode = feesData?.filter(
        (fee) => fee.extraConditions?.destinationBankCode === bankCode
      );
      const sortedFeesByBankCode = sortWithdrawalFees(filteredFeesByBankCode);
      let fees: GetWithdrawalFeesType[];
      if (sortedFeesByBankCode.length > 0) {
        fees = sortedFeesByBankCode;
      } else {
        fees = sortedWithdrawalFees;
      }

      const foundFee = fees.find((item: GetWithdrawalFeesType) => {
        const greaterThan = item.greaterThan;
        return greaterThan <= amount;
      });
      if (foundFee) {
        const fee = amount * foundFee.rate + foundFee.fixed;

        return {
          fee: getRoundedFee(fee),
          amountToReceive: getWithdrawalAmountWithFee(amount, fee),
        };
      }
      return {
        fee: 0,
        amountToReceive: amount,
      };
    },
    [feesData, sortedWithdrawalFees]
  );

  return {
    feesData,
    feesLoading,
    errorMessage: confirmCreateAssetForUserErrorMessage,
    getAmountFeeData,
    getWithdrawalFees,
  };
};
