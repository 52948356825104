import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const TicketIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.interactive.brandColor;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M6.55256 13.3174H9.5M6.55256 10.301H12.4474M1.33327 13.2486C0.888909 11.3098 0.888909 9.2921 1.33327 7.35332C1.94858 4.66874 3.99673 2.5726 6.61985 1.94289C8.51424 1.48811 10.4858 1.48811 12.3801 1.94289C15.0033 2.5726 17.0514 4.66875 17.6667 7.35333C18.1111 9.2921 18.1111 11.3098 17.6667 13.2486C17.0514 15.9332 15.0033 18.0293 12.3801 18.659C10.4858 19.1138 8.51424 19.1138 6.61986 18.659C3.99673 18.0293 1.94858 15.9332 1.33327 13.2486Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </CustomSvg>
  );
};

export default TicketIcon;
