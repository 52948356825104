import styled from "styled-components";

export const PasswordInputContainer = styled.div`
  position: relative;
`;

export const PasswordInfoWrapper = styled.div`
  right: 0%;
  position: absolute;
  bottom: calc(100% + 1.6rem);
`;
