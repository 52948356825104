import { FC } from 'react';

import { Dayjs } from 'dayjs';

import { useTheme } from 'styled-components';

import { useFormatTime } from '@libs/hooks';
import { Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { CardDepositDiscalimerIcon } from 'src/assets';

import {
  Block,
  CloseIconWrapper,
  Container,
  ContentWrapper,
  CustomButton,
  CustomCloseIcon,
  DisaclimerIcon,
  DiscalimerWrapper,
  Line,
  TimerWrapper,
} from './styles';

type CardOperationTimerProps = {
  closeModal: () => void;
  date?: Dayjs;
};

type TimerBlockProps = {
  time: string;
};

export const CardOperationTimer: FC<CardOperationTimerProps> = ({
  closeModal,
  date,
}) => {
  const { t } = useTranslation();

  const { colors } = useTheme();

  const { minutes, seconds } = useFormatTime(
    undefined,
    date?.toDate(),
    closeModal
  );

  const minutesToShow = seconds.length === 2 ? seconds.charAt(0) : '0';

  const secondsToShow = seconds.length === 2 ? seconds.charAt(1) : seconds;

  return (
    <Container>
      <CloseIconWrapper onClick={closeModal}>
        <CustomCloseIcon />
      </CloseIconWrapper>
      <ContentWrapper>
        <DisaclimerIcon src={CardDepositDiscalimerIcon} />
        <DiscalimerWrapper>
          <Typography type="subHeadline" color={colors.text.secondary}>
            {t('disclaimer_interval')}
          </Typography>
        </DiscalimerWrapper>
        <Line />
        <TimerWrapper>
          <TimerBlock time="0" />
          <TimerBlock time={minutes} />
          <Typography type="titleTwo" fontFamily="primaryBold">
            {':'}
          </Typography>
          <TimerBlock time={minutesToShow} />
          <TimerBlock time={secondsToShow} />
        </TimerWrapper>
      </ContentWrapper>
      <CustomButton onClick={closeModal} title={t('common_close')} />
    </Container>
  );
};

const TimerBlock: FC<TimerBlockProps> = ({ time }) => {
  return (
    <Block>
      <Typography type="titleTwo" fontFamily="primaryBold">
        {time}
      </Typography>
    </Block>
  );
};

export default CardOperationTimer;
