import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { BankCode } from '@libs/backend-common';

import { DefaultArg, DefaultApiResponse, assetFragment } from '@libs/graphql';
import { WithdrawalMethods } from '@libs/types';

export type GetWithdrawalFeesType = {
  id: number;
  rate: number;
  fixed: number;
  extraConditions?: {
    destinationBankCode: BankCode;
  };
  greaterThan: number;
};
export type GetWithdrawalFeesArgs = {
  method: WithdrawalMethods;
  assetCode: string | number;
};
export type GetWithdrawalFeesResponse = {
  getWithdrawalFees: DefaultApiResponse<GetWithdrawalFeesType[]>;
};
export const getWithdrawalFeesQuery: TypedDocumentNode<
  GetWithdrawalFeesResponse,
  DefaultArg<GetWithdrawalFeesArgs>
> = gql`
  query GetWithdrawalFees($record: WithdrawalFeesRecord!) {
    getWithdrawalFees(record: $record) {
      id
      asset {
        ...assetFields
      }
      rate
      fixed
      extraConditions {
        destinationBankCode
        destinationNetwork
      }
      greaterThan
    }
  }
  ${assetFragment}
`;
