import {
  basisBankIcon,
  bogIcon,
  cartuBankIcon,
  credoBankIcon,
  halykBankIcon,
  isBankIcon,
  libertyBankIcon,
  pashaBankIcon,
  procreditBankIcon,
  silkBankIcon,
  tbcBankIcon,
  teraBankIcon,
  ziraatBankIcon,
  zenBankIcon,
} from 'src/assets';
import { BankCode } from '@libs/backend-common';

export const BOG_REFERENCE_CODE = '26127159';

export const BANK_ICONS = {
  [BankCode.TBCBGE22]: tbcBankIcon,
  [BankCode.BAGAGE22]: bogIcon,
  [BankCode.JSCRGE22]: credoBankIcon,
  [BankCode.ISBKGE22]: isBankIcon,
  [BankCode.PAHAGE22]: pashaBankIcon,
  [BankCode.HABGGE22]: halykBankIcon,
  [BankCode.TEBAGE22]: teraBankIcon,
  [BankCode.DISNGE22]: silkBankIcon,
  [BankCode.MIBGGE22]: procreditBankIcon,
  [BankCode.TCZBGE22]: ziraatBankIcon,
  [BankCode.CRTUGE22]: cartuBankIcon,
  [BankCode.CBASGE22]: basisBankIcon,
  [BankCode.LBRTGE22]: libertyBankIcon,
  [BankCode.BZENLT22]: zenBankIcon,
};
