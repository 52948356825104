import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useTheme } from 'styled-components';

import { meQuery } from '@libs/graphql';
import { Button, Input, RESEND_CODE_TIME, Typography } from '@libs/components';

import { FeatureRenderer, ResultFeedback } from 'src/components';
import { ResultFeedbackType } from 'src/constants';
import { useApiResponse, useCounter, useTranslation } from 'src/hooks';

import { FeatureModuleEnum } from '@libs/backend-common';
import { useGoogleTwoFa } from './hooks/useGoogleTwoFa';

import {
  CustomBtnCont,
  CustomForm,
  CustomInputCont,
  CustomLi,
  CustomQrContainer,
  CustomUl,
  MutationErrorContn,
  QrImg,
  QrWrapper,
  Wrapper,
} from './styles';

type GoogleTwoFaProps = {
  smsTwoFa?: boolean;
  emailTwoFa?: boolean;
  twoFaStatus: boolean;
  onShowResult?: () => void;
  onClose: () => void;
};

const GoogleTwoFa: React.FC<GoogleTwoFaProps> = ({
  smsTwoFa = false,
  emailTwoFa = false,
  twoFaStatus,
  onClose,
  onShowResult,
}) => {
  let btnText: string;
  const { colors } = useTheme();
  const { t } = useTranslation();
  const meRes = useQuery(meQuery);
  const { response } = useApiResponse(meRes);
  const {
    countDown: smsCountDown,
    counterInterval: smsCounterInterval,
    isCounterRunning: isSmsCounterRunning,
  } = useCounter(RESEND_CODE_TIME);

  const {
    countDown: emailCountDown,
    counterInterval: emailCounterInterval,
    isCounterRunning: isEmailCounterRunning,
  } = useCounter(RESEND_CODE_TIME);

  const {
    values,
    getQrCode,
    twoFactorSecret,
    touched,
    errors,
    isToggling2Fa,
    setFieldValue,
    handleGetQrCode,
    handleSubmit,
    handleSendSmsCode,
    errorMessage,
    twoFaDeactiveStatus,
    twoFaActiveStatus,
    handleSendEmailCode,
  } = useGoogleTwoFa(twoFaStatus, smsCounterInterval, emailCounterInterval);

  if (!twoFaStatus) {
    btnText = 'activate';
  } else {
    btnText = 'deactivate';
  }
  const onSendSmsCode = () => {
    handleSendSmsCode();
  };
  const onSendEmailCode = () => {
    handleSendEmailCode({
      variables: { record: {} },
    });
  };

  const handleFieldChange = (field: string) => (value: string) =>
    setFieldValue(field, value);

  const sendSmsCodeBtnText = isSmsCounterRunning
    ? `${smsCountDown}`
    : t('input_button');
  const sendEmailCodeBtnText = isEmailCounterRunning
    ? `${emailCountDown}`
    : t('input_button');

  useEffect(() => {
    if (!response?.me.isTwoFactorEnabled) {
      handleGetQrCode();
    }
  }, [handleGetQrCode, response]);

  useEffect(() => {
    if ((twoFaDeactiveStatus || twoFaActiveStatus) && onShowResult) {
      onShowResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFaActiveStatus, twoFaDeactiveStatus]);
  if (twoFaDeactiveStatus || twoFaActiveStatus) {
    const description = twoFaActiveStatus
      ? 'success_turnon_google_2fa'
      : 'success_turnoff_google_2fa';
    return (
      <ResultFeedback
        onClick={onClose}
        type={ResultFeedbackType.SUCCESS}
        description={t(description)}
      />
    );
  }
  return (
    <Wrapper data-testid="googleTwoFa">
      {!twoFaStatus && (
        <CustomQrContainer data-testid="twoFaQrCont">
          <QrWrapper>
            <QrImg src={getQrCode} />
            <Typography
              fontSize={1.2}
              lineHeight={1.4}
              type="primary"
              fontFamily="primary"
            >
              {twoFactorSecret}
            </Typography>
          </QrWrapper>
          <CustomUl>
            <CustomLi>
              <Typography
                fontSize={1.4}
                lineHeight={2.1}
                type="secondary"
                fontFamily="primary"
              >
                {t('google_2fa_scan_qr_descr_one')}
              </Typography>
            </CustomLi>
            <CustomLi>
              <Typography
                fontSize={1.4}
                lineHeight={2.1}
                type="secondary"
                fontFamily="primary"
              >
                {t('google_2fa_scan_qr_descr_second')}
              </Typography>
            </CustomLi>
          </CustomUl>
        </CustomQrContainer>
      )}
      <CustomForm onSubmit={handleSubmit}>
        <CustomInputCont>
          <Input
            type="number"
            pattern="[0-9]{6}"
            name="twoFactorCode"
            testId="googleTwoFaInput"
            onChangeText={handleFieldChange('twoFactorCode')}
            value={values.twoFactorCode}
            placeholder={t('google_2fa_input_placeholder')}
            label={t('google_2fa_scan_qr_input_label')}
            errorMessage={touched.twoFactorCode ? t(errors.twoFactorCode) : ''}
          />
        </CustomInputCont>
        <FeatureRenderer acceptedFeature={[FeatureModuleEnum.SMS_2FA]}>
          {smsTwoFa && (
            <CustomInputCont data-testid="twoFaInputCont">
              <Input
                onClick={onSendSmsCode}
                type="number"
                pattern="[0-9]{6}"
                name="smsTwoFactorCode"
                testId="googleTwoFaSmsInput"
                onChangeText={handleFieldChange('smsTwoFactorCode')}
                value={values.smsTwoFactorCode || ''}
                placeholder={t('google_2fa_sms_input_placeholder')}
                label={t('two_fa_sms_input_label')}
                errorMessage={
                  touched.smsTwoFactorCode ? t(errors.smsTwoFactorCode) : ''
                }
                valid={!isSmsCounterRunning}
                sendCode={sendSmsCodeBtnText}
              />
            </CustomInputCont>
          )}
        </FeatureRenderer>
        <FeatureRenderer acceptedFeature={[FeatureModuleEnum.EMAIL_2FA]}>
          {emailTwoFa && (
            <CustomInputCont data-testid="twoFaInputCont">
              <Input
                onClick={onSendEmailCode}
                type="number"
                pattern="[0-9]{6}"
                name="emailTwoFactorCode"
                onChangeText={handleFieldChange('emailTwoFaCode')}
                value={values.emailTwoFaCode || ''}
                placeholder={t('google_2fa_email_input_placeholder')}
                label={t('two_fa_email_input_label')}
                errorMessage={
                  touched.emailTwoFaCode ? t(errors.emailTwoFaCode) : ''
                }
                valid={!isEmailCounterRunning}
                sendCode={sendEmailCodeBtnText}
              />
            </CustomInputCont>
          )}
        </FeatureRenderer>
        <CustomBtnCont>
          <MutationErrorContn>
            <Typography
              fontSize={1.4}
              lineHeight={2.1}
              color={colors.text.critical}
              fontFamily="primary"
            >
              {t(errorMessage)}
            </Typography>
          </MutationErrorContn>
          <Button
            testId="googleTwoFaBtn"
            type="submit"
            isLoading={isToggling2Fa}
          >
            {t(btnText)}
          </Button>
        </CustomBtnCont>
      </CustomForm>
    </Wrapper>
  );
};
export default GoogleTwoFa;
