import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const SendIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.surface.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_2094_1227)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.84183 9.52982L20.7315 3.05375C20.8278 3.0061 20.9362 2.98856 21.0426 3.00344C21.149 3.01832 21.2484 3.06493 21.3278 3.13714C21.4072 3.20936 21.4629 3.30382 21.4875 3.40813C21.5121 3.51245 21.5046 3.62175 21.4659 3.72172L15.0037 20.6568C14.9667 20.7542 14.9019 20.8386 14.8172 20.8996C14.7326 20.9606 14.6318 20.9954 14.5275 20.9998C14.4232 21.0041 14.3198 20.9778 14.2303 20.9242C14.1409 20.8705 14.0691 20.7918 14.0241 20.6978L11.4202 15.3247L16.5884 7.94633L9.20541 13.0865L3.80218 10.5054C3.70836 10.4604 3.62979 10.389 3.57617 10.3001C3.52255 10.2111 3.49622 10.1084 3.50044 10.0046C3.50465 9.90087 3.53923 9.80063 3.5999 9.71626C3.66056 9.63189 3.74467 9.56708 3.84183 9.52982Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2094_1227">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3.5 3)"
          />
        </clipPath>
      </defs>
    </CustomSvg>
  );
};

export default SendIcon;
