import styled from 'styled-components';

export const Info = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.surface.disabled};
  border: 1px solid ${({ theme }) => theme.colors.borders.disabled};
  padding: 1rem 1.8rem;
`;

export const InfoIcon = styled.div`
  margin-right: 1.2rem;
  width: 3.8rem;
  height: 3.8rem;
`;

export const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Img = styled.img`
  margin-right: 0.8rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  width: 3rem;
  height: 3rem;
  object-fit: cover;
`;
