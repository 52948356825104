import { TransactionErrorResponse } from 'src/types';
import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { CurrencyType } from '@libs/types';
import {
  DefaultApiResponse,
  DefaultArg,
  transactionErrorFragment,
} from '@libs/graphql';

export type CalculateLoanValue = {
  loanAmount: number;
  collateralAmount: number;
  interestRate: number;
  minimumInterest: number;
  initialLtv: number;
  liquidationLtv: number;
  marginCallLtv: number;
  interestPerMinute: number;
  collateralAssetPrice: number;
  minLoanAmount: number;
  error?: TransactionErrorResponse;
};
export type CalculateLoanValueArgs = {
  collateralAssetCode: CurrencyType;
  loanAssetCode: CurrencyType;
  originalAmount: number;
  originalAmountAssetCode: CurrencyType;
  loanTypeId: number;
};

export type CalculateLoanValueResponse = {
  calculateLoanValues: DefaultApiResponse<CalculateLoanValue>;
};

export const calculateLoanValueMutation: TypedDocumentNode<
  CalculateLoanValueResponse,
  DefaultArg<CalculateLoanValueArgs>
> = gql`
  query CalculateLoanValues($record: CalculateLoanValuesRecord!) {
    calculateLoanValues(record: $record) {
      loanAmount
      collateralAmount
      interestRate
      minimumInterest
      initialLtv
      liquidationLtv
      marginCallLtv
      interestPerMinute
      collateralAssetPrice
      error {
        ...transactionErrorFields
      }
    }
  }
  ${transactionErrorFragment}
`;
