import { useState } from 'react';

import { ResultFeedbackType } from 'src/constants';
import { useTranslation } from 'src/hooks';
import { AccountInfoModalProps } from '../account-information/types';

import ChangePasswordForm from './components/ChangePasswordForm';
import { useChangePassword } from './hooks/useChangePassword';
import { CustomModalWithTwoFa, CustomResultFeedback } from './styles';

const ChangePasswordModal: React.FC<AccountInfoModalProps> = ({
  onCancel,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const [shouldShowContent, setShouldShowContent] = useState(true);

  const showContent = () => {
    setShouldShowContent(true);
  };
  const hideContent = () => {
    setShouldShowContent(false);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    feedbackType,
    touched,
    isFormValid,
    errorMessage,
    setTouched,
    handlePasswordChange,
  } = useChangePassword({
    onSuccess: showContent,
    onFormSubmit: hideContent,
  });

  const description =
    feedbackType === ResultFeedbackType.SUCCESS
      ? 'success_pasword_change'
      : errorMessage || 'error_pasword_change';

  return (
    <CustomModalWithTwoFa
      shouldShowContent={shouldShowContent}
      title={t('password_change')}
      testId="SecurityPasswordModal"
      onModalClose={onCancel}
      onAuthFinish={handlePasswordChange}
      {...modalProps}
    >
      {feedbackType && onCancel ? (
        <CustomResultFeedback
          onClick={onCancel}
          type={feedbackType}
          description={t(description)}
        />
      ) : (
        <ChangePasswordForm
          errors={errors}
          values={values}
          touched={touched}
          isFormInValid={!isFormValid}
          errorMessage={errorMessage}
          onFormSubmit={handleSubmit}
          handleChange={handleChange}
          onCancel={onCancel}
          setTouched={setTouched}
        />
      )}
    </CustomModalWithTwoFa>
  );
};
export default ChangePasswordModal;
