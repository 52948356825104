import { useMutation } from '@apollo/client';
import { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { screenSizes } from '@libs/theme';
import { JurisdictionsEnum } from '@libs/backend-common';
import { loginMutation, LoginResponse } from '@libs/graphql';

import { ErrorMessageTexts, TwoFactorAuthType } from 'src/constants';
import {
  useApiResponse,
  useAppDisclaimer,
  useAuth,
  useDimensions,
  useJurisdictionDetector,
  useTwoFaDetection,
} from 'src/hooks';
import { detectTwoFaTypeFromToken } from 'src/helpers';

export const useLoginForm = (openTwoFaModal: () => void) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [email, setEmail] = useState<string>();

  const { login, decodeToken } = useAuth();
  const { detectTwoFaType } = useTwoFaDetection(search);
  const [twoFaType, setTwoFaType] = useState<TwoFactorAuthType>();

  const { width } = useDimensions();

  const handleAfterLogin = useCallback(
    (token?: string) => {
      const decodedToken = decodeToken(token);

      if (decodedToken && token) {
        login(token);

        if (
          !decodedToken.isSmsTwoFactorEnabled &&
          !decodedToken.isEmailTwoFactorEnabled &&
          !decodedToken.isTwoFactorEnabled
        ) {
          navigate('/', {
            replace: true,
          });

          return;
        }

        if (width <= screenSizes.tablet) {
          setTwoFaType(detectTwoFaType(decodedToken));
          openTwoFaModal();
          return;
        }

        navigate(
          `/two-factor-auth?type=${detectTwoFaTypeFromToken(decodedToken)}`,
          {
            replace: true,
          }
        );
      }
    },
    [decodeToken, detectTwoFaType, login, navigate, openTwoFaModal, width]
  );

  const [handleLogin, loginRes] = useMutation(loginMutation);

  const { currentJurisdiction } = useJurisdictionDetector();

  const isJurisdictionGe = currentJurisdiction === JurisdictionsEnum.GE;

  const isNotMobileApp = !window.isFromMobileApp;

  const { setIsAppDisclaimerVisible } = useAppDisclaimer();

  const handleSuccessLogin = useCallback(
    (data: LoginResponse) => {
      if (!data?.login) return;
      const { accessToken } = data.login;
      handleAfterLogin(accessToken);
      if (isJurisdictionGe && isNotMobileApp) {
        setIsAppDisclaimerVisible(true);
      }
    },
    [
      handleAfterLogin,
      isJurisdictionGe,
      isNotMobileApp,
      setIsAppDisclaimerVisible,
    ]
  );

  const onTwoFaStepCompete = useCallback((nextTwoFa: TwoFactorAuthType) => {
    setTwoFaType(nextTwoFa);
  }, []);

  const onTwoFaAuthFinish = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const { errorMessage, loading, clearErrorMessage } = useApiResponse(
    loginRes,
    handleSuccessLogin
  );

  useEffect(() => {
    if (errorMessage === ErrorMessageTexts.EMAIL_NOT_VERIFIED && email) {
      navigate(`/verify-email?email=${email}`, {
        state: {
          options: {
            isVerified: true,
          },
        },
      });
    }
  }, [navigate, email, errorMessage]);

  return {
    errorMessage,
    twoFaType,
    loading,
    handleLogin,
    clearErrorMessage,
    onTwoFaAuthFinish,
    onTwoFaStepCompete,
    setEmail,
  };
};
