import gql from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import {
  DefaultApiResponse,
  swapFragment,
  swapTaskFragment,
} from '@libs/graphql';
import { Swap, SwapTask } from '@libs/types';

export type GetSwapsForOngoingTasks = {
  swaps: Swap[];
  swapTasks: SwapTask[];
};

export type GetSwapsForOngoingTasksResponse = {
  getSwapsForOngoingTasks: DefaultApiResponse<GetSwapsForOngoingTasks>;
};

export const getSwapsForOngoingTasksQuery: TypedDocumentNode<GetSwapsForOngoingTasksResponse> = gql`
  query GetSwapsForOngoingTasks {
    getSwapsForOngoingTasks {
      swaps {
        ...swapFields
        swapTask {
          ...swapTaskFields
        }
        deposit {
          amount
        }
        exchange {
          rate
        }
        withdrawal {
          amount
        }
      }
      swapTasks {
        ...swapTaskFields
        swaps {
          ...swapFields
        }
      }
    }
  }
  ${swapTaskFragment}
  ${swapFragment}
`;
