import styled from 'styled-components';
import { Typography } from '@libs/components';

import { CurrencySwitcher } from 'src/components';

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 20rem;

  padding: 0 1.7rem 2rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  box-shadow: ${({ theme }) => theme.shadows.xl3};

  ${({ theme }) => theme.media.laptop`
    padding: 0 2.4rem 2rem;

    box-shadow: 0px 135px 54px rgba(0, 0, 0, 0.01),
    0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09),
    0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  
  `};
`;

export const Content = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  margin-top: 2.9rem;
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.media.tablet`
    margin-top:1.7rem
  `}
`;

export const BalanceWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const AllBalance = styled.div`
  margin-top: 0.4rem;
`;

//fix for WEBPACK_DEFAULT_EXPORT error in cypress
export const CustomCurrencySwitcher = styled((props) => (
  <CurrencySwitcher {...props} />
))`
  max-width: 14rem;
  width: 14rem;
`;

export const BalanceDivision = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;

  padding-top: 0.2rem;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const CryptoBalanceWrapper = styled.div`
  width: 50%;

  margin: 1.2rem 0 1.2rem 2.1rem;
`;

export const CryptoBalance = styled.div`
  margin-top: 0.3rem;
`;

export const Line = styled.div`
  width: 0.1rem;
  height: 5.2rem;

  margin: 0 1.7rem;

  background-color: ${({ theme }) => theme.colors.borders.default};
`;

export const FiatBalanceWrapper = styled.div`
  width: 50%;

  margin: 1.2rem 0 1.2rem 2.1rem;
`;

export const FiatBalance = styled.div`
  margin-top: 0.3rem;
`;

export const TotalBalance = styled(Typography)`
  font-size: 1.5rem;
  line-height: 2rem;

  ${({ theme }) => theme.media.mobileM`
    font-size: 2rem;
    line-height: 2.4rem;
  `}
  ${({ theme }) => theme.media.tablet`
    font-size: 2.8rem;
    line-height: 3.4rem;
  `}
`;
export const UABalanceContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 10rem;

  padding: 0 1.7rem 2rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  background-color: ${({ theme }) => theme.colors.surface.default};

  box-shadow: ${({ theme }) => theme.shadows.xl3};

  ${({ theme }) => theme.media.laptop`
    padding: 0 2.4rem 2rem;
    height:12rem;
    box-shadow: 0px 135px 54px rgba(0, 0, 0, 0.01),
    0px 76px 46px rgba(0, 0, 0, 0.05), 0px 34px 34px rgba(0, 0, 0, 0.09),
    0px 8px 19px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  
  `};
`;
export const CustomBalanceWrapper = styled(BalanceWrapper)`
  margin-top: 2rem;
`;
export const ContentContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const Img = styled.img`
  position: absolute;
  height: 11.9rem;
  right: -2rem;
  top: -0.4rem;
  ${({ theme }) => theme.media.laptop`
    height: 13rem;
    top: 0.2rem;
  `}
`;
export const TotalBalanceUa = styled(Typography)`
  font-size: 1.5rem;
  line-height: 2rem;

  ${({ theme }) => theme.media.mobileL`
    font-size: 2rem;
    line-height: 2.4rem;
  `}
  ${({ theme }) => theme.media.tablet`
    font-size: 2.8rem;
    line-height: 3.4rem;
  `}
`;
