import { Link } from '@libs/components';
import styled from 'styled-components';

const CustomLink = styled(Link)`
  margin: 0.8rem 1.6rem;
  display: inline-block;
`;

const DefaultNav = () => {
  return (
    <nav>
      <CustomLink to="/login">Login</CustomLink>
      <CustomLink to="/register">Register</CustomLink>
      <CustomLink to="/">Dashboard</CustomLink>
      <CustomLink to="/components">Components</CustomLink>
      <CustomLink to="/password-recovery">Password recovery</CustomLink>
      <CustomLink to="/verify-email">Verify email</CustomLink>
      <CustomLink to="/edit-profile">Edit profile</CustomLink>
      <CustomLink to="/edit-email">Edit email</CustomLink>
      <CustomLink to="/password-change">Change password</CustomLink>
      <CustomLink to="/account-information">acc info</CustomLink>
    </nav>
  );
};

export default DefaultNav;
