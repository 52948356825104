import { useTheme } from 'styled-components';

import {
  AllBalanceTitle,
  Container,
  Wrapper,
  CurrencyToggler,
  BalanceAmount,
  AssetPrices,
  ChartContainer,
  Chart,
} from './styles';

type UserBalanceLoaderProps = {
  isOpened: boolean;
};
export const UserBalanceLoader: React.FC<UserBalanceLoaderProps> = ({
  isOpened,
}) => {
  const { colors } = useTheme();
  return (
    <Container isOpened={isOpened}>
      <Wrapper
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <AllBalanceTitle />
        <CurrencyToggler />
        <BalanceAmount />
        {isOpened && <AssetPrices />}
      </Wrapper>
    </Container>
  );
};

export const UserBalanceChartLoader: React.FC = () => {
  const { colors } = useTheme();
  return (
    <ChartContainer>
      <Wrapper
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <Chart />
      </Wrapper>
    </ChartContainer>
  );
};
