import { useState } from 'react';
import { useTranslation } from 'src/hooks';
import { SearchIcon } from 'src/assets';

import { CustomSearchInput } from './styles';

type AssetSearchInputProps = {
  setSearchInputValue: (val: string) => void;
  searchInputValue: string;
  className?: string;
};

export const AssetSearchInput: React.FC<AssetSearchInputProps> = ({
  setSearchInputValue,
  searchInputValue,
  className,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <CustomSearchInput
      className={className}
      icon={<SearchIcon />}
      placeholder={t('common_search')}
      onChangeText={setSearchInputValue}
      value={searchInputValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      isFocused={isFocused}
    />
  );
};
export default AssetSearchInput;
