import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { NewStakingRecord } from 'src/types';

export type CreateStakingPreRequestArgs = DefaultArg<NewStakingRecord>;
export type CreateStakingPreRequestRes = {
  createStakingPreRequest: DefaultApiResponse<{
    id: string | number;
  }>;
};

export const requestStakingMutation: TypedDocumentNode<CreateStakingPreRequestRes> = gql`
  mutation CreateStakingPreRequest($record: NewStakingRecord!) {
    createStakingPreRequest(record: $record) {
      id
    }
  }
`;
