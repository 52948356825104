import { useState, useCallback } from 'react';

import { ApolloError, useMutation } from '@apollo/client';

import { addNewCardMutation, AddNewCardResponse } from 'src/gql';
import { useApiResponse, useError } from 'src/hooks';

export const useAddNewCard = () => {
  const [newCardError, setNewCardError] = useState<ApolloError>();

  const handleSuccessNewCard = useCallback((data: AddNewCardResponse) => {
    const status = data.addNewCard.redirectUrl;
    if (status) {
      window.location.replace(status);
    } else {
      // NOTE: custom function provided from mobile app's WebView to indicate successful verification
      window?.onAddCardErrorForRN?.();
    }
  }, []);

  const [AddNewCardValue, { data: newCardData }] = useMutation(
    addNewCardMutation,
    {
      onError: setNewCardError,
    }
  );

  const { errors: newCardErrors } = useApiResponse<
    AddNewCardResponse,
    ApolloError
  >({ data: newCardData, errors: newCardError }, handleSuccessNewCard);

  const { errorMessage: newCardErrorText } = useError(newCardErrors);

  const errorMessage = newCardErrorText ? newCardErrorText : '';

  return { AddNewCardValue, errorMessage };
};
