import { gql, TypedDocumentNode } from '@apollo/client';

import { Staking } from '@libs/types';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

type ViolateStakingArgs = {
  id: number;
};

export type ViolateStakingResponse = {
  createStaking: DefaultApiResponse<Staking>;
};

export const violateStakingMutation: TypedDocumentNode<
  ViolateStakingResponse,
  DefaultArg<ViolateStakingArgs>
> = gql`
  mutation ViolateStaking($record: StakingIdRecord!) {
    violateStaking(record: $record) {
      id
    }
  }
`;
