import { useTheme } from 'styled-components';
import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

const StarIcon: React.FC<SvgIconDefaultProps> = ({ className, color }) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7731 15.1132C11.4198 15.1132 10.9664 14.9999 10.3998 14.6665L8.40642 13.4865C8.19975 13.3665 7.79975 13.3665 7.59975 13.4865L5.59975 14.6665C4.41975 15.3666 3.72642 15.0866 3.41309 14.8599C3.10642 14.6332 2.62642 14.0532 2.93975 12.7199L3.41309 10.6732C3.46642 10.4599 3.35975 10.0932 3.19975 9.93322L1.54642 8.27988C0.719753 7.45322 0.78642 6.74655 0.899753 6.39988C1.01309 6.05322 1.37309 5.43988 2.51975 5.24655L4.64642 4.89322C4.84642 4.85988 5.13309 4.64655 5.21975 4.46655L6.39975 2.11322C6.93309 1.03988 7.63309 0.879883 7.99975 0.879883C8.36642 0.879883 9.06642 1.03988 9.59975 2.11322L10.7731 4.45988C10.8664 4.63988 11.1531 4.85322 11.3531 4.88655L13.4798 5.23988C14.6331 5.43322 14.9931 6.04655 15.0998 6.39322C15.2064 6.73988 15.2731 7.44655 14.4531 8.27322L12.7998 9.93322C12.6398 10.0932 12.5398 10.4532 12.5864 10.6732L13.0598 12.7199C13.3664 14.0532 12.8931 14.6332 12.5864 14.8599C12.4198 14.9799 12.1531 15.1132 11.7731 15.1132ZM7.99975 12.3932C8.32642 12.3932 8.65309 12.4732 8.91309 12.6266L10.9064 13.8065C11.4864 14.1532 11.8531 14.1532 11.9931 14.0532C12.1331 13.9532 12.2331 13.5999 12.0864 12.9466L11.6131 10.8999C11.4864 10.3465 11.6931 9.63322 12.0931 9.22655L13.7464 7.57322C14.0731 7.24655 14.2198 6.92655 14.1531 6.70655C14.0798 6.48655 13.7731 6.30655 13.3198 6.23322L11.1931 5.87988C10.6798 5.79322 10.1198 5.37988 9.88642 4.91322L8.71309 2.56655C8.49975 2.13988 8.23309 1.88655 7.99975 1.88655C7.76642 1.88655 7.49975 2.13988 7.29309 2.56655L6.11309 4.91322C5.87975 5.37988 5.31975 5.79322 4.80642 5.87988L2.68642 6.23322C2.23309 6.30655 1.92642 6.48655 1.85309 6.70655C1.77975 6.92655 1.93309 7.25322 2.25975 7.57322L3.91309 9.22655C4.31309 9.62655 4.51975 10.3465 4.39309 10.8999L3.91975 12.9466C3.76642 13.6066 3.87309 13.9532 4.01309 14.0532C4.15309 14.1532 4.51309 14.1465 5.09975 13.8065L7.09309 12.6266C7.34642 12.4732 7.67309 12.3932 7.99975 12.3932Z"
        fill={iconColor}
      />
    </CustomSvg>
  );
};
export default StarIcon;
