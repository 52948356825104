import { useState } from 'react';
import { CurrencyConverterType } from '@libs/types';

import { BalanceType, CurrencySymbol } from 'src/constants';

export const useCurrencyConverter = (
  defaultCurrency: BalanceType
): CurrencyConverterType => {
  const [currency, setCurrency] = useState<BalanceType>(defaultCurrency);

  return {
    currency,
    setCurrency,
    currencySymbol: CurrencySymbol[currency],
  };
};
