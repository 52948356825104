import styled from 'styled-components';
type CustomImgProps = {
  shouldDisplayOnMobile: boolean;
  mobileHeight: number;
  shouldTransform: boolean;
  blur: number;
  rightPositionMobile: number;
  topPositionMobile: number;
  laptopHeight: number;
  laptopTopPosition: number;
  laptopRightPosition: number;
};

export const ImagesContainer = styled.div`
  display: relative;
  margin: 0;
  padding: 0;
`;
export const CustomImg = styled.img<CustomImgProps>`
  position: absolute;
  height: ${({ mobileHeight }) => `${mobileHeight}rem`};
  transform: ${({ shouldTransform }) => (shouldTransform ? 'scaleX(-1);' : '')};
  filter: ${({ blur }) => `blur(${blur}rem);`};
  right: ${({ rightPositionMobile }) => `${rightPositionMobile}rem;`};
  top: ${({ topPositionMobile }) => `${topPositionMobile}rem;`};
  display: ${({ shouldDisplayOnMobile }) => !shouldDisplayOnMobile && 'none'};
  ${({ theme, laptopHeight, laptopRightPosition, laptopTopPosition }) => theme
    .media.laptop`
    display: block;
    height:${laptopHeight as unknown as TemplateStringsArray}rem;
    right: ${laptopRightPosition as unknown as TemplateStringsArray}rem;
    top:${laptopTopPosition as unknown as TemplateStringsArray}rem;
  `}
`;
