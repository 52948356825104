import styled from 'styled-components';

import { SelectModalOption } from '@libs/components';

export const CustomSelectModalOption = styled(SelectModalOption)`
  height: 7rem;
  padding: 1.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 0;

  ${({ theme }) => theme.media.tabletL`
    padding: 2rem;
  `}
`;
