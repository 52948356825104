import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';

import { DashboardNavItemType } from 'src/constants';
import { useJurisdictionDetector, useTranslation } from 'src/hooks';

import { JurisdictionsEnum } from '@libs/backend-common';
import {
  ActiveLine,
  MobileIconWrapper,
  MobileNavItemContainer,
} from '../styles';

type MobileNavItemProps = {
  item: DashboardNavItemType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  testId?: string;
  modalIsOpen?: boolean;
};

const MobileNavItem: React.FC<MobileNavItemProps> = ({
  item,
  onClick,
  testId = 'navItem',
  modalIsOpen,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  const { currentJurisdiction } = useJurisdictionDetector();

  const isCrexone = currentJurisdiction === JurisdictionsEnum.UA;

  return (
    <MobileNavItemContainer
      to={item.route}
      onClick={handleClick}
      data-cy={testId}
    >
      {({ isActive }) => {
        const color =
          isActive && !modalIsOpen
            ? colors.interactive.brandColor
            : colors.text.secondary;

        const font = isActive && !modalIsOpen ? 'primaryBold' : 'primaryMedium';

        return (
          <>
            <MobileIconWrapper>
              {isActive && !modalIsOpen ? item.activeIcon : item.icon}
            </MobileIconWrapper>

            <Typography fontFamily={font} type="caption" color={color}>
              {t(item.title)}
            </Typography>
            {isCrexone && isActive && !modalIsOpen && <ActiveLine />}
          </>
        );
      }}
    </MobileNavItemContainer>
  );
};

export default MobileNavItem;
