import {
  getAlternativeAssetCurrency as getNativeAlternativeAssetCurrency,
  getAlternativeAssetLabel as getNativeAlternativeAssetLabel,
  getJurisdictionBaseUrl,
  getUsdOrEurCurrencySymbol as getNativeUsdOrEurCurrencySymbol,
} from '@libs/helpers';
import { CurrencyType } from '@libs/types';

const { jurisdictionId } = getJurisdictionBaseUrl(process.env.ENVIRONMENT);

export const getAlternativeAssetCurrency = (assetCode: CurrencyType) =>
  getNativeAlternativeAssetCurrency(assetCode, jurisdictionId);
export const getAlternativeAssetLabel = (assetCode: CurrencyType) =>
  getNativeAlternativeAssetLabel(assetCode, jurisdictionId);
export const getUsdOrEurCurrencySymbol = () =>
  getNativeUsdOrEurCurrencySymbol(jurisdictionId);
