import { useTheme } from 'styled-components';
import {
  AssetCode,
  AssetIcon,
  AssetPrice,
  BuyButton,
  DashboardPopularAssetsContainer,
  PopularAssetsLoader,
} from './styles';

export const DashboardPopularAssetsLoader = () => {
  const { colors } = useTheme();
  return (
    <DashboardPopularAssetsContainer>
      {[...Array(5)].map((item, i) => {
        return (
          <PopularAssetsLoader
            key={i}
            backgroundColor={colors.surface.pressed}
            foregroundColor={colors.text.interactive}
          >
            <AssetIcon />
            <AssetCode />
            <AssetPrice />
            <BuyButton />
          </PopularAssetsLoader>
        );
      })}
    </DashboardPopularAssetsContainer>
  );
};
