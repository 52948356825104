import { FC } from 'react';

import Lottie from 'lottie-react';

import { useTheme } from 'styled-components';

import { Typography, Button, ErrorMessage } from '@libs/components';
import { RegexValidators } from '@libs/validations';

import { messageAnimation } from 'src/animations';
import { useTranslation } from 'src/hooks';
import { GEORGIAN_NUMBER_LENGTH } from 'src/constants';

import {
  Container,
  CustomGeorgiaIcon,
  LottieWrapper,
  NumberInput,
  NumberInputWrapper,
  NumberPrefixWrapper,
} from './styles';

type EnterSmsProps = {
  handleButtonClick: () => void;
  setAddress: (val: string) => void;
  errorMessage?: string;
  address?: string;
  loading: boolean;
  clearSmsErrorMsg?: () => void;
};

export const EnterSms: FC<EnterSmsProps> = ({
  address,
  handleButtonClick,
  setAddress,
  loading,
  errorMessage,
  clearSmsErrorMsg,
}) => {
  const { colors } = useTheme();

  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (
      isNaN(+value) ||
      !RegexValidators.HAS_ONLY_POSITIVE_NUMBERS.test(value) ||
      value.length > GEORGIAN_NUMBER_LENGTH
    ) {
      return;
    }
    setAddress(value); // Update address state
    clearSmsErrorMsg?.();
  };

  const isValidNumber = RegexValidators.GE_PHONE_NUMBER.test(address || '');
  const isBtnDisabled = !isValidNumber || !!errorMessage;
  return (
    <Container>
      <LottieWrapper>
        <Lottie animationData={messageAnimation} />
      </LottieWrapper>
      <Typography type="bodyButton" color={colors.text.secondary}>
        {t('activate_2fa_enter_sms_desc')}
      </Typography>

      <NumberInputWrapper>
        <NumberPrefixWrapper>
          <CustomGeorgiaIcon />
          <Typography type="subHeadline">
            {t('activate_2fa_georgian_number_prefix')}
          </Typography>
        </NumberPrefixWrapper>
        <NumberInput
          placeholder={t('activate_2fa_sms_input_placeholder')}
          onChange={handleChange}
          value={address}
        />
      </NumberInputWrapper>
      <ErrorMessage errorMessage={errorMessage} />
      <Button
        title={t('activate_2fa_button_title')}
        onClick={handleButtonClick}
        isLoading={loading}
        disabled={isBtnDisabled}
      />
    </Container>
  );
};

export default EnterSms;
