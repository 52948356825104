import { PropsWithChildren } from 'react';

import { PopUpContainer } from './styles';

export type PopUpProps = PropsWithChildren & {
  isOpen: boolean;
  shouldPopTop?: boolean;
};

export const PopUp: React.FC<PopUpProps> = ({
  shouldPopTop,
  isOpen,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <PopUpContainer shouldPopTop={shouldPopTop}>{children}</PopUpContainer>
  );
};

export default PopUp;
