import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse } from '@libs/graphql';

export type RateHistoryItem = {
  buyRate: number;
  createdAt: Date;
};
export type PopularAsset = {
  iconUrl: string;
  code: string;
  price: number;
  change: string;
  rateHistory: RateHistoryItem[];
};
export type GetPopularAssetResponse = {
  getPopularAssets: DefaultApiResponse<PopularAsset[]>;
};
export const getPopularAssetsQuery: TypedDocumentNode<GetPopularAssetResponse> = gql`
  query GetPopularAssets {
    getPopularAssets {
      code
      iconUrl
      change
      price
      rateHistory {
        buyRate
        createdAt
      }
    }
  }
`;
