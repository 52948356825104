import { useTheme } from 'styled-components';

import { useNavigate } from 'react-router-dom';

import { Typography, CloseIcon } from '@libs/components';
import { screenSizes } from '@libs/theme';
import { TwoFactorAuthType } from '@libs/types';

import { ActivateTwoFaPicture } from 'src/assets';
import { useDimensions, useTranslation } from 'src/hooks';

import TwoFaOptions from '../twofa-options/TwoFaOptions';

import {
  Img,
  Container,
  TextContainer,
  TextAndImgContainer,
  Arrow,
  Line,
  LineWrapper,
  OptionAndTitleWrapper,
  CustomTypography,
  TextAndLineWrapper,
  CloseIconWrapper,
  SkipButton,
} from './styles';

type ActivateTwoFaProps = {
  handleClose?: () => void;
  handleTogglerClick?: (type: TwoFactorAuthType, isChecked: boolean) => void;
};

export const ActivateTwoFa: React.FC<ActivateTwoFaProps> = ({
  handleClose,
  handleTogglerClick,
}) => {
  const { t } = useTranslation();

  const { colors } = useTheme();

  const { width } = useDimensions();

  const navigate = useNavigate();

  const handleSkip = () => {
    if (width > screenSizes.tabletL) {
      handleClose?.();
    } else {
      navigate('/');
    }
  };

  return (
    <Container>
      <TextAndImgContainer>
        <Img src={ActivateTwoFaPicture} />
        <TextContainer>
          <Typography
            type={width < screenSizes.tabletL ? 'headline' : 'titleTwo'}
            fontFamily="primaryBold"
          >
            {t('activate_2fa_title')}
          </Typography>
          <Typography type="bodyButton" color={colors.text.secondary}>
            {t('activate_2fa_description')}
          </Typography>
        </TextContainer>
      </TextAndImgContainer>

      <LineWrapper>
        <Line />
        <Arrow />
        <Line />
      </LineWrapper>
      <div>
        {handleClose && (
          <CloseIconWrapper onClick={handleClose}>
            <CloseIcon color={colors.text.default} />
          </CloseIconWrapper>
        )}
        <OptionAndTitleWrapper>
          <Typography
            type={width < screenSizes.tabletL ? 'subHeadline' : 'headline'}
            fontFamily="primaryBold"
          >
            {t('activate_2fa_choose_method')}
          </Typography>
          <TwoFaOptions handleTogglerClick={handleTogglerClick} />
        </OptionAndTitleWrapper>
        <TextAndLineWrapper>
          <Line />
          <CustomTypography type="bodyButton" fontFamily="primaryMedium">
            {t('common_or')}
          </CustomTypography>
          <Line />
        </TextAndLineWrapper>
        <SkipButton onClick={handleSkip} title={t('register_skip_now')} />
      </div>
    </Container>
  );
};

export default ActivateTwoFa;
