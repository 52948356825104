import styled from 'styled-components';

import { Input, MaxContentWrapper, Typography } from '@libs/components';
import { TypographyProps } from '@libs/components/src/typography/Typography';

export const Container = styled(MaxContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5rem;
  max-width: 40rem;
  text-align: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  padding: 2.2rem;

  ${({ theme }) => theme.media.tablet`
    padding: 0;
  `};
`;

export const InputContainer = styled.div`
  width: 100%;
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 4.6rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: 3.2rem;
  `};
`;

export const FAQWrapper = styled.div`
  margin-top: 4rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: 3.2rem;
  `};
`;

export const RecoveryInfo = styled(Typography)<TypographyProps>`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 3.6rem;
  margin-top: 0.8rem;

  ${({ theme }) => theme.media.tablet`
    text-align: left;
    margin-bottom: 2rem;
    margin-top: 1.2rem;
  `};
`;

export const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .auth-banner {
    display: none;
  }

  ${({ theme }) => theme.media.tabletL`
    .auth-banner {
      display: block;
    }
  `}
`;

export const IndicatorWrapper = styled.div`
  width: 100%;
`;

export const CustomInput = styled(Input)`
  ${({ theme }) => theme.media.tablet`
       margin-top: 1.6rem
  `};
`;

export const Img = styled.img`
  width: 12.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin-top: 2rem;
`;

export const ChangeSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 2.2rem;
  gap: 2.2rem;
  max-width: 45rem;
  margin: 0 auto;
`;

export const LottieWrapper = styled.div`
  height: 12.5rem;
  width: 12.5rem;
`;

export const CustomTypography = styled(Typography)`
  display: none;
  ${({ theme }) => theme.media.tablet`
      display:block;
  `}
`;
