import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import {
  AMOUNT_OF_DAYS_IN_MONTH,
  AMOUNT_OF_DAYS_IN_WEEK,
  ONE_DAY,
} from '@libs/types';
import {
  getDate,
  getCurrentDate,
  getDateDifferenceInDays,
  useApiResponse,
} from '@libs/hooks';
import { meQuery } from '@libs/graphql';

import { HistoryPeriodEnumKeys } from 'src/gql';
import { useTranslation } from 'src/hooks';
import { ExchangeTabItem } from 'src/types';
import { formatChartDate } from 'src/helpers';

export const useBalanceTabsOptions = () => {
  const meRes = useQuery(meQuery);
  const { t } = useTranslation();

  const { response: meResponse, loading: meResponseLoading } =
    useApiResponse(meRes);

  const tabItems: ExchangeTabItem[] = useMemo(() => {
    const returnedValue: ExchangeTabItem[] = [
      {
        id: 0,
        amountOfDays: ONE_DAY,
        dayEnum: HistoryPeriodEnumKeys.ONE_DAY,
        dateFormat: 'h:mmA',
        title: t('time_24_hours'),
      },
    ];

    const daysAfterCreatingAccount = getDateDifferenceInDays(
      getCurrentDate(),
      getDate(meResponse?.me?.createdAt)
    );
    const shouldDisplayOneWeek =
      daysAfterCreatingAccount >= AMOUNT_OF_DAYS_IN_WEEK;

    const shouldDisplayOneMonth =
      daysAfterCreatingAccount >= AMOUNT_OF_DAYS_IN_MONTH;

    if (shouldDisplayOneWeek) {
      returnedValue.push({
        id: 1,
        dayEnum: HistoryPeriodEnumKeys.ONE_WEEK,
        amountOfDays: AMOUNT_OF_DAYS_IN_WEEK,
        dateFormat: formatChartDate,
        title: t('time_one_week'),
      });
    }

    if (shouldDisplayOneMonth) {
      returnedValue.push({
        id: 2,
        dayEnum: HistoryPeriodEnumKeys.ONE_MONTH,
        amountOfDays: AMOUNT_OF_DAYS_IN_MONTH,
        dateFormat: formatChartDate,
        title: t('time_one_month'),
      });
    }

    return returnedValue;
  }, [meResponse, t]);

  const [activeTab, setActiveTab] = useState<string | number>(tabItems[0].id);

  const activeTabObject = tabItems.find(
    (e) => e.id === activeTab
  ) as ExchangeTabItem;

  return {
    activeTabObject,
    setActiveTab,
    meResponseLoading,
    activeTab,
    shouldDisplayTabs: tabItems.length > 1,
    meResponse,
    tabItems,
  };
};
