import { Toggle, Typography } from '@libs/components';
import { useTheme } from 'styled-components';

import {
  Container,
  DescriptionContainer,
  InfoContainer,
  MobileContainer,
  TitleContainer,
  ToggleContainer,
} from './style';

type ToggleWithLabelProps = {
  className?: string;
  handleChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  defaultValue?: boolean | undefined;
  descriptionTitle: string;
  title: string;
  testId?: string;
  isContentReversed?: boolean;
  name?: string;
  disabled?: boolean;
};

const ToggleWithLabel: React.FC<ToggleWithLabelProps> = ({
  className,
  handleChange,
  defaultValue,
  title,
  descriptionTitle,
  testId,
  name,
  isContentReversed = false,
  disabled,
}) => {
  const { colors } = useTheme();
  return (
    <>
      <Container
        className={className}
        data-cy={testId}
        isContentReversed={isContentReversed}
      >
        <ToggleContainer>
          <Toggle
            disabled={disabled}
            onChange={handleChange}
            checked={defaultValue}
            labelSize={'2rem'}
            name={name}
          />
        </ToggleContainer>
        <InfoContainer>
          <TitleContainer>
            <Typography
              fontSize={2}
              lineHeight={1.8}
              type="primary"
              fontFamily="primaryBold"
              testId="TwoFAToggler-title"
            >
              {title}
            </Typography>
          </TitleContainer>
          <DescriptionContainer>
            <Typography
              fontSize={1.4}
              lineHeight={1.8}
              type="secondary"
              fontFamily="primary"
              testId="TwoFAToggler-desc"
              color={colors.text.secondary}
            >
              {descriptionTitle}
            </Typography>
          </DescriptionContainer>
        </InfoContainer>
      </Container>
      <MobileContainer>
        <InfoContainer>
          <TitleContainer>
            <Typography
              fontSize={2}
              lineHeight={1.8}
              type="primary"
              fontFamily="primaryMedium"
              testId="TwoFAToggler-title"
            >
              {title}
            </Typography>
          </TitleContainer>
          <DescriptionContainer>
            <Typography
              fontSize={1.4}
              lineHeight={1.8}
              type="secondary"
              fontFamily="primary"
              testId="TwoFAToggler-desc"
              color={colors.text.secondary}
            >
              {descriptionTitle}
            </Typography>
          </DescriptionContainer>
        </InfoContainer>
        <ToggleContainer>
          <Toggle onChange={handleChange} checked={defaultValue} name={name} />
        </ToggleContainer>
      </MobileContainer>
    </>
  );
};

export default ToggleWithLabel;
