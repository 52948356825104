import { useTheme } from 'styled-components';
import { Typography, Toggle, ToggleProps } from '@libs/components';

import { Container, TextWrapper } from './styles';

type TogglerProps = ToggleProps & {
  desc: string;
  title: string;

  isFilled?: boolean;
  className?: string;
};
const Toggler: React.FC<TogglerProps> = ({
  desc,
  title,
  className,
  isFilled = false,
  ...props
}) => {
  const { colors } = useTheme();

  return (
    <Container className={className} isFilled={isFilled}>
      <Toggle labelSize="2.4rem" {...props} />

      <TextWrapper>
        <Typography
          as="p"
          type="subHeadline"
          fontFamily="primaryBold"
          color={colors.text.default}
        >
          {title}
        </Typography>
        <Typography as="p" type="bodyButton" color={colors.text.secondary}>
          {desc}
        </Typography>
      </TextWrapper>
    </Container>
  );
};
export default Toggler;
