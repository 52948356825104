import { TwoFaActions, TwoFactorAuthType } from '@libs/types';
import { Typography } from '@libs/components';

import { FC, useMemo, useState } from 'react';

import { useTranslation } from 'src/hooks';

import AuthHeaderSteps from '../auth-header-steps/AuthHeaderSteps';
import { EnterAuth } from '../enter-auth/EnterAuth';
import TwoFaAuth from '../two-fa-auth/TwoFaAuth';

import { Container, TwoFaContainer } from './styles';
import { useTwoFaActivation } from './hooks';

type TwoFaProps = {
  typeToActivate: TwoFactorAuthType;
  onFinish?: () => void;
  action: TwoFaActions;
  closeModal: () => void;
};

export const TwoFaSteps: FC<TwoFaProps> = ({
  typeToActivate,
  onFinish,
  action,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [shouldRenderSteps, setShouldRenderSteps] = useState(true);

  const {
    activatedTwoFaMethods,
    hasCompletedAllSteps,
    completedOverallSteps,
    handleComplete,
    disclaimerText,
    hasEnteredAddress,
    setHasEnteredAddress,
    alreadyHasAddress,
    activateCodes,
    handleFinalAuth,
    loading,
    address,
    setAddress,
    errorMessage,
    resend,
  } = useTwoFaActivation(typeToActivate, action, onFinish);

  const shouldHavePadding =
    shouldRenderSteps ||
    (typeToActivate === TwoFactorAuthType.GOOGLE &&
      action === TwoFaActions.ACTIVATE);

  const description = useMemo(() => {
    if (!address) return '';

    const text =
      typeToActivate === TwoFactorAuthType.EMAIL
        ? t('activate_email_enter_code')
        : t('activate_sms_enter_code');

    return (
      <div>
        <Typography type="subHeadline">{text} </Typography>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {address}
        </Typography>
        <Typography type="subHeadline">
          {' '}
          {t('register_one_time_code')}
        </Typography>
      </div>
    );
  }, [address, t, typeToActivate]);

  return (
    <Container>
      <AuthHeaderSteps
        activatedMethods={activatedTwoFaMethods}
        completedTwoFaSteps={completedOverallSteps}
        methodToActivate={typeToActivate}
        hasEnteredAddress={hasEnteredAddress}
        alreadyHasAddress={alreadyHasAddress}
        action={action}
        setShouldRenderSteps={setShouldRenderSteps}
        closeModal={closeModal}
        shouldRenderSteps={shouldHavePadding}
      />
      <TwoFaContainer shouldRenderSteps={shouldHavePadding}>
        {hasCompletedAllSteps ? (
          <EnterAuth
            action={action}
            type={typeToActivate}
            address={address}
            setAddress={setAddress}
            setHasEnteredAddress={setHasEnteredAddress}
            onFinish={onFinish}
            activateCodes={activateCodes}
            description={description}
            alreadyHasAddress={alreadyHasAddress}
            hasEnteredAddress={hasEnteredAddress}
            onComplete={handleFinalAuth}
            loading={loading}
            errorMessage={t(errorMessage)}
          />
        ) : (
          <TwoFaAuth
            type={activatedTwoFaMethods[completedOverallSteps]}
            disclaimerText={disclaimerText}
            onComplete={handleComplete}
            errorMessage={t(errorMessage)}
            loading={loading}
            onResend={resend}
          />
        )}
      </TwoFaContainer>
    </Container>
  );
};

export default TwoFaSteps;
