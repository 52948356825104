import { AppStore, GooglePlay } from 'src/assets';
import { AUTHENTICATOR_LINK } from 'src/constants';

import { Img, LinksImgs } from './styles';

export const G2FALinks = () => {
  const handleGoogleImgClick = () => window.open(AUTHENTICATOR_LINK.googlePlay);
  const handleAppStoreClick = () => window.open(AUTHENTICATOR_LINK.appStore);

  return (
    <LinksImgs>
      <Img src={GooglePlay} onClick={handleGoogleImgClick} />
      <Img src={AppStore} onClick={handleAppStoreClick} />
    </LinksImgs>
  );
};
