import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import { Environment } from '@libs/types';

import App from './App';
import { client } from './setup';

if (process.env.ENVIRONMENT === Environment.prod) {
  console.log('testing...');

  Sentry.init({
    dsn: 'https://ca8b0801f603e761f2c209f5c426ed53@o4507701672345600.ingest.de.sentry.io/4507706837827664',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'https://bitnet-web-dev-graphql.kube.bitnet.ge/',
      'https://app.bitnet.ge/',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
