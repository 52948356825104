import styled from 'styled-components';
import { TransparentButton, Typography } from '@libs/components';
type CustomAssetItemProps = {
  disabled?: boolean;
};
export const CustomAssetItem = styled(TransparentButton)<CustomAssetItemProps>`
  display: flex;
  align-items: center;
  padding: 0 2.3rem 0 2rem;
  width: 100%;
  height: 6.8rem;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.borders.disabled : theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.surface.disabled : 'transparent'};
`;
export const CustomAssetItemTextCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const CustomAssetItemInfo = styled(Typography)`
  margin-left: auto;
`;
export const CustomAssetItemDisabledText = styled(Typography)`
  margin-left: auto;
`;
export const CustomAssetItemImg = styled.div<CustomAssetItemProps>`
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  object-fit: cover;
  margin-right: 0.8rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
