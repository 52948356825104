import { useTheme } from 'styled-components';
import {
  Amount,
  BodyLoader,
  Coin,
  Fee,
  HeaderLoader,
  DateHeader,
  OperationId,
  Status,
  TransactionContainer,
  Type,
  AssetIcon,
  AssetName,
  AssetCode,
  DateBody,
  TimeBody,
  TransactionType,
  AmountBody,
  FeeBody,
  OperationIdBody,
  StatusBody,
  BodyLine,
} from './styles';

export const TransactionLoader = () => {
  const { colors } = useTheme();

  return (
    <TransactionContainer>
      <HeaderLoader
        backgroundColor={colors.surface.interactiveDefault}
        foregroundColor={colors.surface.pressed}
      >
        <Coin />
        <DateHeader />
        <Type />
        <Amount />
        <Fee />
        <OperationId />
        <Status />
      </HeaderLoader>
      {[...Array(5)].map((item, i) => {
        return (
          <BodyLoader
            key={i}
            backgroundColor={colors.surface.interactiveDefault}
            foregroundColor={colors.surface.pressed}
          >
            <AssetIcon />
            <AssetName />
            <AssetCode />
            <DateBody />
            <TimeBody />
            <TransactionType />
            <AmountBody />
            <FeeBody />
            <OperationIdBody />
            <StatusBody />
            <BodyLine />
          </BodyLoader>
        );
      })}
    </TransactionContainer>
  );
};
