import {
  CalendarComponentType,
  DateFilter as NativeDateFilter,
  DateFilterProps,
} from '@libs/components';
import { screenSizes } from '@libs/theme';
import { useDimensions, useTranslation } from 'src/hooks';
import { CustomCalendar } from './styles';

type OmittedProps =
  | 'defaultValue'
  | 'clearButtonTitle'
  | 'filterButtonTitle'
  | 'exactDayLabel'
  | 'firstInputPlaceholder'
  | 'secondInputPlaceholder'
  | 'label'
  | 'CalendarComponent'
  | 'calendarTitle';

const DateFilter: React.FC<Omit<DateFilterProps, OmittedProps>> = (props) => {
  const { t } = useTranslation();
  const { width } = useDimensions();

  const datePlaceholder =
    width > screenSizes.tablet
      ? 'filter_date_placeholder'
      : 'filter_date_placeholder_mobile';

  return (
    <NativeDateFilter
      clearButtonTitle={t('common_clear')}
      filterButtonTitle={t('common_filter')}
      exactDayLabel={t('filter_date_exact_day')}
      firstInputPlaceholder={t('common_from')}
      secondInputPlaceholder={t('common_to')}
      label={t(datePlaceholder)}
      CalendarComponent={Calendar}
      calendarTitle={t('filter_date_placeholder')}
      {...props}
    />
  );
};

export default DateFilter;

const Calendar: React.FC<CalendarComponentType> = (props) => {
  return <CustomCalendar allowPartialRange {...props} />;
};
