import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { Staking } from '@libs/types';

type CreateStakingArgs = {
  id: number;
};

export type CreateStakingResponse = {
  createStaking: DefaultApiResponse<Staking>;
};

export const createStakingMutation: TypedDocumentNode<
  CreateStakingResponse,
  DefaultArg<CreateStakingArgs>
> = gql`
  mutation CreateStaking($record: CreateStakingRecord!) {
    createStaking(record: $record) {
      id
      status
    }
  }
`;
