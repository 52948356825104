import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { CurrencyType } from '@libs/types';
import {
  DefaultArg,
  DefaultApiResponse,
  transactionErrorFragment,
} from '@libs/graphql';
import { OriginalAmountAssetType } from '@libs/backend-common';

import { TradeType } from 'src/constants';
import { TransactionErrorResponse } from 'src/types';

export type CalculateExchange = {
  baseAssetAmount: number;
  quoteAssetAmount: number;
  disableReset?: boolean;
  error?: TransactionErrorResponse;
};
export type CalculateExchangeArgs = {
  amount: number;
  originalAmountAssetType: OriginalAmountAssetType;
  baseAssetCode: CurrencyType;
  quoteAssetCode: CurrencyType;
  action: TradeType;
};

export type CalculateExchangeResponse = {
  calculateExchange: DefaultApiResponse<CalculateExchange>;
};

export const calculateExchangeQuery: TypedDocumentNode<
  CalculateExchangeResponse,
  DefaultArg<CalculateExchangeArgs>
> = gql`
  query CalculateExchange($record: ExchangeRequestRecord!) {
    calculateExchange(record: $record) {
      baseAssetAmount
      quoteAssetAmount
      disableReset
      error {
        ...transactionErrorFields
      }
    }
  }

  ${transactionErrorFragment}
`;
