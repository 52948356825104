import { TypedDocumentNode } from '@apollo/client';
import {
  CalculateSwapArgs,
  CalculateSwapResponse,
  DefaultApiResponse,
  DefaultArg,
} from '@libs/graphql';

import gql from 'graphql-tag';

type CalculateSwap = {
  calculateSwap: CalculateSwapResponse;
};

export const calculateSwapMutation: TypedDocumentNode<
  DefaultApiResponse<CalculateSwap>,
  DefaultArg<CalculateSwapArgs>
> = gql`
  query CalculateSwap($record: CalculateSwapRecord!) {
    calculateSwap(record: $record) {
      neededAmount
      receivedAmount
      taskId
    }
  }
`;
