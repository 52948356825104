import { Button, SelectModal, TableCell, Typography } from '@libs/components';
import { DISCLAIMER_HEIGHT } from 'src/constants';
import styled from 'styled-components';

export const AssetDetailsHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  display: none;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.xs};

  ${({ theme }) => theme.media.tabletL`
    display:flex;
    padding: 1.7rem 1.2rem;

  `}

  ${({ theme }) => theme.media.laptopM`
  padding: 1.7rem 3rem;
  
  `}
`;

export const AssetDetailsHeaderNavWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;
export const CustomButton = styled(Button)`
  gap: 0.8rem;
  padding: 1.2rem 2rem;
  width: fit-content;
  ${({ theme }) => theme.media.tabletL`
    min-width: 18.9rem;
    width:100%;
  `}
`;

export const AssetCurrentPriceWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  padding: 2rem;
  width: 100%;
`;

type AssetChangeRateProps = {
  color?: string;
};
export const AssetChangeRate = styled(Typography)<AssetChangeRateProps>`
  color: ${({ color, theme }) => (color ? color : theme.colors.text.default)};
  display: block;
`;

export const AssetUserBalanceWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  padding: 2rem;
`;

export const AssetUserUsdBalance = styled(Typography)``;

type HeaderWrapperProps = {
  isUnderConstruction?: boolean;
  isAppDisclaimerVisible?: boolean;
};

const BASE_TOP_POSITION = 8.2;

export const AssetDetailsMobileHeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  gap: 1.2rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  position: fixed;
  top: ${({ isUnderConstruction, isAppDisclaimerVisible }) => {
    if (isAppDisclaimerVisible && isUnderConstruction) {
      return `${DISCLAIMER_HEIGHT * 2 + BASE_TOP_POSITION}rem`;
    } else if (isUnderConstruction || isAppDisclaimerVisible) {
      return `${DISCLAIMER_HEIGHT + BASE_TOP_POSITION}rem`;
    } else {
      return `${BASE_TOP_POSITION}rem`;
    }
  }};
  z-index: ${({ theme }) => theme.zIndex.medium};
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.md};
  ${({ theme }) => theme.media.tabletL`
    display:none;
  `};
`;
export const AssetDetailsHeaderMobileNavWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  padding: 1.4rem 3rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  background-color: ${({ theme }) => theme.colors.borders.highlight};
`;

export const AssetDetailsNavButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;

export const CustomSelectModal = styled(SelectModal)`
  width: 16rem;
  height: 3.4rem;
`;

export const AssetUserBalanceRatePnlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const AssetDetailsMobileHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
`;

export const Img = styled.img`
  height: 2.8rem;
  width: 2.8rem;
`;

export const CustomTableCell = styled(TableCell)`
  display: none;

  ${({ theme }) => theme.media.laptopM`
     display:flex
  `};
`;

export const CustomTableCellLaptop = styled(TableCell)`
  display: flex;
  ${({ theme }) => theme.media.laptopM`
     display:none;
  `};
`;
