import { JurisdictionsEnum } from '@libs/backend-common';
import { useState } from 'react';

import { useJurisdictionDetector } from './useJurisdictionDetector';

export const useAppDisclaimerContext = () => {
  const { currentJurisdiction } = useJurisdictionDetector();
  const defaultVisibilityState =
    currentJurisdiction === JurisdictionsEnum.GE && !window.isFromMobileApp;

  const [isAppDisclaimerVisible, setIsAppDisclaimerVisible] = useState(
    defaultVisibilityState
  );

  return {
    isAppDisclaimerVisible,
    setIsAppDisclaimerVisible,
  };
};
