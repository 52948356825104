import { PropsWithChildren } from 'react';

import { Container } from './styles';

type InputRadiusProps = PropsWithChildren & {
  topRadiusFlat?: boolean;
  bottomRadiusFlat?: boolean;
  testId?: string;
  className?: string;
};

const InputRadius: React.FC<InputRadiusProps> = ({
  children,
  topRadiusFlat = false,
  bottomRadiusFlat = false,
  testId = 'input-radius',
  className,
}) => {
  return (
    <Container
      data-cy={testId}
      data-testid={testId}
      topRadiusFlat={topRadiusFlat}
      bottomRadiusFlat={bottomRadiusFlat}
      className={className}
    >
      {children}
    </Container>
  );
};

export default InputRadius;
