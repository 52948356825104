import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { screenSizes } from '@libs/theme';

import { useDimensions, useOngoingSwapsCount, useTranslation } from 'src/hooks';

import {
  Container,
  Text,
  CustomButton,
  CustomDropDownIcon,
  Loader,
} from './styles';

const ActiveSwapsNav = () => {
  const navigate = useNavigate();
  const { width } = useDimensions();
  const { response } = useOngoingSwapsCount();
  const { t } = useTranslation();
  const { colors } = useTheme();

  if (width < screenSizes.laptop || !response?.getOngoingSwapsCount)
    return null;

  const text =
    response?.getOngoingSwapsCount === 1
      ? t('swap_nav_swap_in_progress')
      : t('swap_nav_swaps_in_progress');

  const handleButtonClick = () => {
    navigate('/', { state: { shouldScroll: true } });
  };

  return (
    <Container>
      <Text>
        {response?.getOngoingSwapsCount} {text}
      </Text>
      <Loader />
      <CustomButton onClick={handleButtonClick}>
        <Text>{t('swap_nav_see_details')}</Text>
        <CustomDropDownIcon color={colors.text.interactive} />
      </CustomButton>
    </Container>
  );
};
export default ActiveSwapsNav;
