import { FeatureModuleEnum, JurisdictionsEnum } from '@libs/backend-common';
import { LoansIcon, ReferralsIcon, TransactionsIcon } from '@libs/components';
import { Environment } from '@libs/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ConvertIcon,
  DashboardActiveIcon,
  DashboardIcon,
  DepositIcon,
  ReferralsActiveIcon,
  TransactionsActiveIcon,
  WalletActiveIcon,
  WalletIcon,
  WithdrawIcon,
  MessageQuestionIcon,
  FeesIcon,
} from 'src/assets';
import { DashboardNavItemType } from 'src/constants';
import { useEnabledFeatures, useJurisdictionDetector } from 'src/hooks';
import { useTheme } from 'styled-components';

export const useDashboardNavItems = () => {
  const { colors } = useTheme();
  const iconBrandColor = colors.interactive.brandColor;
  const iconSecondaryColor = colors.text.secondary;

  const { currentJurisdiction } = useJurisdictionDetector();
  const isUaJurisdiction = currentJurisdiction === JurisdictionsEnum.UA;

  const { checkFeature } = useEnabledFeatures();
  const isStakingAvailable = checkFeature(FeatureModuleEnum.STAKING);
  const navigationItems: DashboardNavItemType[] = [
    {
      title: isUaJurisdiction ? 'common_crexswap' : 'common_dashboard',
      route: '/',
      icon: <DashboardIcon />,
      activeIcon: <DashboardActiveIcon />,
    },

    {
      title: 'common_wallet',
      route: '/wallet',
      icon: <WalletIcon />,
      activeIcon: <WalletActiveIcon />,
    },
    {
      title: 'common_transactions',
      route: '/transactions',
      icon: <TransactionsIcon />,
      activeIcon: <TransactionsActiveIcon />,
    },
    // {
    //   title: 'common_loans',
    //   route: '/quick-cash',
    //   icon: <LoansIcon />,
    //   activeIcon: <LoansIcon isActive={true} />,
    //   isNew: true,
    // },
    {
      title: 'common_referrals',
      route: '/referrals',
      icon: <ReferralsIcon />,
      activeIcon: <ReferralsActiveIcon />,
    },
  ];

  if (isUaJurisdiction) {
    navigationItems.splice(
      1,
      0,
      {
        title: 'dashboard_convert',
        route: '/convert',
        icon: <ConvertIcon />,
        activeIcon: <ConvertIcon color={iconBrandColor} />,
      },
      {
        title: 'dashboard_deposit',
        route: '/deposit',
        icon: <DepositIcon color={iconSecondaryColor} />,
        activeIcon: <DepositIcon />,
      },

      {
        title: 'dashboard_withdraw',
        route: '/withdraw',
        icon: <WithdrawIcon color={iconSecondaryColor} />,
        activeIcon: <WithdrawIcon />,
      }
    );
  }

  if (
    isStakingAvailable.isAvailable &&
    process.env.ENVIRONMENT === Environment.dev
  ) {
    navigationItems.push({
      title: 'common_staking',
      route: '/staking',
      icon: <LoansIcon />,
      activeIcon: <LoansIcon isActive={true} />,
      isNew: true,
    });
  }

  const location = useLocation();

  const currPathName = location.pathname;

  const helpNavRoute = useMemo(() => {
    const helpPaths = [
      '/support-info/deposit-methods',
      '/support-info/withdraw-methods',
    ];

    return helpPaths.includes(currPathName)
      ? currPathName
      : '/support-info/general';
  }, [currPathName]);

  const feesNavRoute = useMemo(() => {
    const feesPaths = [
      '/dashboard-fees/withdraw-methods',
      '/dashboard-fees/withdraw-crypto-fee',
      '/dashboard-fees/convert-fee',
    ];

    return feesPaths.includes(currPathName)
      ? currPathName
      : '/dashboard-fees/deposit';
  }, [currPathName]);

  const supportNavigationItems = [
    {
      title: 'common_help',
      route: helpNavRoute,
      icon: <MessageQuestionIcon />,
      activeIcon: <MessageQuestionIcon />,
    },
    {
      title: 'common_fees',
      route: feesNavRoute,
      icon: <FeesIcon />,
      activeIcon: <FeesIcon />,
    },
  ];

  return { navigationItems, supportNavigationItems };
};
