import { object, string } from 'yup';
import { ValidationErrorMessages } from './types';

export const googleTwoFaValidation = object({
  smsTwoFactorCode: string()
    .optional()
    .matches(/^[0-9]{6}$/, ValidationErrorMessages.SMS_CODE),
  twoFactorCode: string()
    .required(ValidationErrorMessages.REQUIRED)
    .matches(/^[0-9]{6}$/, ValidationErrorMessages.TWO_FA_CODE),
  emailTwoFaCode: string()
    .optional()
    .matches(/^[0-9]{6}$/, ValidationErrorMessages.TWO_FA_CODE),
});
