import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

type DeleteAccountArgs = {
  smsCode: string;
  password: string;
  googleCode: string;
  emailCode: string;
};

export type DeleteAccountResponse = {
  deleteAccount: DefaultApiResponse<boolean>;
};

export const deleteAccountMutation: TypedDocumentNode<
  DeleteAccountResponse,
  DefaultArg<DeleteAccountArgs>
> = gql`
  mutation DeleteAccount($record: DeleteAccountRecord!) {
    deleteAccount(record: $record)
  }
`;
