import { SvgIconDefaultProps, CustomSvg } from '@libs/components';

export const GoogleAuthIcon: React.FC<SvgIconDefaultProps> = ({
  className,
}) => {
  return (
    <CustomSvg
      width="46"
      height="41"
      viewBox="0 0 46 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.391 16.8188H16.1302L9.91112 6.0464C8.89203 4.28233 9.49646 2.02453 11.2623 1.00633C11.843 0.670731 12.4773 0.511719 13.1037 0.511719C14.3784 0.511719 15.6189 1.17325 16.3024 2.35749L22.5214 13.1282L20.391 16.8188Z"
        fill="#FCBC05"
      />
      <path
        d="M33.7818 40.0098C32.0177 41.0288 29.7599 40.4235 28.74 38.6586L22.5209 27.8879L16.3019 38.6586C15.2828 40.4227 13.0259 41.0288 11.2618 40.0098C10.0784 39.3263 9.41602 38.0858 9.41602 36.8111C9.41602 36.1847 9.57503 35.5504 9.91062 34.9679L16.1297 24.1973H28.9139L35.133 34.9679C36.152 36.7338 35.5459 38.9907 33.7818 40.0098Z"
        fill="#E94335"
      />
      <path
        d="M45.0415 20.5072C45.0415 22.5453 43.389 24.1978 41.3508 24.1978H28.9136L26.7823 20.5072L24.6528 16.8174L22.5215 13.1267L28.7405 2.35603C29.7596 0.590206 32.0174 -0.0142159 33.7824 1.00487C35.5464 2.02307 36.1526 4.28087 35.1335 6.04494L28.9136 16.8182H41.3508C43.3899 16.8182 45.0415 18.4699 45.0415 20.5072Z"
        fill="#1973E8"
      />
      <path
        d="M20.3896 16.8184L18.2583 20.5081L16.1288 24.1988H3.68979C1.6525 24.1988 0 22.5463 0 20.5081C0 19.4899 0.412025 18.5675 1.08058 17.8989C1.74913 17.2321 2.66983 16.8184 3.68979 16.8184H20.3896Z"
        fill="#34A853"
      />
      <path
        d="M28.9123 24.1981H16.1289L18.2584 20.5074L20.3897 16.8185L22.5201 13.127L24.6514 16.8185L26.781 20.5074L28.9123 24.1981Z"
        fill="#174FA7"
      />
    </CustomSvg>
  );
};

export default GoogleAuthIcon;
