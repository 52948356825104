import { CurrencyType, TradeType } from 'src/constants';

import { ExchangeCurrencyOption } from 'src/types';

const PREFFERED_BASE_ASSET = CurrencyType.BTC;

const PREFFERED_QUOTE_ASSET = CurrencyType.USDT;

export const handleDefaultCryptoSelection = (
  options: ExchangeCurrencyOption[],
  tradeType: TradeType,
  currency?: CurrencyType
) => {
  if (currency) {
    const foundCurr = options.find((item) => item.value === currency);

    if (foundCurr) {
      return foundCurr;
    }
  }

  const prefferedAsset = options.find(
    (item) => item.value === PREFFERED_BASE_ASSET
  );

  if (tradeType === TradeType.BUY && prefferedAsset) {
    return prefferedAsset;
  }

  return options[0];
};

export const handleDefaultFiatSelection = (
  options: ExchangeCurrencyOption[],
  tradeType: TradeType,
  currency?: CurrencyType
) => {
  if (currency) {
    const urlCurrency = options.find((item) => item.value === currency);

    if (urlCurrency) {
      return urlCurrency;
    }
  }
  const prefferedAsset = options.find(
    (item) => item.value === PREFFERED_QUOTE_ASSET
  );

  if (tradeType === TradeType.SELL && prefferedAsset) {
    return prefferedAsset;
  }

  return options[0];
};
