import styled, { css } from 'styled-components';

type DepositFiatContainerProps = {
  hasPadding: boolean;
};
export const DepositFiatContainer = styled.div<DepositFiatContainerProps>`
  height: 100%;

  ${({ theme, hasPadding }) =>
    hasPadding &&
    css`
      padding: 2.8rem 2.2rem 3.5rem;

      ${theme.media.tablet`
        padding: 2.8rem 3.7rem 3.5rem;
      `}
    `}
`;
