import { getApolloClient } from '@libs/graphql';

import { TOKEN_KEY } from 'src/constants';

export const { client, setOnUnauthorized } = getApolloClient(
  process.env.BACKEND_URL_WEB + '/graphql',
  TOKEN_KEY
);

export const { client: swapClient } = getApolloClient(
  process.env.SWAP_URL + '/graphql',
  TOKEN_KEY
);
