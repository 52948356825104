import styled from 'styled-components';
import { Typography } from '@libs/components';

export const Container = styled.div`
  display: flex;
  width: 27.7rem;
  padding: 1.6rem 1.8rem;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.high};
  box-shadow: ${({ theme }) => theme.shadows.xl2};
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};

  &::after {
    content: '';
    bottom: -6px;
    right: 2rem;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0 2px 0 0;
    transform: rotate(135deg);
    background: ${({ theme }) => theme.colors.surface.default};
    border: 1px solid ${({ theme }) => theme.colors.borders.default};
    border-left: 0;
    border-bottom: 0;
  }
`;

export const InfoText = styled(Typography)`
  font-size: 1.3rem;
  line-height: 1.82rem;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.colors.text.default};

  &:last-of-type {
    margin-bottom: 0rem;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
`;

type InvalidIconWrapperProps = {
  isInvalid: boolean;
};
export const InvalidIconWrapper = styled.div<InvalidIconWrapperProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ isInvalid, theme }) =>
    isInvalid ? theme.colors.interactive.critical : 'transparent'};
`;
