import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';
import { Copy } from 'src/components';
import {
  BeneficiaryItem,
  BeneficiaryItemInfoContainer,
  BeneficiaryItemTitle,
} from './styles';
type DepositInfoRowProps = {
  title: string;
  desc?: string;
};

export const DepositInfoRow: React.FC<DepositInfoRowProps> = ({
  title,
  desc,
}) => {
  const { colors } = useTheme();
  if (!desc) return null;

  return (
    <BeneficiaryItem>
      <BeneficiaryItemInfoContainer>
        <BeneficiaryItemTitle>
          <Typography
            type="caption"
            fontFamily="primaryMedium"
            color={colors.text.secondary}
          >
            {title}
          </Typography>
        </BeneficiaryItemTitle>
        <Typography
          type="bodyButton"
          fontFamily="primaryMedium"
          color={colors.text.default}
        >
          {desc}
        </Typography>
      </BeneficiaryItemInfoContainer>
      <Copy text={desc} />
    </BeneficiaryItem>
  );
};
