import { FC, useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { redirectToAppDownload } from '@libs/helpers';

type OSNavigatorProps = {
  iosLink: string;
  androidLink: string;
};

export const OSNavigator: FC<OSNavigatorProps> = ({ androidLink, iosLink }) => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const handleNavigation = () => navigate('/login');

    redirectToAppDownload(iosLink, androidLink, handleNavigation);
  }, [androidLink, iosLink, navigate]);

  return null;
};
