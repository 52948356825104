import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import {
  DefaultArg,
  DefaultApiResponse,
  extendedStakingFragment,
} from '@libs/graphql';
import { Staking } from '@libs/types';

type GetStakingByIdArgs = {
  id: string;
};

export type GetStakingByIdResponse = {
  getStakingById: DefaultApiResponse<Staking>;
};

export const getStakingByIdQuery: TypedDocumentNode<
  GetStakingByIdResponse,
  DefaultArg<GetStakingByIdArgs>
> = gql`
  query GetStakingById($record: StakingIdRecord!) {
    getStakingById(record: $record) {
      ...stakingExtendedFields
      asset {
        code
      }
    }
  }
  ${extendedStakingFragment}
`;
