import { object, string } from 'yup';

import { RegexValidators } from '@libs/validations';
import { getJurisdictionBaseUrl } from '@libs/helpers';
import { JurisdictionsEnum } from '@libs/backend-common';

import { ValidationErrorMessages } from './types';

const { isGlobal, jurisdictionId } = getJurisdictionBaseUrl();

export const emailOrPhoneValidation = {
  emailOrPhone: string()
    .required(
      jurisdictionId === JurisdictionsEnum.GE
        ? ValidationErrorMessages.EMAIL_OR_PHONE
        : ValidationErrorMessages.ENTER_EMAIL
    )
    .test(
      'emailOrPhoneFormat',
      ValidationErrorMessages.EMAIL_OR_PHONE,
      (value) => {
        if (!value) return false;

        if (!isGlobal) {
          return (
            string()
              .matches(RegexValidators.IS_EMAIL_VALID)
              .isValidSync(value) ||
            string().matches(RegexValidators.GE_PHONE_NUMBER).isValidSync(value)
          );
        } else {
          return string()
            .matches(RegexValidators.IS_EMAIL_VALID)
            .isValidSync(value);
        }
      }
    ),
};

export const registerFormValidation = () => {
  return object().shape({
    ...emailOrPhoneValidation,
    password: string()
      .required(ValidationErrorMessages.PASSWORD)
      .min(8, ValidationErrorMessages.PASSWORD_LENGTH),
  });
};

export const registerPhoneValidation = object({
  phone: string()
    .required(ValidationErrorMessages.REQUIRED)
    .min(11, ValidationErrorMessages.PHONE)
    .matches(/^\d{3}|(?!^)\d{3}(?!$)/, ValidationErrorMessages.PHONE),
});
