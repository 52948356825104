import { gql, TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { BalanceType, CurrencyType } from '@libs/types';

import { HistoryPeriodEnumKeys } from 'src/gql';

export type GetBalanceHistoryByUserArgs = {
  duration: HistoryPeriodEnumKeys;
  valueAssetCode: CurrencyType | BalanceType;
};
export type GetBalanceHistoryByUserRes = {
  amount: string;
  createdAt: Date | string;
};
export type GetBalanceHistoryByUserResponse = {
  getBalanceHistoryByUser: DefaultApiResponse<GetBalanceHistoryByUserRes[]>;
};
export const getBalanceHistoryByUserQuery: TypedDocumentNode<
  GetBalanceHistoryByUserResponse,
  DefaultArg<GetBalanceHistoryByUserArgs>
> = gql`
  query GetBalanceHistoryByUser($record: GetBalanceHistoryByUserRecord!) {
    getBalanceHistoryByUser(record: $record) {
      amount
      createdAt
    }
  }
`;
