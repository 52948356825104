import styled from 'styled-components';
import { Typography } from '@libs/components';

export const DepositCardContainer = styled.div``;
export const DisclaimerContainer = styled.div`
  margin: 1.2rem 0 3.2rem;
`;
export const CardContainer = styled.div`
  margin-top: 1.2rem;
`;
export const AmountInputContainer = styled.div`
  margin: 0.8rem 0 1.6rem;
`;

export const CustomTypography = styled(Typography)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1.2rem;
`;
