import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 1.4rem;
`;

export const CurrenciesDetailsWrapper = styled.div`
  display: flex;
  height: 11.3rem;
  margin-bottom: 2rem;
  justify-content: space-evenly;
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const DetailsWrapper = styled.div`
  height: 100%;
  position: relative;
  text-align: center;
  padding-bottom: 1.7rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const IconWrapper = styled.div`
  top: -1.4rem;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  .currency_icon {
    height: 5rem;
    width: 5rem;
  }
`;

export const TradeIconWrapper = styled.div`
  z-index: 1;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  align-self: center;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  background-color: ${({ theme }) => theme.colors.surface.default};

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    z-index: -1;
    width: 11.3rem;
    height: 0.1rem;
    position: absolute;
    transform: rotate(90deg);
    background: ${({ theme }) => theme.colors.borders.default};
  }
`;

export const DummyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(-90deg);
  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const FeesWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

export const FeeItem = styled.div`
  display: flex;
  padding: 1.6rem 3rem;
  justify-content: space-between;
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.borders.default};
  height: 0.1rem;
  width: 100%;
`;
