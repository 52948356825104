import styled from 'styled-components';
import { Button } from '@libs/components';
import {
  CalculationDetailsBlock,
  CalculationDetailsDesc,
} from '../calculation-details-block/CalculationDetailsBlock';

export const LoanDetailsContainer = styled(CalculationDetailsBlock)`
  width: 100%;
  margin-left: 0;
  ${({ theme }) => theme.media.tablet`
    width: 50%;
    margin-left: 2rem;
  `};
`;
export const LoanDetailsDescrCont = styled(CalculationDetailsDesc)`
  margin-top: 3rem;
`;
export const NewLoanInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-bottom: 2.8rem;
  padding: 0 2.4rem;
  ${({ theme }) => theme.media.tablet`
      width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom:0;
  padding:0;
  `};
`;
export const NewLoanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet`
        flex-direction: row;
          align-items: unset;
   
  `};
`;
export const InputContainer = styled.div`
  margin-top: 2.7rem;
`;
export const CustomBtn = styled(Button)``;
export const CurrencyInputCont = styled.div`
  margin-bottom: 1.9rem;
`;
export const FlexCont = styled.div`
  display: flex;
`;
export const NewLoanHeader = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
  ${({ theme }) => theme.media.tablet`
      flex-direction: column;
      text-align: unset;
  `};
`;
export const ErrorCont = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 1.6rem;
  min-height: 7.2rem;
`;
