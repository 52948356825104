import styled from 'styled-components';

import { Button, CloseIcon } from '@libs/components';

export const Line = styled.div`
  border: 0.1rem solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(213, 220, 229, 0) 0%,
    #d5dce5 51%,
    rgba(213, 220, 229, 0) 100%
  );
  border-image-slice: 1;
  flex: 1;
  margin: 1.6rem 0;

  width: 100%;
  height: 0.1rem;
`;

export const Container = styled.div`
  padding: 7.1rem 1.6rem 2rem;
  max-width: 50rem;

  ${({ theme }) => theme.media.tabletL`
    padding: 6.8rem 3.6rem 3.6rem;

  `}
`;

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
`;

export const Block = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 0.1rem solid ${({ theme }) => theme.colors.interactive.brandColor};
`;

export const DiscalimerWrapper = styled.div`
  text-align: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  position: relative;
  padding: 4.7rem 2.2rem 2rem;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 3rem; 
  `}
`;

export const CustomButton = styled(Button)`
  width: 100%;
  height: 6rem;
`;

export const DisaclimerIcon = styled.img`
  position: absolute;
  margin: 0 auto;
  left: 0rem;
  right: 0rem;
  top: -4rem;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  align-items: center;
  justify-content: center;

  width: 3.2rem;
  height: 3.2rem;

  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  cursor: pointer;
`;

export const CustomCloseIcon = styled(CloseIcon)`
  height: 1.2rem;
  width: 1.2rem; ;
`;
