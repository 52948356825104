import { gql, TypedDocumentNode } from '@apollo/client';

import { User, DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type RegisterUserArgs = {
  agreedToTerms: boolean;
  confirmationCode: string;
  emailOrPhone: string;
  password: string;
  isCampaignUser?: boolean;
  referrerCode?: string;
};

export type RegisterUser = {
  registerUser: {
    accessToken: string;
    user: User;
  };
};

export type RegisterUserResponse = DefaultApiResponse<RegisterUser>;

export const registerUserMutation: TypedDocumentNode<
  RegisterUserResponse,
  DefaultArg<RegisterUserArgs>
> = gql`
  mutation RegisterUser($record: RegisterUserRecord!) {
    registerUser(record: $record) {
      accessToken
    }
  }
`;
