import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CardType = {
  id: string;
};

export type BogPaymentType = {
  fee: number;
};

export type GetCardsType = {
  id: string;
  cardMask?: string;
  bogPayments: BogPaymentType[];
};

export type GetCardsResponse = {
  getCards: DefaultApiResponse<GetCardsType[]>;
};

export const getCardsQuery: TypedDocumentNode<
  GetCardsResponse,
  DefaultArg
> = gql`
  query GetCardsOption {
    getCards {
      id
      cardMask
      bogPayments {
        fee
      }
    }
  }
`;
