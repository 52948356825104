import gql from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

type SendResetPasswordMessageResponse = {
  sendResetPasswordMessage: DefaultApiResponse<string>;
};

type SendResetPasswordEmailArgs = {
  email: string;
};

export const SendResetPasswordMessageMutation: TypedDocumentNode<
  SendResetPasswordMessageResponse,
  DefaultArg<SendResetPasswordEmailArgs>
> = gql`
  mutation SendResetPasswordMessage($record: EmailRecord!) {
    sendResetPasswordMessage(record: $record)
  }
`;

type ResetPasswordEmailResponse = {
  resetPassword: DefaultApiResponse<string>;
};
type ResetPasswordEmailArgs = {
  token: string;
  password: string;
};

export const resetPasswordMutation: TypedDocumentNode<
  ResetPasswordEmailResponse,
  DefaultArg<ResetPasswordEmailArgs>
> = gql`
  mutation ResetPassword($record: ResetPasswordRecord!) {
    resetPassword(record: $record)
  }
`;
