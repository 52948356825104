import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';

import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { UserTokenPayload } from 'src/types';

// ----- Send Phone Verification code -----
export type SendPhoneVerificationArgs = {
  destination: string;
};

export type SendPhoneVerificationResponse = {
  sendPhoneVerificationCode: DefaultApiResponse<boolean>;
};

export const sendPhoneCodeMutation: TypedDocumentNode<
  SendPhoneVerificationResponse,
  DefaultArg<SendPhoneVerificationArgs>
> = gql`
  mutation SendPhoneVerificationCode($record: PhoneVerificationRecord!) {
    sendPhoneVerificationCode(record: $record)
  }
`;

// ----- SHARED ARGS ------
type PhoneMutationArgs = {
  code: string;
  phone: string;
  formattedPhone: string;
  tokenPayload?: UserTokenPayload;
};

// ----- Change phone -----
export type ChangePhoneResponse = {
  changePhone: DefaultApiResponse<boolean>;
};
export type ChangePhoneArgs = PhoneMutationArgs;

export const changePhoneMutation: TypedDocumentNode<
  ChangePhoneResponse,
  DefaultArg<ChangePhoneArgs>
> = gql`
  mutation ChangePhone($record: UpdatePhoneRecord!) {
    changePhone(record: $record)
  }
`;

// ----- Save/register phone -----
export type SavePhoneResponse = {
  savePhone: DefaultApiResponse<{ accessToken: string }>;
};
export type SavePhoneArgs = PhoneMutationArgs;

export const savePhoneMutation: TypedDocumentNode<
  SavePhoneResponse,
  DefaultArg<SavePhoneArgs>
> = gql`
  mutation SavePhone($record: UpdatePhoneRecord!) {
    savePhone(record: $record) {
      accessToken
    }
  }
`;

// Check phone and send code

export type CheckPhoneAndSendCodeResponse = {
  checkPhoneAndSendCode: DefaultApiResponse<boolean>;
};
export type CheckPhoneAndSendCodeArgs = {
  destination: string;
};

export const checkPhoneAndSendCodeMutation: TypedDocumentNode<
  CheckPhoneAndSendCodeResponse,
  DefaultArg<CheckPhoneAndSendCodeArgs>
> = gql`
  mutation CheckPhoneAndSendCode($record: PhoneVerificationRecord!) {
    checkPhoneAndSendCode(record: $record)
  }
`;
