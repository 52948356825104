import { useCallback, useState } from 'react';

import { ModalBase, Portal } from '@libs/components';

import { ConfirmationModal } from 'src/components';
import { ConfirmationDialogType } from '@libs/types';

export const useConfirmationModal = (
  onModalClose?: () => void,
  onConfirmation?: () => void
) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    onModalClose?.();
    setIsOpen(false);
  }, [setIsOpen, onModalClose]);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleConfirmation = useCallback(() => {
    setIsOpen(false);
    onConfirmation?.();
  }, [setIsOpen, onConfirmation]);

  const Dialog = useCallback(
    (props: ConfirmationDialogType) => (
      <Portal>
        <ModalBase isOpen={isOpen} onClose={closeModal}>
          <ConfirmationModal
            {...props}
            onConfirm={handleConfirmation}
            onClose={closeModal}
          />
        </ModalBase>
      </Portal>
    ),
    [closeModal, isOpen, handleConfirmation]
  );

  return { Dialog, closeModal, openModal, isOpen };
};
