import { useState } from 'react';

import { ResultFeedbackType } from 'src/constants';
import { useTranslation } from 'src/hooks';
import { AccountInfoModalProps } from '../account-information/types';

import EditEmailForm from './components/EditEmailForm';
import { useEditEmail } from './hooks/useEditEmail';
import { CustomModalWithTwoFa, CustomResultFeedback } from './styles';

const EditEmailModal: React.FC<AccountInfoModalProps> = ({
  onCancel,
  initialValue,
  shouldRender,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const [shouldShowContent, setShouldShowContent] = useState(true);

  const showContent = () => {
    setShouldShowContent(true);
  };
  const hideContent = () => {
    setShouldShowContent(false);
  };

  const {
    feedbackType,
    setCode,
    clearCode,
    formErrors,
    isCounterRunning,
    sendCodeHandler,
    handleEmailChange,
    countDown,
    errorMessage,
    handleSubmit,
    values,
    handleChange,
    touched,
    isValid,
    isSameEmail,
    isButtonActive,
    emailToDisplay,
    code,
    handleBlur,
    error,
    handleFocus,
    resendHandler,
  } = useEditEmail({
    onSuccess: showContent,
    onFormSubmit: hideContent,
    initialValue,
    showContent,
  });

  const description =
    feedbackType === ResultFeedbackType.SUCCESS
      ? 'success_email_change'
      : errorMessage || 'error_email_change';

  const modalTitle = initialValue ? 'edit_email_title' : 'add_email_title';

  return (
    <CustomModalWithTwoFa
      shouldShowContent={shouldShowContent}
      testId="SecurityEmailModal"
      onModalClose={onCancel}
      onAuthFinish={handleEmailChange}
      title={t(modalTitle)}
      {...modalProps}
    >
      {feedbackType && onCancel ? (
        <CustomResultFeedback
          onClick={onCancel}
          type={feedbackType}
          description={t(description)}
        />
      ) : (
        <EditEmailForm
          handleFocus={handleFocus}
          error={error}
          handleBlur={handleBlur}
          setCode={setCode}
          clearCode={clearCode}
          formErrors={formErrors}
          isCodeSent={isCounterRunning}
          sendCodeHandler={sendCodeHandler}
          countDown={countDown}
          errorMessage={errorMessage}
          handleSubmit={handleSubmit}
          values={values}
          handleChange={handleChange}
          touched={touched}
          isValid={isValid}
          isSameEmail={isSameEmail}
          isButtonActive={isButtonActive}
          emailToDisplay={emailToDisplay}
          code={code}
          onCancel={onCancel}
          initialValue={initialValue}
          shouldRender={shouldRender}
          handleEmailChange={handleEmailChange}
          resendHandler={resendHandler}
        />
      )}
    </CustomModalWithTwoFa>
  );
};
export default EditEmailModal;
