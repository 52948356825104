import { useCallback, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  createCryptoAddressMutation,
  CreateCryptoAddressResponse,
} from 'src/gql';
import { useApiResponse } from 'src/hooks';

export const useCreateCryptoAddress = (openSuccess: () => void) => {
  const [createdCryptoAddress, setCreateCryptoAddress] =
    useState<CreateCryptoAddressResponse>();

  const handleCreateCryptoAdressSucccess = useCallback(
    (data: CreateCryptoAddressResponse) => {
      setCreateCryptoAddress(data);
      openSuccess();
    },
    [openSuccess]
  );
  const [
    handleCreateCryptoAddress,
    {
      data: confirmCreateAddress,
      error: confirmCreateAddressError,
      loading: confirmCreateAddressLoading,
    },
  ] = useMutation(createCryptoAddressMutation);
  const {
    errors: confirmCreateAddressErrorMEssage,
    loading: isCreatingAddress,
  } = useApiResponse<CreateCryptoAddressResponse, ApolloError>(
    {
      data: confirmCreateAddress,
      errors: confirmCreateAddressError,
      loading: confirmCreateAddressLoading,
    },
    handleCreateCryptoAdressSucccess
  );

  const createCryptoAddress = (assetId: string | number) => {
    handleCreateCryptoAddress({
      variables: {
        record: {
          assetId,
        },
      },
    });
  };

  return {
    errorMessage: confirmCreateAddressErrorMEssage,
    createdCryptoAddress,
    createCryptoAddress,
    isCreatingAddress,
    generatedAddressId: confirmCreateAddress?.createCryptoAddress,
  };
};
