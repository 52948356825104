import { useCallback } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { screenSizes } from '@libs/theme';
import { Button, ErrorMessage } from '@libs/components';

import {
  useApiResponse,
  useDimensions,
  useError,
  useModal,
  useTranslation,
} from 'src/hooks';
import { resetPasswordValidation } from 'src/validations';
import { resetPasswordMutation } from 'src/gql';
import { FAQInfo, PasswordInput, ResultFeedback } from 'src/components';
import { ResultFeedbackType } from 'src/constants';

import { Form, InputWrapper, Container, FAQWrapper } from '../styles';

type ResetPasswrodPageParams = {
  resetToken: string;
};
type ResetPasswordFormProps = {
  onChangeSuccess: (isSuccess: boolean) => void;
};
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onChangeSuccess,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useDimensions();
  const params = useParams<ResetPasswrodPageParams>();

  const handleLoginNavigation = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const { Dialog, openModal } = useModal(handleLoginNavigation);

  const [resetPassword, { data, loading, error }] = useMutation(
    resetPasswordMutation
  );

  const onSuccessResetPassword = useCallback(() => {
    if (width <= screenSizes.tablet) {
      openModal();
    } else {
      onChangeSuccess(true);
    }
  }, [width, openModal, onChangeSuccess]);

  const { errors: resetPassErrors, loading: resetPassLoading } = useApiResponse(
    {
      data,
      loading,
      errors: error,
    },
    onSuccessResetPassword
  );
  const { errorMessage, clearErrorMessage } = useError(resetPassErrors);

  const {
    values,
    handleChange,
    handleSubmit,
    // errors,
    // touched,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (vals) => {
      if (resetPassLoading) return;
      clearErrorMessage();
      resetPassword({
        variables: { record: { ...vals, token: params.resetToken || '' } },
      });
    },
    validateOnChange: true,
    validationSchema: resetPasswordValidation,
  });

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <PasswordInput
            name="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            // errorMessage={touched.password ? t(errors?.password) : ''}
          />
          <ErrorMessage errorMessage={errorMessage} />
        </InputWrapper>
        <Button
          type="submit"
          title={t('common_reset')}
          isLoading={resetPassLoading}
          disabled={!(dirty && isValid)}
        />

        <FAQWrapper>
          <FAQInfo />
        </FAQWrapper>
      </Form>

      <Dialog leftSpace={0} title={t('reset_password_succ_title')}>
        <ResultFeedback
          hasTitle={false}
          onClick={handleLoginNavigation}
          type={ResultFeedbackType.SUCCESS}
          description={t('reset_password_succ_desc')}
          buttonText={t('reset_password_succ_buttonText')}
        />
      </Dialog>
    </Container>
  );
};
export default ResetPasswordForm;
