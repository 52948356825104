import { WalletTypeEnum } from '@libs/backend-common';

export const allAssetsEmptyStateTitle = (
  searchInputValue: string,
  walletType: WalletTypeEnum
) => {
  if (walletType === WalletTypeEnum.NON_ZERO_ASSETS && !searchInputValue) {
    return 'dashboard_asset_do_not_own';
  }
  if (walletType === WalletTypeEnum.FAVORITE_ASSETS && !searchInputValue) {
    return 'dashboard_favorite_asset_not_found';
  }
  return 'error_asset_not_found';
};
