import QRCode from 'react-qr-code';

export type QrProps = {
  value: string;
  size?: number; // defaults to 128
  bgColor?: string; // defaults to '#FFFFFF'
  fgColor?: string; // defaults to '#000000'
  level?: string; // defaults to 'L' , Can be one of 'L,M,H,Q'
};
const Qr: React.FC<QrProps> = ({ value, size, bgColor, fgColor, level }) => {
  return (
    <QRCode
      value={value}
      bgColor={bgColor}
      fgColor={fgColor}
      level={level}
      size={size}
    />
  );
};
export default Qr;
