import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { OriginalAmountAssetType } from '@libs/backend-common';
import { CurrencyType, ResultFeedbackType, TradeType } from 'src/constants';

// Exchange Pre-Request
export type RequestExchangeEntity = {
  id: string | number;
  originalAmount: number;
  rate: number;
  feeAmount: number;
  neededAmount: number;
  receivedAmount: number;
  expiresIn: number;
};
export type RequestExchangeResponse = {
  requestExchange: DefaultApiResponse<RequestExchangeEntity>;
};
export type RequestExchangeArgs = {
  amount: number;
  originalAmountAssetType: OriginalAmountAssetType;
  baseAssetCode: CurrencyType;
  quoteAssetCode: CurrencyType;
  action: TradeType;
};

export const requestExchangeMutation: TypedDocumentNode<
  RequestExchangeResponse,
  DefaultArg<RequestExchangeArgs>
> = gql`
  mutation RequestExchange($record: ExchangeRequestRecord!) {
    requestExchange(record: $record) {
      id
      originalAmount
      rate
      feeAmount
      neededAmount
      receivedAmount
      expiresIn
    }
  }
`;

// Exchange
export type ExchangeCurrencyResponse = {
  exchange: DefaultApiResponse<ResultFeedbackType>;
};
export type ExchangeCurrencyArgs = {
  requestId: number | string;
};

export const exchangeCurrencyMutation: TypedDocumentNode<
  ExchangeCurrencyResponse,
  DefaultArg<ExchangeCurrencyArgs>
> = gql`
  mutation Exchange($record: ExchangeRecord!) {
    exchange(record: $record)
  }
`;
