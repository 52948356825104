import { DefaultApiResponse, DefaultArg } from '@libs/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

type SaveAgreedToMarketingMessages = { agreed: boolean };

export type SaveAgreedToMarketingMessagesResponse = {
  saveAgreedToMarketingMessages: DefaultApiResponse<boolean>;
};
export const saveAgreedToMarketingMessagesMutation: TypedDocumentNode<
  SaveAgreedToMarketingMessagesResponse,
  DefaultArg<SaveAgreedToMarketingMessages>
> = gql`
  mutation SaveAgreedToMarketingMessages(
    $record: AgreedToMarketingMessagesRecord!
  ) {
    saveAgreedToMarketingMessages(record: $record)
  }
`;
