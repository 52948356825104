import styled from 'styled-components';
import { Input, Typography } from '@libs/components';
type AmountInputTypes = {
  hasError?: boolean;
};
export const WithdrawCryptoContainer = styled.div`
  padding: 1.5rem 2.2rem 0;
  ${({ theme }) => theme.media.tablet`
  padding: 2rem 3.7rem 0;
    `}
`;
export const AmountInputContainer = styled.div<AmountInputTypes>`
  margin: ${({ hasError }) => (hasError ? '0.8rem 0 1rem' : '0.8rem 0 2.4rem')};
`;
export const TransactionStatusContainer = styled.div`
  margin: 2.4rem 0 0;
`;
export const TransactionStatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  margin-bottom: 0.7rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  &:last-child {
    padding: 0;
    margin: 0;
    border: none;
  }
`;
export const CustomSelectModal = styled.div`
  margin-top: 2.5rem;
`;
export const CustomNetworkImg = styled.img`
  min-width: 3.8rem;
  min-height: 3.8rem;
  margin-right: 1.2rem;
`;
export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  height: 100%;
`;
export const ValueTypography = styled(Typography)`
  text-align: left;
`;
export const ValueWrapper = styled.div<{ hasIcon?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;

  margin-top: 0.1rem;
  margin-left: ${({ hasIcon }) => (hasIcon ? 0.8 : 0)}rem;
`;
export const QrScanIcon = styled.img`
  cursor: pointer;
`;
export const ErrMessageWrapper = styled.div`
  height: 2.8rem;
`;

export const CustomInput = styled(Input)`
  margin-top: 2.4rem;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.8rem;
  padding: 0 1.8rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};

  &:last-child {
    border-bottom: none;
  }
`;

export const OptionContainerRightCorner = styled.div`
  display: flex;
  gap: 1.6rem;
`;
