import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';

import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

export type SendSmsVerificationCodeResponse = {
  sendSmsVerifyCode: DefaultApiResponse<{ sendSmsVerificationCode: boolean }>;
};
export const sendSmsVerificationCodeMutation: TypedDocumentNode<
  SendSmsVerificationCodeResponse,
  DefaultArg
> = gql`
  mutation Mutation {
    sendSmsVerificationCode
  }
`;
