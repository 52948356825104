import { gql } from 'graphql-tag';

import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type CreateCryptoAddressProps = {
  assetId: string | number;
  name?: string;
};

export type CreateCryptoAddressResponse = {
  createCryptoAddress: DefaultApiResponse<number>;
};

export const createCryptoAddressMutation: TypedDocumentNode<
  CreateCryptoAddressResponse,
  DefaultArg<CreateCryptoAddressProps>
> = gql`
  mutation CreateCryptoAddress($record: GenerateAddressRecord!) {
    createCryptoAddress(record: $record)
  }
`;
