import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';

type GetCryptoAddressesByAssetArgs = {
  assetCode: string;
  networkId: number;
  term?: string;
};

export type Address = {
  id: string;
  name: string;
  address: string;
  tag?: string;
};

type CryptoAddressesByAsset = {
  isMultiCryptoAddressesEnabled: boolean;
  addresses: Address[];
};
export type GetCryptoAddressesByAssetResponse = {
  getCryptoAddressesByAsset: DefaultApiResponse<CryptoAddressesByAsset>;
};

export const getCryptoAddressesByAssetQuery: TypedDocumentNode<
  GetCryptoAddressesByAssetResponse,
  DefaultArg<GetCryptoAddressesByAssetArgs>
> = gql`
  query GetCryptoAddressesByAsset($record: GetCryptoAddressesByAssetRecord!) {
    getCryptoAddressesByAsset(record: $record) {
      isMultiCryptoAddressesEnabled
      addresses {
        name
        id
        address
        tag
      }
    }
  }
`;
