import { useTheme } from 'styled-components';

import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const SearchIcon: React.FC<SvgIconDefaultProps> = ({
  className,
  testId,
  color,
}) => {
  const { colors } = useTheme();
  const iconColor = color ? color : colors.text.default;

  return (
    <CustomSvg
      className={className}
      data-cy={testId}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M18.2305 18.2308L14.8653 14.8654M17.269 9.09617C17.269 13.61 13.6098 17.2693 9.09593 17.2693C4.58206 17.2693 0.922852 13.61 0.922852 9.09617C0.922852 4.58231 4.58206 0.923096 9.09593 0.923096C13.6098 0.923096 17.269 4.58231 17.269 9.09617Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CustomSvg>
  );
};

export default SearchIcon;
