export { BalanceType, CurrencyType, CurrencySymbol } from '@libs/types';

import { JurisdictionsEnum } from '@libs/backend-common';
import { BalanceType } from '@libs/types';

import { DEFAULT_JURISDICTION_ID } from './urls';

export const currencies = [
  {
    label: {
      text: BalanceType.USD,
    },
    value: BalanceType.USD,
  },
  JurisdictionsEnum.GE === DEFAULT_JURISDICTION_ID
    ? {
        label: {
          text: BalanceType.GEL,
        },
        value: BalanceType.GEL,
      }
    : {
        label: {
          text: BalanceType.EUR,
        },
        value: BalanceType.EUR,
      },
];

export enum CurrencyOrigin {
  FIAT = 'fiat',
  CRYPTO = 'crypto',
}
