import { useMemo } from 'react';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import { useTheme } from 'styled-components';

import dayjs from 'dayjs';

import { getChartDomain, getChartInterval } from '@libs/helpers';
import { formatDate, useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { CurrencySymbol } from '@libs/types';

import { ChartTooltip } from 'src/components';

export type ChartData = {
  createdAt?: Date | string;
  amount?: number | string;
};

type BalanaceFinancialChartProps<T> = {
  data?: T[];
  currencySymbol?: CurrencySymbol;
  fontSize?: number;
  fontHeight?: number;
  dateFormat?: string | ((val: string) => string);
  chartColor?: string;
  surfaceColor?: string;
  shouldHideAxisLines?: boolean;
  shouldShowGraph?: boolean;
  shouldHideXAxis?: boolean;
  shouldHideYAxis?: boolean;
  shouldHideTooltip?: boolean;
  xAxisMargin?: number;
  tooltipPrecision?: number;
  customMargin?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  shouldDetectColor?: boolean;
  tickInterval?: number;
};

const BalanaceFinancialChart = <T extends ChartData>({
  data,
  currencySymbol,
  dateFormat,
  chartColor,
  fontSize,
  fontHeight,
  customMargin,
  surfaceColor,
  shouldHideXAxis,
  shouldShowGraph = true,
  tooltipPrecision,
  shouldDetectColor,
  shouldHideYAxis,
  tickInterval,
  xAxisMargin,
  shouldHideTooltip = false,
}: BalanaceFinancialChartProps<T>) => {
  const { width } = useDimensions();
  const { colors } = useTheme();

  const secondaryColor = surfaceColor || colors.surface.default;

  const domain = useMemo(() => {
    if (!data || data.length === 0) return [0, 0];

    const numbersArray = data.map((e) => Number(e.amount));

    const min = Math.min(...numbersArray);
    const max = Math.max(...numbersArray);

    return getChartDomain(min, max);
  }, [data]);

  let color;

  if (chartColor) {
    color = chartColor;
  } else if (shouldDetectColor && data?.length) {
    if (Number([data[data.length - 1].amount]) > Number(data[0].amount)) {
      color = colors.text.success;
    } else {
      color = colors.text.critical;
    }
  } else {
    color = colors.interactive.brandColor;
  }

  const textProps = {
    fontSize: fontSize || 12,
    fontFamily: 'Satoshi-medium',
    height: fontHeight || 18,
  };

  const areaChartMargin = {
    top: 10,
    left: width < screenSizes.tablet ? 0 : 40,
    right: width < screenSizes.tablet ? 0 : 30,
    bottom: 0,
  };

  const margin = customMargin || areaChartMargin;

  const tickFormatter = (value: string | number) => {
    if (typeof value === 'string') value = +value;

    if (value > 1000) {
      value = (value / 1000).toFixed(2) + 'K';
    }

    return (currencySymbol || '') + value;
  };

  const xAxisTickFormatter = (val: string) => {
    if (!dateFormat) return val;

    if (typeof dateFormat === 'string') {
      return formatDate(dateFormat, dayjs(val));
    } else {
      return dateFormat(val);
    }
  };

  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data} margin={margin}>
        {shouldShowGraph && (
          <CartesianGrid stroke={colors.borders.default} strokeWidth={0.25} />
        )}
        <Area
          type="monotone"
          dataKey="amount"
          stroke={color}
          fill={`url(#${color}${secondaryColor})`}
          activeDot={{ r: 7.2 }}
        />
        <defs>
          <linearGradient
            id={`${color}${secondaryColor}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor={secondaryColor} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="createdAt"
          hide={shouldHideXAxis}
          tick={textProps}
          axisLine={false}
          tickLine={false}
          interval={getChartInterval(data.length, tickInterval)}
          tickFormatter={xAxisTickFormatter}
          includeHidden={false}
          domain={['auto', 'auto']}
          dx={xAxisMargin}
        />
        <YAxis
          orientation="right"
          hide={shouldHideYAxis}
          domain={domain}
          tickFormatter={tickFormatter}
          tick={textProps}
          tickLine={false}
          axisLine={false}
          allowDataOverflow={true}
        />
        {!shouldHideTooltip && (
          <Tooltip
            content={
              <ChartTooltip
                currencySymbol={currencySymbol}
                tooltipPrecision={tooltipPrecision}
              />
            }
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default BalanaceFinancialChart;
