import styled from 'styled-components';

import {
  Button,
  LoansIcon,
  TransparentButton,
  Typography,
} from '@libs/components';

export const LoanPageAdsContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;

  padding-bottom: 1rem;
  padding-top: 3.1rem;
  margin-top: 2.4rem;

  border-radius: ${({ theme }) => theme.borderRadius.high};

  background-color: ${({ theme }) => theme.colors.surface.default};
  box-shadow: ${({ theme }) => theme.shadows.xs};

  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;

    return theme.media.tabletL`
    
    margin-top: 0;

    box-shadow: ${shadow};
  `;
  }};
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  ${({ theme }) => theme.media.mobileS`
      padding: 3.1rem 2.2rem 2.2rem;
  `};
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
        padding: 2.1rem 3rem 2.4rem;

  `}
`;
export const Img = styled.img`
  max-height: 14rem;
  object-fit: contain;
  ${({ theme }) => theme.media.mobileL`
  max-height: 20rem;
    object-fit: none;
  `};
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;

  top: -1rem;
  left: calc(100% - 3.9rem);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.2rem;
  height: 3.2rem;

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.surface.default};

  ${({ theme }) => theme.media.tabletL`
      top: -1.6rem;
      left: calc(100% - 1.6rem);
  `};
`;

export const AdContainer = styled.div`
  display: flex;

  width: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};

  &:last-child {
    margin-top: 1.4rem;
  }
  flex-direction: column;
  align-items: baseline;
  ${({ theme }) => theme.media.tabletL`
      &:last-child {
        margin-left: 3rem;
        margin-top: 0;
      }
  `};
  ${({ theme }) => theme.media.mobileL`
      flex-direction: row;
      align-items:unset;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.1rem 1.4rem 1.5rem 1.4rem;

  ${({ theme }) => theme.media.tabletL`
      padding: 4.5rem 2.7rem 1.5rem;
  `};
`;

export const AdTitle = styled(Typography)`
  margin-bottom: 1.2rem;
`;
export const HeaderContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
  ${({ theme }) => theme.media.mobileS`
      padding:0;
  `};
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
        padding: 0 3rem 0;

  `}
`;
export const FirstContainer = styled.div`
  width: 100%;
  padding: 0;
  ${({ theme }) => theme.media.mobileS`
      padding:0 2.2rem;
  `};
  ${({ theme }) => theme.media.tabletL`
      width: 60%;
      padding:0;
  `}
`;
export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-right: 4rem;
  padding-top: 15rem;
  margin-top: 3.7rem;
  align-items: unset;
  padding-left: 0;
  ${({ theme }) => theme.media.mobileS`
      align-items:end;
        padding-right: 0;
        padding-left:5.5rem;
  `};
  ${({ theme }) => theme.media.tabletL`
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-top:0;
      align-items: unset;
  `}
`;
export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 2.9rem;
`;
export const CustomBtn = styled(Button)`
  margin-top: 4.7rem;
  width: 100%;
  display: block;
  ${({ theme }) => theme.media.mobileS`
      display:none;
      width:100%;
  `};
  ${({ theme }) => theme.media.tabletL`
    display: block;
      width:46rem;
  `}
`;
export const TransBottomCont = styled.div`
  display: flex;
  align-items: end;
  position: relative;
  width: 26rem;
  height: 17.4rem;
  padding: 0 0.5rem 2.7rem 3rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};

  ${({ theme }) => theme.media.mobileS`
    width:100%;
   border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  `};
`;
export const TransTopCont = styled.div`
  position: absolute;
  right: -4rem;
  top: -15.5rem;
  display: flex;
  width: 25rem;
  height: 25rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  box-shadow: 0px 24px 48px -12px #1018282e;
  background: ${({
    theme,
  }) => `linear-gradient(180deg, #286af6 0%, ${theme.colors.surface.black} 100%),
    linear-gradient(0deg, ${theme.colors.surface.black}, ${theme.colors.surface.black})`};
  padding: 0.1rem;
  margin: 0;
  ${({ theme }) => theme.media.mobileS`
    width:100%;
    margin: 0 2.2rem;
    left: -49px;
  `}
`;

export const TransInsideCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  background-color: ${({ theme }) => theme.colors.surface.default};
`;

export const CustomLoanIcon = styled(LoansIcon)`
  width: 10.5rem;
  height: 10.5rem;
`;

export const MobileCustomBtnCont = styled.div`
  display: block;
  ${({ theme }) => theme.media.mobileS`
    display:block;
    width:100%;
    padding: 2.4rem 3.1rem;
  `}
  ${({ theme }) => theme.media.tabletL`
    display: none;
      width:46rem;
  `}
`;

export const CheckIconsCont = styled.div`
  display: flex;
  margin-top: 2.9rem;
`;

export const StatusCont = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.8rem;
  &:last-child {
    margin: 0;
  }
`;
export const CheckBoxIconCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;
