import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

import { UserTokenPayload } from 'src/types';

export type TwoFaTokenPayload = {
  isSmsSecondFactorAuthenticated?: boolean;
  isSecondFactorAuthenticated?: boolean;
  isEmailSecondFactorAuthenticated?: boolean;
};
export type TwoFaReqArgs = {
  keepUser: boolean;
  tokenPayload: TwoFaTokenPayload;
  twoFactorCode: string;
};

// ----- Send SMS ------
type SendSmsTwoFaResponse = {
  sendSmsVerificationCode: DefaultApiResponse<boolean>;
};

export const sendSmsTwoFaMutation: TypedDocumentNode<SendSmsTwoFaResponse> = gql`
  mutation SendSmsVerificationCode($record: SmsVerificationCodeRecord!) {
    sendSmsVerificationCode(record: $record)
  }
`;

// ----- SMS Login -----
export type LoginWithSmsTwoFaResponse = {
  loginSms2FA: DefaultApiResponse<{ accessToken: string }>;
};
type LoginWithSmsTwoFaArgs = DefaultArg<TwoFaReqArgs>;

export const loginWithSmsTwoFaMutation: TypedDocumentNode<
  LoginWithSmsTwoFaResponse,
  LoginWithSmsTwoFaArgs
> = gql`
  mutation LoginSms2FA($record: TwoFactorLoginRecord!) {
    loginSms2FA(record: $record) {
      accessToken
    }
  }
`;

// ----- G2 Login -----
export type LoginWithG2FaResponse = {
  loginGoogle2FA: DefaultApiResponse<{ accessToken: string }>;
};
export type LoginWithG2FaArgs = DefaultArg<TwoFaReqArgs>;

export const loginWithG2FaMutation: TypedDocumentNode<
  LoginWithG2FaResponse,
  LoginWithG2FaArgs
> = gql`
  mutation LoginGoogle2FA($record: TwoFactorLoginRecord!) {
    loginGoogle2FA(record: $record) {
      accessToken
    }
  }
`;

// ----- Two factor toggle arg type -----
export type toggle2FaArgs = {
  twoFactorCode: string;
  googleTwoFactorCode?: string;
  emailTwoFactorCode?: string;
  smsTwoFactorCode?: string;
  tokenPayload?: UserTokenPayload;
};

export type twoFaResponse = {
  accessToken: string;
};
// ----- SMS deactivation -----
export type turnOffSms2FaResponse = {
  turnOffSms2FA: DefaultApiResponse<twoFaResponse>;
};
export type turnOffSms2FaArgs = DefaultArg<toggle2FaArgs>;
export const turnOffSms2FaMutation: TypedDocumentNode<
  turnOffSms2FaResponse,
  turnOffSms2FaArgs
> = gql`
  mutation TurnOffSms2FA($record: ToggleSms2FARecord!) {
    turnOffSms2FA(record: $record) {
      accessToken
    }
  }
`;

// ----- SMS activation -----
export type turnONSms2FaResponse = {
  turnOnSms2FA: DefaultApiResponse<twoFaResponse>;
};
export type turnONSms2FaArgs = DefaultArg<
  toggle2FaArgs & {
    phone?: string;
  }
>;
export const turnOnSms2FaMutation: TypedDocumentNode<
  turnONSms2FaResponse,
  turnONSms2FaArgs
> = gql`
  mutation TurnOnSms2FA($record: ToggleSms2FARecord!) {
    turnOnSms2FA(record: $record) {
      accessToken
    }
  }
`;

// ----- Email activation ----

export type TurnOnEmail2FAResponse = {
  turnOnEmail2FA: DefaultApiResponse<twoFaResponse>;
};

type TurnOnEmail2FAArgs = {
  emailCode: string;
  googleTwoFactorCode?: string;
  tokenPayload?: UserTokenPayload;
  email?: string;
};

export const turnOnEmail2FAMutation: TypedDocumentNode<
  TurnOnEmail2FAResponse,
  DefaultArg<TurnOnEmail2FAArgs>
> = gql`
  mutation TurnOnEmail2FA($record: ToggleEmail2FARecord!) {
    turnOnEmail2FA(record: $record) {
      accessToken
    }
  }
`;

export type TurnOffEmail2FAResponse = {
  turnOffEmailFA: DefaultApiResponse<twoFaResponse>;
};

export const turnOffEmail2FAMutation: TypedDocumentNode<
  TurnOffEmail2FAResponse,
  DefaultArg<TurnOnEmail2FAArgs>
> = gql`
  mutation TurnOffEmail2FA($record: ToggleEmail2FARecord!) {
    turnOffEmailFA(record: $record) {
      accessToken
    }
  }
`;
