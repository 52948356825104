import styled from 'styled-components';

import { Button, Typography } from '@libs/components';

export const BuyButton = styled(Button)`
  width: 19rem;
  height: 4rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  margin-top: 1.8rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: 1.8rem;
`;

export const ChangesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};
  padding: 2.2rem 1.6rem 2.2rem 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  margin-right: 1.6rem;
  height: 21rem;
  width: 22rem;
`;
export const AssetName = styled(Typography)`
  font-weight: 700;
`;
export const Img = styled.img`
  height: 3.8rem;
  width: 3.8rem;
`;
export const ChartWrapper = styled.div`
  width: 7.5rem;
  height: 3.8rem;
`;
export const IconAndChartWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
