import styled, { keyframes } from 'styled-components';

import { Button, Input, TableCell, TransparentButton } from '@libs/components';

import { EditIcon } from 'src/assets';

type CopyButtonType = {
  isCopied: boolean;
};

type EditButtonProps = {
  isEditOpen: boolean;
  length: number;
};

const iconSwitcher = keyframes`
  0% {
    opacity: 1;
    display: initial;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 0;
    display: none;
  }
`;

export const MultiAddressViewContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  padding: 1.2rem 1.6rem;

  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.mediumHigh};

  .modal-header {
    height: 7.4rem;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.5rem 1.8rem 2.5rem;
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};

  ${({ theme }) => theme.media.tablet`
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  `};
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const ImgContainer = styled.div`
  width: 2rem;

  margin-left: 0.5rem;
`;

export const AddressCopyContainer = styled(TransparentButton)<CopyButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 11.6rem;

  padding: 0 1.6rem;
  margin-right: 0.8rem;

  border: 1px solid
    ${({ theme, isCopied }) =>
      isCopied
        ? theme.colors.interactive.brandColor
        : theme.colors.borders.default};

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme, isCopied }) =>
    isCopied
      ? theme.colors.interactive.brandColor
      : theme.colors.surface.default};
`;

export const DetailsButton = styled(Button)`
  flex: 1;

  border-radius: ${({ theme }) => theme.borderRadius.medium} !important;
`;

export const AllAddress = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 4.6rem;

  ${({ theme }) => theme.media.tablet`
    margin-bottom: -4.6rem;
  `};
`;

export const GradientBackground = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  pointer-events: none;

  height: 10rem;
  width: 100%;

  background: ${({ theme }) =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.colors.surface.default} 100%)`};

  ${({ theme }) => theme.media.tablet`
    position: sticky;
  `};
`;

export const GenerateButton = styled(Button)`
  position: sticky;
  bottom: 1.6rem;
  left: 0;

  min-height: 6rem;

  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 3.5rem;
`;

export const CloseButton = styled(Button)`
  width: calc(100% - 7.4rem);

  margin: 0 3.7rem 2.8rem;
`;

export const CustomEditIcon = styled(EditIcon)`
  width: 1.5rem;
  height: 1.5rem;

  margin-left: 1rem;
`;

export const SaveButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 3.5rem;
  min-height: 3.5rem;

  border-radius: ${({ theme }) => theme.borderRadius.medium};

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const EditInput = styled.input`
  border: none;

  font-size: 1.2rem;
  line-height: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0.5rem 0;

  background-color: ${({ theme }) => theme.colors.surface.default};

  &:read-only {
    cursor: pointer;
    border-color: none;
  }
  width: -webkit-fill-available !important;
`;

export const EditButton = styled.div<EditButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: ${({ isEditOpen }) => (isEditOpen ? 'auto ' : 'none')};

  height: ${({ isEditOpen }) => (isEditOpen ? '5.2rem' : '3rem')};

  padding: 1.5rem 1.4rem;

  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};

  background-color: ${({ theme }) => theme.colors.surface.default};

  transition: height 0.5s linear, flex 0.5s linear;

  cursor: ${({ isEditOpen }) => (isEditOpen ? 'initial' : 'pointer')};

  ${SaveButton} {
    display: ${({ isEditOpen }) => (isEditOpen ? 'initial' : 'none')};
    opacity: ${({ isEditOpen }) => (isEditOpen ? 1 : 0)};

    animation: ${({ isEditOpen }) => (isEditOpen ? iconSwitcher : 'none')} 0.5s
      linear reverse;
  }

  ${CustomEditIcon} {
    display: ${({ isEditOpen }) => (isEditOpen ? 'none' : 'initial')};
    opacity: ${({ isEditOpen }) => (isEditOpen ? 0 : 1)};

    animation: ${({ isEditOpen }) => (isEditOpen ? iconSwitcher : 'none')} 0.5s
      linear;
  }

  ${EditInput} {
    width: ${({ isEditOpen, length }) =>
      isEditOpen ? 'initial' : (length + 5) / 2 + 'em'};

    color: ${({ theme, isEditOpen }) =>
      isEditOpen
        ? theme.colors.text.default
        : theme.colors.interactive.brandColor};

    transition: color 0.5s linear;
  }
`;

export const EditContainer = styled.div`
  position: absolute;
  top: 5rem;
  left: 0;

  display: flex;
  justify-content: center;

  width: 100%;

  padding: 0 2.2rem;

  ${({ theme }) => theme.media.tablet`
    top: 4rem;

    padding: 0 3.7rem;
  `};
`;

export const CustomTableCell = styled(TableCell)`
  margin-bottom: 2rem;
  .TableCellDescription {
    word-break: break-word;
  }

  ${({ theme }) => theme.media.tablet`
  margin-bottom: 1.6rem;
`};
`;

export const AddressHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const DeleteIconWrapper = styled.div`
  padding: 0.57rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12.7rem;
  height: 12.7rem;
  margin: 0 auto 4.2rem;
  margin-bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
`;

export const NewAddressGenerationWrapper = styled.div`
  ${({ theme }) => theme.media.tablet`
    width: 42rem;
`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.8rem;
`;

export const CustomInput = styled(Input)`
  margin-top: 3.2rem;
  margin-bottom: 2rem;
`;
