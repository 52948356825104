export type PasswordInfoProps = {
  value: string;
  shouldDisplayError?: boolean;
};

export enum PasswordInfoValidity {
  VALID = 1,
  INVALID = -1,
  UNTOUCHED = 0,
}

export type PasswordInfoState = {
  caseValidity: PasswordInfoValidity;
  // symbolValidity: PasswordInfoValidity;
  charLengthValidity: PasswordInfoValidity;
  containsNumberValidity: PasswordInfoValidity;
};

export type InfoRowProps = {
  title: string;
  testId: string;
  validity: PasswordInfoValidity;
};
