import ContentLoader from 'react-content-loader';
import styled, { css } from 'styled-components';

type LoaderType = {
  size: number;
  showGreeting?: boolean;
  hasButton?: boolean;
};

export const Loader = styled(ContentLoader)<LoaderType>`
  height: ${({ size }) => (size < 6.6 ? 6.6 : size)}rem;
  width: 100%;
`;

export const ImgLoader = styled.circle<LoaderType>`
  ${({ size }) =>
    css`
      r: ${size / 2}rem;
      cx: ${size / 2}rem;
      cy: 50%;
    `}
`;

export const GreetingLoader = styled.rect<LoaderType>`
  height: 2rem;
  width: 9rem;

  y: calc(50% - 3.1rem);
  x: ${({ size }) => size + 0.8}rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const NameLoader = styled.rect<LoaderType>`
  height: 2rem;
  width: 10rem;

  y: ${({ showGreeting }) =>
    showGreeting ? 'calc(50% - 0.7rem)' : 'calc(50% - 1.9rem)'};
  x: ${({ size }) => size + 0.8}rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;

export const UserIdLoader = styled.rect<LoaderType>`
  height: 1.6rem;
  width: 8rem;

  y: ${({ showGreeting }) =>
    showGreeting ? 'calc(50% + 1.6rem)' : 'calc(50% + 0.4rem)'};
  x: ${({ size }) => size + 0.8}rem;

  rx: ${({ theme }) => theme.borderRadius.medium};
  ry: ${({ theme }) => theme.borderRadius.medium};
`;
