import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Typography, useStatusInfo } from '@libs/components';
import {
  CurrencyType,
  TransactionStatusType,
  TransactionType,
} from '@libs/types';
import { formatDate } from '@libs/hooks';
import { convertExponentialToDecimal, formatBalance } from '@libs/helpers';

import { getTransactionDetailsQuery } from 'src/gql';
import { useApiResponse, useModal, useTranslation } from 'src/hooks';
import { getAlternativeAssetLabel } from 'src/helpers';
import { TRANSACTION_LABELS } from 'src/constants';
import { DASHBOARD_NAV_WIDTH } from 'src/components/dashboard-nav/DashboardNav';

import {
  AssetIcon,
  CustomOptionList,
  StatusWrapper,
  TransactionDetails,
} from '../styles';
import { InfoFieldDescription } from '../components/InfoFieldDescription';

export const useTransactionsModal = (modalLeftSpace = DASHBOARD_NAV_WIDTH) => {
  const { Dialog, openModal } = useModal();
  const { t } = useTranslation();

  const { getStatusData } = useStatusInfo();

  const [transactionDetails, { data: DetailsData, loading }] = useLazyQuery(
    getTransactionDetailsQuery
  );

  const { response } = useApiResponse({
    data: DetailsData,
    loading,
  });

  const data = useMemo(
    () => response?.getTransactionDetails?.[0]?.details,
    [response]
  );

  const formattedRate =
    data?.rate && data?.rate > 1
      ? formatBalance(convertExponentialToDecimal(data?.rate))
      : convertExponentialToDecimal(data?.rate || 0);

  const handleTransactionDetails = useCallback(
    (index: number) => {
      transactionDetails({
        variables: { record: { transactionId: index } },
      });
    },
    [transactionDetails]
  );

  const modifiedDate = useMemo(() => {
    const date = new Date(data?.date || '');

    return formatDate('D MMM YYYY HH:mm', date);
  }, [data]);

  const infoFields = useMemo(() => {
    const tempData = [];

    const shouldCopyButtonHide = [
      CurrencyType.GEL,
      CurrencyType.EUR,
      CurrencyType.USD,
    ].includes(data?.assetCode as CurrencyType);

    if (data) {
      data.type &&
        tempData.push({
          title: t('transaction_invoice_type'),
          description: t(TRANSACTION_LABELS[data.type]),
        });
      tempData.push({
        title: t('transaction_invoice_id'),
        description: data.internalOperationId,
      });
      tempData.push({
        title: t('transaction_invoice_time'),
        description: modifiedDate,
      });
      tempData.push({
        title: t('transaction_invoice_amount'),
        description: `${data.amount} ${getAlternativeAssetLabel(
          data.assetCode
        )}`,
      });
      !!data.destination &&
        tempData.push({
          title: t('transaction_invoice_destination'),
          description: (
            <InfoFieldDescription
              shouldCopyButtonHide={shouldCopyButtonHide}
              shouldShorten={false}
              data={data.destination as string}
            />
          ),
        });
      !!data.blockchainTransactionId &&
        tempData.push({
          title: t('transaction_invoice_blockchain_id'),
          description: (
            <InfoFieldDescription
              data={data.blockchainTransactionId as string}
            />
          ),
        });
      !!data.network &&
        tempData.push({
          title: t('transaction_invoice_network'),
          description: `${data.network}`,
        });
      !!data.quoteAmount &&
        tempData.push({
          title:
            data.type === TransactionType.Buy
              ? t('transaction_invoice_price')
              : t('transaction_invoice_received_amount'),
          description: `${data.quoteAmount} ${getAlternativeAssetLabel(
            data.quoteAssetCode
          )}`,
        });
      !!data.fee &&
        tempData.push({
          title: t('transaction_invoice_fee'),
          description: `${data.fee} ${getAlternativeAssetLabel(
            data.feeAssetCode
          )}`,
        });

      !!data.rate &&
        tempData.push({
          title: t('transaction_invoice_rate'),
          description: `1 ${getAlternativeAssetLabel(
            data.assetCode
          )} ≈ ${formattedRate} ${getAlternativeAssetLabel(
            data.quoteAssetCode
          )}`,
        });
    }
    return tempData;
  }, [data, t, modifiedDate, formattedRate]);

  const transactionModal = useMemo(() => {
    if (!data) return;

    const transactionStatus =
      data.status === TransactionStatusType.DRAFT
        ? getStatusData(TransactionStatusType.PENDING)
        : getStatusData(data.status);

    return (
      <Dialog
        leftSpace={modalLeftSpace}
        titleIcon={<AssetIcon src={data.assetIconUrl} />}
        title={data.assetLabel}
      >
        <TransactionDetails>
          <StatusWrapper>
            <Typography
              type="subHeadline"
              fontFamily="primaryBold"
              color={transactionStatus.colors[0]}
            >
              {t(transactionStatus.text)}
            </Typography>
          </StatusWrapper>
          <CustomOptionList options={infoFields} fontType="bodyButton" />
          {/* TODO: implement PDF download */}
          {/* <DownloadButton title={t('transaction_receipt')} /> */}
        </TransactionDetails>
      </Dialog>
    );
  }, [Dialog, infoFields, data, modalLeftSpace, getStatusData, t]);

  return {
    transactionModal,
    openModal,
    handleTransactionDetails,
  };
};
